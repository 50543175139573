import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
} from 'providers/axios';

export class ChatProvider {

  static async getToken() {

    try {

      const response = await get(`${Environment.apiHost}twilio-auth`);

      return resolveResponse(response);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }
}
