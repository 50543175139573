import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Images,
  Image,
} from 'common';

import {
  Text,
} from 'common/components';

const Container = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const MenuText = styled(Text)`
  text-align: center;

  @media ${DeviceMediaQueries.Tablet} {
    text-align: left;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuHeaderMobilePlaceholder = styled.div`
  display: block;
  width: 25px;

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

export const ProjectsSideMenuItem = props => {

  const onProjectClick = () => {
    props.onClick && props.onClick(props.onClickArgs);
  };

  return (

    <Container
      onClick={onProjectClick}>

      <MenuHeader>

        <MenuHeaderMobilePlaceholder/>

        <MenuText
          color={Colors.White}
          fontSize={FontSizes.Regular}
          padding={'0 0 5px 0'}>
            
            {props.title || 'New project'}

            { props.isMain &&

              <Text
                color={Colors.White}
                fontSize={FontSizes.Smallest}>
                  &nbsp;(main)
              </Text>
            }

        </MenuText>

        <Image
          src={Images.Icons.ArrowRight}
          padding={'0 0 0 5px'}
          height={'25px'}
          width={'25px'}
        />

      </MenuHeader>

      <MenuText
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>
          
          {props.nature || 'Not specified'}
      </MenuText>

      <MenuText
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>
          
          {props.industry || 'Not specified'}
      </MenuText>

      <MenuText
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>
          
          {props.country || 'Not specified'}
      </MenuText>

      <MenuText
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>
          
          {props.stage || 'Not specified'}
      </MenuText>

      <MenuText
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>
          
          {props.goal || 'Not specified'}
      </MenuText>

    </Container>
  );
};