import axios from 'axios';

import {
  DataStore,
  Environment,
  Logger,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
} from 'providers/axios';

export class LookupProvider {

  static async loadAllLookups() {

    try {

      let res = await axios.get(`${Environment.configHost}lookups.json?v=${new Date().getTime()}`);

      let ret = resolveResponse(res);

      if (!ret.ok) {
        return null;
      }

      DataStore.set(
        'LOOKUPS',
        ret.data
      );

      return ret.data;

    } catch (ex) {

      Logger.error(ex);

      return {};
    }
  }

  static async getAllLookups() {

    try {

      const lookups = DataStore.get('LOOKUPS');

      if (lookups) {
        return lookups;
      }

      return await LookupProvider.loadAllLookups();

    } catch (ex) {

      Logger.error(ex);

      return {};
    }
  }

  static async getLanguages() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.languages) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getCountries(includeHidden) {

    try {

      const lookups = await LookupProvider.getAllLookups();

      const countries = (lookups && lookups.countries) || [];

      return countries.filter(c => {
        return includeHidden || !c.hidden;
      });

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectCountries() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.projectCountries) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getDegreeLevels() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.degreeLevel) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getMonths() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.months) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getEmploymentTypes() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.employmentTypes) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getSkills() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.skills) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectNatureList() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.projectNatures) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectTypes() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.projectTypes) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectStages() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.projectStages) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectIndustries() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.projectIndustries) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getEmployeeRoles() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.employeeRoles) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getEmployeeEmploymentTypes() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.employeeEmploymentType) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getEmployeeCompensations() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.employeeCompensation) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getAdviceTopics() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.adviceTopic) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getAdviceFrequency() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.adviceFrequency) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getAdviceContactMethods() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.adviceContactMethod) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getCurrencies() {

    try {

      const lookups = await LookupProvider.getAllLookups();

      return (lookups && lookups.currencies) || [];

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getLinkedAccounts() {

    try {

      const res = await get(`${Environment.apiHost}linked-accounts-lookup`);

      let ret = resolveResponse(res);

      if (!ret.ok || !Array.isArray(ret.data)) {

        Logger.error(ret.message || 'Unable to load linked accounts lookup');

        return [];
      }

      const list = ret.data.map(item => {

        return {
          ...item,
          display: item.name,
          value: item.id,
        };
      });

      list.push({
        display: 'Other',
        value: 'OTHER',
      });

      return list;

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}
