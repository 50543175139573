import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import {
  IsMobile,
} from 'common';

import {
  Button,
} from 'common/components';

import {
  MatchesSideMenu,
} from 'ui/matches/components';

import {
  Container,
  ContentContainer,
  LeftContainer,
  RightContainer,
} from 'ui/matches/components/matchesComponentsStyled';

export const withMatchesSideMenu = (WrappedComponent, options = {}) => {

  const {
    hideSideMenuOnMobile = false,
    clearSideMenu = false,
  } = options;

  class Component extends React.Component {

    state = {
      skipReload: false,
      filters: {},
      containerLeft: 0,
      currentContainer: undefined,
      hideContent: false,
      matchTotals: {},
    };

    locationListener = null;

    onSearchClick = (filters, skipReload) => {

      // used as next tick, please leave this here
      setTimeout(() => {
        this.setState({
          skipReload: !!skipReload,
          filters,
        });
      });
    };

    onMatchTotalsChange = (val, reload) => {

      const matchTotals = this.state.matchTotals;

      if (!reload && matchTotals.actionedCount > val.actionedCount) {
        return;
      }

      this.setState({
        matchTotals: val,
      });
    };

    onAddOneReviewed = () => {

      const matchTotals = this.state.matchTotals;

      matchTotals.actionedCount = matchTotals.actionedCount + 1;

      this.setState({
        matchTotals,
      });
    };

    componentWillUnmount() {

      this.locationListener && this.locationListener();
    }

    componentDidMount() {

      if (!IsMobile) {
        return;
      }

      if (hideSideMenuOnMobile) {

        let element = document.getElementById('matches-content-container');

        this.setState({
          containerLeft: -element.offsetLeft,
          currentContainer: 'right',
          hideContent: false,
        });

        return;
      }

      this.locationListener = this.props.history.listen((location) => {

        if (!location) {
          return;
        }

        let mainElement = document.getElementById('matches-main-container');

        mainElement.scrollTop = 0;
  
        if (location.hash === '#matches-side-container') {
  
          this.setState({
            containerLeft: 0,
            currentContainer: 'left',
            hideContent: true,
          });
        }

        if (location.hash === '#matches-content-container') {
  
          let element = document.getElementById('matches-content-container');

          this.setState({
            containerLeft: -element.offsetLeft,
            currentContainer: 'right',
            hideContent: false,
          });
        }
      });

      if (this.props.history.location.hash === '#matches-content-container') {

        let element = document.getElementById('matches-content-container');

        this.setState({
          containerLeft: -element.offsetLeft,
          currentContainer: 'right',
          hideContent: false,
        });

        return;
      }

      this.props.history.push('#matches-side-container');
    }

    render() {
    
      return (

        <Container
          id={'matches-main-container'}>

          <ContentContainer>
    
            <LeftContainer
              id={'matches-side-container'}
              style={{ display: (this.state.currentContainer && this.state.currentContainer !== 'left') && 'none' }}>

                { clearSideMenu
                
                  ? <div style={{ position: 'relative', padding: '30px 15px' }}>

                      <Button
                        text={'Back to matches'}
                        width={'100%'}
                        onClick={() => { this.props.history.goBack(); }}
                      />

                    </div>
                
                  : <MatchesSideMenu
                      matchTotals={this.state.matchTotals}
                      onSearchClick={this.onSearchClick}
                    />
                }
    
            </LeftContainer>
    
            <RightContainer
              id={'matches-content-container'}
              style={{ display: (this.state.currentContainer && this.state.currentContainer !== 'right') && 'none' }}>
    
              { !this.state.hideContent &&

                <WrappedComponent
                  {...this.props}
                  skipReload={this.state.skipReload}
                  filters={this.state.filters}
                  onMatchesLoaded={this.onMatchTotalsChange}
                  onAddOneReviewed={this.onAddOneReviewed}
                />
              }
    
            </RightContainer>

          </ContentContainer>
  
        </Container>
      );
    }
  }

  return withRouter(Component);
};