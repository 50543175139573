import
  React
from 'react';

import {
  Colors,
  TextInputContainer,
  formatDateForDisplay,
} from 'common';

import {
  Accordion,
  PSmallWhite,
} from 'common/components';

import {
  FormContainer,
  TextContainer,
} from './projectsComponentsStyled';

export const EmployeeForm = ({
  e,
  color,
  padding,
  compensations,
}) => (

  <FormContainer
    padding={padding}>

    <TextInputContainer
      padding={'0 0 15px 0'}>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Position
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(e.role && e.role.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Work type
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(e.employmentType && e.employmentType.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Compensation
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(compensations.length && compensations) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

    <TextInputContainer
      padding={'0'}>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

          Hire Location
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(e.country && e.country.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Starting date
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {formatDateForDisplay(e.requiredDate) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer/>

    </TextInputContainer>

    <TextInputContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Further information
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {e.description || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

  </FormContainer>
);

export const EmployeeGoalDisplay = ({
  project,
  padding,
  color = Colors.Black,
  showHeader = true,
}) => {

  const employees = project.project_employees || [];

  const cards = employees.map((e, index) => {

    const cs = e.compensations || [];

    const compensations = cs.map(c => {

      return (
        <div key={c.value}>{c.display}</div>
      );
    });

    return (

      <React.Fragment key={index}>

      { showHeader &&

        <Accordion
          header={'Hiring goal'}
          headerColor={Colors.White}
          backgroundColor={Colors.Blue3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}
          isCollapsable={true}
          showArrow={true}>
            
            <EmployeeForm
              e={e}
              color={color}
              padding={padding}
              compensations={compensations}
            />

        </Accordion>
      }

      { !showHeader &&

        <EmployeeForm
          e={e}
          color={color}
          padding={padding}
          compensations={compensations}
        />
      }

      </React.Fragment>
    );
  });

  return (

    <React.Fragment>

      {cards}

    </React.Fragment>
  );
};