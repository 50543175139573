import
  React
from 'react';

import
  ReactDOM
from 'react-dom';

import
  * as Sentry
from '@sentry/react';

import {
  Integrations
} from '@sentry/tracing';

import './index.css';

import
  App
from './App';

import
  reportWebVitals
from './reportWebVitals';

import {
  Environment,
  Images,
} from 'common';

import {
  Version,
} from 'generated/constants';

Sentry.init({
  dsn: 'https://f75138224f3544c68fdf1a9bae7335e5@o543746.ingest.sentry.io/5667966',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
  maxBreadcrumbs: 20,
  environment: Environment.name,
  release: `aon-ui-pwa@${Version}`,
});

let images = [];

const preCacheAssets = (catalogue) => {

  if (!catalogue) {
    return;
  }

  let image;

  for (const val of Object.values(catalogue)) {

    if (typeof val !== 'string') {

      preCacheAssets(val);
      continue;
    }

    image = new Image();
    image.src = val;
    images.push(image);
  }
};

preCacheAssets(Images);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();