import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  MenuItems,
  ErrorToast,
  Logger,
  Messages,
  IsMobile,
  SafeAreas,
  isValidProviderResult,
} from 'common';

import {
  Loader,
  Text,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  MatchGrid,
} from 'ui/matches/components';

import {
  withMatchesSideMenu,
} from 'ui/matches/hocs';

import {
  ProjectsProvider,
} from 'providers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 65px 15px;
  margin-bottom: ${SafeAreas.bottom};
  position: relative;

  @media ${DeviceMediaQueries.LaptopL} {
    padding: 10px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 55px;
  width: 40px;

  @media ${DeviceMediaQueries.LaptopL} {
    height: 50;
  }
`;

class MatchesViewUI extends React.Component {

  state = {
    matches: DataStore.get('matchesList') || [],
    isLazyLoading: true,
  };

  matchesCount = DataStore.get('matchesCount') || {};
  pageIndex = DataStore.get('matchesPageIndex') || 0;
  lastCallId = null;

  scrollElement = null;

  onFullProfileClick = (project) => {

    DataStore.set(
      'selectedMatch', {
        projectId: project.projectId,
        profileScore: project.profileRating,
      },
    );

    DataStore.set(
      'matchesList',
      this.state.matches,
    );

    DataStore.set(
      'matchesPageIndex',
      this.pageIndex,
    );

    DataStore.set(
      'matchesScrollTop',
      this.scrollElement.scrollTop
    );

    DataStore.set(
      'matchesCount',
      this.matchesCount,
    );

    ProjectsProvider.projectViewed(this.props.common.user.id, project.projectId)
      .catch(e => {});

    this.props.common.navigate('/matches/view/profile');
  };

  onProjectsScroll = (e) => {

    if (window.location.hash === '#matches-side-container') {
      return;
    }

    const target = !IsMobile
      ? e.target
      : e.target.documentElement;

    const ratio = IsMobile ? 6 : 3;
    const currentScrollPosition = target.scrollTop + (target.clientHeight * ratio);

    if (!this.state.isLazyLoading && target.scrollHeight < currentScrollPosition) {

      if (((this.state.matches.length % 9) === 0 && (this.state.matches.length / 9) === this.pageIndex)) {

        this.setState({
          isLazyLoading: true
        });
      }

      this
        .load()
        .catch(ex => {

          ErrorToast.show();

          Logger.error(ex);
        });
    }
  };

  removeMatchFromList = (projectId) => {

    if (!projectId || !Array.isArray(this.state.matches)) {
      return;
    }

    let match = this.state.matches.find(match => match.projectId === projectId);

    if (!match) {
      return;
    }

    match.hidden = true;

    this.props.onAddOneReviewed && this.props.onAddOneReviewed();

    this.forceUpdate();
  };

  load = async (forceReload) => {

    this.lastCallId = new Date().getTime();

    let shouldLoad = forceReload
      || ((this.state.matches.length % 9) === 0
      && (this.state.matches.length / 9) === this.pageIndex);

    if (!shouldLoad) {

      this.state.isLazyLoading && this.setState({
        isLazyLoading: false,
      });

      this.props.common.hideLoading();
      return;
    }

    let ret = await ProjectsProvider.searchProject({
      ...this.props.filters,
      page: this.pageIndex,
      pageSize: 9,
      callId: this.lastCallId,
    });

    if (ret.callId !== this.lastCallId) {
      return;
    }

    this.pageIndex++;

    this.props.common.hideLoading();

    if (!isValidProviderResult(ret) || !Array.isArray(ret.data.cards)) {

      this.props.common.hideLoading();

      ErrorToast.show();

      Logger.error(ret.error || Messages.TechnicalError);

      this.setState({
        isLazyLoading: false,
      });

      return;
    }

    const matches = forceReload
      ? ret.data.cards
      : [...this.state.matches, ...ret.data.cards];

    this.setState({
      matches,
      isLazyLoading: false,
    });

    this.matchesCount = ret.data.matchesCount || {};
    this.props.onMatchesLoaded && this.props.onMatchesLoaded(ret.data.matchesCount || {}, forceReload);
  };

  componentDidMount() {

    this.scrollElement = !IsMobile
      ? document.getElementById('matches-content-container')
      : document;

    MatchesViewUI.top = DataStore.get('matchesScrollTop') || 0;
    this.props.onMatchesLoaded && this.props.onMatchesLoaded(this.matchesCount || {}, false);

    if (this.scrollElement) {
      this.scrollElement.addEventListener('scroll', this.onProjectsScroll, { passive: true });
    }

    DataStore.clear('matchesList');
    DataStore.clear('matchesPageIndex');
    DataStore.clear('matchesScrollTop');
    DataStore.clear('matchesCount');

    this.props.common.showLoading();
  }

  componentWillUnmount() {

    if (this.scrollElement) {
      this.scrollElement.removeEventListener('scroll', this.onProjectsScroll);
    }

    MatchesViewUI.top = 0;

    this.lastCallId = null;
  }

  componentDidUpdate(prevProps) {

    if (prevProps.filters !== this.props.filters) {

      this.props.common.showLoading({
        showSideMenu: true,
      });

      if (!this.props.skipReload) {
        this.pageIndex =  0;
      }

      this
        .load(!this.props.skipReload)
        .then(() => {

          if (this.scrollElement) {
            
            this.scrollElement.scrollTop = !this.props.skipReload
              ? 0
              : MatchesViewUI.top || 0;
          }
        })
        .catch(e => {

          ErrorToast.show();

          Logger.error(e);

          this.props.common.hideLoading();
        });
    }
  }

  render () {

    return (

      <Container>

        <MatchGrid
          matches={this.state.matches}
          onRemoveMatch={this.removeMatchFromList}
          onFullProfileClick={this.onFullProfileClick}
        />

        { this.state.isLazyLoading &&
        
          <LoadingContainer>

            <Loader
              backgroundColor={Colors.Gray1}
              margin={'0'}
              size={40}
            />

          </LoadingContainer>
        }

        { !this.state.isLazyLoading && !this.props.filters.showSaved && !!this.state.matches.length &&
        
        <Text
          width={'100%'}
          textAlign={'center'}
          padding={'20px 0 0 0'}>
            There are no more projects to display.<br />
            Please try checking again later.<br />
            <br />
            We will notify you as soon as some great projects become available.
        </Text>
        }

      </Container>
    );
  }
}

export const MatchesView = withCommon(
  withMatchesSideMenu(MatchesViewUI), {
    selectedMenuItem: MenuItems.Matches,
    sideMenuActionUrl: '/matches/view#matches-side-container',
  }
);