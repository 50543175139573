import {
  AccountRoutes,
  HomeRoutes,
  IdentityRoutes,
  ProjectsRoutes,
  MatchesRoutes,
  ContactsRoutes,
  PaymentRoutes,
} from 'navigation/routes';

export const Routes = {
  ...AccountRoutes,
  ...HomeRoutes,
  ...IdentityRoutes,
  ...ProjectsRoutes,
  ...MatchesRoutes,
  ...ContactsRoutes,
  ...PaymentRoutes,
};