import {
  Images,
} from 'common/constants';

import {
  DataStore,
} from 'common/data';

import {
  Logger,
} from 'common/logger';

import {
  Environment,
} from 'common/environment';

export function resolveResponse(res) {

  if (!res) {

    return {
      ok: false,
      message: 'Invalid response',
      data: null,
    }
  }

  let ok = res.status >= 200 && res.status < 300;

  return {
    ok: ok,
    message: (res.data && res.data.message) || '',
    data: (res.data && res.data.data) || res.data || {},
    status: res.status
  }
}

export function resolveRejected(res) {

  let err;

  if (
    res.response
    && res.response.data
    && res.response.data.message
  ) {
    err = res.response.data.message;
  }

  Logger.error(res);

  return {
    ok: false,
    message: err || 'Unfortunately a technical error occurred',
    data: res.response && res.response.data,
    status: res.response && res.response.status
  };
}

export function getQueryParameterByName(name, url = window.location.href) {

  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');

  var regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

  const results = regex.exec(url);

  if (!results || !results.length > 2) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function generateYearArray(yearFrom, yearTo, toString = false) {

  if (!yearTo) {
    yearTo = new Date().getFullYear();
  }

  if  (!yearFrom) {
    yearFrom = 1950;
  }

  let yearList = [];
  let val;

  for (let i = yearTo; i >= yearFrom; i--) {

    val = !toString ? i : i.toString();

    yearList.push({
      display: val,
      value: val,
    });
  }

  return yearList;
}

export function getLookupDisplayByValue(lookupKey, value) {

  const lookups = DataStore.get('LOOKUPS');

  const lookup = lookups[lookupKey];

  if (!Array.isArray(lookup)) {
    return '';
  }

  let current;

  for (let i = 0; i < lookup.length; i++) {

    current = lookup[i];

    if (current.value === value) {
      return current.display;
    }
  }

  return '';
}

export function addColorTransparency(hex, alpha) {

  if (alpha === null || alpha === undefined) {
    return hex;
  }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const colors = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;

  if (!colors) {
    return hex;
  }

  return `rgba(${colors.r},${colors.g},${colors.b},${alpha})`;
}

export function generateLinkedInLoginUrl(fromPayment) {

  const clientId = '77cakvzdim6v51';

  const state = 'woeifhjseirhtoiaserhts';

  const redirectUrl = encodeURIComponent(`${Environment.uiHost}identity/linkedin/auth${fromPayment ? '?fromPayment=true' : ''}`);

  return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}&scope=r_liteprofile%20r_emailaddress`;
}

export function getFileExtension(
  url,
) {

  if (!url || typeof url !== 'string') {
    return null;
  }

  return url.split('.').pop().toLowerCase();
}

export function getImageByExtension(
  fileUrl,
) {

  if (!fileUrl || typeof fileUrl !== 'string') {
    return fileUrl;
  }

  switch (fileUrl.split('.').pop().toLowerCase()) {
    case 'doc':
    case 'docx':
      return Images.Icons.DocPlaceholder;

    case 'pdf':
      return Images.Icons.PdfPlaceholder;

    default:
      return fileUrl;
  }
}

export function formatDateForDisplay(dateString) {

  if (!dateString) {
    return null;
  }

  let d = new Date(dateString),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;

  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('/');
}

export function formatDateTimeForDisplay(dateTimeString) {

  if (!dateTimeString) {
    return null;
  }

  let time = ' ';
  
  let hours = dateTimeString.getHours();

  if(hours < 10)
  hours = '0' + hours; 

  let minutes = dateTimeString.getMinutes();

  if(minutes < 10)
  minutes = '0' + minutes;

  time = " " + hours + ":" + minutes;

  return formatDateForDisplay(dateTimeString) + time;
}

export function formatStatForDisplay(num) {

  if (!num) {
    return 0;
  }

  let toTest = num / 1000000;

  if (toTest >= 1) {
    return `${toTest.toFixed(1)}M`;
  }

  toTest = num / 100000;

  if (toTest >= 1) {
    return `${(num / 1000).toFixed(0)}K`;
  }

  toTest = num / 1000;

  if (toTest >= 1) {
    return `${toTest.toFixed(1)}K`;
  }

  return num.toString();
}

export function imageFileToCompressedBlob(
  file,
  callback,
) {

  let img = document.createElement('img');

  img.src = URL.createObjectURL(file);

  img.onload = function() {
   
    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 800;

    let width = img.width;
    let height = img.height;

    if (width > height) {

      if (width > MAX_WIDTH) {

        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }

    } else {

      if (height > MAX_HEIGHT) {

        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    let canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    let dataurl = canvas.toDataURL('image/jpeg');

    var byteString = atob(dataurl.split(',')[1]);

    var mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    callback && callback(new Blob([ab], {
      type: mimeString
    }));
  }
}

export function convertURIToBlob(dataURI, mimeType) {

  let BASE64_MARKER = ';base64,';
  let markerIndex = dataURI.indexOf(BASE64_MARKER);

  let base64 = markerIndex === -1
    ? dataURI
    : dataURI.substring(markerIndex + BASE64_MARKER.length);

  let raw = window.atob(base64);
  let rawLength = raw.length;
  let arr = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    arr[i] = raw.charCodeAt(i);
  }

  return new Blob([arr], {
    type: mimeType
  });
}

export function blobToBase64(blob) {

  return new Promise((resolve, reject) => {

    const reader = new FileReader();
    reader.onerror = reject;
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
