import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  Image,
  Images,
  SafeAreas,
  addColorTransparency,
  getImageByExtension,
  getFileExtension,
} from 'common';

const Overlay = styled.div`
  background-color: ${addColorTransparency(Colors.Black, 0.7)};
  position: fixed;
  top: ${SafeAreas.top};
  bottom: ${SafeAreas.bottom};
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 80%;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    height: 70%;
    width: 80%;
  }
`;

const ImageContainer = styled.div`
  background-color: ${Colors.Black};
  display: flex;
  height: 100%;
  width: 85%;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media ${DeviceMediaQueries.Tablet} {
    width: 90%;
  }
`;

const IFrame = styled.iframe`
  border: 1px solid ${Colors.Gray2};
  height: 100%;
  width: 80%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 90%;
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  padding: 15px 0 0 0;
`;

const ThumbnailImage = styled.div`
  border: 1px solid ${Colors.Gray2};
  cursor: pointer;
  position: relative;
  margin: 0 15px 0 0;
  height: 50px;
  width: 50px;
  background-color: ${addColorTransparency(Colors.White, 0.2)};
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  :last-child {
    margin: 0;
  }

  @media ${DeviceMediaQueries.Tablet} {
    height: 100px;
    width: 100px;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 15px 0;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 70%;
  }
`;

export const OverlayImageGallery = props => {

  const [state, setState] = React.useState({
    selectedImage: null,
    selectedIndex: 0,
  });

  const onKeyDown = (event) => {

    // we need to hide this when escape is pressed, a requirement
    if(event.keyCode === 27) {
      typeof props.onClose === 'function' && props.onClose();
    }
  }

  const getFileComponent = (url) => {

    let extension = getFileExtension(url) || '';

    if (extension === 'docx' || extension === 'doc') {

      let docUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${url}`;

      return <IFrame onClick={(e) => e.stopPropagation()} title='Document viewer' src={docUrl}>Loading document...</IFrame>;
    }

    if (extension === 'pdf') {

      let docUrl = `https://docs.google.com/gview?url=${url}&embedded=true`;

      return <IFrame onClick={(e) => e.stopPropagation()} title='Document viewer' src={docUrl}>Loading document...</IFrame>;
    }

    return <ImageContainer
      onClick={(e) => e.stopPropagation()}
      src={url}
    />;
  };

  React.useEffect(() => {

    if (!props.show) {

      setState(prevProps => ({
        ...prevProps,
        selectedImage: null,
        selectedIndex: 0,
      }));

      return;
    }

    setState(prevProps => ({
      ...prevProps,
      selectedImage: getFileComponent(props.images[props.selectedIndex].url),
      selectedIndex: props.selectedIndex,
    }));

  }, [props]);

  React.useEffect(() => {

    document.addEventListener('keydown', onKeyDown, false);

    return () => {

      document.removeEventListener('keydown', onKeyDown, false);
    };

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.show || !Array.isArray(props.images)) {
    return null;
  }

  const onArrowRightClick = (e) => {

    e.stopPropagation();

    let newIndex = state.selectedIndex + 1;

    if (newIndex < props.images.length) {

      let component = getFileComponent(props.images[newIndex].url);

      setState(prevProps => ({
        ...prevProps,
        selectedImage: component,
        selectedIndex: newIndex,
      }));
    }
  };

  const onArrowLeftClick = (e) => {

    e.stopPropagation();

    let newIndex = state.selectedIndex - 1;

    if (newIndex >= 0) {

      let component = getFileComponent(props.images[newIndex].url);

      setState(prevProps => ({
        ...prevProps,
        selectedImage: component,
        selectedIndex: newIndex,
      }));
    }
  };

  const onThumbnailImageClick = (url, index) => {

    let component = getFileComponent(url);

    setState(prevProps => ({
      ...prevProps,
      selectedImage: component,
      selectedIndex: index,
    }));
  };

  const onCloseClick = (e) => {

    e.stopPropagation();

    typeof props.onClose === 'function' && props.onClose();
  };

  let images = [];

  for (let i = 0; i < props.images.length; i++) {

    const image = props.images[i];

    if (!image || !image.url) {
      continue;
    }

    images.push(
      <ThumbnailImage
        key={i}
        src={getImageByExtension(image.url)}
        onClick={(e) => { e.stopPropagation(); onThumbnailImageClick(image.url, i); }}
      />
    );
  }

  return (

    <Overlay
      onClick={onCloseClick}>

      <CloseContainer>

        <Image
          src={Images.Icons.Close}
          height={'20px'}
          onClick={onCloseClick}
        />

      </CloseContainer>

      <ContentContainer>

        <Image
          src={Images.Icons.ArrowRight}
          height={'20px'}
          transform={'rotate(180deg)'}
          onClick={onArrowLeftClick}
        />

        {state.selectedImage}

        <Image
          src={Images.Icons.ArrowRight}
          height={'20px'}
          onClick={onArrowRightClick}
        />

      </ContentContainer>

      <ThumbnailContainer>
        
        {images}

      </ThumbnailContainer>

    </Overlay>
  );
};