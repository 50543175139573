import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  Logger,
  ErrorToast,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  LookupProvider,
  UserProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const PaymentProcessUI = props => {

  React.useEffect(() => {

    if (!props.common.uriParams.t) {

      props.common.navigate(props.common.uriParams.url);
      return;
    }

    DataStore.set(
      'ACCESS_TOKEN',
      props.common.uriParams.t,
    );

    LookupProvider.loadAllLookups()
      .then(() => {

      UserProvider.getUserInformation(props.common.uriParams.userId)
        .then(ret => {

          if (isValidProviderResult(ret)) {

            DataStore.set('ACCOUNT_INFORMATION', ret);

            DataStore.set(
              'LOGGED_IN_USER', {
                id: props.common.uriParams.userId,
                email: ret.data.email,
              },
            );

            props.common.navigate(props.common.uriParams.url);
            return;
          }

          Logger.error(ret.message || Messages.TechnicalError);
          ErrorToast.show();
        })
        .catch(e => {

          Logger.error(e);
          ErrorToast.show();
        });
      })
      .catch(e => {

        Logger.error(e);
        ErrorToast.show();
      });

  }, [props.common]);

  return (

    <Container>
      <Loader/>
    </Container>
  );
};

export const PaymentProcess = withCommon(
  PaymentProcessUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);