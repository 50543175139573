import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  Images,
  addColorTransparency,
} from 'common';

import {
  InformationBubble,
} from 'common/components';

const Container = styled.div`
  display: flex;
  position: relative;
  padding: ${props => props.padding || '0'};
  ${props => props.flex && `flex: ${props.flex};`}
`;

const Check = styled.div`
  display: inline-block;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  height: 28px;
  width: 28px;
  background-color: ${props => props.isChecked ? Colors.Tan : props.backgroundColor || addColorTransparency(Colors.Gray1, 0.75)};
  background-image: url(${Images.Icons.Tick});
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

const Label = styled.div`
  color: ${props => props.color || Colors.Black};
  display: flex;
  align-items: center;
  font-size: ${FontSizes.Smaller};
  padding: 0 0 0 10px;
  cursor: pointer;
`;

export const Checkbox = props => {

  const [isChecked, isCheckedSetState] = React.useState(!!props.isDefaultChecked);

  const onClick = () => {

    typeof props.onClick === 'function' && props.onClick(!isChecked);
    isCheckedSetState(!isChecked);
  };

  return (

    <Container
      flex={props.flex}
      padding={props.padding}>

      <Check
        isChecked={isChecked}
        onClick={onClick}
        backgroundColor={props.backgroundColor}
      />

      <Label
        color={props.labelColor}
        onClick={onClick}>

          {props.label}
      </Label>

      <InformationBubble
        id={props.id}
      />

    </Container>
  );
};