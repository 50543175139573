import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  Images,
} from 'common';

import {
  Text,
} from 'common/components/text';

const Container = styled.div`
  background-color: ${Colors.Gray1};
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  position: relative;
  z-index: 1;

  ${props => props.addShadow && 'box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);'}
  ${props => props.onClick && 'cursor: pointer;'}
`;

const ProfileImage = styled.div`
  background-image: url(${props => props.image || Images.Icons.ProfilePlaceholder});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  height: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const OnlineIndicatorContainer = styled.div`
  position: absolute;
  left: 15px;
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;
`;

const OnlineIndicatorOuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.color};
  border-radius: 10px;
  height: 20px;
  width: 20px;
`;

const OnlineIndicatorInnerCircle = styled.div`
  background-color: ${props => props.color};
  border-radius: 6px;
  height: 12px;
  width: 12px;
`;

const OnlineIndicator = props => (

  <OnlineIndicatorContainer>

    <OnlineIndicatorOuterCircle
      color={props.isOnline ? Colors.Green4 : Colors.Gray7}>

      <OnlineIndicatorInnerCircle
        color={props.isOnline ? Colors.Green4 : Colors.Gray7}
      />

    </OnlineIndicatorOuterCircle>

  </OnlineIndicatorContainer>
);

export const ProjectChatHeader = props => {

  return (

    <Container
      addShadow={props.addShadow}
      onClick={props.onClick}>

      <ProfileImage
        image={props.profileImage}
      />

      { props.showOnlineIndicator && 

        <OnlineIndicator
          isOnline={props.isOnline}
        />
      }

      <TextContainer>

        <Text
          fontSize={FontSizes.Small}
          fontWeight={'bold'}
          textAlign={'center'}>

          {props.projectName || 'Unknown project'}

        </Text>

        { props.projectOwner &&

          <Text
            fontSize={FontSizes.Smaller}
            textAlign={'center'}>

            {props.projectOwner}

          </Text>
        }

      </TextContainer>

    </Container>
  );
};