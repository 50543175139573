import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  InformationIcons,
  Image,
  Images,
} from 'common';

import {
  Button,
  Text,
  TextInput,
} from 'common/components';

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${props => props.flex && `flex: ${props.flex};`}
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ValueContainer = styled.div`
  background-color: ${Colors.Tan};
  border-radius: 15px;
  font-size: ${FontSizes.Smaller};
  min-height: 30px;
  margin: 6px 0 0 0;
  padding: 2px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const ValueClose = styled.div`
  cursor: pointer;
  width: 15px;
  text-align: right;
`;

const InformationContainer = styled.div`
  background-color: ${Colors.White};
  font-size: ${FontSizes.Smaller};
  position: absolute;
  border: 1px solid ${Colors.Gray6};
  border-radius: 10px;
  padding: 10px;
  top: ${props => props.top || '25px'};
  max-width: 100%;
  z-index: 5;

  ${props => props.floatRight ? 'right: 0' : 'left: 0'};
`;

export class MultiAddInput extends React.Component {

  state = {
    inputElements: [],
    inputValue: '',
    showInformation: false,
  };

  inputValues = [];

  onShowInformation = () => {

    this.setState({
      showInformation: true
    });
  };

  onHideInformation = () => {

    this.setState({
      showInformation: false
    });
  };

  onAddClick = () => {

    if (!this.state.inputValue) {
      return;
    }

    this.inputValues.push({
      description: this.state.inputValue,
    });

    typeof this.props.onChange === 'function' && this.props.onChange(this.inputValues);

    this.setState({
      inputValue: '',
      inputElements: this.createValueElements(),
    });
  };

  onRemoveClick = (index) => {

    let item = this.inputValues[index];

    // if item is in db already, set delete flag to delete on save changes
    if (item.id) {
      item.shouldDelete = true;
    }
    else {
      this.inputValues.splice(index, 1);
    }

    typeof this.props.onChange === 'function' && this.props.onChange(this.inputValues);

    this.setState({
      inputElements: this.createValueElements(),
    });
  };

  onInputChange = (val) => {

    this.setState({
      inputValue: val,
    });
  };

  createValueElements = () => {

    if (!Array.isArray(this.inputValues) || !this.inputValues.length) {
      return [];
    }

    let inputList = [];
    let item;

    for (let i = 0; i < this.inputValues.length; i++) {

      item = this.inputValues[i];

      if (item.shouldDelete) {
        continue;
      }

      inputList.push(
        <ValueContainer
          key={i}>

          <span>{item.description}</span>
          
          <ValueClose
            onClick={() => this.onRemoveClick(i)}>x</ValueClose>

        </ValueContainer>
      );
    }

    return inputList;
  };

  componentDidMount() {

    if (!this.props.items || !this.props.items.length) {
      return;
    }

    this.inputValues = this.props.items;

    this.setState({
      inputElements: this.createValueElements(),
    });
  }

  componentDidUpdate() {

    if (!this.inputValues.length && this.props.items.length) {

      this.inputValues = this.props.items;

      this.setState({
        inputElements: this.createValueElements(),
      });
    }
  }

  render () {

    return (

      <Container
        id={this.props.id}
        flex={this.props.flex}>

        <LabelContainer>

          { this.props.label &&
        
            <Text
              color={this.props.labelColor || Colors.Black}
              fontSize={FontSizes.Small}
              fontWeight={'bold'}
              padding={'0 0 10px 0'}>

                {this.props.label}
            </Text>
          }

          { !!InformationIcons()[this.props.id] &&
              
            <>

              <Image
                height={'19px'}
                width={'30px'}
                padding={'5px 0 0 0'}
                src={Images.Icons.InformationIcon}
                onMouseOver={this.onShowInformation}
                onMouseLeave={this.onHideInformation}
                onClick={() => {}}
              />

              { this.state.showInformation &&

                <InformationContainer>{InformationIcons()[this.props.id]}</InformationContainer>
              }

            </>
          }

        </LabelContainer>

        { this.state.inputElements.length < (this.props.maxItems || 50) &&

          <InputContainer>

            <TextInput
              margin={'0 8px 0 0'}
              width={'100%'}
              value={this.state.inputValue}
              charCounterMax={this.props.charCounterMax}
              showCharCounter={!!this.props.charCounterMax}
              onChange={this.onInputChange}
              hasError={this.props.hasError}
              errorMessage={this.props.errorMessage}
            />

            <Button
              text={'Add'}
              width={'70px'}
              onClick={this.onAddClick}
            />

          </InputContainer>
        }

        {this.state.inputElements}

      </Container>
    );
  }
}