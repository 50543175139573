import {
  ProjectsEdit,
  ProjectsView,
  ProjectsNoAccount,
} from 'ui/projects';

export const ProjectsRoutes = {
  projectsEdit: {
    component: ProjectsEdit,
    path: '/projects/edit',
  },
  projectsView: {
    component: ProjectsView,
    path: '/projects/view',
  },
  projectsNoAccount: {
    component: ProjectsNoAccount,
    path: '/projects/account/incomplete',
  },
}