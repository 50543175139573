import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  FontSizes,
  Images,
} from 'common';

import {
  H2,
  P,
} from 'common/components/text';

const Container = styled.div`
  width: 85%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding-bottom: 55px;
`;

const Item = styled.div`
  background-color: ${props => props.fill ? Colors.PrimaryOne : Colors.Transparent};
  border: 2px solid ${Colors.PrimaryOne};
  border-radius: 15px;
  color: ${props => props.fill ? Colors.White : Colors.PrimaryOne};
  font-size: ${FontSizes.Small};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  position: relative;
  text-align: center;

  ${props => props.showImage && `
    background-image: url(${Images.Icons.Tick});
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
  `}

  &:after {
    color: ${Colors.Black};
    content: "${props => props.text}";
    position: absolute;
    top: 40px;
  }
`;

const Line = styled.div`
  background-color: ${Colors.Black};
  height: 1px;
  display: flex;
  flex: 1;
  margin: 0 2%;
`;

export const PaymentProgressStepper = props => {

  let propItems = [
    {
      text: 'Select package',
    }
  ];

  if (!DataStore.get('LOGGED_IN_USER')) {

    propItems.push({
      text: 'Login / Register',
    });
  }
    
  propItems.push({
    text: 'Payment',
  });

  let items = [];
  let item;
  let isCompleted;
  let isCompletedOrBusy;
  let content;

  for (let i = 0; i < propItems.length; i++) {

    item = propItems[i];

    isCompleted = i < props.selectedIndex;

    isCompletedOrBusy = (i <= props.selectedIndex)
      ? 1
      : 0;

    content = isCompleted
      ? ''
      : i + 1;

    if (i === propItems.length - 1) {

      items.push(
        <Item
          key={i}
          text={item.text}
          fill={isCompletedOrBusy}
          showImage={isCompleted}>
            {content}
        </Item>
      );

      break;
    }

    items.push(
      <React.Fragment key={i}>

        <Item
          text={item.text}
          fill={isCompletedOrBusy}
          showImage={isCompleted}>
            {content}
        </Item>

        <Line/>

      </React.Fragment>
    );
  }

  return (
    <Container>

      <H2 textAlign={'center'}>{props.title}</H2>

      <StepperContainer>

        { items.length > 0
          
          ? items
          
          : <P>No items to display</P>
        }

      </StepperContainer>

    </Container>
  );
};
