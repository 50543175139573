import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Image,
} from 'common';

export const EmailImage = styled(Image)`
  height: auto;
  width: 165px;

  @media ${DeviceMediaQueries.Laptop} {
    width: 200px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    width: 230px;
  }
`;

export const TickImage = styled(Image)`
  height: auto;
  width: 100px;

  @media ${DeviceMediaQueries.Laptop} {
    width: 140px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    width: 160px;
  }
`;

export const HeaderText = styled.div`
  color: ${Colors.PrimaryOne};
  font-size: ${FontSizes.Big};
  font-weight: bold;
  padding: 25px 0 20px 0;
  text-align: center;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Bigger};
    padding: 30px 15px 25px 15px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Biggest};
  }
`;

export const SubHeaderText = styled.div`
  font-size: ${FontSizes.Small};
  text-align: center;
  max-width: 550px;
  padding: 0 0 25px 0;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Regular};
    padding: 0 0 40px 0;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Big};
  }
`;