import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  Environment,
  FontSizes,
  Logger,
  Messages,
  generateLinkedInLoginUrl,
  isValidEmail,
  isValidPassword,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Checkbox,
  PSmaller,
  TextInput,
  Text,
} from 'common/components';

import {
  Version,
} from 'generated/constants';

import {
  withCommon,
} from 'common/hocs';

import {
  Container,
  InputContainer,
  ToggleContainer,
  ToggleText,
  Header,
} from '../identityStyled';

import {
  IdentityProvider,
} from 'providers';

const Link = styled.span`
  text-decoration: underline;
`;

const VersionText = styled(Text)`
  position: fixed;
  top: ${window.innerHeight - 30}px;
  left: 0;
  right: 0;
`;

class RegisterUI extends React.Component {

  state = {
    showButtonLoading: false,
    hasEmailError: false,
    hasPasswordError: false,
    hasConfirmPasswordError: false,
    showError: false,
    emailMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
    errorMessage: '',
  };

  email = '';
  password = '';
  confirmPassword = '';
  termsAccepted = false;

  isFromPayment = !!(new URLSearchParams(window.location.search).get('fromPayment'));

  onEmailChange = val => {
    
    this.email = val;

    if (this.state.hasEmailError || this.state.showError) {

      this.setState({
        hasEmailError: false,
        showError: false,
      });
    }
  };

  onPasswordChange = val => {
    
    this.password = val;

    if (this.state.hasPasswordError || this.state.showError) {

      this.setState({
        hasPasswordError: false,
        showError: false,
      });
    }
  };

  onConfirmPasswordChange = val => {

    this.confirmPassword = val;

    if (this.state.hasConfirmPasswordError || this.state.showError) {

      this.setState({
        hasConfirmPasswordError: false,
        showError: false,
      });
    }
  };

  onViewTermsClick = e => {

    e.stopPropagation();

    this.props.common.showIframePopup('https://www.afrion.com/terms-and-conditions?embedded=true');
  };

  onTermsAcceptClick = val => {
    this.termsAccepted = val;
  };

  onRegisterClick = () => {
    
    if (!this.validate()) {
      return;
    }

    this.setState({
      showButtonLoading: true,
    });

    IdentityProvider
      .register(
        this.email,
        this.password,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
            errorMessage: ret.message || Messages.TechnicalError,
            showButtonLoading: false,
          });

          return;
        }

        if(this.isFromPayment) {

          DataStore.set(
            'REGISTERED_USER',
            ret.data,
          );

          window.parent.postMessage('register_success', window.location.origin);
          return;
        }

        DataStore.set('REGISTRATION_EMAIL', this.email);

        this.props.common.navigate('/identity/email/verify');
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          showButtonLoading: false,
        });
      });
  }

  onLinkedInSignUpClick = () => {

    if (this.isFromPayment) {

      window.top.window.location.href = generateLinkedInLoginUrl(true);
      return;
    }

    window.location.href = generateLinkedInLoginUrl();
  };

  onLoginClick = () => {
    this.props.common.navigate('/identity/login' + window.location.search);
  };

  validate = () => {

    let emailMessage;
    let passwordMessage;
    let confirmPasswordMessage;
    let termsAcceptedMessage;

    if (!this.email) {
      emailMessage = 'Please provide a valid email';
    }

    if (this.email && !isValidEmail(this.email)) {
      emailMessage = 'Incorrect email format';
    }

    if (!isValidPassword(this.password)) {
      passwordMessage = Messages.InvalidPassword;
    }

    if (!this.confirmPassword) {
      confirmPasswordMessage = 'Please confirm your password';
    }

    if (this.password && this.confirmPassword && this.password !== this.confirmPassword) {
      confirmPasswordMessage = 'Passwords do not match';
    }

    if (!this.termsAccepted) {
      termsAcceptedMessage = 'Please accept the terms and conditions';
    }

    if (!emailMessage && !passwordMessage && !confirmPasswordMessage && !termsAcceptedMessage) {
      return true;
    }

    this.setState({
      hasEmailError: !!emailMessage,
      hasPasswordError: !!passwordMessage,
      hasConfirmPasswordError: !!confirmPasswordMessage,
      emailMessage,
      passwordMessage,
      confirmPasswordMessage: confirmPasswordMessage,
      showError: !!termsAcceptedMessage,
      errorMessage: termsAcceptedMessage,
    });

    return false;
  };

  componentDidMount() {
    DataStore.clearAll();
  }

  render () {

    const termsAndConditionsComponent = <span>I agree to the <Link onClick={this.onViewTermsClick}>terms and conditions</Link></span>

    return (

      <Container>

        { !this.isFromPayment &&

          <Header
            textAlign={'center'}>

              Welcome to AfriOn
          </Header>
        }

        <InputContainer>

          <ToggleContainer>

            <ToggleText
              onClick={this.onLoginClick}>

                login
            </ToggleText>

            <ToggleText
              selected={true}>
                
                sign up
            </ToggleText>

          </ToggleContainer>

          { this.state.showError &&
            
            <PSmaller
              color={Colors.Red}
              padding={'0 0 10px 0'}
              textAlign={'center'}
              width={'100%'}>

                {this.state.errorMessage}
            </PSmaller>
          }

          <TextInput
            type={'email'}
            placeholder={'Email'}
            width={'100%'}
            onChange={this.onEmailChange}
            hasError={this.state.hasEmailError}
            errorMessage={this.state.emailMessage}
          />

          <TextInput
            type={'password'}
            placeholder={'Password'}
            width={'100%'}
            onChange={this.onPasswordChange}
            hasError={this.state.hasPasswordError}
            errorMessage={this.state.passwordMessage}
          />

          <TextInput
            type={'password'}
            placeholder={'Retype Password'}
            width={'100%'}
            onChange={this.onConfirmPasswordChange}
            hasError={this.state.hasConfirmPasswordError}
            errorMessage={this.state.confirmPasswordMessage}
          />

          <Checkbox
            label={termsAndConditionsComponent}
            padding={'0 0 10px 0'}
            onClick={this.onTermsAcceptClick}
          />

          <Button
            margin={'0 0 10px 0'}
            text={'SIGN UP'}
            width={'100%'}
            showLoading={this.state.showButtonLoading}
            onClick={this.onRegisterClick}
          />

          { false &&

            <Button
              backgroundColor={Colors.PrimaryOne}
              activeColor={Colors.White}
              margin={'0 0 5px 0'}
              text={'LINKEDIN SIGNUP'}
              width={'100%'}
              onClick={this.onLinkedInSignUpClick}
            />
          }

        </InputContainer>

        <VersionText
          fontSize={FontSizes.Smallest}
          textAlign={'center'}>

            {Environment.name + ' ' + Version}
        </VersionText>

      </Container>
    );
  }
}

export const Register = withCommon(
  RegisterUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
