const STORE_KEY = 'AON_STORAGE';

export class DataStore {

  static set(key, value) {

    if (!key) {
      return;
    }

    let aonStorage = sessionStorage.getItem(STORE_KEY) || '{}';

    aonStorage = JSON.parse(aonStorage);
    aonStorage[key] = value;

    sessionStorage.setItem(STORE_KEY, JSON.stringify(aonStorage));
  }

  static get(key, fallback) {

    if (!key) {
      return;
    }

    let aonStorage = sessionStorage.getItem(STORE_KEY) || '{}';
    aonStorage = JSON.parse(aonStorage);
    
    let val = aonStorage[key];

    if (val === undefined || val === null) {

      let aonStorage = localStorage.getItem(STORE_KEY) || '{}';
      aonStorage = JSON.parse(aonStorage);

      val = aonStorage[key];
    }

    return val || fallback;
  }

  static clear(key) {

    if (!key) {
      return;
    }

    let aonStorage = sessionStorage.getItem(STORE_KEY) || '{}';
    aonStorage = JSON.parse(aonStorage);

    delete aonStorage[key];

    sessionStorage.setItem(STORE_KEY, JSON.stringify(aonStorage));
  }

  static clearAll() {

    sessionStorage.removeItem(STORE_KEY);
    localStorage.removeItem(STORE_KEY);
  }
}