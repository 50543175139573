import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  TextInputContainer,
} from 'common';

import {
  PSmall,
  Text,
} from 'common/components';

import {
  TextContainer,
} from './projectsComponentsStyled';

const DisplayText = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
`;

export const SwotAnalysisDisplay = ({
  project,
  showHeader = true,
  color = Colors.Black
}) => {

  const swots = project.project_swots || [];

  const strengths = [];
  const weaknesses = [];
  const threats = [];
  const opportunities = [];

  for (const swot of swots) {

    if (swot.swotTypeId === 1) {

      strengths.push(<DisplayText key={swot.id}>{`${strengths.length + 1}. ${swot.description}`}</DisplayText>);
      continue;
    }

    if (swot.swotTypeId === 2) {

      weaknesses.push(<DisplayText key={swot.id}>{`${weaknesses.length + 1}. ${swot.description}`}</DisplayText>);
      continue;
    }

    if (swot.swotTypeId === 3) {

      opportunities.push(<DisplayText key={swot.id}>{`${opportunities.length + 1}. ${swot.description}`}</DisplayText>);
      continue;
    }

    if (swot.swotTypeId === 4) {

      threats.push(<DisplayText key={swot.id}>{`${threats.length + 1}. ${swot.description}`}</DisplayText>);
      continue;
    }
  }

  return (

    <React.Fragment>

      { showHeader &&

        <Text
          color={color}
          fontSize={FontSizes.Small}
          fontWeight={'bold'}
          padding={'10px 0'}>

            SWOT analysis
        </Text>
      }

      <TextInputContainer
        padding={'0 0 15px 0'}>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Strengths
          </PSmall>

          <PSmall
            color={color}>

              {strengths.length ? strengths : 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Weaknesses
          </PSmall>

          <PSmall
            color={color}>

              {weaknesses.length ? weaknesses : 'Not specified'}
          </PSmall>

        </TextContainer>

      </TextInputContainer>

      <TextInputContainer
        padding={'0 0 15px 0'}>

          <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Opportunities
          </PSmall>

          <PSmall
            color={color}>

              {opportunities.length ? opportunities : 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Threats
          </PSmall>

          <PSmall
            color={color}>

              {threats.length ? threats : 'Not specified'}
          </PSmall>

        </TextContainer>

      </TextInputContainer>

    </React.Fragment>
  );
};