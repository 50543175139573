import
  React
from 'react';

import {
  Colors,
  DesktopSpace,
  TextInputContainer,
} from 'common';

import {
  Select,
  TextInput,
} from 'common/components';

import {
  LookupProvider,
} from 'providers';

export class ProjectInformationForm extends React.Component {

  state = {
    titleErrorMessage: '',
    natureErrorMessage: '',
    typeErrorMessage: '',
    industryErrorMessage: '',
    countryErrorMessage: '',
    stageErrorMessage: '',
    natureList: [],
    typeList: [],
    stageList: [],
    countryList: [],
    industryList: [],
  };

  formValues = {
    nature: {},
    type: {},
    stage: {},
    selectedIndustries: [],
    selectedCountries: [],
    title: '',
    isPublic: false,
  };

  onIndustriesChange = val => {

    const toDelete = this.formValues.selectedIndustries.filter(l => {

      if (!l.id) {
        return false;
      }

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {
          return false;
        }
      }

      l.shouldDelete = true;

      return true;
    });

    for (let j = 0; j < val.length; j++) {

      val[j].industryDescription = val[j].value;
      val[j].projectId = this.props.project.id;
      val[j].shouldDelete = false;
    }

    const combined = [
      ...val,
      ...toDelete,
    ];

    this.formValues.selectedIndustries = combined;
    this.formValues.project_industries = combined;

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (this.state.industryErrorMessage) {

      this.setState({
        industryErrorMessage: '',
      });
    }

    if (!val.length) {

      this.setState({
        industryErrorMessage: 'Please select at least one industry',
      });
    }
  };

  onCountriesChange = val => {

    const toDelete = this.formValues.selectedCountries.filter(l => {

      if (!l.id) {
        return false;
      }

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {
          return false;
        }
      }

      l.shouldDelete = true;

      return true;
    });

    for (let j = 0; j < val.length; j++) {

        val[j].countryId = val[j].value;
        val[j].cityId = 0;
        val[j].projectId = this.props.project.id;
        val[j].shouldDelete = false;
    }

    const combined = [
      ...val,
      ...toDelete,
    ];

    this.formValues.selectedCountries = combined;
    this.formValues.project_locations = combined;

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (this.state.countryErrorMessage) {

      this.setState({
        countryErrorMessage: '',
      });
    }

    if (!val.length) {

      this.setState({
        countryErrorMessage: 'Please select at least one country',
      });
    }
  };

  onNatureChange = (val) => {
    
    if (!val || !val.value) {
      return;
    }

    this.formValues.nature = val;
    this.formValues.natureId = val.value;

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (this.state.natureErrorMessage) {

      this.setState({
        natureErrorMessage: '',
      });
    }
  };

  onTypeChange = (val) => {

    if (!val || !val.value) {
      return;
    }

    this.formValues.type = val;
    this.formValues.typeId = val.value;

    this.formValues.project_type = {
      typeDescription: val.value
    };

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (this.state.typeErrorMessage) {

      this.setState({
        typeErrorMessage: '',
      });
    }
  };

  onStageChange = (val) => {

    if (!val || !val.value) {
      return;
    }

    this.formValues.stage = val;
    this.formValues.project_stage = this.formValues.project_stage || {};

    this.formValues.project_stage.stageDescription = val.value.toString();
    this.formValues.project_stage.projectId = this.props.project.id;

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (this.state.stageErrorMessage) {

      this.setState({
        stageErrorMessage: '',
      });
    }
  };

  onTitleChange = (val) => {

    this.formValues.title = val;

    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!val) {

      this.setState({
        titleErrorMessage: 'Please enter a project title',
      });

      return;
    }

    if (!!this.state.titleErrorMessage) {

      this.setState({
        titleErrorMessage: '',
      });
    }
  };

  validate = () => {

    let errors = {};

    if (!this.formValues.nature.value) {
      errors.natureErrorMessage = 'Please select the project nature';
    }

    if (!this.formValues.type.value) {
      errors.typeErrorMessage = 'Please select the project type';
    }

    if (!this.formValues.stage.value) {
      errors.stageErrorMessage = 'Please select the project stage';
    }

    if (!this.formValues.selectedCountries.length) {
      errors.countryErrorMessage = 'Please select at least one country';
    }

    if (!this.formValues.selectedIndustries.length) {
      errors.industryErrorMessage = 'Please select at least one industry';
    }

    if (!this.formValues.title) {
      errors.titleErrorMessage = 'Please enter a project title';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  load = async () => {

    const natureList = await LookupProvider.getProjectNatureList();
    const typeList = await LookupProvider.getProjectTypes();
    const stageList = await LookupProvider.getProjectStages();
    const countryList = await LookupProvider.getProjectCountries();
    const industryList = await LookupProvider.getProjectIndustries();

    const project = this.props.project || {};

    this.formValues= {
      nature: project.nature || {},
      type: project.type || {},
      stage: project.status || {},
      selectedIndustries: project.industries || [],
      selectedCountries: project.countries || [],
      title: project.title || '',
      isPublic: false,
    };

    this.formValues.validate = this.validate;

    typeof this.props.onFormLoad === 'function' && this.props.onFormLoad(this.formValues, true);

    this.setState({
      natureList,
      typeList,
      stageList,
      countryList,
      industryList,
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {

    const project = this.props.project || {};

    return (

      <React.Fragment>

        <TextInputContainer>

          <Select
            id={'select-project-information-nature'}
            flex={'1'}
            width={'auto'}
            label={'Type'}
            options={this.state.natureList}
            defaultValue={project.natureId}
            onChange={this.onNatureChange}
            hasError={!!this.state.natureErrorMessage}
            errorMessage={this.state.natureErrorMessage}
          />

          <DesktopSpace/>

          <Select
            id={'select-project-information-type'}
            flex={'1'}
            width={'auto'}
            label={'Form'}
            options={this.state.typeList}
            defaultValue={project.typeId}
            onChange={this.onTypeChange}
            hasError={!!this.state.typeErrorMessage}
            errorMessage={this.state.typeErrorMessage}
          />

          <DesktopSpace/>

          <TextInput
            id={'txt-project-information-title'}
            flex={'1'}
            width={'auto'}
            label={'Title'}
            defaultValue={project.title}
            onChange={this.onTitleChange}
            hasError={!!this.state.titleErrorMessage}
            errorMessage={this.state.titleErrorMessage}
          />

        </TextInputContainer>

        <TextInputContainer>

          <Select
            id={'select-project-information-industry'}
            flex={'1'}
            width={'auto'}
            isMultiSelect={true}
            displaySelectedInline={true}
            trashcanColor={Colors.Blue3}
            label={'Industry'}
            maxItems={3}
            onChange={this.onIndustriesChange}
            options={this.state.industryList}
            defaultOptions={project.industries}
            hasError={!!this.state.industryErrorMessage}
            errorMessage={this.state.industryErrorMessage}
          />

          <DesktopSpace/>

          <Select
            id={'select-project-information-country'}
            flex={'1'}
            width={'auto'}
            isMultiSelect={true}
            displaySelectedInline={true}
            trashcanColor={Colors.Blue3}
            label={'Country'}
            maxItems={3}
            onChange={this.onCountriesChange}
            options={this.state.countryList}
            defaultOptions={project.countries}
            hasError={!!this.state.countryErrorMessage}
            errorMessage={this.state.countryErrorMessage}
          />

          <DesktopSpace/>

          <Select
            id={'select-project-information-stage'}
            flex={'1'}
            width={'auto'}
            label={'Stage'}
            options={this.state.stageList}
            defaultValue={(project.status && project.status.value)}
            onChange={this.onStageChange}
            hasError={!!this.state.stageErrorMessage}
            errorMessage={this.state.stageErrorMessage}
          />

        </TextInputContainer>

      </React.Fragment>
    );
  }
}