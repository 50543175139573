import {
  Messages,
} from './constants';

export class ErrorToast {

  static showFunc = () => {};
  static hideFunc = () => {};

  static init(
    showFunction,
    hideFunction,
  ) {

    if (typeof showFunction === 'function') {
      ErrorToast.showFunc = showFunction;
    }

    if (typeof hideFunction === 'function') {
      ErrorToast.hideFunc = hideFunction;
    }
  }

  static show(
    heading,
    subHeading,
  ) {

    ErrorToast.showFunc(heading || Messages.ErrorHeading, subHeading || Messages.TechnicalError);
  }

  static hide() {

    ErrorToast.hideFunc();
  }
}

export class SuccessToast {

  static showFunc = () => {};
  static hideFunc = () => {};

  static init(
    showFunction,
    hideFunction,
  ) {

    if (typeof showFunction === 'function') {
      SuccessToast.showFunc = showFunction;
    }

    if (typeof hideFunction === 'function') {
      SuccessToast.hideFunc = hideFunction;
    }
  }

  static show(
    heading,
    subHeading,
  ) {

    SuccessToast.showFunc(heading || 'Success', subHeading || 'Your task was successfully completed.');
  }

  static hide() {

    SuccessToast.hideFunc();
  }
}