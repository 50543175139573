import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
} from 'common';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ContentContainer = styled.div`
  background-color: ${Colors.Gray1};
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    height: 100%;
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  background-color: ${Colors.Green3};
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    position: absolute;
    width: 300px;
    overflow: auto;
  }
`;

export const RightContainer = styled.div`
  width: 100%;
  position: relative;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 10px;
    position: absolute;
    left: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: auto;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    padding: 40px;
  }
`;