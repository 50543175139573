import
  React
from 'react';

import
  styled, {
  keyframes,
} from 'styled-components/macro';

import {
  Colors,
  FontSizes,
} from 'common';

const Animation = size => keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin};
`;

const LoadingContainer = styled.div`
  font-size: 10px;
  margin: ${props => props.margin || '50px auto'};
  text-indent: -9999em;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background: ${props => props.spinnerColor};
  background: -moz-linear-gradient(left, ${props => props.spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, ${props => props.spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, ${props => props.spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, ${props => props.spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, ${props => props.spinnerColor} 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: ${Animation} 1s infinite linear;
  animation: ${Animation} 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  :before {
    width: 50%;
    height: 50%;
    background: ${props => props.spinnerColor};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  :after {
    background: ${props => props.backgroundColor};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const Label = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: ${FontSizes.Regular};
  font-weight: bold;
`;

export const Loader = props => (

  <Container
    margin={props.showLabel ? '0 0 30px 0' : '0'}>

    <LoadingContainer
      backgroundColor={props.backgroundColor || Colors.White}
      spinnerColor={props.spinnerColor || Colors.Gray9}
      size={props.size || 70}
      margin={props.margin}
    />

    { props.showLabel &&

      <Label>
        {props.label || 'Loading...'}
      </Label>
    }

  </Container>
);