import {
  Environment,
  Logger,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  post,
  deleteCall,
} from 'providers/axios';

export class MediaProvider {

  static async uploadFile(
    projectId,
    file,
    description,
    progressCallback,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Please specify the project ID',
          data: null,
        };
      }

      if (!file) {

        return {
          ok: false,
          message: 'Please specify the file you want to upload',
          data: null,
        };
      }

      const data = new FormData();

      data.append('projectId', projectId);
      data.append('file', file);
      data.append('description', description || '');

      let res = await post(
        `${Environment.apiHost}uploads`,
        data,
        {}, {
          onUploadProgress: (progressEvent) => { progressCallback && progressCallback(progressEvent); }
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async uploadEmailAttachment(
    file,
    fileId,
  ) {

    try {

      if (!file) {

        return {
          ok: false,
          message: 'Please specify the file you want to upload',
          data: null,
        };
      }

      const data = new FormData();

      data.append('file', file);

      let res = await post(
        `${Environment.apiHost}upload-email-attachment`,
        data,
        {},
      );

      let ret = resolveResponse(res);

      ret.data = ret.data || {};

      ret.data.fileId = fileId;

      return ret;
    }
    catch (ex) {

      Logger.error(ex);

      let res = resolveRejected(ex);

      res.data = res.data || {};

      res.data.fileId = fileId;

      return res;
    }
  }

  static async deleteFile(
    fileId,
  ) {

    try {

      if (!fileId) {

        return {
          ok: false,
          message: 'Please specify the file ID',
          data: null,
        };
      }

      let res = await deleteCall(`${Environment.apiHost}media/${fileId}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }
}