import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Chat,
  Invites,
  ShareRequest,
  Colors,
  DataStore,
  DeviceMediaQueries,
  Environment,
  ErrorToast,
  Logger,
  FontSizes,
  isValidProviderResult,
} from 'common';

import {
  Accordion,
  Button,
  Loader,
  Text,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  Container,
} from './homeStyled';

import {
  DashboardProvider,
  LookupProvider,
  UserProvider,
} from 'providers';

const SideMenu = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: block;
    position: fixed;
    left: 60px;
    top: 60px;
    bottom: 0;
    width: 300px;
    border-right: 2px solid ${Colors.Gray4};
  }
`;

const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideMenuBottomContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
  overflow: auto;

  @media ${DeviceMediaQueries.Tablet} {
    left: 300px;
    padding: 40px;
  }
`;

const ContentGrid = styled.div`
  display: block;
  width: 100%;
  min-height: 550px;

  @media ${DeviceMediaQueries.Tablet} {
    display: block;
    top: 60px;
    left: 390px;
    padding: 0 0 10px 0;
  }

  @media ${DeviceMediaQueries.Laptop} {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto;
  }

  @media ${DeviceMediaQueries.LaptopM} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    min-height: 75%;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    grid-gap: 20px 4%;
  }

  @media ${DeviceMediaQueries.desktop} {
    grid-gap: 20px 10%;
  }
`;

const AccountAccordionContainer = styled.div`
  display: grid;
  width: 100%;
  min-height: 200px;

  @media ${DeviceMediaQueries.Tablet} {
    min-height: 190px;
  }
`;

const AccordionContent = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const StatGrid = styled.div`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: 100%;
  padding: 15px;
  grid-auto-rows: 1fr;

  @media ${DeviceMediaQueries.LaptopL} {
    grid-gap: 20px;
    padding: 20px;
  }
`;

const StatCardContainer = styled.div`
  background-color: ${Colors.Gray1};
  border: 2px solid ${props => props.borderColor};
  border-radius: 15px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatText = styled.div`
  color: ${Colors.Black};
  font-size: ${FontSizes.Bigger};
  text-align: center;

  @media ${DeviceMediaQueries.MobileS} {
    font-size: ${FontSizes.Huge};
  }
`;

const ProjectStatGrid = styled.div`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(0, 1fr);
  width: 100%;
  height: 100%;
  padding: 15px;
  grid-auto-rows: 1fr;

  @media ${DeviceMediaQueries.LaptopL} {
    grid-gap: 20px;
    padding: 20px;
  }
`;

const ProjectStatCardContainer = styled.div`
  background-color: ${Colors.Gray1};
  border: 2px solid ${props => props.borderColor};
  border-radius: 15px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 10px 20px;
  }
`;

const ProjectStatCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75%;
  
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

const NoProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

const AccountsTipGrid = styled.div`
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AccountsTipCardContainer = styled.div`
  background-color: ${Colors.Gray1};
  border: 2px solid ${props => props.borderColor};
  border-radius: 15px;
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceMediaQueries.LaptopL} {
    padding: 10px;
  }
`;

const AccountsTipCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 85%;
  padding: 0 0 0 10px;
`;

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MultilineEllipsisText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 50;
  -webkit-box-orient: vertical;
`;

const SmallFontSize = window.innerWidth <= 1420
                        ? FontSizes.Smaller
                        : FontSizes.Small;

const StatCard = props => (

  <StatCardContainer
    borderColor={props.borderColor || Colors.Gray5}>

    <StatText>
      {props.stat}
    </StatText>

    <Text
      fontSize={SmallFontSize}
      textAlign={'center'}
      padding={'0 0 8px 0'}>

        {props.text}
    </Text>

  </StatCardContainer>
);

const ProjectStatCard = props => (

  <ProjectStatCardContainer
    borderColor={props.borderColor || Colors.Gray5}>

    <ProjectStatCardTextContainer>

      <EllipsisText
        fontSize={SmallFontSize}
        fontWeight={'bold'}>

          {props.projectName}
      </EllipsisText>

      <EllipsisText
        fontSize={SmallFontSize}>

          {props.projectNature}
      </EllipsisText>
      
    </ProjectStatCardTextContainer>

    <ProjectStatCardTextContainer
      maxWidth={'80px'}>

      <StatText>
        {props.stat}
      </StatText>

      <Text
        fontSize={SmallFontSize}
        textAlign={'center'}
        padding={'0 0 8px 0'}>

          {props.text}
      </Text>

    </ProjectStatCardTextContainer>

  </ProjectStatCardContainer>
);

const AccountTipCard = props => (

  <AccountsTipCardContainer
    borderColor={props.borderColor || Colors.Gray5}>

    <AccountsTipCardTextContainer>

      <Text
        fontSize={SmallFontSize}
        fontWeight={'bold'}>

          Tips:
      </Text>

      <MultilineEllipsisText
        fontSize={SmallFontSize}>

          {props.tip}
      </MultilineEllipsisText>
      
    </AccountsTipCardTextContainer>

    <AccountsTipCardTextContainer
      maxWidth={'80px'}>

      <StatText>
        {props.stat}
      </StatText>

      <Text
        fontSize={SmallFontSize}
        textAlign={'center'}
        padding={'0 0 8px 0'}>

          Account completion
      </Text>

    </AccountsTipCardTextContainer>

  </AccountsTipCardContainer>
);

class HomeUI extends React.Component {

  state = {
    contactsData: {
      totalPendingInvites: Invites.inviteCount || 0,
      totalPendingShareRequests: ShareRequest.requestCount || 0,
    },
    matchesData: {},
    projectComponents: [],
    profileScore: 0,
    isContactsLoading: true,
    accountName: '',
  };

  onQuickNavClick = (url) => {

    window.open(url, '_blank');
  };

  onUserUpdated = () => {
    
    Chat.getConversations()
      .then(conversations => {

        this.onConversationUpdated(conversations);
      });
  };

  onInviteCountUpdate = (count) => {

    this.setState({
      contactsData: {
        ...this.state.contactsData,
        totalPendingInvites: count || 0,
      }
    });
  };

  onShareRequestCountUpdate = (count) => {

    this.setState({
      contactsData: {
        ...this.state.contactsData,
        totalPendingShareRequests: count || 0,
      }
    });
  };

  onChatNotificationCountUpdate = (count) => {

    this.setState({
      contactsData: {
        ...this.state.contactsData,
        totalUnreadMessages: count || 0,
      }
    });
  };

  onConversationUpdated = (conversations) => {

    if (!conversations || !conversations.length) {

      this.setState({
        isContactsLoading: false,
      });

      return;
    }

    let totalUnreadMessages = 0;
    let onlineUsers = [];

    for (const conversation of conversations) {

      totalUnreadMessages = totalUnreadMessages + (conversation.totalUnreadMessages || 0);
      conversation.users = conversation.users || [];

      for (const user of conversation.users) {

        if (this.props.common.user.id.toString() === user.identity) {
          continue;
        }

        if (user.isOnline && onlineUsers.indexOf(user.identity) === -1) {
          onlineUsers.push(user.identity);
        }
      }
    }

    this.setState({
      contactsData: {
        ...this.state.contactsData,
        totalOpenChats: conversations.filter(c => !!c.lastConversationMessage).length,
        totalContacts: conversations.length,
        totalContactsOnline: onlineUsers.length,
        totalUnreadMessages,
      },
      isContactsLoading: false,
    });
  };

  createProjectComponents = (data) => {

    if (!Array.isArray(data) || !data.length) {
      return [];
    }

    let toReturn = [];
    let count = 0;

    for (const project of data) {

      count++;

      toReturn.push(

        <ProjectStatCard
          key={project.id}
          stat={project.totalActions}
          text={'Project views'}
          projectName={project.title || 'Unknown project'}
          projectNature={(project.natureId === 1 && 'Collaboration') || (project.natureId === 2 && 'Proposition') || 'Unknown type'}
          borderColor={Colors.Blue3}
        />
      );

      if (count >= 3) {
        break;
      }
    }

    if (window.innerWidth >= 1200) {

      for (let i = 0; i < 3 - count; i++) {

        toReturn.push(<div/>);
      }
    }

    return toReturn;
  };

  checkTutorial = () => {

    if (!this.props.common.user.tutorialShown && !DataStore.get('tutorialShown')) {

      window.showTutorial && window.showTutorial();

      DataStore.set('tutorialShown', true);

      UserProvider.tutorialShown()
        .catch(e => Logger.error(e));
    }
  };

  load = async () => {

    this.props.common.showLoading();

    if (this.props.common.uriParams.login !== 'true') {
      await LookupProvider.loadAllLookups();
    }

    let accountName = '';

    let ret = this.props.common.uriParams.login === 'true'
      ? DataStore.get('ACCOUNT_INFORMATION')
      : await UserProvider.getUserInformation(this.props.common.user.id);

    if (isValidProviderResult(ret)) {

      DataStore.set('ACCOUNT_INFORMATION', ret);
      
      accountName = (
        Array.isArray(ret.data.account)
        && !!ret.data.account.length
        && `${ret.data.account[0].name} ${ret.data.account[0].surname}`
      ) || '';
    }

    let profileScore = 0;

    if (ret.data.profileScore) {
      profileScore = ret.data.profileScore;
    }

    DashboardProvider.getData()
      .then(dashboardRet => this.props.withCleanup(() => {

        if (isValidProviderResult(dashboardRet)) {

          this.setState({
            accountName,
            matchesData: dashboardRet.data.matchesData,
            projectComponents: this.createProjectComponents(dashboardRet.data.projectsData),
            profileScore,
          });
        }
        else {
  
          ErrorToast.show('Unable to load dashboard data');
          Logger.error('Unable to load dashboard data');
        }

        this.checkTutorial();

        this.props.common.hideLoading();
      }));

    Chat.getConversations()
      .then(conversations => this.props.withCleanup(() => {

        this.onConversationUpdated(conversations);
      }));
  };

  componentDidMount() {

    let t = new Date().getTime();

    fetch(`${Environment.configHost}info-bubbles.json?v=${t}`)
      .then(response => response.json())
      .then(data => DataStore.set('INFO_BUBBLES', data))
      .catch(e => Logger.error(e));

    fetch(`${Environment.configHost}config.json?v=${t}`)
      .then(response => response.json())
      .then(data => DataStore.set('MAIN_CONFIG', data))
      .catch(e => Logger.error(e));

    Chat.subscribe('messageCountUpdated', this.onChatNotificationCountUpdate);
    Chat.subscribe('conversationAdded', this.onConversationUpdated);
    Chat.subscribe('conversationRemoved', this.onConversationUpdated);
    Chat.subscribe('userUpdated', this.onUserUpdated);
    Invites.subscribe('inviteCountUpdated', this.onInviteCountUpdate);
    ShareRequest.subscribe('shareRequestCountUpdated', this.onShareRequestCountUpdate);

    this.load()
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  }

  componentWillUnmount() {

    Chat.unsubscribe('messageCountUpdated', this.onChatNotificationCountUpdate);
    Chat.unsubscribe('conversationAdded', this.onConversationUpdated);
    Chat.unsubscribe('conversationRemoved', this.onConversationUpdated);
    Chat.unsubscribe('userUpdated', this.onUserUpdated);
    Invites.unsubscribe('inviteCountUpdated', this.onInviteCountUpdate);
    ShareRequest.unsubscribe('shareRequestCountUpdated', this.onShareRequestCountUpdate);
  }

  render () {

    return (

      <>

      <Container>

        <SideMenu>

          <Text
            fontSize={FontSizes.Biggest}
            padding={'40px 0 0 0'}
            textAlign={'center'}
            width={'100%'}>

              Welcome
          </Text>

          <Text
            fontSize={FontSizes.Bigger}
            padding={'0 15px'}
            textAlign={'center'}
            width={'100%'}>

              {this.state.accountName}
          </Text>

          <SideMenuBottomContainer>

            <Text
              fontWeight={'bold'}
              fontSize={FontSizes.Small}
              padding={'0 0 5px 0'}>

                Quick Navigation
            </Text>

            <Text
              fontSize={FontSizes.Small}
              onClick={() => this.props.common.showIframePopup('https://www.afrion.com/terms-and-conditions?embedded=true')}>
                Terms and conditions
            </Text>

            <Text
              fontSize={FontSizes.Small}
              onClick={() => this.props.common.showIframePopup('https://www.afrion.com/cookies-policy?embedded=true')}>
                Cookies policy
            </Text>

            <Text
              fontSize={FontSizes.Small}
              onClick={() => this.props.common.showIframePopup('https://www.afrion.com/privacy-policy?embedded=true')}> 
                Privacy policy
            </Text>

            <Text
              fontSize={FontSizes.Small}
              onClick={() => this.onQuickNavClick('https://www.afrion.com')}>
                AfriOn.com
            </Text>

          </SideMenuBottomContainer>

        </SideMenu>

        <Content>

          <ContentGrid>

            <Accordion
              header={'Projects'}
              headerColor={Colors.White}
              headerPadding={'10px'}
              headerCenterText={true}
              backgroundColor={Colors.Blue3}
              contentBackgroundColor={Colors.White}
              borderRadius={'15px'}
              padding={'0 0 20px 0'}
              showArrow={false}
              isCollapsable={false}
              onHeaderClick={() => this.props.common.navigate('/projects/view')}>

                <AccordionContent
                  onClick={() => this.props.common.navigate('/projects/view')}>

                { Array.isArray(this.state.projectComponents) && this.state.projectComponents.length

                  ? <ProjectStatGrid>

                      {this.state.projectComponents}

                    </ProjectStatGrid>
                  
                  : <NoProjectContainer>

                      <Text
                        fontSize={FontSizes.Small}
                        padding={'0 0 15px 0'}>

                          Please create a project first.
                      </Text>

                      <Button
                        width={'100%'}
                        text={'Create new project'}
                        onClick={() => { this.props.common.navigate('/projects/view'); }}
                      />

                    </NoProjectContainer>
                }

                </AccordionContent>

            </Accordion>

            <Accordion
              header={'Contacts'}
              headerColor={Colors.White}
              headerPadding={'10px'}
              headerCenterText={true}
              backgroundColor={Colors.Orange2}
              contentBackgroundColor={Colors.White}
              borderRadius={'15px'}
              padding={'0 0 20px 0'}
              showArrow={false}
              isCollapsable={false}
              onHeaderClick={() => this.props.common.navigate('/contacts/chat')}>

                <AccordionContent
                  onClick={() => this.props.common.navigate('/contacts/chat')}>

                   { this.state.isContactsLoading
                   
                    ? <LoaderContainer>
                        <Loader/>
                      </LoaderContainer>

                    : <StatGrid>

                        <StatCard
                          stat={this.state.contactsData.totalContacts || 0}
                          text={'Total contacts'}
                          borderColor={Colors.Orange2}
                        />

                        <StatCard
                          stat={this.state.contactsData.totalContactsOnline || 0}
                          text={'Contacts online'}
                          borderColor={Colors.Orange2}
                        />

                        <StatCard
                          stat={this.state.contactsData.totalOpenChats || 0}
                          text={'Chats open'}
                          borderColor={Colors.Orange2}
                        />

                        <StatCard
                          stat={this.state.contactsData.totalUnreadMessages || 0}
                          text={'New messages'}
                          borderColor={Colors.Orange2}
                        />

                        <StatCard
                          stat={this.state.contactsData.totalPendingInvites || 0}
                          text={'Pending invites'}
                          borderColor={Colors.Orange2}
                        />

                        <StatCard
                          stat={this.state.contactsData.totalPendingShareRequests || 0}
                          text={'Pending share requests'}
                          borderColor={Colors.Orange2}
                        />

                      </StatGrid>
                  }

                </AccordionContent>

            </Accordion>

            <Accordion
              header={'Matches'}
              headerColor={Colors.White}
              headerPadding={'10px'}
              headerCenterText={true}
              backgroundColor={Colors.Green3}
              contentBackgroundColor={Colors.White}
              borderRadius={'15px'}
              padding={'0 0 20px 0'}
              showArrow={false}
              isCollapsable={false}
              onHeaderClick={() => this.props.common.navigate('/matches/view')}>

                <AccordionContent
                  onClick={() => this.props.common.navigate('/matches/view')}>

                  <StatGrid>

                    <StatCard
                      stat={this.state.matchesData.totalProjects || 0}
                      text={'Total available projects'}
                      borderColor={Colors.Green3}
                    />

                    <StatCard
                      stat={this.state.matchesData.fundingCount || 0}
                      text={'Projects with funding goal'}
                      borderColor={Colors.Green3}
                    />

                    <StatCard
                      stat={this.state.matchesData.employeeCount || 0}
                      text={'Projects with hiring goal'}
                      borderColor={Colors.Green3}
                    />

                    <StatCard
                      stat={this.state.matchesData.adviceCount || 0}
                      text={'Projects with mentoring goal'}
                      borderColor={Colors.Green3}
                    />

                    <StatCard
                      stat={this.state.matchesData.propositionProjectCount || 0}
                      text={'Proposition projects'}
                      borderColor={Colors.Green3}
                    />

                    <StatCard
                      stat={this.state.matchesData.collaborationProjectCount || 0}
                      text={'Collaboration projects'}
                      borderColor={Colors.Green3}
                    />

                  </StatGrid>

                </AccordionContent>

            </Accordion>

          </ContentGrid>

          <AccountAccordionContainer>

            <Accordion
              header={'Account'}
              headerColor={Colors.White}
              headerPadding={'10px'}
              headerCenterText={true}
              backgroundColor={Colors.Red3}
              contentBackgroundColor={Colors.White}
              borderRadius={'15px'}
              showArrow={false}
              isCollapsable={false}
              onHeaderClick={() => this.props.common.navigate('/account/view')}>

                <AccordionContent
                  onClick={() => this.props.common.navigate('/account/view')}>

                  <AccountsTipGrid>

                    <AccountTipCard
                      stat={`${this.state.profileScore}%`}
                      tip={this.state.profileScore < 100
                          ? 'The more information you add to your account, the higher your score will be. Aim for 100% completion so other users know how serious you are. We are also more likely to boost your account for others to see it more frequently if you have carefully filled all the fields.'
                          : 'Congratulations! Your account details are now complete. The AfriOn community users will now see your profile more frequently. We recommend updating these details regularly in case of change.'}
                      borderColor={Colors.Red3}
                    />

                  </AccountsTipGrid>

                </AccordionContent>

            </Accordion>

          </AccountAccordionContainer>

        </Content>

      </Container>

      </>
    );
  }
}

export const Home = withCommon(
  HomeUI, {
    addExitSafeguard: true
  }
);