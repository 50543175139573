import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  FontSizes,
  GoalTypes,
  Logger,
  ErrorToast,
  SuccessToast,
  Messages,
  Images,
  Image,
  Space,
  formatDateForDisplay,
  isValidProviderResult,
  numberWithCommas,
} from 'common';

import {
  Button,
  Popup,
  ProfileImage,
  ProjectChatHeader,
  PSmaller,
  Rating,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  MatchesProvider,
  ProjectsProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  border: 2px solid ${Colors.Green3};
  border-radius: 20px;
  padding: 20px 0;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  ${props => props.flex && `flex: ${props.flex};`}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SideContainer = styled.div`
  background-color: ${Colors.White};
  border-top: 2px solid ${Colors.Green3};
  border-left: 2px solid ${Colors.Green3};
  border-bottom: 2px solid ${Colors.Green3};
  border-radius: 35px 0 0 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  right: 0;
  top: -70px;
  padding: 15px 2px;
  height: 140px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${props => props.padding && `padding: ${props.padding};`};
`;

const TextRow = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  padding: 10px 0 0 0;
`;

const TextColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 10px 0 0;
  overflow: hidden;

  :last-child {
    padding: 0;
  }
`;

const Line = styled.div`
  background-color: ${Colors.Green3};
  position: relative;
  height: 2px;
  width: ${props => props.width || `75%`};
  margin: 20px auto;
`;

const EllipsisText = styled(PSmaller)`
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;

  ${props => !props.multiline && `white-space: nowrap;`};

  ${props => props.multiline && `
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;`
  }
`;

const FokkinSlideContainer = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  min-height: 290px;
  width: 100%;
  ${props => props.showPointer && `cursor: pointer;`}
`;

const GoalContainer = styled.div`
  padding: 0 0 15px 0;
`;

const MatchSlideSpace = styled.div`
  display: flex;
  flex: 1;
`;

const RadioSliderButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 10px 0;
  width: 100%;
`;

const RadioToggleContainer = styled.div`
  display: flex;
  border: 2px solid ${props => props.selected ? Colors.Green3 : Colors.Transparent};
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 4px;
`;

const RadioToggleCenter = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${Colors.Green3};
  border-radius: 4px;
  margin: 6px;
`;

const ActionImage = styled.div`
  background-color: ${props => props.backgroundColor || Colors.White};
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${Colors.Green2};
  border-radius: 12.5px;
  width: 25px;
  height: 25px;
  min-width: 25px;

  ${props => props.showCursor && 'cursor: pointer;'}
`;

const MatchLabel = styled.div`
  background-color: ${props => props.backgroundColor || Colors.Green};
  color: ${Colors.White};
  font-size: 11.2px;
  height: 20px;
  width: 100px;
  text-align: center;

  position: absolute;
  top: 9px;
  right: -27px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(40deg);
`;

const RadioToggle = props => (

  <RadioToggleContainer
    selected={props.selected}
    onClick={props.onClick}>

    <RadioToggleCenter/>

  </RadioToggleContainer>
);

const FokkinEmployeeGoal = props => (

  <GoalContainer>

    <Text
      fontSize={FontSizes.Small}
      fontWeight={'bold'}>
        Hiring
    </Text>

    <TextRow>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Role
        </PSmaller>

        <PSmaller
          fontStyle={'italic'}>
            {props.position || 'Not specified'}
        </PSmaller>

      </TextColumn>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Contract type
        </PSmaller>

        <PSmaller
          fontStyle={'italic'}>
            {props.contractType || 'Not specified'}
        </PSmaller>

      </TextColumn>

    </TextRow>

    <TextRow>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Hire Location
        </PSmaller>

        <PSmaller
          fontStyle={'italic'}>
            {props.country || 'Not specified'}
        </PSmaller>

      </TextColumn>

    </TextRow>

  </GoalContainer>
);

const FokkinFundingGoal = props => (

  <GoalContainer>

    <Text
      fontSize={FontSizes.Small}
      fontWeight={'bold'}>
        Funding
    </Text>

    <TextRow>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Amount
        </PSmaller>

        <EllipsisText>
            {`${numberWithCommas(props.amountFrom)}-${numberWithCommas(props.amountTo)} ${props.currency}` || 'Not specified'}
        </EllipsisText>

      </TextColumn>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Funding period
        </PSmaller>

        <PSmaller
          fontStyle={'italic'}>
            {formatDateForDisplay(props.requiredDate) || 'Not specified'}
        </PSmaller>

      </TextColumn>

    </TextRow>

  </GoalContainer>
);

const FokkinAdviceGoal = props => (

  <GoalContainer>

    <Text
      fontSize={FontSizes.Small}
      fontWeight={'bold'}>
        Mentoring
    </Text>

    <TextRow>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Subject
        </PSmaller>

        <EllipsisText>
            {props.description || 'Not specified'}
        </EllipsisText>

      </TextColumn>

      <TextColumn>

        <PSmaller
          fontWeight={'bold'}>
            Frequency
        </PSmaller>

        <EllipsisText>
            {props.frequency || 'Not specified'}
        </EllipsisText>

      </TextColumn>

    </TextRow>

  </GoalContainer>
);

export const MatchCard = props => {

  const [currentSlide, currentSlideSetState] = React.useState(0);
  const [showSavePopup, showSavePopupSetState] = React.useState(false);
  const [showHidePopup, showHidePopupSetState] = React.useState(false);
  const [showReportPopup, showReportPopupSetState] = React.useState(false);
  const [showSendInvitePopup, showSendInvitePopupSetState] = React.useState(false);

  const reportReason = React.useRef('');
  const inviteNote = React.useRef('');
  const selectedProject = React.useRef({});

  let project = props.project || {};
  const isCollaboration = project.nature === 1;
  project.goals = project.goals || [];

  const projectsList = (DataStore.get('MATCHES_CARDS_PROJECT_LIST') || []).filter(p => p.nature !== project.nature);

  const onProjectChange = (val) => {
    selectedProject.current = val;
  };

  const onRadioButtonClick = (index) => {
    currentSlideSetState(index);
  };

  const onSlideClick = () => {

    if (!Array.isArray(project.goals) || project.goals.length <= 1) {
      return;
    }
    
    const index = currentSlide === 0
      ? currentSlide + 1
      : 0;

    currentSlideSetState(index);
  };

  const onHideMatchClick = () => {
    showHidePopupSetState(true);
  };

  const onSaveMatchClick = () => {
    showSavePopupSetState(true);
  };

  const onReportMatchClick = () => {
    showReportPopupSetState(true);
  };

  const onSaveMatchConfirm = () => {

    showSavePopupSetState(false);

    const user = DataStore.get('LOGGED_IN_USER') || {};

    ProjectsProvider.saveProject(user.id, project.projectId)
      .then(ret => {

        if (isValidProviderResult(ret)) {

          SuccessToast.show('', 'Project successfully saved');

          props.onRemoveMatch && props.onRemoveMatch(project.projectId);

          return;
        }

        ErrorToast.show(Messages.ErrorHeading, ret.message || Messages.TechnicalError);

        Logger.error(ret.message || Messages.TechnicalError);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  const onHideMatchConfirm = () => {

    showHidePopupSetState(false);

    const user = DataStore.get('LOGGED_IN_USER') || {};

    ProjectsProvider.hideProject(user.id, project.projectId)
      .then(ret => {

        if (isValidProviderResult(ret)) {

          SuccessToast.show('', 'Project hidden');

          props.onRemoveMatch && props.onRemoveMatch(project.projectId);

          return;
        }

        ErrorToast.show(Messages.ErrorHeading, ret.message || Messages.TechnicalError);

        Logger.error(ret.message || Messages.TechnicalError);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  const onReportMatchConfirm = () => {

    showReportPopupSetState(false);

    const user = DataStore.get('LOGGED_IN_USER') || {};

    ProjectsProvider.reportProject(user.id, project.projectId, reportReason.current)
      .then(ret => {

        if (isValidProviderResult(ret)) {

          SuccessToast.show('', 'You have successfully reported the project');

          props.onRemoveMatch && props.onRemoveMatch(project.projectId);

          return;
        }

        ErrorToast.show(Messages.ErrorHeading, ret.message || Messages.TechnicalError);

        Logger.error(ret.message || Messages.TechnicalError);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  const onSendInvitationConfirm = () => {

    if (!selectedProject.current.value) {

      ErrorToast.show('Invalid form', 'Please select the project you want to match with');
      return;
    }

    showSendInvitePopupSetState(false);

    const user = DataStore.get('LOGGED_IN_USER') || {};

    MatchesProvider
      .sendInvite(
        user.id,
        project.projectId,
        inviteNote.current,
        selectedProject.current.value,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show('We were unable to send your invite', ret.message || Messages.TechnicalError);

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        props.onRemoveMatch && props.onRemoveMatch(project.projectId);

        SuccessToast.show('', 'Your invite was sent successfully');
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  const getGoalComponent = goal => {

    goal = goal || {};

    if (goal.type === GoalTypes.Employee) {

      return <FokkinEmployeeGoal key={`employee-${goal.id}`} {...goal} />;
    }

    if (goal.type === GoalTypes.Funding) {

      return <FokkinFundingGoal key={`funding-${goal.id}`} {...goal} />;
    }

    if (goal.type === GoalTypes.Advice) {

      return <FokkinAdviceGoal key={`advice-${goal.id}`} {...goal} />;
    }

    return (
      <Text
        fontSize={FontSizes.Smaller}
        padding={'0 0 20px 0'}>

          No information available
      </Text>
    );
  };

  let slides = [
    <FokkinSlideContainer
      key={'main-slide'}
      show={currentSlide === 0}
      showPointer={project.goals.length > 1}
      onClick={onSlideClick}>

      { !isCollaboration &&

        <>
          <Text
            color={Colors.Green3}
            fontWeight={'bold'}
            fontSize={FontSizes.Small}
            padding={'0 0 10px 0'}>

              Executive summary
          </Text>

          <EllipsisText
            margin={'0 0 10px 0'}
            multiline>

              {project.executiveSummary || 'No information available'}
          </EllipsisText>
        </>
      }

      <Text
        color={Colors.Green3}
        fontWeight={'bold'}
        fontSize={FontSizes.Small}
        padding={'0 0 10px 0'}>

          Primary goal
      </Text>

      {getGoalComponent(project.goals.length && project.goals[0])}

    </FokkinSlideContainer>
  ];

  let secondaryGoals = [];

  for (let i = 1; i < project.goals.length; i++) {
    secondaryGoals.push(getGoalComponent(project.goals[i]));
  }

  if (secondaryGoals.length) {

    slides.push(
      <FokkinSlideContainer
        key={'secondary-slide'}
        show={currentSlide === 1}
        showPointer={true}
        onClick={onSlideClick}>

        <Text
          color={Colors.Green3}
          fontWeight={'bold'}
          fontSize={FontSizes.Small}
          padding={'0 0 10px 0'}>

            Secondary goals
        </Text>

        {secondaryGoals}

      </FokkinSlideContainer>
    );
  }

  let radioButtons = [];

  for (let i = 0; i < slides.length; i++) {

    radioButtons.push(
      <RadioToggle
        key={i}
        selected={i === currentSlide}
        onClick={() => onRadioButtonClick(i)}
      />
    );
  }

  let socialButtons = [];
  const linkedAccounts =  project.linkedAccounts || [];

  for (let i = 0; i < linkedAccounts.length; i++) {

    let icon;

    if (linkedAccounts[i].linkedAccountsLookupId === 1) {
      icon = Images.Icons.TwitterDark;
    }

    if (linkedAccounts[i].linkedAccountsLookupId === 2) {
      icon = Images.Icons.FacebookDark;
    }

    if (linkedAccounts[i].linkedAccountsLookupId === 3) {
      icon = Images.Icons.InstagramDark;
    }
    
    if (linkedAccounts[i].linkedAccountsLookupId === 4) {
      icon = Images.Icons.LinkedInDark;
    }
    
    if (linkedAccounts[i].linkedAccountsLookupId === 5) {
      icon = Images.Icons.WebsiteDark;
    }

    if (icon) {
      
      socialButtons.push(
        <Image
          key={i}
          src={icon}
          height={'20px'}
          width={'20px'}
        />
      );

      if (i !== (linkedAccounts.length - 1)) {

        socialButtons.push(
          <Space
            key={`space-${i}`}
            height={'7px'}
            width={'20px'}
          />
        );
      }
    }
  }

  if (!socialButtons.length) {

    socialButtons.push(
      <Space
        key={`space`}
        height={'5px'}
        width={'20px'}
      />
    );
  }

  return (

    <>

    <Container>

      <ContentContainer>

        <HeaderContainer>

          <Rating
            rating={project.profileRating}
          />

          <ProfileImage
            image={project.profilePictureUrl}
            label={isCollaboration ? 'COLLABORATOR' :'PROPOSER'}
            labelBackgroundColor={isCollaboration ? Colors.Blue1 : Colors.Red1}
          />

          <SocialsContainer>

            {socialButtons}

          </SocialsContainer>

        </HeaderContainer>

        <Text
          fontWeight={'bold'}
          fontSize={FontSizes.Small}
          textAlign={'center'}>

            {`${project.name} ${project.surname}`}
        </Text>

        <Text
          fontSize={FontSizes.Small}
          textAlign={'center'}>

            {`${project.cityId || 'Not specified'}, ${project.country || 'Not specified'}`}
        </Text>

        <Line/>

        <Text
          color={Colors.Green3}
          fontWeight={'bold'}
          fontSize={FontSizes.Small}>

            Project information
        </Text>

        <TextRow>

          <TextColumn>

            <PSmaller
              fontWeight={'bold'}>
                Form
            </PSmaller>

            <EllipsisText>
              {project.type || 'Not specified'}
            </EllipsisText>

          </TextColumn>

          <TextColumn>

            <PSmaller
              fontWeight={'bold'}>
                Stage
            </PSmaller>

            <EllipsisText>
                {project.stage || 'Not specified'}
            </EllipsisText>

          </TextColumn>

        </TextRow>

        <TextRow>

          <TextColumn>

            <PSmaller
              fontWeight={'bold'}>
                Industry
            </PSmaller>

            <EllipsisText>
                {(project.industries.length && project.industries[0] && project.industries[0].display) || 'Not specified'}
            </EllipsisText>

            <PSmaller
              fontStyle={'italic'}>
                {(project.industries.length > 1 && `and ${project.industries.length - 1} more`)} &nbsp;
            </PSmaller>

          </TextColumn>

          <TextColumn>

            <PSmaller
              fontWeight={'bold'}>
                Country
            </PSmaller>

            <EllipsisText>
                {(project.locations.length && project.locations[0] && project.locations[0].display) || 'Not specified'}
            </EllipsisText>

            <PSmaller
              fontStyle={'italic'}>
                {(project.locations.length > 1 && `and ${project.locations.length - 1} more`)} &nbsp;
            </PSmaller>

          </TextColumn>

        </TextRow>

      </ContentContainer>

      { project.matchType === 'strong' && <MatchLabel>STRONG</MatchLabel> }
      { project.matchType === 'weak' && <MatchLabel backgroundColor={Colors.Gold}>WEAK</MatchLabel> }

      <Line
        width={'100%'}>

        <SideContainer>

          <ActionImage
            showCursor={!project.hiddenProjectCount}
            backgroundColor={!!project.hiddenProjectCount && Colors.Green3}
            image={Images.Icons.HideUserGreenCircle}
            onClick={(e) => !project.hiddenProjectCount && onHideMatchClick(e)}
          />

          <ActionImage
            showCursor={!project.savedProjectCount}
            backgroundColor={!!project.savedProjectCount && Colors.Green3}
            image={Images.Icons.SaveUserGreenCircle}
            onClick={(e) => !project.savedProjectCount && onSaveMatchClick(e)}
          />

          <ActionImage
            showCursor={!project.reportedProjectCount}
            backgroundColor={!!project.reportedProjectCount && Colors.Green3}
            image={Images.Icons.ReportUserGreenCircle}
            onClick={(e) => !project.reportedProjectCount && onReportMatchClick(e)}
          />

        </SideContainer>

      </Line>

      <ContentContainer
        flex={1}>

        {slides}

        <MatchSlideSpace/>

        { !!radioButtons.length &&

          <RadioSliderButtonContainer>

            {radioButtons}

          </RadioSliderButtonContainer>
        }

        <ButtonContainer>

          <Button
            border={`1px solid ${Colors.Black}`}
            hoverBorder={`1px solid ${Colors.Tan}`}
            text={'Full profile'}
            height={'40px'}
            width={'48%'}
            onClick={() => props.onFullProfileClick && props.onFullProfileClick(props.project || {})}
          />

          <Button
            border={`1px solid ${Colors.Black}`}
            hoverBorder={`1px solid ${Colors.Tan}`}
            text={'Send invite'}
            height={'40px'}
            width={'48%'}
            onClick={() => showSendInvitePopupSetState(true)}
          />

        </ButtonContainer>

      </ContentContainer>

      { showSavePopup &&

        <Popup
          header={'Save project'}
          headerBackgroundColor={Colors.Green3}
          maxWidth={'500px'}
          onClose={() => showSavePopupSetState(false)}>

            <ProjectChatHeader
              profileImage={project.profilePictureUrl}
              projectName={project.title}
              projectOwner={`${project.name} ${project.surname}`}
            />

            <Text
              fontSize={FontSizes.Small}
              padding={'30px 30px 120px 30px'}
              flex={'1'}>

                Do you want to save this project?
            </Text>

            <ButtonContainer
              backgroundColor={Colors.Green3}
              padding={'15px'}>

              <Button
                text={'Cancel'}
                height={'40px'}
                width={'48%'}
                onClick={() => showSavePopupSetState(false)}
              />

              <Button
                text={'Save project'}
                height={'40px'}
                width={'48%'}
                onClick={onSaveMatchConfirm}
              />

            </ButtonContainer>
        </Popup>
      }

      { showHidePopup &&

        <Popup
          header={'Hide project'}
          headerBackgroundColor={Colors.Green3}
          maxWidth={'500px'}
          onClose={() => showHidePopupSetState(false)}>

            <ProjectChatHeader
              profileImage={project.profilePictureUrl}
              projectName={project.title}
              projectOwner={`${project.name} ${project.surname}`}
            />

            <Text
              fontSize={FontSizes.Small}
              padding={'30px 30px 120px 30px'}
              flex={'1'}>

                Do you want to hide this project?
            </Text>

            <ButtonContainer
              backgroundColor={Colors.Green3}
              padding={'15px'}>

              <Button
                text={'Cancel'}
                height={'40px'}
                width={'48%'}
                onClick={() => showHidePopupSetState(false)}
              />

              <Button
                text={'Hide project'}
                height={'40px'}
                width={'48%'}
                onClick={onHideMatchConfirm}
              />

            </ButtonContainer>
        </Popup>
      }

      { showReportPopup &&

        <Popup
          header={'Report project'}
          headerBackgroundColor={Colors.Green3}
          maxWidth={'500px'}
          onClose={() => showReportPopupSetState(false)}>

            <ProjectChatHeader
              profileImage={project.profilePictureUrl}
              projectName={project.title}
              projectOwner={`${project.name} ${project.surname}`}
            />

            <Text
              fontSize={FontSizes.Small}
              padding={'20px 20px 0 20px'}>

                Report project reason:
            </Text>

            <div style={{
              padding: '20px 20px 10px 20px',
              flex: 1,
            }}>

              <TextInput
                width={'100%'}
                label={''}
                multiline={true}
                placeholder={'Provide a reason for reporting this project...'}
                onChange={val => { reportReason.current = val }}
                showCharCounter={true}
                charCounterColor={Colors.Green3}
                charCounterMax={5000}
              />

            </div>

            <ButtonContainer
              backgroundColor={Colors.Green3}
              padding={'15px'}>

              <Button
                text={'Cancel'}
                height={'40px'}
                width={'48%'}
                onClick={() => showReportPopupSetState(false)}
              />

              <Button
                text={'Report project'}
                height={'40px'}
                width={'48%'}
                onClick={onReportMatchConfirm}
              />

            </ButtonContainer>
        </Popup>
      }

    </Container>

    { showSendInvitePopup &&

      <Popup
        header={'Invite'}
        headerBackgroundColor={Colors.Green3}
        maxWidth={'500px'}
        onClose={() => showSendInvitePopupSetState(false)}>

          <ProjectChatHeader
            profileImage={project.profilePictureUrl}
            projectName={project.title}
            projectOwner={`${project.name} ${project.surname}`}
          />

          <Text
            fontSize={FontSizes.Small}
            padding={'20px 20px 0 20px'}>

              Select project to match with:
          </Text>

          <Select
            padding={'20px 20px 0 20px'}
            width={'100%'}
            options={projectsList}
            onChange={onProjectChange}
          />

          <Text
            fontSize={FontSizes.Small}
            padding={'20px 20px 0 20px'}>

              (Optional) Add an invite note:
          </Text>

          <div style={{
            padding: '20px 20px 10px 20px',
            flex: 1,
          }}>

            <TextInput
              width={'100%'}
              label={''}
              multiline={true}
              placeholder={'Provide a note for the user to see with the intive...'}
              onChange={val => { inviteNote.current = val }}
              showCharCounter={true}
              charCounterColor={Colors.Green3}
              charCounterMax={5000}
            />

          </div>

          <ButtonContainer
            backgroundColor={Colors.Green3}
            padding={'15px'}>

            <Button
              text={'Cancel'}
              height={'40px'}
              width={'48%'}
              onClick={() => showSendInvitePopupSetState(false)}
            />

            <Button
              text={'Invite'}
              height={'40px'}
              width={'48%'}
              onClick={onSendInvitationConfirm}
            />

          </ButtonContainer>
      </Popup>
    }

    </>
  );
};