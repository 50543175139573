import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  Image,
  Images,
  Messages,
  ErrorToast,
  Logger,
  isValidProviderResult,
  getImageByExtension,
} from 'common';

import {
  Text,
  InformationBubble,
} from 'common/components';

import {
  MediaProvider,
  ProjectsProvider,
} from 'providers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const UploadButton = styled.div`
  background-color: ${Colors.Gray2};
  cursor: pointer;
  height: 200px;
  width: 200px;
  margin: 0 15px 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const UploadedDocTile = styled.div`
  border: 1px solid ${Colors.Gray2};
  position: relative;
  margin: 0 15px 15px 0;
  height: 200px;
  width: 200px;

  ${props => props.src &&
  `
    background-image: url(${props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `}

  ${props => props.isDeleting && 'opacity: 0.5;'}
`;

const DeleteFileContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
`;

const UploadInput = styled.input`
  display: none;
`;

export class FileUpload extends React.Component {

  state = {
    fileTiles: [],
    isUploading: false,
    uploadPercentage: 0,
    showInformation: false,
  };

  uploadInput = React.createRef();
  files = [];
  isDeleting = false;
  maxUpload = this.props.maxUpload || 10;

  onShowInformation = () => {

    this.setState({
      showInformation: true
    });
  };

  onHideInformation = () => {

    this.setState({
      showInformation: false
    });
  };

  onUploadClick = () => {

    if (this.state.isUploading) {
      return;
    }

    this.uploadInput
    && this.uploadInput.current
    && typeof this.uploadInput.current.click === 'function'
    && this.uploadInput.current.click();
  };

  onDeleteFileClick = (id) => {

    if (this.isDeleting) {
      return;
    }

    let file = this.files.find(f => f.id === id);

    if (file) {

      file.isDeleting = true;

      this.setState({
        fileTiles: this.createFileTiles(this.files),
      });
    }

    this.isDeleting = true;

    MediaProvider.deleteFile(id)
      .then(ret => {

        this.isDeleting = false;

        if (!isValidProviderResult(ret)) {

          ErrorToast.show(
            null,
            'We were unable to remove your file, please try again.'
          );

          Logger.error(ret.message || 'Delete file failed');

          return;
        }

        this.files = this.files.filter(f => f.id !== id);

        this.setState({
          fileTiles: this.createFileTiles(this.files),
        });
      })
      .catch(e => {

        this.isDeleting = false;

        ErrorToast.show(
          null,
          'We were unable to remove your file, please try again.'
        );

        Logger.error(e);
      });
  };

  onFileProgressChange = (e) => {

    if (!e || !e.loaded || !e.total) {
      return;
    }

    this.setState({
      isUploading: true,
      uploadPercentage: Math.round((e.loaded / e.total) * 100),
    });
  };

  onChange = e => {

    if (!e || !e.target || !e.target.files) {
      return;
    }

    let currentFile;

    for (let i = 0; i < e.target.files.length; i++) {

      currentFile = e.target.files[i];

      this.upload(currentFile);
    }
  };

  upload = (file) => {

    if (file.size > 10 * 1024 * 1024) {

      ErrorToast.show('File too big', 'Please make sure that your file is not bigger than 10MB.');
      return;
    }

    MediaProvider.uploadFile(
      this.props.project.id,
      file,
      '',
      this.onFileProgressChange,
    )
    .then(ret => {

      if (isValidProviderResult(ret)) {

        this.getProjectMedia();

        typeof this.props.onUploadSuccess === 'function' && this.props.onUploadSuccess(file);
        return;
      }

      typeof this.props.onUploadFailed === 'function' && this.props.onUploadFailed(ret.message || Messages.FileUploadError);
    })
    .catch(e => {
      typeof this.props.onUploadFailed === 'function' && this.props.onUploadFailed((e && e.message) || Messages.FileUploadError);
    });
  };

  getProjectMedia = () => {

    ProjectsProvider.getProjectMedia(this.props.project.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show(
            null,
            'We were unable to retrieve your uploads, please try again later.'
          );

          Logger.error(ret.message || 'Retrieve uploads failed');

          return;
        }

        this.props.onLoaded && this.props.onLoaded();

        this.files = ret.data || [];

        this.setState({
          fileTiles: this.createFileTiles(ret.data),
          isUploading: false,
          uploadPercentage: 0,
        });
      })
      .catch(e => {

        ErrorToast.show(
          null,
          'We were unable to retrieve your uploads, please try again later.'
        );

        Logger.error(e);
      });
  };

  createFileTiles = (files) => {

    if (!Array.isArray(files) || !files.length) {
      return [];
    }

    let fileTiles = [];
    let file;
    let tileImage;
    let tilePadding;

    for (let i = 0; i < files.length; i++) {

      file = files[i];

      if (!file || typeof file.url !== 'string') {
        continue;
      }

      const fileId = file.id;

      tileImage = getImageByExtension(file.url);

      fileTiles.push(
        <UploadedDocTile
          key={i}
          src={tileImage}
          padding={tilePadding}
          isDeleting={file.isDeleting}>

            <DeleteFileContainer>

              <Image
                height={'100%'}
                src={Images.Icons.Close}
                onClick={() => { this.onDeleteFileClick(fileId); }}
              />

            </DeleteFileContainer>
        
        </UploadedDocTile>
      );
    }

    return fileTiles;
  };

  componentDidMount() {
    this.getProjectMedia();
  }

  render() {

    return (

      <Container
        id={this.props.id}>

        <Text
          color={Colors.White}
          fontSize={FontSizes.Small}
          padding={'10px 0'}>

            {`Media attachments ${this.state.fileTiles.length}/${this.maxUpload}`}
        </Text>

        <UploadInput
          ref={this.uploadInput}
          type={'file'}
          accept={'image/png,image/jpeg,application/pdf,.doc,.docx'}
          onChange={this.onChange}
        />

        <TileContainer>

          {this.state.fileTiles}

          { this.state.fileTiles.length < this.maxUpload &&

            <UploadButton
              onClick={this.onUploadClick}>

                { this.state.isUploading

                  ? <React.Fragment>

                      <Text
                        color={Colors.Gray8}
                        fontSize={FontSizes.Big}>
                          {this.state.uploadPercentage}%
                      </Text>

                      <Text
                        color={Colors.Gray8}
                        fontSize={FontSizes.Small}>
                          Uploading...
                      </Text>

                    </React.Fragment>
                  
                  : <Image
                      height={'40px'}
                      src={Images.Icons.PlusCircle}
                    />
                }

                <InformationBubble
                  id={this.props.id}
                  height={'34x'}
                  width={'34px'}
                  padding={'8px'}
                  position={'absolute'}
                  top={'0px'}
                  right={'0px'}
                />

            </UploadButton>
          }

        </TileContainer>

      </Container>
    );
  }
}