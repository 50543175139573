import 
  React, {
  useState,
} from 'react';

import
  DatePickerComponent
from 'react-date-picker';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
} from 'common';

import {
  PSmaller,
  Text,
  InformationBubble,
} from 'common/components';

import './datePicker.css';

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 10px 0'};
  display: flex;
  flex-direction: column;

  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  top: 2px;
  right: 3px;
  position: relative;
  text-align: right;
`;

const LabelContainer = styled.div`
  display: flex;
  position: relative;
`;

export const DatePicker = props => {

  const [value, valueSetState] = useState(
    props.defaultValue
      ? new Date(props.defaultValue)
      : null
    );

  const onChange = date => {

    typeof props.onChange === 'function' && props.onChange(date);

    valueSetState(date);
  };

  React.useEffect(() => {

    valueSetState(
      props.defaultValue
        ? new Date(props.defaultValue)
        : null
    );

  }, [props.defaultValue]);

  return (

    <Container
      id={props.id}
      margin={props.margin}
      flex={props.flex}
      maxWidth={props.maxWidth}>

      { props.label &&
        
        <LabelContainer>

          <Text
            color={props.labelColor || Colors.Black}
            fontSize={FontSizes.Small}
            padding={'0 0 10px 0'}
            fontWeight={'bold'}>

              {props.label}
          </Text>

          <InformationBubble
            id={props.id}
          />

        </LabelContainer>
      }

      <DatePickerComponent
        onChange={onChange}
        value={value}
        showLeadingZeros={true}
        format={'dd/MM/y'}
        clearIcon={null}
        className={props.hasError ? 'react-date-picker__error' : ''}
        maxDate={props.maxDate}
      />

      { props.hasError && props.errorMessage &&

        <ErrorMessage>

          <PSmaller
            color={Colors.Red}>

              {props.errorMessage}
          </PSmaller>

        </ErrorMessage>
      }

    </Container>
  );
}