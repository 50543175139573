import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  ErrorToast,
  Environment,
  Logger,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  withCommon,
} from 'common/hocs';

import {
  PaymentProvider,
} from 'providers';

import {
  PaymentProgressStepper,
} from 'ui/payment/components';

import {
  StepperContainer,
} from './paymentStyled';

const Container = styled.div`
  background-color: ${Colors.White};
  width: 100%;
`;

const IdentityFrame = styled.iframe`
  height:100%;
  width:100%;
`;

const IframeContainer = styled.div`
  position: absolute;
  top: 200px;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PaymentLoginRegisterUI = props => {

  const priceId = DataStore.get('price_id');

  const initiateCheckoutSession = () => {

    const user = DataStore.get('LOGGED_IN_USER') || DataStore.get('REGISTERED_USER');

    if (user.paymentCustomerId) {
      
      PaymentProvider.createPortalSession(Environment.channel)
      .then(ret => {

        if (isValidProviderResult(ret) && ret.data.redirect) {

          window.location.href = ret.data.redirect;
          return;
        }

        ErrorToast.show();
        Logger.error(ret.data.message);
      })
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  
      return;
    }

    PaymentProvider
      .getPaymentUrl(user.id, priceId, Environment.channel)
      .then(ret => {

        if (!isValidProviderResult(ret) || !ret.data.redirect) {

          ErrorToast.show();
          Logger.error(ret.error || Messages.TechnicalError);
          return;
        }

        window.location.href = ret.data.redirect;
      })
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  };

  React.useEffect(() => {

    window.addEventListener('message', (event) => {

      if (event.data === 'login_success' || event.data === 'register_success') {
        initiateCheckoutSession();
      }
    }, false);

    return () => {
      window.removeEventListener('message');
    };
  }, []); // eslint-disable-line

  return (
    <Container>

      <StepperContainer>

        <PaymentProgressStepper
          selectedIndex={1}
          title={'Login / Register'}
        />

      </StepperContainer>

      <IframeContainer>

        <IdentityFrame
          frameBorder={0}
          src={'/identity/login?fromPayment=true'}
        />

      </IframeContainer>

    </Container>
  );
};

export const PaymentLoginRegister = withCommon(
  PaymentLoginRegisterUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
