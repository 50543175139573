export function isValidProviderResult(res) {

  if (res.status === 401 && res.message === 'jwt expired') {

    window.showUnauthenticatedPrompt();
  }

  return res.ok && !!res.data;
}

export function isValidEmail(email) {

  if (typeof email !== 'string') {
    return false;
  }

  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function isValidPassword(password) {

  return password.match(/[a-z]/g) 
    && password.match(/[A-Z]/g)
    && password.match(/[0-9]/g)
    && password.match( /[^a-zA-Z\d]/g)
    && password.length >= 8;
}