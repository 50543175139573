import
  React
from 'react';

import {
  DataStore,
  Logger,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  AccountsSideMenu,
} from './AccountsSideMenu';

import {
  Container,
  LeftContainer,
  RightContainer,
} from 'ui/account/accountStyled';

import {
  UserProvider,
} from 'providers';

export const AccountScreen = props => {

  const [score, scoreSetState] = React.useState(DataStore.get('PROFILE_SCORE') || 'Loading score...');

  const load = () => {

    const profileScore = DataStore.get('PROFILE_SCORE');

    if (!props.reloadScore && profileScore !== null && profileScore !== undefined) {

      scoreSetState(profileScore);
      return;
    }

    const user = DataStore.get('LOGGED_IN_USER') || {};

    UserProvider.getProfileScore(user.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          scoreSetState('Unable to load score.');

          return;
        }

        DataStore.set('PROFILE_SCORE', ret.data.profileScore);

        scoreSetState(ret.data.profileScore);
      })
      .catch(e => {

        Logger.error(e);

        scoreSetState('Unable to load score.');
      });
  };

  React.useEffect(
    load,
    [props.reloadScore],
  );

  return (

    <Container>

      <LeftContainer>

        <AccountsSideMenu
          profileScore={score}
          onShowIframePopup={props.onShowIframePopup}
        />

      </LeftContainer>

      <RightContainer
        id={'account-right-container'}>

        {props.children}

      </RightContainer>

    </Container>
  );
}