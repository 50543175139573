import {
  Environment,
  resolveResponse,
  resolveRejected,
  isValidProviderResult,
  formatStatForDisplay,
} from 'common';

import {
  get,
} from 'providers/axios';

export class DashboardProvider {

  static async getData() {

    try {

      const response = await get(`${Environment.apiHost}dashboard-data`);

      let ret = resolveResponse(response);

      ret.ok = isValidProviderResult(ret) && Array.isArray(ret.data.matchesData) && !!ret.data.matchesData.length;

      if (!ret.ok) {
        return ret;
      }

      const matchesData = ret.data.matchesData[0];

      matchesData.collaborationProjectCount = matchesData.collaborationProjectCount || 0;
      matchesData.propositionProjectCount = matchesData.propositionProjectCount || 0;

      ret.data.matchesData.totalProjects = formatStatForDisplay(matchesData.collaborationProjectCount + matchesData.propositionProjectCount);
      ret.data.matchesData.collaborationProjectCount = formatStatForDisplay(matchesData.collaborationProjectCount);
      ret.data.matchesData.propositionProjectCount = formatStatForDisplay(matchesData.propositionProjectCount);
      ret.data.matchesData.adviceCount = formatStatForDisplay(matchesData.adviceCount);
      ret.data.matchesData.employeeCount = formatStatForDisplay(matchesData.employeeCount);
      ret.data.matchesData.fundingCount = formatStatForDisplay(matchesData.fundingCount);

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async sanityCheck() {
 
    try {
      const response = await get(`${Environment.apiHost}sanity-check`);
      return resolveResponse(response);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }
}