import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceMediaQueries,
  DataStore,
  FontSizes,
  MenuItems,
  ErrorToast,
  Logger,
  Messages,
  Space,
  Images,
  Image,
  MobileButtonContainer,
  RoundButton,
  isValidProviderResult,
  formatDateForDisplay,
  addColorTransparency,
} from 'common';

import {
  Accordion,
  Button,
  H3,
  PSmall,
  Text,
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountScreen,
  EducationBar,
  ProfessionalExperienceBar,
  LinkedAccountsBar,
} from 'ui/account/components';

import {
  ActionContainer,
  ActionButtonContainer,
  HighestLevelContainer,
  NoItemsTextContainer,
  ContentContainer,
  RowContainer,
  TextContainer,
  DesktopSpace,
  MobileSpace,
  ProfilePicContentContainer,
  LinkedAccountContainer,
} from './accountStyled';

import {
  UserProvider,
} from 'providers';

const ProfileImage = styled.div`
  background-color: ${Colors.Gray2};
  border-radius: 100px;
  height: 200px;
  width: 200px;
  background-image: url(${props => props.picUrl || Images.Icons.ProfilePlaceholder});
  background-position: center;
  background-size: cover;
  margin: 0 auto;

  @media ${DeviceMediaQueries.Laptop} {
    margin: 0 0 15px 0;
  }
`;

export const DesktopInputPlaceholder = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Laptop} {
    display: flex;
    flex: 1;
  }
`;

const CVContainerOverlay = styled.div`
  background-color: ${addColorTransparency(Colors.Black, 0.6)};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const CVContainer = styled.div`
  position: absolute;
  height: 90%;
  width: 90%;
`;

const CVCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 15px 0;
  width: 100%;
`;

const IFrameContainer = styled.div`
  background-color: ${Colors.Black};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IFrame = styled.iframe`
  border: 1px solid ${Colors.Gray2};
  height: 100%;
  width: 100%;
  position: absolute;
`;

const IFrameLoadingContainer = styled.div`
  position: absolute;
`;

class AccountViewUI extends React.Component {

  state = {
    languageList: [],
    educationBars: [],
    experienceBars: [],
    linkedAccountsBars: [],
    skillsList: [],
    showCv: false,
  };

  accountInformation = {};
  educationalHistory = [];
  professionalExperienceList = [];
  linkedAccountsList = [];

  highestEducation = {};

  onEditAccountClick = () => {
    this.props.common.navigate('/account/edit');
  };

  createEducationBars = () => {

    if (!Array.isArray(this.educationalHistory) || !this.educationalHistory.length) {
      return [];
    }

    let educationBars = [];
    let education;

    for (let i = 0; i < this.educationalHistory.length; i++) {

      education = this.educationalHistory[i];

      if (!education) {
        continue;
      }

      educationBars.push(
        <EducationBar
          key={i}
          data={education}
          onEditClick={this.onEditEducationClick}
          onDeleteClick={this.onDeleteEducationClick}
          hideButtons={true}
        />
      );
    }

    return educationBars;
  };

  createExperienceBars = () => {

    if (!Array.isArray(this.professionalExperienceList) || !this.professionalExperienceList.length) {
      return [];
    }

    let experienceBars = [];
    let experience;

    for (let i = 0; i < this.professionalExperienceList.length; i++) {

      experience = this.professionalExperienceList[i];

      if (!experience) {
        continue;
      }
      
      experienceBars.push(
        <ProfessionalExperienceBar
          key={i}
          data={experience}
          onEditClick={this.onEditExperienceClick}
          onDeleteClick={this.onDeleteExperienceClick}
          hideButtons={true}
        />
      );
    }

    return experienceBars;
  };

  createLinkedAccountsBars = () => {

    if (!Array.isArray(this.linkedAccountsList) || !this.linkedAccountsList.length) {
      return [];
    }

    let linkedAccountsBars = [];
    let account;

    for (let i = 0; i < this.linkedAccountsList.length; i++) {

      account = this.linkedAccountsList[i];

      if (!account) {
        continue;
      }

      linkedAccountsBars.push(
        <LinkedAccountsBar
          key={i}
          data={account}
          onEditClick={this.onEditLinkedAccountClick}
          onDeleteClick={this.onDeleteLinkedAccountClick}
          hideButtons={true}
        />
      );
    }

    return linkedAccountsBars;
  };

  createLanguageList = () => {

    if (!Array.isArray(this.accountInformation.languages) || !this.accountInformation.languages.length) {

      return ['Not specified'];
    }

    let languageList = [];
    let language;

    for (let i = 0; i < this.accountInformation.languages.length; i++) {

      language = this.accountInformation.languages[i];

      if (!language) {
        continue;
      }

      languageList.push(language.display);
    }

    return languageList;
  };

  createSkillsList = () => {

    if (!Array.isArray(this.accountInformation.skills) || !this.accountInformation.skills.length) {

      return ['Not specified'];
    }

    let skillsList = [];
    let skill;

    for (let i = 0; i < this.accountInformation.skills.length; i++) {

      skill = this.accountInformation.skills[i];

      if (!skill) {
        continue;
      }

      skillsList.push(skill.display);
    }

    return skillsList;
  };

  load = async () => {

    this.props.common.showLoading();

    let ret = await UserProvider.getUserInformation(this.props.common.user.id, true);

    if (!isValidProviderResult(ret)) {

      this.props.common.hideLoading();

      ErrorToast.show();

      Logger.error(ret.message || Messages.TechnicalError);

      return;
    }

    const loggedInUser = DataStore.get('LOGGED_IN_USER') || {};
    loggedInUser.packageId = ret.data.packageId;
    DataStore.set('LOGGED_IN_USER', loggedInUser);

    DataStore.set('ACCOUNT_INFORMATION', ret);

    if (Array.isArray(ret.data.account) && ret.data.account.length) {
      this.accountInformation = ret.data.account[ret.data.account.length - 1] || {};
      this.highestEducation = this.accountInformation.highestEducation || {};
    }

    this.educationalHistory = ret.data.educationalHistory || [];
    this.professionalExperienceList = ret.data.professionalExperience || [];
    this.linkedAccountsList  = ret.data.linkedAccounts || [];

    const educationBars = this.createEducationBars();
    const experienceBars = this.createExperienceBars();
    const linkedAccountsBars = this.createLinkedAccountsBars();
    const languageList = this.createLanguageList();
    const skillsList = this.createSkillsList();

    this.setState({
      educationBars,
      experienceBars,
      linkedAccountsBars,
      languageList,
      skillsList,
    });

    this.props.common.hideLoading();
  };

  componentDidMount() {

    this.load()
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);

        this.props.common.hideLoading();
      });
  }

  render() {

    const personalSummary = this.accountInformation.aboutMe || 'Not specified';

    const currentWorkStatus = (this.accountInformation.currentWorkStatus
      && this.accountInformation.currentWorkStatus.display)
      || 'Not specified';

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}
        reloadScore={true}>

        <ActionContainer>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Edit information'}
              onClick={this.onEditAccountClick}
            />

          </ActionButtonContainer>

        </ActionContainer>

        <DesktopSpace
          height={'25px'}
        />

        <Accordion
          header={'Personal information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <ProfilePicContentContainer>

            <div>

              <PSmall
                padding={'0 0 10px 0'}
                fontWeight={'bold'}>

                  Profile picture
              </PSmall>

              <ProfileImage
                picUrl={this.accountInformation.profilePictureUrl}
              />

            </div>

            <Space
              size={'25px'}
            />

            <div style={{ flex: 1 }}>

              <RowContainer>

                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Name
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {this.accountInformation.name || 'Not specified'}
                  </Text>
                  
                </TextContainer>

                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Surname
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {this.accountInformation.surname || 'Not specified'}
                  </Text>
                  
                </TextContainer>

                <DesktopInputPlaceholder/>
              
              </RowContainer>

              <RowContainer>
              
                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Country
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {this.accountInformation.country || 'Not specified'}
                  </Text>
                  
                </TextContainer>
                
                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      City
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {this.accountInformation.city || 'Not specified'}
                  </Text>
                  
                </TextContainer>

                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Language(s)
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                    {this.state.languageList.join(', ') || 'Not specified'}

                  </Text>

                </TextContainer>

              </RowContainer>

              <RowContainer>
              
                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Contact number
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {this.accountInformation.phoneNumber || 'Not specified'}
                  </Text>
                  
                </TextContainer>
                
                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Date of birth
                  </Text>

                  <Text
                    fontSize={FontSizes.Small}
                    padding={'0 0 15px 0'}>

                      {formatDateForDisplay(this.accountInformation.dateOfBirth) || 'Not specified'}
                  </Text>
                  
                </TextContainer>

                <TextContainer>

                  <Text
                    fontSize={FontSizes.Small}
                    fontWeight={'bold'}
                    padding={'2px 0 15px 0'}>

                      Curriculum vitae
                  </Text>

                  { this.accountInformation.cvUrl 
                  
                    ? <Button
                        fontSize={FontSizes.Smaller}
                        text={'View CV'}
                        width={'100%'}
                        height={'40px'}
                        margin={'0 0 15px 0'}
                        onClick={() => this.setState({ showCv: true })}
                      />

                    : <Text
                        fontSize={FontSizes.Small}
                        padding={'0 0 15px 0'}>

                          No CV uploaded
                      </Text>
                  }
                </TextContainer>

              </RowContainer>

            </div>

            </ProfilePicContentContainer>

            <RowContainer>

              <TextContainer>

                <Text
                  fontSize={FontSizes.Small}
                  fontWeight={'bold'}
                  padding={'2px 0 15px 0'}>

                    Personal summary
                </Text>

                <Text
                  fontSize={FontSizes.Small}
                  padding={'0 0 2px 0'}>

                    {personalSummary}
                </Text>

              </TextContainer>

            </RowContainer>

          </ContentContainer>
          
        </Accordion>

        <Space
          size={'25px'}
        />

        <Accordion
          header={'Educational information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <HighestLevelContainer>

              <Text
                fontSize={FontSizes.Small}
                fontWeight={'bold'}
                padding={'2px 0 15px 0'}>

                  Highest level of education
              </Text>

              <Text
                fontSize={FontSizes.Small}
                padding={'0 0 20px 0'}>

                {(this.highestEducation && this.highestEducation.display) || 'N/A'}
              </Text>

            </HighestLevelContainer>

            <H3>
                Education
            </H3>

            { Array.isArray(this.state.educationBars) && this.state.educationBars.length

              ? this.state.educationBars

              : <NoItemsTextContainer>

                  <Text
                    fontSize={FontSizes.Small}>

                      Seems like you have not listed any previous education yet.
                  </Text>

                  <br/>

                  <Text
                    color={Colors.Gray6}
                    fontSize={FontSizes.Smaller}
                    padding={'0 0 10px 0'}>

                      Use the "add" button to add your previous education to the list.
                  </Text>

                </NoItemsTextContainer>
              }

          </ContentContainer>
          
        </Accordion>

        <Space
          size={'25px'}
        />

        <Accordion
          header={'Professional information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <RowContainer
              showBorderBottom={true}>
            
              <TextContainer>

                <Text
                  fontSize={FontSizes.Small}
                  fontWeight={'bold'}
                  padding={'2px 0 15px 0'}>

                    Current work status
                </Text>

                <Text
                  fontSize={FontSizes.Small}
                  padding={'0 0 15px 0'}>

                    {currentWorkStatus}
                </Text>
                
              </TextContainer>

              <TextContainer>

                <Text
                  fontSize={FontSizes.Small}
                  fontWeight={'bold'}
                  padding={'2px 0 15px 0'}>

                    Skills
                </Text>

                <Text
                  fontSize={FontSizes.Small}
                  padding={'0 0 15px 0'}>

                  {this.state.skillsList.join(', ') || 'Not specified'}

                </Text>

              </TextContainer>

            </RowContainer>

            <H3>
                Experience
            </H3>

            { Array.isArray(this.state.experienceBars) && this.state.experienceBars.length

              ? this.state.experienceBars

              : <NoItemsTextContainer>

                  <Text
                    fontSize={FontSizes.Small}>

                      Seems like you have not listed any previous experience yet.
                  </Text>

                  <br/>

                  <Text
                    color={Colors.Gray6}
                    fontSize={FontSizes.Smaller}
                    padding={'0 0 10px 0'}>

                      Use the "add" button to add your previous experience to the list.
                  </Text>

                </NoItemsTextContainer>
              }

          </ContentContainer>

        </Accordion>

        <Space
          size={'25px'}
        />

        <Accordion
          header={'Linked accounts'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            { Array.isArray(this.state.linkedAccountsBars) && this.state.linkedAccountsBars.length

              ? <LinkedAccountContainer>{this.state.linkedAccountsBars}</LinkedAccountContainer>

              : <Text
                  fontSize={FontSizes.Small}>

                    Seems like you have not listed any linked accounts yet.
                </Text>
            }

          </ContentContainer>
          
        </Accordion>

        <MobileButtonContainer>

          <RoundButton
            onClick={this.onEditAccountClick}>

            <Image
              src={Images.Icons.EditPencil}
              height={'60px'}
              width={'60px'}
            />

          </RoundButton>

        </MobileButtonContainer>

        <MobileSpace/>

        { this.state.showCv &&
        
          <CVContainerOverlay
            onClick={() => this.setState({ showCv: false })}>

            <CVContainer>

              <CVCloseContainer>

                <Image
                  src={Images.Icons.Close}
                  height={'20px'}
                  onClick={() => this.setState({ showCv: false })}
                />

              </CVCloseContainer>

              <IFrameContainer>

                <IFrameLoadingContainer>

                  <Loader
                    backgroundColor={Colors.Black}
                    spinnerColor={Colors.White}
                  />

                </IFrameLoadingContainer>

                <IFrame
                  src={`https://docs.google.com/gview?url=${this.accountInformation.cvUrl}&embedded=true`}
                />

              </IFrameContainer>

            </CVContainer>

          </CVContainerOverlay>
        }

      </AccountScreen>
    );
  }
}

export const AccountView = withCommon(
  AccountViewUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);