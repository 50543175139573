import
  React
from 'react';

import {
  Colors,
  FontSizes,
  ErrorToast,
  Logger,
  Messages,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  generateYearArray,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Checkbox,
  Loader,
  Popup,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  Container,
  ItemRow,
  Item,
  PopupContent,
  ActionContainer,
  ActionButtonContainer,
  LoadingContainer,
  DeleteContainer,
} from './accountComponentsStyled';

import {
  LookupProvider,
  UserProvider,
} from 'providers';

export class EducationForm extends React.Component {

  state = {
    degreeLevelList: [],
    yearList: [],
    monthList: [],
    countryList: [],
    degreeLevelError: '',
    degreeTitleError: '',
    countryError: '',
    institutionNameError: '',
    startMonthError: '',
    endMonthError: '',
    startYearError: '',
    endYearError: '',
    descriptionError: '',
    isInProgress: !!(this.props.data && this.props.data.isInProgress),
    isLoading: false,
    showDeletePopup: false,
  };

  data = this.props.data || {};

  onSaveChangesClick = () => {

    const errors = this.validate();

    if (Object.keys(errors).length) {
      return;
    }

    this.saveChanges()
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  };

  onInProgressClick = checked => {

    this.data.shouldSave = true;

    this.data.isInProgress = checked;

    this.props.onChange && this.props.onChange();

    this.setState({
      isInProgress: checked,
    });
  };

  onDegreeLevelChange = val => {

    this.data.shouldSave = true;

    this.data.degreeLevel = val;

    this.props.onChange && this.props.onChange();

    if (this.state.degreeLevelError) {

      this.setState({
        degreeLevelError: '',
      });
    }
  };

  onInstituteNameChange = val => {

    this.data.shouldSave = true;

    this.data.institutionName = val;

    this.props.onChange && this.props.onChange();

    if (this.state.institutionNameError) {

      this.setState({
        institutionNameError: '',
      });
    }
  };

  onDegreeTitleChange = val => {

    this.data.shouldSave = true;

    this.data.degreeTitle = val;

    this.props.onChange && this.props.onChange();

    if (this.state.degreeTitleError) {

      this.setState({
        degreeTitleError: '',
      });
    }
  };

  onCountryChange = val => {

    this.data.shouldSave = true;

    this.data.country = val;

    this.props.onChange && this.props.onChange();

    if (this.state.countryError) {

      this.setState({
        countryError: '',
      });
    }
  };

  onStartYearChange = val => {

    this.data.shouldSave = true;

    this.data.startYear = val;

    this.props.onChange && this.props.onChange();

    if (this.state.startYearError) {

      this.setState({
        startYearError: '',
      });
    }
  };

  onEndYearChange = val => {

    this.data.shouldSave = true;

    this.data.endYear = val;

    this.props.onChange && this.props.onChange();

    if (this.state.endYearError) {

      this.setState({
        endYearError: '',
      });
    }
  };

  onStartMonthChange = val => {

    this.data.shouldSave = true;

    this.data.startMonth = val;

    this.props.onChange && this.props.onChange();

    if (this.state.startMonthError) {

      this.setState({
        startMonthError: '',
      });
    }
  };

  onEndMonthChange = val => {

    this.data.shouldSave = true;

    this.data.endMonth = val;

    this.props.onChange && this.props.onChange();

    if (this.state.endMonthError) {

      this.setState({
        endMonthError: '',
      });
    }
  };

  onDescriptionChange = val => {

    this.data.shouldSave = true;

    this.data.description = val;

    this.props.onChange && this.props.onChange();

    if (this.state.descriptionError) {

      this.setState({
        descriptionError: '',
      });
    }
  };

  onDeleteYesClick = () => {

    this.setState({
      showDeletePopup: false
    });

    typeof this.props.onDeleteClick === 'function' && this.props.onDeleteClick(this.data);
  };

  saveChanges = async () => {

    document.getElementById('education-popup-content').scrollIntoView();

    this.setState({
      isLoading: true,
    });

    let ret;

    if (this.data.id) {

      ret = await UserProvider.updateEducation(
        this.props.userId,
        this.data.id,
        this.data.degreeLevel.value,
        this.data.degreeTitle,
        this.data.institutionName,
        this.data.country.value,
        this.data.startYear.value,
        this.data.startMonth.value,
        !this.state.isInProgress ? this.data.endYear.value : undefined,
        !this.state.isInProgress ? this.data.endMonth.value : undefined,
        this.data.description,
      );
    }

    if (!this.data.id) {

      ret = await UserProvider.addEducation(
        this.props.userId,
        this.data.degreeLevel.value,
        this.data.degreeTitle,
        this.data.institutionName,
        this.data.country.value,
        this.data.startYear.value,
        this.data.startMonth.value,
        !this.state.isInProgress ? this.data.endYear.value : undefined,
        !this.state.isInProgress ? this.data.endMonth.value : undefined,
        this.data.description,
      );
    }

    if (!isValidProviderResult(ret)) {

      ErrorToast.show();

      Logger.error(ret.message || Messages.TechnicalError);

      this.setState({
        isLoading: false,
      });

      return;
    }

    this.data.isInProgress = this.state.isInProgress;

    this.data.id = ret.data.id;

    typeof this.props.onSaveClick === 'function' && this.props.onSaveClick(this.data);

    typeof this.props.onClose === 'function' && this.props.onClose();
  };

  validate = () => {

    let returnObj = {};

    if (!this.data.degreeLevel || !this.data.degreeLevel.value) {
      returnObj.degreeLevelError = 'Please select a degree level';
    }

    if (!this.data.degreeTitle) {
      returnObj.degreeTitleError = 'Please enter a degree title';
    }

    if (!this.data.institutionName) {
      returnObj.institutionNameError = 'Please enter an institution name';
    }

    if (!this.data.country || !this.data.country.value) {
      returnObj.countryError = 'Please select a country';
    }

    if (!this.data.startYear || !this.data.startYear.value) {
      returnObj.startYearError = 'Please select a start year';
    }

    if (!this.data.startMonth || !this.data.startMonth.value) {
      returnObj.startMonthError = 'Please selec a start month';
    }

    if (!this.state.isInProgress && (!this.data.endYear || !this.data.endYear.value)) {
      returnObj.endYearError = 'Please select an end year';
    }

    if (!this.state.isInProgress && (!this.data.endMonth || !this.data.endMonth.value)) {
      returnObj.endMonthError = 'Please select an end month';
    }

    if (!this.state.isInProgress) {

      if (!returnObj.startYearError && !returnObj.endYearError && this.data.startYear.value > this.data.endYear.value) {
        returnObj.startYearError = 'Start year cannot be greater than end year';
      }
      else if (!returnObj.startYearError && this.data.startYear.value === this.data.endYear.value && this.data.startMonth.value > this.data.endMonth.value) {
        returnObj.startMonthError = 'Start month cannot be greater than end month';
      }
    }

    if (!this.data.description) {
      returnObj.descriptionError = 'Please provide a description';
    }

    if (Object.keys(returnObj).length) {

      this.setState({
        degreeLevelError: '',
        degreeTitleError: '',
        countryError: '',
        institutionNameError: '',
        startMonthError: '',
        endMonthError: '',
        startYearError: '',
        endYearError: '',
        descriptionError: '',
        ...returnObj,
      });
    }

    return returnObj;
  };

  load = async () => {

    const yearList = generateYearArray(null, null, true);

    const monthList = await LookupProvider.getMonths();
    const degreeLevelList = await LookupProvider.getDegreeLevels();
    const countryList = await LookupProvider.getCountries();

    this.data.validate = this.validate;

    this.setState({
      yearList,
      monthList,
      degreeLevelList,
      countryList,
    });
  };

  componentDidMount() {
    this.load();
  }

  render () {

    return (
      
      <React.Fragment>

        <PopupContent
          scroll={!this.state.isLoading}>

          { this.state.isLoading &&

            <LoadingContainer>

              <Loader
                size={50}
              />

            </LoadingContainer>
          }

          <Container
            id={'education-popup-content'}>

            <ItemRow>

              <Item>

                <Select
                  id={'select-account-education-degree-level'}
                  width={'100%'}
                  label={'Degree level'}
                  onChange={this.onDegreeLevelChange}
                  defaultValue={this.data.degreeLevel && this.data.degreeLevel.value}
                  options={this.state.degreeLevelList}
                  hasError={!!this.state.degreeLevelError}
                  errorMessage={this.state.degreeLevelError}
                />

              </Item>

              <Item>

                <TextInput
                  id={'txt-account-education-degree-title'}
                  width={'100%'}
                  label={'Degree title'}
                  onChange={this.onDegreeTitleChange}
                  defaultValue={this.data.degreeTitle}
                  hasError={!!this.state.degreeTitleError}
                  errorMessage={this.state.degreeTitleError}
                />

              </Item>

              <Item>

                <TextInput
                  id={'txt-account-education-institution'}
                  width={'100%'}
                  label={'Institution name'}
                  defaultValue={this.data.institutionName}
                  onChange={this.onInstituteNameChange}
                  hasError={!!this.state.institutionNameError}
                  errorMessage={this.state.institutionNameError}
                />

              </Item>

              <Item>

                <Select
                  id={'select-account-education-country'}
                  width={'100%'}
                  label={'Country'}
                  onChange={this.onCountryChange}
                  defaultValue={this.data.country && this.data.country.value}
                  options={this.state.countryList}
                  isSearchable={true}
                  hasError={!!this.state.countryError}
                  errorMessage={this.state.countryError}
                />

              </Item>

            </ItemRow>

            <ItemRow>

              <Item>

                <Checkbox
                  id={'checkbox-account-education-in-progress'}
                  isDefaultChecked={this.state.isInProgress}
                  onClick={this.onInProgressClick}
                  label={'In progress'}
                />

              </Item>

            </ItemRow>

            <ItemRow>

              <Item>

                <Select
                  id={'select-account-education-start-year'}
                  width={'100%'}
                  defaultValue={this.data.startYear && this.data.startYear.value && this.data.startYear.value.toString()}
                  label={'Start year'}
                  options={this.state.yearList}
                  onChange={this.onStartYearChange}
                  hasError={!!this.state.startYearError}
                  errorMessage={this.state.startYearError}
                />

              </Item>

              <Item>

                <Select
                  id={'select-account-education-start-month'}
                  width={'100%'}
                  defaultValue={this.data.startMonth && this.data.startMonth.value}
                  label={'Start month'}
                  onChange={this.onStartMonthChange}
                  options={this.state.monthList}
                  hasError={!!this.state.startMonthError}
                  errorMessage={this.state.startMonthError}
                />

              </Item>

              { !this.state.isInProgress &&

                <React.Fragment>

                  <Item>

                    <Select
                  id={'select-account-education-end-year'}
                      width={'100%'}
                      defaultValue={this.data.endYear && this.data.endYear.value && this.data.endYear.value.toString()}
                      label={'End year'}
                      options={this.state.yearList}
                      onChange={this.onEndYearChange}
                      hasError={!!this.state.endYearError}
                      errorMessage={this.state.endYearError}
                    />

                  </Item>

                  <Item>

                    <Select
                  id={'select-account-education-end-month'}
                      width={'100%'}
                      defaultValue={this.data.endMonth && this.data.endMonth.value}
                      label={'End month'}
                      options={this.state.monthList}
                      onChange={this.onEndMonthChange}
                      hasError={!!this.state.endMonthError}
                      errorMessage={this.state.endMonthError}
                    />

                  </Item>

                </React.Fragment>
              }

            </ItemRow>

            <ItemRow>
              
              <Item>

                <TextInput
                  id={'txt-account-education-description'}
                  width={'100%'}
                  defaultValue={this.data.description}
                  multiline={true}
                  label={'Further information'}
                  onChange={this.onDescriptionChange}
                  hasError={!!this.state.descriptionError}
                  errorMessage={this.state.descriptionError}
                  margin={'0'}
                  showCharCounter={true}
                  charCounterMax={2500}
                  charCounterColor={Colors.Red3}
                />

              </Item>

            </ItemRow>

          </Container>

          { this.props.onDeleteClick && 
            
            <DeleteContainer
              onClick={() => this.setState({ showDeletePopup: true })}
            />
          }

        </PopupContent>

        { !this.props.hideFooter &&

          <PopupFooter
            footerBackgroundColor={Colors.Red3}>

            <ActionContainer>

              <ActionButtonContainer>

                <Button
                  width={'100%'}
                  text={'CANCEL'}
                  onClick={() => { this.props.onClose && this.props.onClose(); }}
                />

              </ActionButtonContainer>

              &nbsp;&nbsp;

              <ActionButtonContainer>

                <Button
                  width={'100%'}
                  text={'SAVE CHANGES'}
                  onClick={this.onSaveChangesClick}
                />

              </ActionButtonContainer>

            </ActionContainer>

          </PopupFooter>
        }

        { this.state.showDeletePopup &&

          <Popup
            maxWidth={'500px'}
            header={'Confirm delete'}
            headerBackgroundColor={Colors.Red3}
            onClose={() => this.setState({ showDeletePopup: false })}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 15px 30px'}>

                Are you sure you want to delete this entry?
            </Text>

            <Text
              color={Colors.Red2}
              fontSize={FontSizes.Small}
              padding={'0 30px 110px 30px'}
              flex={'1'}>

                Please note that this action cannot be undone.
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Red3}>

              <PopupActionContainer>

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'NO'}
                    onClick={() => this.setState({ showDeletePopup: false })}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'YES'}
                    onClick={this.onDeleteYesClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

      </React.Fragment>
    );
  }
}