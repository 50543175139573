import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
} from 'common';

export const SvgContainer = styled.div`
  position: relative;
  height: ${props => props.height || '38px'};
  width: ${props => props.width || '38px'};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.showBackground && `

    border-radius: ${props.backgroundBorderRadius || '25px'};

    background-color: ${!props.selected
      ? props.backgroundColor || Colors.White
      : props.selectedBackgroundColor || Colors.PrimaryOne};
  `}

  ${props => props.onClick && `cursor: pointer;`}

  @media ${DeviceMediaQueries.Tablet} {
    height: ${props => props.height || '45px'};
    width: ${props => props.width || '45px'};
    ${props => props.margin && `margin: ${props.margin};`}
  }
`;

export const Svg = styled.svg``;

export const Path = styled.path``;