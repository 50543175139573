const deviceSizes = {
  mobileS: '345px',
  mobileL: '425px',
  tablet: '795px',
  laptop: '1024px',
  laptopM: '1200px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const DeviceMediaQueries = {
  MobileS: `(min-width: ${deviceSizes.mobileS})`,
  MobileL: `(min-width: ${deviceSizes.mobileL})`,
  Tablet: `(min-width: ${deviceSizes.tablet})`,
  Laptop: `(min-width: ${deviceSizes.laptop})`,
  LaptopM: `(min-width: ${deviceSizes.laptopM})`,
  LaptopL: `(min-width: ${deviceSizes.laptopL})`,
  desktop: `(min-width: ${deviceSizes.desktop})`,
};

export let IsMobile = window.outerWidth < 795;

const mql = window.matchMedia(DeviceMediaQueries.Tablet);

if (!!mql.addEventListener) {

  mql.addEventListener('change', (e) => {
    IsMobile = !e.matches;
  });
}
else if (!!mql.addListener) {

  mql.addListener((e) => {
    IsMobile = !e.matches;
  });
}
