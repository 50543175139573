import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  FontSizes,
  ErrorToast,
  Logger,
  Messages,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  Space,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Loader,
  Text,
} from 'common/components';

import {
  FormContainer,
  LoadingContainer,
} from './projectsComponentsStyled';

import {
  ProjectsProvider,
} from 'providers';

const Row = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;

  :nth-child(odd) {
    background-color: ${Colors.Gray1};
  }

  @media ${DeviceMediaQueries.Laptop} {

    :hover {
      background-color: ${Colors.Gray2};
    }
  }
`;

const Circle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 1px solid ${props => props.selected ? Colors.Blue3 : Colors.Gray5};
  background-color: ${props => props.selected ? Colors.Blue3 : Colors.White};
`;

export class ProjectDelete extends React.Component {

  state = {
    projectList: [],
    isLoading: false,
  };

  selectedId = 0;

  onDeleteClick = () => {

    if (!this.selectedId) {

      return;
    }

    this.setState({
      isLoading: true,
    });

    ProjectsProvider.deleteProject(this.selectedId)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          this.setState({
            isLoading: false,
          });

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        const currentProject = DataStore.get('current_project') || {};

        if (currentProject.id === this.selectedId) {
          DataStore.clear('current_project');
        }

        typeof this.props.onDeleted === 'function' && this.props.onDeleted();
        typeof this.props.onClose === 'function' && this.props.onClose();
      })
      .catch(e => {

        this.setState({
          isLoading: false,
        });

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onRowClick = (projectId) => {

    this.selectedId = projectId;

    this.setState({
      projectList: this.createProjectList(),
    });
  };

  createProjectList = () => {

    const projects = DataStore.get('USER_PROJECTS') || [];

    if (!projects.length) {
      return [];
    }

    let projectList = [];

    for (const project of projects) {

      projectList.push(

        <Row
          key={project.id}
          onClick={() => this.onRowClick(project.id)}>

          <Circle
            selected={project.id === this.selectedId}
          />

          <Text
            fontSize={FontSizes.Small}
            padding={'0 15px'}>

              {project.title}
          </Text>

        </Row>
      );
    }

    return projectList;
  };

  componentDidMount() {

    let projects = this.createProjectList();

    if (!projects || !projects.length) {
      
      projects = (
        <Text
          fontSize={FontSizes.Small}
          padding={'0 15px'}>

            Nothing to display
        </Text>
      );
    }

    this.setState({
      projectList: projects,
    });
  }

  render() {

    return (

      <React.Fragment>

        { this.state.isLoading &&

          <LoadingContainer>

            <Loader
              size={50}
            />

          </LoadingContainer>
        }

        <FormContainer>

          {this.state.projectList}

        </FormContainer>

        <PopupFooter
          footerBackgroundColor={Colors.Blue3}>

          <PopupActionContainer>

            <PopupActionButtonContainer>

              <Button
                text={'Cancel'}
                width={'100%'}
                onClick={() => { typeof this.props.onClose === 'function' && this.props.onClose(); }}
              />

            </PopupActionButtonContainer>

            <Space/>

            <PopupActionButtonContainer>

              <Button
                text={'Delete'}
                width={'100%'}
                onClick={this.onDeleteClick}
              />

            </PopupActionButtonContainer>

          </PopupActionContainer>

        </PopupFooter>

      </React.Fragment>
    );
  }
}