import
  React
from 'react';

import {
  Colors,
  DesktopSpace,
  FontSizes,
  ErrorToast,
  Logger,
  Messages,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  Space,
  TextInputContainer,
  isValidProviderResult,
} from 'common';

import {
  Button,
  H3White,
  Loader,
  Popup,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  FormContainer,
  LoadingContainer,
  GoalDeleteContainer,
} from './projectsComponentsStyled';

import {
  LookupProvider,
  ProjectsProvider,
} from 'providers';

export class AdviceGoalForm extends React.Component {

  state = {
    topicError: '',
    frequencyError: '',
    contactMethodError: '',
    descriptionError: '',
    topicsError: '',
    topicList: [],
    frequencyList: [],
    contactMethodList: [],
    showDeletePopup: false,
    isLoading: false,
  };

  formValues = {
    topics: [],
    frequency: {},
    contactMethod: {},
    description: '',
  };

  onSubmitClick = () => {

    if (!this.validate()) {
      return;
    }

    const data = this.getCombinedValues();

    typeof this.props.onClose === 'function' && this.props.onClose(data);
  };

  onDeleteGoalClick = (index) => {

    this.deleteIndex = index;

    this.setState({
      showDeletePopup: true,
    });
  };

  onConfirmDeleteClick = () => {

    this.onHideDeletePopup();

    if (!this.props.advice.id) {

      typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      return;
    }

    ProjectsProvider.deleteAdviceGoal(this.props.advice.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onHideDeletePopup = () => {

    this.setState({
      showDeletePopup: false,
    });
  };

  onFieldBlur = () => {

    if (!this.props.saveOnBlur) {
      return;
    }

    typeof this.props.onFieldBlur === 'function' && this.props.onFieldBlur(
      this.getCombinedValues()
    );
  };

  onTopicChange = val => {

    if (this.state.topicsError) {

      this.setState({
        topicsError: '',
      });
    }

    // this is a new goal, not editing a goal
    if (!this.props.advice) {

      this.formValues.topics = val;

      this.formValues.project_advice_type = [];

      for (const v of val) {

        this.formValues.project_advice_type.push({
          typeDescription: v.value,
        });
      }

      this.onFieldBlur();

      return;
    }

    const toDelete = this.formValues.topics.filter(l => {

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {
          return false;
        }
      }

      l.shouldDelete = true;

      return true;
    });

    for (let j = 0; j < val.length; j++) {

      val[j].typeDescription = val[j].value;
      val[j].projectAdviceId = this.props.advice.id;
      val[j].shouldDelete = false;
    }

    const combined = [
      ...val,
      ...toDelete,
    ];

    this.formValues.topics = combined;
    this.formValues.project_advice_type = combined;

    this.onFieldBlur();
  };

  onFrequencyChange = val => {

    this.formValues.frequency = val;

    this.onFieldBlur();
    
    if (this.state.frequencyError) {

      this.setState({
        frequencyError: '',
      });
    }
  };

  onContactMethodChange = val => {

    this.formValues.contactMethod = val;

    this.onFieldBlur();
    
    if (this.state.contactMethodError) {

      this.setState({
        contactMethodError: '',
      });
    }
  };

  onDescriptionChange = val => {

    this.formValues.description = val;

    if (this.state.descriptionError) {

      this.setState({
        descriptionError: '',
      });
    
      return;
    }

    if (!this.formValues.description) {

      this.setState({
        descriptionError: 'Please enter your motivation'
      });
    }
  };

  getCombinedValues = () => {

    return {
      ...this.formValues,
      communicationMethodId: this.formValues.contactMethod.value,
      frequencyTypeId: this.formValues.frequency.value,
      projectId: this.props.project.id,
      id: this.props.advice && this.props.advice.id,
    };
  };

  validate = () => {

    let errors = {};

    if (!Array.isArray(this.formValues.topics) || !this.formValues.topics.length) {
      errors.topicsError = 'Please select at least one topic';
    }

    if (!this.formValues.frequency || !this.formValues.frequency.value) {
      errors.frequencyError = 'Please select a frequency';
    }

    if (!this.formValues.contactMethod || !this.formValues.contactMethod.value) {
      errors.contactMethodError = 'Please select a contact method';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  load = async () => {

    const topicList = await LookupProvider.getAdviceTopics();
    const frequencyList = await LookupProvider.getAdviceFrequency();
    const contactMethodList = await LookupProvider.getAdviceContactMethods();

    if (this.props.advice) {

      this.formValues = {
        topics: this.props.advice.topics || [],
        frequency: this.props.advice.frequency || {},
        contactMethod: this.props.advice.communicationMethod || this.props.advice.contactMethod || {},
        description: this.props.advice.description || '',
      };
    }

    this.setState({
      topicList,
      frequencyList,
      contactMethodList,
    });
  };

  componentDidMount() {
    this.load();
  };

  render() {

    return (

      <React.Fragment>

        { this.state.isLoading &&

          <LoadingContainer>

            <Loader
              size={50}
            />

          </LoadingContainer>
        }
      
        <FormContainer>

          { this.props.header &&

            <H3White
              color={Colors.White}
              fontStyle={'italic'}
              margin={'0 0 15px 0'}>

                {this.props.header}
            </H3White>
          }

          <TextInputContainer>

            <Select
              id={'select-project-goal-advice-topic'}
              flex={'1'}
              width={'auto'}
              label={'Topic'}
              isMultiSelect={true}
              displaySelectedInline={true}
              trashcanColor={Colors.Blue3}
              options={this.state.topicList}
              defaultOptions={this.formValues.topics}
              maxItems={3}
              onChange={this.onTopicChange}
              hasError={!!this.state.topicsError}
              errorMessage={this.state.topicsError}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-advice-frequency'}
              flex={'1'}
              width={'auto'}
              label={'Frequency'}
              isSearchable={true}
              defaultValue={this.formValues.frequency.value}
              options={this.state.frequencyList}
              onChange={this.onFrequencyChange}
              hasError={!!this.state.frequencyError}
              errorMessage={this.state.frequencyError}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-advice-contact-method'}
              flex={'1'}
              width={'auto'}
              label={'Contact method'}
              isSearchable={true}
              defaultValue={this.formValues.contactMethod.value}
              options={this.state.contactMethodList}
              onChange={this.onContactMethodChange}
              hasError={!!this.state.contactMethodError}
              errorMessage={this.state.contactMethodError}
            />

          </TextInputContainer>

          <TextInput
            id={'txt-project-goal-advice-description'}
            width={'100%'}
            margin={'0'}
            label={'Further Information'}
            multiline={true}
            defaultValue={this.formValues.description}
            onBlur={this.onFieldBlur}
            onChange={this.onDescriptionChange}
            hasError={!!this.state.descriptionError}
            errorMessage={this.state.descriptionError}
            showCharCounter={true}
            charCounterColor={Colors.Blue3}
            charCounterMax={2500}
          />

          { this.props.allowDelete &&

            <GoalDeleteContainer
              onClick={this.onDeleteGoalClick}
            />
          }

        </FormContainer>

        { !this.props.hideFooter &&

          <PopupFooter
            footerBackgroundColor={Colors.Blue3}>

            <PopupActionContainer>

              <PopupActionButtonContainer>

                <Button
                  text={'Cancel'}
                  width={'100%'}
                  onClick={() => { typeof this.props.onClose === 'function' && this.props.onClose(); }}
                />

              </PopupActionButtonContainer>

              <Space/>

              <PopupActionButtonContainer>

                <Button
                  text={'Save'}
                  width={'100%'}
                  onClick={this.onSubmitClick}
                />

              </PopupActionButtonContainer>

            </PopupActionContainer>

          </PopupFooter>
        }

        { this.state.showDeletePopup &&

          <Popup
            header={'Confirm delete'}
            headerBackgroundColor={Colors.Blue3}
            maxWidth={'500px'}
            onClose={this.onHideDeletePopup}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 15px 30px'}>

                Are you sure you want to delete this entry?
            </Text>

            <Text
              color={Colors.Red2}
              fontSize={FontSizes.Small}
              padding={'0 30px 110px 30px'}
              flex={'1'}>

                Please note that this action cannot be undone.
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Blue3}>

              <PopupActionContainer>

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'NO'}
                    onClick={this.onHideDeletePopup}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'YES'}
                    onClick={this.onConfirmDeleteClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

      </React.Fragment>
    );
  }
}