import
  React
from 'react';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const ChatBubbleSvg = props => {

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <Path d="M37,2A35,35,0,1,1,2,37,35,35,0,0,1,37,2m0-2A37,37,0,1,0,74,37,37,37,0,0,0,37,0Z"/>
        
        <circle fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" cx="43.86" cy="35.62" r="2.07"/>
        
        <circle fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" cx="29.86" cy="35.62" r="2.07"/>
        
        <circle fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" cx="36.86" cy="35.62" r="2.07"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M50.86,35.21a13.26,13.26,0,0,1-1,5,11.94,11.94,0,0,1-2.82,4.1A11.69,11.69,0,0,1,42.89,47a13.59,13.59,0,0,1-5.11.93,6.12,6.12,0,0,1-.83,0,8.79,8.79,0,0,1-1.1-.05c-.35,0-.71,0-1-.09a7.83,7.83,0,0,0-.84-.09l-.4.05,0,0L28.1,50.83V45.51l-.18-.44v0l0-.05A11.39,11.39,0,0,1,26,43a12.39,12.39,0,0,1-1.37-2.29,13.56,13.56,0,0,1-.83-2.6,15.11,15.11,0,0,1-.27-2.9,11,11,0,0,1,1.06-4.67,11.87,11.87,0,0,1,3.08-4.05,14.12,14.12,0,0,1,4.62-2.68,15.8,15.8,0,0,1,5.5-1,12.44,12.44,0,0,1,4.85,1,14,14,0,0,1,4.31,2.82,12.36,12.36,0,0,1,2.91,4A10.68,10.68,0,0,1,50.86,35.21Z"/>
        
      </Svg>
 
    </SvgContainer>
  );
};