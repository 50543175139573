import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  FontSizes,
  ErrorToast,
  Environment,
  Logger,
  Messages,
  getQueryParameterByName,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  LinkedInProvider,
  PaymentProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const HeaderText = styled.div`
  color: ${Colors.PrimaryOne};
  font-size: ${FontSizes.Big};
  font-weight: bold;
  padding: 25px 0 20px 0;
  text-align: center;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Bigger};
    padding: 30px 15px 25px 15px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Biggest};
  }
`;

export const SubHeaderText = styled.div`
  font-size: ${FontSizes.Small};
  text-align: center;
  max-width: 550px;
  padding: 0 0 25px 0;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Regular};
    padding: 0 0 40px 0;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Big};
  }
`;

class LinkedInAuthUI extends React.Component {

  state = {
    showLoading: true,
  };

  onBackClick = () => {
    this.props.common.navigate('/identity/login');
  };

  
  initiateCheckoutSession = () => {

    const user = DataStore.get('LOGGED_IN_USER') || DataStore.get('REGISTERED_USER');

    if (user.paymentCustomerId) {
      
      PaymentProvider.createPortalSession(Environment.channel)
      .then(ret => {

        if (isValidProviderResult(ret) && ret.data.redirect) {

          window.location.href = ret.data.redirect;
          return;
        }

        ErrorToast.show();
        Logger.error(ret.data.message);
      })
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  
      return;
    }

    const priceId = DataStore.get('price_id');

    PaymentProvider
      .getPaymentUrl(user.id, priceId, Environment.channel)
      .then(ret => {

        if (!isValidProviderResult(ret) || !ret.data.redirect) {

          ErrorToast.show();
          Logger.error(ret.error || Messages.TechnicalError);
          return;
        }

        window.location.href = ret.data.redirect;
      })
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });
  };

  load = () => {

    const code = getQueryParameterByName('code');
    const state = getQueryParameterByName('state');

    LinkedInProvider.login(code, state)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showLoading: false,
          });

          return;
        }

        DataStore.set(
          'LOGGED_IN_USER',
          ret.data.user,
        );

        DataStore.set(
          'ACCESS_TOKEN',
          ret.data.accessToken,
        );

        if(this.props.common.uriParams.fromPayment) {

          this.initiateCheckoutSession();
          return;
        }

        this.props.common.navigate('/home');
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);

        this.setState({
          showLoading: false,
        });
      });
  };

  componentDidMount() {
    this.load();
  }

  render () {

    return (

      <Container>
        
        { this.state.showLoading
          
          ? <Loader
              showLabel={true}
            />
          
          : <React.Fragment>

              <HeaderText>
                We were not able to verify your LinkedIn account
              </HeaderText>

              <SubHeaderText>
                Please try again later, or use your email to register a new account
              </SubHeaderText>

              <Button
                text={'BACK TO LOGIN'}
                onClick={this.onBackClick}
              />

            </React.Fragment>
        }

      </Container>
    );
  }
}

export const LinkedInAuth = withCommon(
  LinkedInAuthUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);