import
  React
from 'react';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const PeopleConnectionSvg = props => {

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <Path d="M37,2A35,35,0,1,1,2,37,35,35,0,0,1,37,2m0-2A37,37,0,1,0,74,37,37,37,0,0,0,37,0Z"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M30.16,47.22,26.43,49a2.25,2.25,0,0,1-3-1.09L16.61,33.28a2.25,2.25,0,0,1,1.09-3l9-4.21a2.24,2.24,0,0,1,2.12.1,2.07,2.07,0,0,0-.1.66v18.3A2.25,2.25,0,0,0,30.16,47.22Z"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M56.85,33.28,50,47.87A2.25,2.25,0,0,1,47,49L43.3,47.22a2.24,2.24,0,0,0,1.43-2.09V26.83a2.07,2.07,0,0,0-.1-.66,2.24,2.24,0,0,1,2.12-.1l9,4.21A2.24,2.24,0,0,1,56.85,33.28Z"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M44.63,26.17a2.25,2.25,0,0,0-2.16-1.59H31a2.24,2.24,0,0,0-2.15,1.59,2.07,2.07,0,0,0-.1.66v18.3a2.25,2.25,0,0,0,1.42,2.09,2.08,2.08,0,0,0,.83.16H42.47a2,2,0,0,0,.83-.16,2.24,2.24,0,0,0,1.43-2.09V26.83A2.07,2.07,0,0,0,44.63,26.17Z"/>
        
      </Svg>

    </SvgContainer>
  );
};