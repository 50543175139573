import {
  Environment,
  getLookupDisplayByValue,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
  post,
  put,
  patch,
  deleteCall,
} from 'providers/axios';

export class UserProvider {

  static async getUserInformation(
    userId,
    skipProfileScore = false,
  ) {
    
    try
    {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}user-information?userId=${userId}&skipProfileScore=${skipProfileScore}`);

      let ret = resolveResponse(res);

      if (!ret.ok) {
        return ret;
      }

      let account = (
        Array.isArray(ret.data.account)
        && ret.data.account.length
        && ret.data.account[ret.data.account.length - 1]
      ) || {};

      account.currentWorkStatus = {
        display: getLookupDisplayByValue('employmentTypes', account.currentWorkStatusId),
        value: account.currentWorkStatusId,
      };

      account.country = getLookupDisplayByValue('countries', account.countryId);
      account.city = account.cityId;

      account.highestEducation = {
        display: getLookupDisplayByValue('degreeLevel', account.pin),
        value: account.pin,
      };

      account.languages = [];
      account.skills = [];

      if (Array.isArray(account.user_languages)) {

        let language;

        for (let i = 0; i < account.user_languages.length; i++) {

          language = account.user_languages[i];

          account.languages.push({
            display: getLookupDisplayByValue('languages', language.languageId),
            value: language.languageId,
            id: language.id,
          });
        }
      }

      if (Array.isArray(account.user_skills)) {

        let skill;
        let skillId;
        let skillDisplay;

        for (let i = 0; i < account.user_skills.length; i++) {

          skill = account.user_skills[i];
          skillId = parseInt(skill.skillName);
          skillDisplay = getLookupDisplayByValue('skills', skillId);

          account.skills.push({
            display: skillDisplay || skill.skillName,
            value: isNaN(skillId) ? skill.skillName : skillId,
            id: skill.id,
            isOtherOption: !skillDisplay,
          });
        }
      }

      let education;

      for (let i = 0; i < ret.data.educationalHistory.length; i++) {

        education = ret.data.educationalHistory[i];

        education.isInProgress = !education.endYear && !education.endMonth;

        education.country = {
          display: getLookupDisplayByValue('countries', education.countryId),
          value: education.countryId,
        };

        education.degreeLevel = {
          display: getLookupDisplayByValue('degreeLevel', education.degreeLevelId),
          value: education.degreeLevelId,
        };

        education.startYear = {
          display: education.startYear,
          value: education.startYear,
        };

        education.startMonth = {
          display: getLookupDisplayByValue('months', education.startMonth),
          value: education.startMonth,
        };

        education.endYear = education.endYear
          ? {
              display: education.endYear,
              value: education.endYear,
            }
          : {};

        education.endMonth = education.endMonth
          ? {
              display: getLookupDisplayByValue('months', education.endMonth),
              value: education.endMonth,
            }
          : {};

        education.institutionName = education.instituteName;
      }

      let experience;

      for (let i = 0; i < ret.data.professionalExperience.length; i++) {
        
        experience = ret.data.professionalExperience[i];

        experience.isCurrentlyWorkingInThisRole = !experience.endYear && !experience.endMonth;

        experience.employmentType = {
          display: getLookupDisplayByValue('employmentTypes', experience.employmentTypeId),
          value: experience.employmentTypeId,
        };

        experience.startYear = {
          display: experience.startYear,
          value: experience.startYear,
        };

        experience.startMonth = {
          display: getLookupDisplayByValue('months', experience.startMonth.toString()),
          value: experience.startMonth.toString(),
        };

        experience.endYear = experience.endYear
          ? {
              display: experience.endYear,
              value: experience.endYear,
            }
          : {};

        experience.endMonth = experience.endMonth
          ? {
              display: getLookupDisplayByValue('months', experience.endMonth.toString()),
              value: experience.endMonth.toString(),
            }
          : {};
      }

      let linkedAccount;
      let display;
      let value;

      for (let i = 0; i < ret.data.linkedAccounts.length; i++) {

        linkedAccount = ret.data.linkedAccounts[i];

        display = (linkedAccount.linked_accounts_lookup && linkedAccount.linked_accounts_lookup.name)
          || linkedAccount.customAccountName;

        value = (linkedAccount.linked_accounts_lookup && linkedAccount.linked_accounts_lookup.id) || 'OTHER';

        linkedAccount.accountType = {
          display,
          value,
        };

        linkedAccount.accountOtherType = linkedAccount.customAccountName;

        linkedAccount.accountLink = linkedAccount.customAccountUrl;
      }

      return ret;

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async createAccountDetails(
    userId,
    name,
    surname,
    countryId,
    cityId,
    aboutMe,
    currentWorkStatusId,
    cvUrl,
    profilePictureUrl,
    phoneNumber,
    dateOfBirth,
    highestEducation,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}accounts`,
        {
          userId,
          name,
          surname,
          countryId,
          cityId,
          aboutMe,
          currentWorkStatusId,
          cvUrl,
          profilePictureUrl,
          phoneNumber,
          dateOfBirth,
          pin: highestEducation,
        }
      );

      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async updateAccountDetails(
    userId,
    accountId,
    name,
    surname,
    countryId,
    cityId,
    aboutMe,
    currentWorkStatusId,
    cvUrl,
    profilePictureUrl,
    phoneNumber,
    dateOfBirth,
    highestEducation,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      let res = await put(
        `${Environment.apiHost}accounts/${accountId}`,
        {
          userId,
          name,
          surname,
          countryId,
          cityId,
          aboutMe,
          currentWorkStatusId,
          cvUrl,
          profilePictureUrl,
          phoneNumber,
          dateOfBirth,
          pin: highestEducation, // store highest education in pin for now.
        }
      );

      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async addEducation(
    userId,
    degreeLevelId,
    degreeTitle,
    instituteName,
    countryId,
    startYear,
    startMonth,
    endYear,
    endMonth,
    description,
  ) {

    try {

      let res = await post(
        `${Environment.apiHost}user-education`,
        {
          userId,
          degreeLevelId,
          degreeTitle,
          instituteName,
          countryId,
          startYear,
          startMonth,
          endYear,
          endMonth,
          description,
        }
      );
      
      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async updateEducation(
    userId,
    educationId,
    degreeLevelId,
    degreeTitle,
    instituteName,
    countryId,
    startYear,
    startMonth,
    endYear,
    endMonth,
    description,
  ) {

    try {

      let res = await put(
        `${Environment.apiHost}user-education/${educationId}`,
        {
          userId,
          degreeLevelId,
          degreeTitle,
          instituteName,
          countryId,
          startYear,
          startMonth,
          endYear,
          endMonth,
          description,
        }
      );
      
      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async deleteEducation(
    educationId
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}user-education/${educationId}`);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addExperience(
    userId,
    role,
    company,
    employmentTypeId,
    startYear,
    startMonth,
    endYear,
    endMonth,
    description,
  ) {

    try {

      let res = await post(
        `${Environment.apiHost}user-professional-experience`,
        {
          userId,
          role,
          company,
          employmentTypeId,
          startYear,
          startMonth,
          endYear,
          endMonth,
          description,
        }
      );
      
      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async updateExperience(
    userId,
    experienceId,
    role,
    company,
    employmentTypeId,
    startYear,
    startMonth,
    endYear,
    endMonth,
    description,
  ) {

    try {

      let res = await put(
        `${Environment.apiHost}user-professional-experience/${experienceId}`,
        {
          userId,
          role,
          company,
          employmentTypeId,
          startYear,
          startMonth,
          endYear,
          endMonth,
          description,
        }
      );
      
      return resolveResponse(res);

    } catch (ex) {
      
      return resolveRejected(ex);
    }
  }

  static async deleteExperience(
    experienceId
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}user-professional-experience/${experienceId}`);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }


  static async addLanguage(
    accountId,
    languageId,
  ) {

    try {

      if (!accountId) {

        return {
          ok: false,
          message: 'Invalid account ID provided',
          data: null,
        };
      }

      if (!languageId) {

        return {
          ok: false,
          message: 'Invalid language ID provided',
          data: null,
        };
      }

      let ret = await post(
        `${Environment.apiHost}user-languages`,
        {
          accountId,
          languageId,
        }
      );

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteLanguage(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid language ID provided',
          data: null,
        };
      }

      let ret = await deleteCall(`${Environment.apiHost}user-languages/${id}`);

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addSkill(
    accountId,
    skillName,
  ) {

    try {

      if (!accountId) {

        return {
          ok: false,
          message: 'Invalid account ID provided',
          data: null,
        };
      }

      if (!skillName) {

        return {
          ok: false,
          message: 'Invalid skill provided',
          data: null,
        };
      }

      let ret = await post(
        `${Environment.apiHost}user-skills`,
        {
          accountId,
          skillName,
        }
      );

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteSkill(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid skill ID provided',
          data: null,
        };
      }

      let ret = await deleteCall(`${Environment.apiHost}user-skills/${id}`);

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addLinkedAccount(
    userId,
    linkedAccountsLookupId,
    customAccountUrl,
    customAccountName,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!customAccountUrl) {

        return {
          ok: false,
          message: 'Invalid account url provided',
          data: null,
        };
      }

      let ret = await post(
        `${Environment.apiHost}linked-accounts`,
        {
          userId,
          linkedAccountsLookupId,
          customAccountUrl,
          customAccountName,
        }
      );

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async updateLinkedAccount(
    userId,
    linkedAccountId,
    linkedAccountsLookupId,
    customAccountUrl,
    customAccountName,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!linkedAccountId) {

        return {
          ok: false,
          message: 'Invalid linked account ID provided',
          data: null,
        };
      }

      if (!customAccountUrl) {

        return {
          ok: false,
          message: 'Invalid account url provided',
          data: null,
        };
      }

      let ret = await put(
        `${Environment.apiHost}linked-accounts/${linkedAccountId}`,
        {
          userId,
          linkedAccountsLookupId,
          customAccountUrl,
          customAccountName,
        }
      );

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteLinkedAccount(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid linked account ID provided',
          data: null,
        };
      }

      let ret = await deleteCall(`${Environment.apiHost}linked-accounts/${id}`);

      return resolveResponse(ret);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProfileScore(
    userId,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      let ret = await get(`${Environment.apiHost}profile-score?userId=${userId}`);

      let r = resolveResponse(ret);

      if (!r.ok) {
        return r;
      }

      r.data.profileScore = Math.floor((r.data.profileScore || 0) / 20);  // profilScore is out of 100, profileRating out of 5, thus val.profileScore / 10 / 2 = val.profileScore / 20

      return r;

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async uploadCv(
    file,
    accountId,
    progressCallback,
  ) {

    try {

      if (!file) {

        return {
          ok: false,
          message: 'Please specify the file you want to upload',
          data: null,
        };
      }

      const data = new FormData();

      if (accountId) {
        data.append('accountId', accountId);
      }

      data.append('file', file);

      let res = await post(
        `${Environment.apiHost}upload-cv`,
        data,
        {}, {
          onUploadProgress: (progressEvent) => { progressCallback && progressCallback(progressEvent); }
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async uploadProfilePicture(
    file,
    accountId,
    progressCallback,
  ) {

    try {

      if (!file) {

        return {
          ok: false,
          message: 'Please specify the file you want to upload',
          data: null,
        };
      }

      const data = new FormData();

      if (accountId) {
        data.append('accountId', accountId);
      }

      data.append('file', file);

      let res = await post(
        `${Environment.apiHost}upload-profile-picture`,
        data,
        {}, {
          onUploadProgress: (progressEvent) => { progressCallback && progressCallback(progressEvent); }
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async removeCv(
    accountId
  ) {

    try {

      let res = await patch(
        `${Environment.apiHost}accounts/${accountId}`, {
          cvUrl: '',
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async tutorialShown() {

    try {

      let res = await put(
        `${Environment.apiHost}user-information`
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }
}