import
  React
from 'react';

import {
  Colors,
  DesktopSpace,
  FontSizes,
  ErrorToast,
  Logger,
  Messages,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  Space,
  TextInputContainer,
  isValidProviderResult,
} from 'common';

import {
  Button,
  DatePicker,
  H3White,
  Loader,
  Popup,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  DesktopInputPlaceholder,
  FormContainer,
  LoadingContainer,
  GoalDeleteContainer,
} from './projectsComponentsStyled';

import {
  LookupProvider,
  ProjectsProvider,
} from 'providers';

export class EmployeeGoalForm extends React.Component {

  state = {
    roleError: '',
    employmentTypeError: '',
    compensationError: '',
    requiredDateError: '',
    countryError: '',
    descriptionError: '',
    roleList: [],
    employmentTypeList: [],
    compensationList: [],
    countryList: [],
    showDeletePopup: false,
    isLoading: false,
  };

  formValues = {
    role: {},
    employmentType: {},
    compensations: [],
    requiredDate: null,
    country: {},
    description: '',
  };

  onSubmitClick = () => {

    if (!this.validate()) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const data = this.getCombinedValues();

    typeof this.props.onClose === 'function' && this.props.onClose(data);
  };

  onDeleteGoalClick = (index) => {

    this.deleteIndex = index;

    this.setState({
      showDeletePopup: true,
    });
  };

  onConfirmDeleteClick = () => {

    this.onHideDeletePopup();

    if (!this.props.employee.id) {

      typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      return;
    }

    ProjectsProvider.deleteEmployeeGoal(this.props.employee.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onHideDeletePopup = () => {

    this.setState({
      showDeletePopup: false,
    });
  };

  onFieldBlur = () => {

    if (!this.props.saveOnBlur) {
      return;
    }

    typeof this.props.onFieldBlur === 'function' && this.props.onFieldBlur(
      this.getCombinedValues()
    );
  };

  onRoleChange = val => {

    this.formValues.role = val;

    this.onFieldBlur();
    
    if (this.state.roleError) {

      this.setState({
        roleError: '',
      });
    }
  };

  onCompensationChange = val => {

    if (this.state.compensationError) {

      this.setState({
        compensationError: '',
      });
    }

    // this is a new goal, not editing a goal
    if (!this.props.employee) {

      this.formValues.compensations = val;

      this.formValues.compensation_types = [];

      for (const v of val) {

        this.formValues.compensation_types.push({
          typeDescription: v.value,
        });
      }

      this.onFieldBlur();

      return;
    }

    const toDelete = this.formValues.compensations.filter(l => {

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {
          return false;
        }
      }

      l.shouldDelete = true;

      return true;
    });

    for (let j = 0; j < val.length; j++) {

      val[j].typeDescription = val[j].value;
      val[j].projectEmployeeId = this.props.employee.id;
      val[j].shouldDelete = false;
    }

    const combined = [
      ...val,
      ...toDelete,
    ];

    this.formValues.compensations = combined;
    this.formValues.compensation_types = combined;

    this.onFieldBlur();
  };

  onEmploymentTypeChange = val => {

    this.formValues.employmentType = val;

    this.onFieldBlur();
    
    if (this.state.employmentTypeError) {

      this.setState({
        employmentTypeError: '',
      });
    }
  };

  onCountryChange = val => {

    this.formValues.country = val;

    this.onFieldBlur();
    
    if (this.state.countryError) {

      this.setState({
        countryError: '',
      });
    }
  };

  onRequiredDateChange = val => {

    this.formValues.requiredDate = val;

    this.onFieldBlur();

    if (this.state.requiredDateError) {

      this.setState({
        requiredDateError: '',
      });
    
      return;
    }

    if (!this.formValues.requiredDate) {
      
      this.setState({
        requiredDateError: 'Please select a date'
      });
    }
  };

  onDescriptionChange = val => {

    this.formValues.description = val;

    if (this.state.descriptionError) {

      this.setState({
        descriptionError: '',
      });

      return;
    }

    if (!this.formValues.description) {
      
      this.setState({
        descriptionError: 'Please enter your motivation'
      });
    }
  };

  getCombinedValues = () => {

    return {
      ...this.formValues,
      roleId: this.formValues.role.value,
      employmentTypeId: this.formValues.employmentType.value,
      countryId: this.formValues.country.value,
      cityId: 0,
      projectId: this.props.project.id,
      id: this.props.employee && this.props.employee.id,
    };
  };

  validate = () => {

    let errors = {};

    if (!this.formValues.role || !this.formValues.role.value) {
      errors.roleError = 'Please select a role';
    }

    if (!this.formValues.employmentType || !this.formValues.employmentType.value) {
      errors.employmentTypeError = 'Please select an employment type';
    }

    if (!this.formValues.country || !this.formValues.country.value) {
      errors.countryError = 'Please select a country';
    }

    if (!Array.isArray(this.formValues.compensations) || !this.formValues.compensations.length) {
      errors.compensationError = 'Please select at least one compensation';
    }

    if (!this.formValues.requiredDate) {
      errors.requiredDateError = 'Please select a date';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  load = async () => {

    const roleList = await LookupProvider.getEmployeeRoles();
    const employmentTypeList = await LookupProvider.getEmployeeEmploymentTypes();
    const compensationList = await LookupProvider.getEmployeeCompensations();
    const countryList = await LookupProvider.getCountries(true);

    if (this.props.employee) {

      this.formValues = {
        role: this.props.employee.role || {},
        employmentType: this.props.employee.employmentType || {},
        compensations: this.props.employee.compensations || [],
        requiredDate: this.props.employee.requiredDate|| null,
        country: this.props.employee.country || {},
        description: this.props.employee.description || '',
      };
    }

    this.setState({
      roleList,
      employmentTypeList,
      compensationList,
      countryList,
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {

    return (

      <React.Fragment>

        { this.state.isLoading &&

          <LoadingContainer>

            <Loader
              size={50}
            />

          </LoadingContainer>
        }

        <FormContainer>

          { this.props.header &&

            <H3White
              color={Colors.White}
              fontStyle={'italic'}
              margin={'0 0 15px 0'}>

                {this.props.header}
            </H3White>
          }

          <TextInputContainer>

            <Select
              id={'select-project-goal-employee-role'}
              flex={'1'}
              width={'auto'}
              label={'Position'}
              isSearchable={true}
              defaultValue={this.formValues.role.value}
              options={this.state.roleList}
              onChange={this.onRoleChange}
              hasError={!!this.state.roleError}
              errorMessage={this.state.roleError}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-employee-employment-type'}
              flex={'1'}
              width={'auto'}
              label={'Work type'}
              isSearchable={true}
              defaultValue={this.formValues.employmentType.value}
              options={this.state.employmentTypeList}
              onChange={this.onEmploymentTypeChange}
              hasError={!!this.state.employmentTypeError}
              errorMessage={this.state.employmentTypeError}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-employee-compensation'}
              flex={'1'}
              width={'auto'}
              label={'Compensation'}
              isMultiSelect={true}
              displaySelectedInline={true}
              trashcanColor={Colors.Blue3}
              defaultValue={this.formValues.compensations.value}
              options={this.state.compensationList}
              defaultOptions={this.formValues.compensations}
              onChange={this.onCompensationChange}
              hasError={!!this.state.compensationError}
              errorMessage={this.state.compensationError}
            />

          </TextInputContainer>

          <TextInputContainer>

            <Select
              id={'select-project-goal-employee-country'}
              flex={'1'}
              width={'auto'}
              label={'Hire Location'}
              isSearchable={true}
              defaultValue={this.formValues.country.value}
              options={this.state.countryList}
              onChange={this.onCountryChange}
              hasError={!!this.state.countryError}
              errorMessage={this.state.countryError}
            />

            <DesktopSpace/>

            <DatePicker
              id={'dp-project-goal-employee-required-date'}
              flex={'1'}
              label={'Starting date'}
              defaultValue={this.formValues.requiredDate}
              onChange={this.onRequiredDateChange}
              hasError={!!this.state.requiredDateError}
              errorMessage={this.state.requiredDateError}
            />

            <DesktopSpace/>

            <DesktopInputPlaceholder/>

          </TextInputContainer>

          <TextInput
            id={'txt-project-goal-employee-description'}
            width={'100%'}
            margin={'0'}
            label={'Further Information'}
            multiline={true}
            defaultValue={this.formValues.description}
            onChange={this.onDescriptionChange}
            onBlur={this.onFieldBlur}
            hasError={!!this.state.descriptionError}
            errorMessage={this.state.descriptionError}
            showCharCounter={true}
            charCounterColor={Colors.Blue3}
            charCounterMax={2500}
          />

          { this.props.allowDelete &&

            <GoalDeleteContainer
              onClick={this.onDeleteGoalClick}
            />
          }

        </FormContainer>

        { !this.props.hideFooter &&

          <PopupFooter
            footerBackgroundColor={Colors.Blue3}>

            <PopupActionContainer>

              <PopupActionButtonContainer>

                <Button
                  text={'Cancel'}
                  width={'100%'}
                  onClick={() => { typeof this.props.onClose === 'function' && this.props.onClose(); }}
                />

              </PopupActionButtonContainer>

              <Space/>

              <PopupActionButtonContainer>

                <Button
                  text={'Save'}
                  width={'100%'}
                  onClick={this.onSubmitClick}
                />

              </PopupActionButtonContainer>

            </PopupActionContainer>

          </PopupFooter>
        }

        { this.state.showDeletePopup &&

          <Popup
            header={'Confirm delete'}
            headerBackgroundColor={Colors.Blue3}
            maxWidth={'500px'}
            onClose={this.onHideDeletePopup}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 15px 30px'}>

                Are you sure you want to delete this entry?
            </Text>

            <Text
              color={Colors.Red2}
              fontSize={FontSizes.Small}
              padding={'0 30px 110px 30px'}
              flex={'1'}>

                Please note that this action cannot be undone.
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Blue3}>

              <PopupActionContainer>

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'NO'}
                    onClick={this.onHideDeletePopup}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'YES'}
                    onClick={this.onConfirmDeleteClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

      </React.Fragment>
    );
  }
}