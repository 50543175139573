import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
} from 'common';

import {
  PlusSvg,
} from 'common/svg';

import {
  Text,
} from 'common/components/text';

import {
  InformationBubble,
} from 'common/components/InformationBubble';

const Container = styled.div`
  border-radius: 20px;
  border: 1px solid ${Colors.White};
  background-color: ${Colors.White};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: ${props => props.hasText ? '100%' : 'auto'};
  ${props => props.margin && `margin: ${props.margin};`}
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0px;
  }

  & > :first-child {
    border: 1px solid ${Colors.White};
  }

  :hover > :first-child {
    background-color: ${Colors.White};
    border: 2px solid ${props => props.color || Colors.Blue2Transparent};
  }

  :hover path {
    fill: ${props => props.color || Colors.Blue2Transparent};
  }

  @media ${DeviceMediaQueries.LaptopM} {
    margin-bottom: auto;
    width: auto;
  }
`;

const CounterContainer = styled.div`
  background-color: ${Colors.White};
  border: 2px solid ${props => props.color || Colors.Blue2Transparent};
  border-radius: 11px;
  font-weight: bold;
  height: 22px;
  width: 22px;
  position: absolute;
  top: -12px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MultiAddButton = props => {

  return (

    <Container
      margin={props.margin}
      color={props.color || Colors.Blue2Transparent}
      hasText={!!props.text}
      onClick={() => { props.onClick && props.onClick() }}>

      <PlusSvg
        backgroundColor={props.color || Colors.Blue2Transparent}
        backgroundBorderRadius={'20px'}
        showBackground={true}
        fill={Colors.White}
        height={'40px'}
        width={'40px'}
      />

      { !!props.text &&

        <Text
          color={props.color || Colors.Blue2Transparent}
          fontSize={FontSizes.Small}
          padding={'0 22px 0 10px'}>
            {props.text}
        </Text>
      }

      { !props.hideCounter &&

        <CounterContainer
          color={props.color || Colors.Blue2Transparent}>

          <Text
            color={props.color || Colors.Blue2Transparent}
            fontSize={FontSizes.Smaller}>

            {props.counter || 0}
          </Text>

        </CounterContainer>
      }

      <div style={{ flex: 1 }}/>

      <InformationBubble
        id={props.id}
      />

    </Container>
  );
};