import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  Images,
  SafeAreas,
} from 'common';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const LeftContainer = styled.div`
  ${props => !props.mobileShow && 'display: none;'};
  position: fixed;
  left: 0;
  top: 50px;
  bottom: 50px;
  width: 100%;
  z-index: 1;
  margin-top: ${SafeAreas.top};
  margin-bottom: ${SafeAreas.bottom};

  @media ${DeviceMediaQueries.Tablet} {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    width: 300px;
    overflow: auto;
  }
`;

export const RightContainer = styled.div`
  background-color: ${Colors.Gray1};
  width: 100%;
  padding: 15px;
  position: relative;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 40px;
    position: absolute;
    left: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: auto;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '15px'};
  overflow: auto;
  width: 100%;
  flex: 1;

  @media ${DeviceMediaQueries.Tablet} {
    padding: ${props => props.padding || '30px'};
  }
`;

export const DesktopInputPlaceholder = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Laptop} {
    display: flex;
    flex: 1;
  }
`;

export const LoadingContainer = styled.div`
  background-color: ${Colors.White};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoalDeleteContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;

  background-image: url(${Images.Icons.TrashCanBlue});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${DeviceMediaQueries.Laptop} {
    top: 20px;
    right: 20px;
    height: 22px;
    width: 22px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0 15px 0;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 0 10px 0 0;
  }

  :last-child {
    padding: 0;
  }
`;