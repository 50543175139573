import
  React
from 'react';

import {
  Colors,
  Images,
  Logger,
  Messages,
  getQueryParameterByName,
  isValidPassword,
  isValidProviderResult,
} from 'common';

import {
  Button,
  PSmaller,
  TextInput,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  TickImage,
  HeaderText,
  SubHeaderText,
} from './forgotPasswordStyled';

import {
  Container,
  InputContainer,
} from '../identityStyled';

import {
  IdentityProvider,
} from 'providers';

class ForgotPasswordChangeUI extends React.Component {

  state = {
    showButtonLoading: false,
    hasPasswordError: false,
    hasConfirmPasswordError: false,
    showError: false,
    isPasswordChangeSuccess: false,
    passwordMessage: '',
    confirmPasswordMessage: '',
    errorMessage: '',
  };

  email = '';
  password = '';
  confirmPassword = '';
  token = '';

  onPasswordChange = val => {
    
    this.password = val;

    if (this.state.hasPasswordError || this.state.showError) {

      this.setState({
        hasPasswordError: false,
        showError: false,
      });
    }
  };

  onConfirmPasswordChange = val => {

    this.confirmPassword = val;

    if (this.state.hasConfirmPasswordError || this.state.showError) {

      this.setState({
        hasConfirmPasswordError: false,
        showError: false,
      });
    }
  };

  onResetPasswordClick = () => {
    
    if (!this.validate()) {
      return;
    }

    this.setState({
      showButtonLoading: true,
    });

    IdentityProvider
      .resetPassword(
        this.email,
        this.password,
        this.token,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
            errorMessage: ret.message || Messages.TechnicalError,
            showButtonLoading: false,
          });

          return;
        }

        this.setState({
          isPasswordChangeSuccess: true,
        });
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          showButtonLoading: false,
        });
      });
  };

  onBackClick = () => {
    this.props.common.navigate('/identity/login');
  };

  validate = () => {

    let passwordMessage;
    let confirmPasswordMessage;

    if (!isValidPassword(this.password)) {
      passwordMessage = Messages.InvalidPassword;
    }

    if (!this.confirmPassword) {
      confirmPasswordMessage = 'Please confirm your password';
    }

    if (this.password && this.confirmPassword && this.password !== this.confirmPassword) {
      confirmPasswordMessage = 'Passwords do not match';
    }

    if (!passwordMessage && !confirmPasswordMessage) {
      return true;
    }

    this.setState({
      hasPasswordError: !!passwordMessage,
      hasConfirmPasswordError: !!confirmPasswordMessage,
      passwordMessage,
      confirmPasswordMessage: confirmPasswordMessage,
    });

    return false;
  };

  componentDidMount () {

    this.token = getQueryParameterByName('t');
    this.email = getQueryParameterByName('email');
  }

  render () {

    if (this.state.isPasswordChangeSuccess) {

      return (

        <Container>
        
          <TickImage
            src={Images.Icons.CircleTick}
          />

          <HeaderText>
            Password change success!
          </HeaderText>

          <SubHeaderText>
            You have successfully changed your password.  Please login to continue.
          </SubHeaderText>

          <Button
            text={'BACK TO LOGIN'}
            onClick={this.onBackClick}
          />

        </Container>
      );
    }

    return (

      <Container>

        <HeaderText>
            Create new password
        </HeaderText>

        <SubHeaderText>
          You are about to change your password. Please enter and confirm your new password.
        </SubHeaderText>

        { this.state.showError &&
          
          <PSmaller
            color={Colors.Red}
            padding={'0 0 10px 0'}
            textAlign={'center'}>

              {this.state.errorMessage}
          </PSmaller>
        }

        <InputContainer>

          <TextInput
            type={'password'}
            placeholder={'Password'}
            width={'100%'}
            onChange={this.onPasswordChange}
            hasError={this.state.hasPasswordError}
            errorMessage={this.state.passwordMessage}
          />

          <TextInput
            type={'password'}
            placeholder={'Retype Password'}
            width={'100%'}
            onChange={this.onConfirmPasswordChange}
            hasError={this.state.hasConfirmPasswordError}
            errorMessage={this.state.confirmPasswordMessage}
          />

          <Button
            text={'CHANGE PASSWORD'}
            width={'100%'}
            showLoading={this.state.showButtonLoading}
            onClick={this.onResetPasswordClick}
          />

        </InputContainer>

      </Container>
    );
  }
}

export const ForgotPasswordChange = withCommon(
  ForgotPasswordChangeUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);