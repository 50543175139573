import
  React
from 'react';

import {
  DataStore,
  ErrorToast,
  Logger,
  Messages,
  IsMobile,
  getQueryParameterByName,
  isValidProviderResult,
} from 'common';

import {
  ProjectsSideMenu,
} from 'ui/projects/components';

import {
  Container,
  LeftContainer,
  RightContainer,
} from 'ui/projects/components/projectsComponentsStyled';

import {
  ProjectsProvider,
} from 'providers';

export const withProjectsSideMenu = (WrappedComponent, options = {}) => {

  const {
    loadProjects = true,
    showOnlySelected = false,
  } = options;

  return class Component extends React.Component {

    state = {
      selectedProject: null,
      projects: [],
      isLoading: true,
    };

    account = {};

    onProjectClick = (project) => {

      this.loadAndSetProject(project)
        .then(() => {
          this.props.common.navigate(`/projects/view?selected=true`);
        });
    };

    onProjectDelete = () => {
      this.load();
    };

    onSetMainProject = () => {
      this.load();
    };

    loadAndSetProject = async (project) => {

      try {

        this.props.common && this.props.common.showLoading && this.props.common.showLoading({
          showSideMenu: !IsMobile,
          showContent: !IsMobile,
        });

        let ret = await ProjectsProvider.getUserProjectById(project.id);

        if (isValidProviderResult(ret)) {
          project = ret.data;
        }

        let advicesRet = await ProjectsProvider.getProjectAdvices(project.id);

        if (isValidProviderResult(advicesRet)) {
          project.project_advices = advicesRet.data || [];
        }

        let employeesRet = await ProjectsProvider.getProjectEmployees(project.id);

        if (isValidProviderResult(employeesRet)) {
          project.project_employees = employeesRet.data || [];
        }

        project.goals = [
          ...project.project_advices || [],
          ...project.project_employees || [],
          ...project.project_fundings || [],
        ];

        project.goals.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });

        DataStore.set(
          'current_project',
          project,
        );
  
        this.props.common && this.props.common.hideLoading && this.props.common.hideLoading();
      }
      catch (e) {

        ErrorToast.show();

        Logger.error(e);

        this.props.common && this.props.common.hideLoading && this.props.common.hideLoading();
      }
    };

    load = () => {

      this.props.common && this.props.common.showLoading && this.props.common.showLoading({
        showContent: false,
      });

      const accountInformation = DataStore.get('ACCOUNT_INFORMATION') || {};

      this.account = (accountInformation.data && Array.isArray(accountInformation.data.account) && accountInformation.data.account.length && accountInformation.data.account[0]) || {};
  
      if (!this.account.id) {

        this.props.common && this.props.common.navigate && this.props.common.navigate('/projects/account/incomplete');
        return;
      }

      ProjectsProvider.getBasicProjectList()
        .then(ret => {

          this.props.common && this.props.common.hideLoading && this.props.common.hideLoading();

          if (!isValidProviderResult(ret)) {
  
            ErrorToast.show();

            Logger.error(ret.message || Messages.TechnicalError);
            return;
          }
  
          DataStore.set(
            'USER_PROJECTS',
            ret.data,
          );

          let currentProject = DataStore.get('current_project');

          if (currentProject) {

            for (let i = 0; i < ret.data.length; i++) {

              if (ret.data[i].id === currentProject.id) {

                currentProject = ret.data[i];

                DataStore.set(
                  'current_project',
                  currentProject,
                );

                break;
              }
            }

            this.loadAndSetProject(currentProject);
          }

          this.setState({
            projects: ret.data,
          });
        })
        .catch(e => {

          ErrorToast.show();

          Logger.error(e);

          this.props.common && this.props.common.hideLoading && this.props.common.hideLoading();
        });
    };

    componentDidMount() {

      if (!loadProjects) {

        this.setState({
          projects: DataStore.get('USER_PROJECTS') || [],
        });

        return;
      }
  
      this.load();
    };
  
    render() {

      const showMobileSideMenu = !getQueryParameterByName('selected');

      return (
  
        <Container>
  
          <LeftContainer
            mobileShow={showMobileSideMenu}>
  
            <ProjectsSideMenu
              showOnlySelected={showOnlySelected}
              account={this.account}
              user={(this.props.common && this.props.common.user) || {}}
              projects={this.state.projects}
              onProjectClick={this.onProjectClick}
              onProjectDelete={this.onProjectDelete}
              onSetMainProject={this.onSetMainProject}
            />
  
          </LeftContainer>
  
          <RightContainer>
  
            <WrappedComponent
              {...this.props}
              selectedProject={this.state.selectedProject}
            />
  
          </RightContainer>
  
        </Container>
      );
    }
  }
};