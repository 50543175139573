import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceMediaQueries,
} from 'common';

import {
  Loader,
} from './Loader';

import {
  Popup,
} from './Popup';

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media ${DeviceMediaQueries.Tablet} {
    height: 700px;
  }
`;

const LoaderFrame = styled.div`
  background-color: ${Colors.White};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const IFramePopup = props => {

  const [showLoading, setStateShowLoading] = React.useState(true);

  const onFrameLoaded = () => {
    setStateShowLoading(false);
  };

  return (

    <Popup
      headerBackgroundColor={Colors.Gray8}
      onClose={props.onClose}>

        <Content>

          <IFrame
            frameBorder={0}
            src={props.src}
            onLoad={onFrameLoaded}
          />

          { showLoading &&

            <LoaderFrame>
            
              <Loader/>
          
            </LoaderFrame>
          }

        </Content>

    </Popup>
  );
};