import
  React, {
  useEffect,
  useState
} from 'react';

import {
  Prompt
} from 'react-router-dom';

import  {
  Colors,
  FontSizes,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
} from 'common';

import {
  Text,
} from 'common/components/text';

import {
  Button,
} from './Button';

import {
  Popup,
} from './Popup';

export const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  headerBackgroundColor = Colors.Blue3,
  footerBackgroundColor = Colors.Blue3,
}) => {

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {

      document.getElementById('root').click();
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {

    setModalVisible(false);
    setConfirmedNavigation(true);
  }; 

  useEffect(() => {

    if (confirmedNavigation && lastLocation) {

      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <React.Fragment>

      <Prompt when={when} message={handleBlockedNavigation} />
      
      { modalVisible &&

        <Popup
          maxWidth={'500px'}
          header={'Close without saving?'}
          headerBackgroundColor={headerBackgroundColor}
          onClose={closeModal}>

          <Text
            fontSize={FontSizes.Normal}
            padding={'30px 30px 120px 30px'}
            flex={'1'}>

              You have unsaved changes. Are you sure you want to leave this page without saving?
          </Text>

          <PopupFooter
            footerBackgroundColor={footerBackgroundColor}>

            <PopupActionContainer>

              <PopupActionButtonContainer>

                <Button
                  width={'100%'}
                  text={'NO'}
                  onClick={closeModal}
                />
              
              </PopupActionButtonContainer>

              &nbsp;&nbsp;

              <PopupActionButtonContainer>

                <Button
                  width={'100%'}
                  text={'YES'}
                  onClick={handleConfirmNavigationClick}
                />

              </PopupActionButtonContainer>

            </PopupActionContainer>

          </PopupFooter>

        </Popup>
      }

    </React.Fragment>
  );
};