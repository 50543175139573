import
  React
from 'react';

import {
  Colors,
  DataStore,
  FontSizes,
  Images,
  Link,
  Logger,
  Messages,
  ErrorToast,
  SuccessToast,
  isValidProviderResult
} from 'common';

import {
  Button,
  Text,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  IdentityProvider
} from 'providers';

import {
  EmailImage,
  HeaderText,
  SubHeaderText,
} from './verifyEmailStyled';

import {
  Container,
} from '../identityStyled';

class VerifyEmailUI extends React.Component {

  onBackClick = () => {
    this.props.common.navigate('/identity/login');
  };

  onResendClick = () => {

    this.props.common.showLoading({
      showContent: false,
      backgroundColor: Colors.White,
    });

    IdentityProvider
      .register(
        DataStore.get('REGISTRATION_EMAIL'),
      )
      .then(ret => {

        this.props.common.hideLoading();

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);
          ErrorToast.show();
          return;
        }

        SuccessToast.show('We\'ve resent you a verification email.', 'Please click the link in the email to activate your account.')
      });
  };

  render () {

    return (

      <Container>
        
        <EmailImage
          src={Images.Icons.EmailSent}
        />

        <HeaderText>
          Thank you for registering!
        </HeaderText>

        <SubHeaderText>
          We've sent you a verification email. Please click the link in the email to activate your account.
        </SubHeaderText>

        <Text
          fontSize={FontSizes.Small}
          padding={'0 0 20px 0'}>
            Did not receive the email? <Link onClick={this.onResendClick}>Resend</Link>
        </Text>

        <Button
          text={'BACK TO LOGIN'}
          onClick={this.onBackClick}
        />

      </Container>
    );
  }
}

export const VerifyEmail = withCommon(
  VerifyEmailUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
