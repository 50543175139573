import {
  Environment,
} from './environment';

const neverResolvingPromise = () => new Promise(() => undefined);

const successResponse = () => ({ value: true });
const failureResponse = () => ({ value: false });
const missingPermissionError = () => new Error('MISSING_PERMISSION');
const alreadyRecordingError = () => new Error('ALREADY_RECORDING');
const deviceCannotVoiceRecordError = () => new Error('DEVICE_CANNOT_VOICE_RECORD');
const failedToRecordError = () => new Error('FAILED_TO_RECORD');
const recordingHasNotStartedError = () => new Error('RECORDING_HAS_NOT_STARTED');
const failedToFetchRecordingError = () => new Error('FAILED_TO_FETCH_RECORDING');
const couldNotQueryPermissionStatusError = () => new Error('COULD_NOT_QUERY_PERMISSION_STATUS');

export class WebVoiceRecorder {

  constructor() {
    this.mediaRecorder = null;
    this.chunks = [];
    this.pendingResult = neverResolvingPromise();
  }

  async canDeviceVoiceRecord() {

    let _a;

    if (((_a = navigator === null || navigator === void 0 ? void 0 : navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getUserMedia) == null) {
      return failureResponse();
    }
    else {
      return successResponse();
    }
  }

  async startRecording() {

    if (this.mediaRecorder != null) {
      throw alreadyRecordingError();
    }

    const deviceCanRecord = await this.canDeviceVoiceRecord();

    if (!deviceCanRecord.value) {
      throw deviceCannotVoiceRecordError();
    }

    const havingPermission = await WebVoiceRecorder.hasAudioRecordingPermission().catch(() => successResponse());

    if (!havingPermission.value) {
      throw missingPermissionError();
    }

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(this.onSuccessfullyStartedRecording.bind(this))
      .catch(this.onFailedToStartRecording.bind(this));

    return successResponse();
  }

  async stopRecording() {

    if (this.mediaRecorder == null) {
      throw recordingHasNotStartedError();
    }

    try {
      this.mediaRecorder.stop();
      return this.pendingResult;
    }
    catch (ignore) {
      throw failedToFetchRecordingError();
    }
    finally {
      this.prepareInstanceForNextOperation();
    }
  }

  static async hasAudioRecordingPermission() {

    return navigator.permissions.query({ name: 'microphone' })
      .then(result => ({ value: result.state === 'granted' }))
      .catch(() => { throw couldNotQueryPermissionStatusError(); });
  }

  stopStream() {

    if (!this.stream || !this.stream.getTracks) {
      return;
    }

    this.stream.getTracks().forEach(function(track) {

      if (track && track.stop) {
        track.stop(); 
      }
    });

    this.stream = null;
  } 

  async requestAudioRecordingPermission() {

    const havingPermission = await WebVoiceRecorder.hasAudioRecordingPermission().catch(() => failureResponse());

    if (havingPermission.value) {
      return successResponse();
    }

    return navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => successResponse())
      .catch(() => failureResponse());
  }

  pauseRecording() {

    if (this.mediaRecorder == null) {
      throw recordingHasNotStartedError();
    }

    if (this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.pause();
      return Promise.resolve(successResponse());
    }

    return Promise.resolve(failureResponse());
  }

  resumeRecording() {

    if (this.mediaRecorder == null) {
      throw recordingHasNotStartedError();
    }
    
    if (this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume();
      return Promise.resolve(successResponse());
    }

    return Promise.resolve(failureResponse());
  }

  getCurrentStatus() {

    if (this.mediaRecorder == null) {
      return Promise.resolve({ status: 'NONE' });
    }

    if (this.mediaRecorder.state === 'recording') {
      return Promise.resolve({ status: 'RECORDING' });
    }

    if (this.mediaRecorder.state === 'paused') {
      return Promise.resolve({ status: 'PAUSED' });
    }
    
    return Promise.resolve({ status: 'NONE' });
  }

  onSuccessfullyStartedRecording(stream) {
    
    this.stopStream();

    this.stream = stream;

    this.pendingResult = new Promise((resolve, reject) => {

      const mimeType = 'audio/wave';

      const options = {
        mimeType
      };
      
      const workerOptions = {
        OggOpusEncoderWasmPath: `${Environment.uiHost}OggOpusEncoder.wasm`,
        WebMOpusEncoderWasmPath: `${Environment.uiHost}WebMOpusEncoder.wasm`,
      };

      this.mediaRecorder = new window.OpusMediaRecorder(stream, options, workerOptions);

      this.mediaRecorder.onerror = () => {
      
        reject(failedToRecordError());
        this.prepareInstanceForNextOperation();
      };

      this.mediaRecorder.onstop = async () => {

        if (mimeType == null) {
          reject(failedToFetchRecordingError());
        }
        else {
          const blobVoiceRecording = new Blob(this.chunks, {type: mimeType});
          const recordDataBase64 = await WebVoiceRecorder.blobToBase64(blobVoiceRecording);
          this.prepareInstanceForNextOperation();
          resolve({ value: { recordDataBase64, mimeType: mimeType  } });
        }
      };

      this.mediaRecorder.ondataavailable = (event) => this.chunks.push(event.data);
      this.mediaRecorder.start();
    });
  }

  onFailedToStartRecording() {
    this.prepareInstanceForNextOperation();
    throw failedToRecordError();
  }

  static blobToBase64(blob) {

    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(String(reader.result));
      reader.readAsDataURL(blob);
    });
  }

  prepareInstanceForNextOperation() {

    if (this.mediaRecorder != null && this.mediaRecorder.state === 'recording') {
      try {
        this.mediaRecorder.stop();
      }
      catch (ignore) { }
    }
    this.pendingResult = neverResolvingPromise();
    this.mediaRecorder = null;
    this.chunks = [];
  }
}