import {
  ContactsChat,
  ContactsShares,
  ContactsInvites,
} from 'ui/contacts';

export const ContactsRoutes = {
  contactsChat: {
    component: ContactsChat,
    path: '/contacts/chat',
  },
  contactsShares: {
    component: ContactsShares,
    path: '/contacts/shares',
  },
  contactsInvites: {
    component: ContactsInvites,
    path: '/contacts/invites',
  }
};