import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
} from 'common';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const LeftContainer = styled.div`
  background-color: ${Colors.Red3};
  position: relative;
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    overflow: auto;
  }
`;

export const RightContainer = styled.div`
  background-color: ${Colors.Gray1};
  width: 100%;
  padding: 15px;
  position: relative;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 40px;
    position: absolute;
    left: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: auto;
  }
`;

export const ContentContainer = styled.div`
  dispay: flex;
  flex-direction: column;
  padding: 15px 15px 20px 15px;
`;

export const ProfilePicContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DeviceMediaQueries.Laptop} {
    flex-direction: row;
  }
`;

export const EvenlySpacedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0;
`;

export const DesktopSpace = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    width: 15px;
    ${props => props.height && `height: ${props.height};`}
  }
`;

export const Space = styled.div`
  display: flex;
  width: 15px;
`;

export const ActionContainer = styled.div`

  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    ${props => props.padding && `padding: ${props.padding};`}
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 180px;
  }
`;

export const PopupContent = styled.div`
  width: 100%;
  padding: 15px;
`;

export const HighestLevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${Colors.Gray2};
  width: 100%;
`;

export const NoItemsTextContainer = styled.div`
  border-top: 1px solid ${Colors.Gray2};
  padding-top: 15px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.showBorderBottom && `border-bottom: 1px solid ${Colors.Gray2};`}

  @media ${DeviceMediaQueries.Tablet} {
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HtmlContent = styled.div`
  width: 100%;
`;

export const DeactivateActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 300px;
  }
`;

export const MobileSpace = styled.div`
  height: 80px;

  @media ${DeviceMediaQueries.Tablet} {
    height: 0;
  }
`;

export const LinkedAccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media ${DeviceMediaQueries.Tablet} {
    justify-content: flex-start;
  }
`;

export const LinkedAccountDesktopSpace = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: block;
    width: 50px;
  }
`;