import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  FontSizes,
  Logger,
  ErrorToast,
  Messages,
  IsMobile,
  SafeAreas,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Select,
  Text,
  Toggle,
  InformationBubble,
} from 'common/components';

import {
  LookupProvider,
  ProjectsProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.Green3};
  padding: 15px 15px 65px 15px;
  margin-bottom: ${SafeAreas.bottom};
  min-height: 100%;
`;

const Line = styled.div`
  background-color: ${Colors.White};
  height: 2px;
  width: 100%;
  margin: 10px 0;
`;

const MatchesRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 5px 0;
`;

const MatchesCol = styled.div`
  color: ${Colors.White};
  font-size: 11.2px;
  position: relative;
  display: flex;
  align-items: center;

  ${props => props.flex && `flex: ${props.flex};`}
`;

const MatchesContainer = props => (

    <MatchesRow>

      <MatchesCol flex={1}>Reviewed / All available (matches)<InformationBubble id={'custom-matches-reviewed'}/></MatchesCol>
      <MatchesCol>{props.reviewed || 0}/{props.total || 0}</MatchesCol>

    </MatchesRow>
);

const ToggleContainer = ({
  text,
  onChange,
  isOn = true,
}) => (

  <MatchesRow>

    <MatchesCol flex={1}>

      <Text
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0 0 5px 0'}>

          {text}

          <InformationBubble
            id={'custom-matches-hide-actioned'}
            renderAbove={true}
          />
      </Text>

    </MatchesCol>

    <MatchesCol>

      <Toggle
        isOn={isOn}
        onChange={onChange}
      />

    </MatchesCol>

  </MatchesRow>
);

const MatchesSideMenuComponent = props => {

  const [state, setState] = React.useState({
    natureList: [],
    typeList: [],
    stageList: [],
    countryList: [],
    industryList: [],
    projectsList: [],
    goalsList: [],
    matchTotals: {},
    mainProject: undefined,
  });

  const filters = React.useRef(DataStore.get('matchesFilters') || {});

  const onProjectChange = (val, skipReload) => {

    clearFilters();

    filters.current = {
      ...filters.current,
      matchProject: val.value || val,
    };

    if (IsMobile) {
      props.history.push('#matches-content-container');
    }

    const f = getFiltersForSearch();

    typeof props.onSearchClick === 'function' && props.onSearchClick(f, skipReload);
  };

  const onNatureChange = (val) => {

    filters.current = {
      ...filters.current,
      natures: val,
    };
  };

  const onProjectTypeChange = (val) => {

    filters.current = {
      ...filters.current,
      types: val,
    };
  };

  const onIndustriesChange = (val) => {

    filters.current = {
      ...filters.current,
      industries: val,
    };
  };

  const onCountriesChange = (val) => {

    filters.current = {
      ...filters.current,
      countries: val,
    };
  };

  const onStagesChange = (val) => {

    filters.current = {
      ...filters.current,
      stages: val,
    };
  };

  const onGoalsChange = (val) => {

    filters.current = {
      ...filters.current,
      goals: val,
    };
  };

  const onHideViewedToggle = (val) => {

    filters.current = {
      ...filters.current,
      hideViewed: val,
    };

    if (!IsMobile) {

      const f = getFiltersForSearch();

      typeof props.onSearchClick === 'function' && props.onSearchClick(f);
    }
  };

  const onClearFiltersClick = () => {

    clearFilters();
    onSearchClick();
  };

  const onSearchClick = (skipReload) => {

    if (IsMobile) {
      props.history.push('#matches-content-container');
    }

    const f = getFiltersForSearch();
    f.matchProject = null;

    DataStore.set(
      'matchesFilters',
      f,
    );

    clearProject();

    typeof props.onSearchClick === 'function' && props.onSearchClick(f, skipReload);
  };

  const onShowSavedMatchesClick = () => {

    if (IsMobile) {
      props.history.push('#matches-content-container');
    }

    clearFilters();

    const f = getFiltersForSearch();

    f.showSaved = true;
    f.hideViewed = false;

    DataStore.set(
      'matchesFilters',
      f,
    );

    clearProject();

    typeof props.onSearchClick === 'function' && props.onSearchClick(f);
  };

  const getFiltersForSearch = () => {

    let obj = {};
    filters.current = filters.current || {};

    if (filters.current.natures) {
      obj.natures = filters.current.natures.map(v => v.value || v);
    }

    if (filters.current.types) {
      obj.types = filters.current.types.map(v => v.value || v);
    }

    if (filters.current.industries) {
      obj.industries = filters.current.industries.map(v => v.value || v);
    }

    if (filters.current.countries) {
      obj.countries = filters.current.countries.map(v => v.value || v);
    }

    if (filters.current.stages) {
      obj.stages = filters.current.stages.map(v => v.value || v);
    }

    if (filters.current.goals) {
      obj.goals = filters.current.goals.map(v => v.value || v);
    }

    obj.hideViewed = filters.current.hideViewed;
    obj.matchProject = filters.current.matchProject;

    DataStore.set(
      'matchesFilters',
      filters.current,
    );

    return obj;
  };

  const clearFilters = () => {

    const el = document.getElementsByClassName('select-trash-can');

    for (let i = 0; i < el.length; i++) {

      const  e = el[i];
      setTimeout(() => { e.click() }, 0);
    }

    filters.current = {
      hideViewed: filters.current.hideViewed,
    };
  };

  const clearProject = () => {

    filters.current.matchProject = null;

    setState(prevState => ({
      ...prevState,
      mainProject: '',
    }));

    const el =  document.getElementById('matches-project-filter-secretclear');

    if (el && el.click) {
      document.getElementById('matches-project-filter-secretclear').click();
    }
  };

  const loadLookups = async () => {

    const natureList = await LookupProvider.getProjectNatureList();
    const typeList = await LookupProvider.getProjectTypes();
    const stageList = await LookupProvider.getProjectStages();
    const countryList = await LookupProvider.getProjectCountries();
    const industryList = await LookupProvider.getProjectIndustries();

    // TODO: this will change
    const goalsList = [
      {
        display: 'Mentoring',
        value: 'Advice',
      },
      {
        display: 'Hiring',
        value: 'Employee',
      },
      {
        display: 'Funding',
        value: 'Finance',
      }
    ];

    let projectsList = [];

    let mainProject;

    let ret = await ProjectsProvider.getBasicProjectList();

    if (!isValidProviderResult(ret)) {

      ErrorToast.show(
        null,
        'We were unable to load your project list.'
      );

      Logger.error(ret.message || Messages.TechnicalError);
    }

    if (isValidProviderResult(ret)) {

      let accountInfo = await DataStore.get('ACCOUNT_INFORMATION') || {};

      accountInfo = accountInfo.data
        && Array.isArray(accountInfo.data.account)
        && !!accountInfo.data.account.length
        && accountInfo.data.account[0];

      let mainProjectId = accountInfo.mainProject || -1;

      projectsList = ret.data.map(project => {

        const listItem = {
          display: project.title,
          value: project.id,
          nature: project.natureId,
        };

        if (!mainProject) {
          mainProject = listItem;
        }

        if (listItem.value === mainProjectId) {
          mainProject = listItem;
        }

        return listItem;
      });

      DataStore.set('MATCHES_CARDS_PROJECT_LIST', projectsList);
    }

    mainProject = filters.current.matchProject
      || (!Object.keys(filters.current || {}).length && mainProject)
      || undefined;

    if (mainProject) {
      onProjectChange(mainProject, true);
    }
    else {
      onSearchClick(true);
    }

    setState(prevState => ({
      ...prevState,
      natureList,
      typeList,
      stageList,
      countryList,
      industryList,
      projectsList,
      goalsList,
      mainProject: (mainProject && mainProject.value) || mainProject,
    }));
  };

  const load = () => {

    loadLookups()
      .catch(e => Logger.error(e));
  };

  React.useEffect(() => {

    return () => {

      if (props.history.location.pathname !== '/matches/view/profile') {
        DataStore.clear('matchesFilters');
      }
    };
  }, [props.history]);

  React.useEffect(() => {

    setState(prevState => ({
      ...prevState,
      matchTotals: props.matchTotals,
    }));

  }, [props.matchTotals]);

  React.useEffect(
    load,
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    
    <Container>

      <Text
        color={Colors.White}
        fontSize={FontSizes.Regular}
        padding={'0 0 10px 0'}>

          Filter by project

          <InformationBubble
            id={'custom-matches-selected-project'}
          />

      </Text>

      <Select
        id={'matches-project-filter'}
        width={'100%'}
        options={state.projectsList}
        defaultValue={state.mainProject}
        onChange={onProjectChange}
      />

      <MatchesContainer
        reviewed={state.matchTotals.isProjectMatch && state.matchTotals.actionedCount}
        total={state.matchTotals.isProjectMatch && state.matchTotals.totalMatched}
      />

      <Line/>

      <Text
        color={Colors.White}
        fontSize={FontSizes.Regular}
        padding={'0 0 10px 0'}>

          Filter manually:

          <InformationBubble
            id={'custom-matches-filter-projects'}
          />
      </Text>

      <Button
        border={'none'}
        text={'Search'}
        width={'100%'}
        margin={'0 0 10px 0'}
        onClick={() => onSearchClick()}
      />

      <MatchesContainer
        reviewed={!state.matchTotals.isProjectMatch && state.matchTotals.actionedCount}
        total={!state.matchTotals.isProjectMatch && state.matchTotals.totalMatched}
      />

      <Select
        width={'100%'}
        placeholder={'Project type'}
        options={state.natureList}
        defaultOptions={filters.current.natures}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onNatureChange}
        margin={'10px 0'}
      />

      <Select
        width={'100%'}
        placeholder={'Project form'}
        options={state.typeList}
        defaultOptions={filters.current.types}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onProjectTypeChange}
      />

      <Select
        width={'100%'}
        placeholder={'Project industry'}
        options={state.industryList}
        defaultOptions={filters.current.industries}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onIndustriesChange}
      />

      <Select
        width={'100%'}
        placeholder={'Project country'}
        options={state.countryList}
        defaultOptions={filters.current.countries}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onCountriesChange}
      />

      <Select
        width={'100%'}
        placeholder={'Project stage'}
        options={state.stageList}
        defaultOptions={filters.current.stages}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onStagesChange}
      />

      <Select
        width={'100%'}
        placeholder={'Project goal'}
        options={state.goalsList}
        defaultOptions={filters.current.goals}
        isMultiSelect={true}
        displaySelectedInline={true}
        onChange={onGoalsChange}
      />

      <Text
        color={Colors.White}
        fontSize={FontSizes.Small}
        padding={'0'}
        textAlign={'center'}
        width={'100%'}
        onClick={onClearFiltersClick}>

          Clear filters
      </Text>

      <Line/>

      <Text
        color={Colors.White}
        fontSize={FontSizes.Regular}
        padding={'0 0 10px 0'}>

          Display settings
      </Text>

      <ToggleContainer
        isOn={filters.current.hideViewed}
        text={'Hide reviewed matches'}
        onChange={onHideViewedToggle}
      />

      <Line/>

      <Button
        border={'none'}
        text={'Show saved matches'}
        width={'100%'}
        margin={'15px 0 0 0'}
        onClick={onShowSavedMatchesClick}
      />

    </Container>
  );
};

export const MatchesSideMenu = withRouter(MatchesSideMenuComponent);