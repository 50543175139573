import
  React
from 'react';

import {
  Image,
  Images,
  InformationIcons,
  IsMobile,
} from 'common';

export const InformationBubble = props => {

  const text = props.text || InformationIcons()[props.id];

  const onClick = (e) => {

    if (!IsMobile) {
      return;
    }

    onShowInformation(e);
  };

  const onHover = (e) => {

    if (IsMobile) {
      return;
    }

    onShowInformation(e);
  };

  const onShowInformation = (e) => {

    window.addEventListener('touchstart', onHideInformation);

    window.showInformationBubble(text, e);
  };

  const onHideInformation = () => {

    window.removeEventListener('touchstart', onHideInformation);

    window.hideInformationBubble();
  };

  if (!text) {
    return <></>;
  }

  return (

    <Image
      height={props.height || '19px'}
      width={props.width || '30px'}
      padding={props.padding || '5px 0 0 0'}
      position={props.position}
      top={props.top}
      right={props.right}
      src={Images.Icons.InformationIcon}
      onMouseOver={onHover}
      onMouseLeave={onHideInformation}
      onClick={(e) => { e.stopPropagation(); onClick(e); }}
    />
  );
};