import
  React
from 'react';

import {
  Colors,
  TextInputContainer,
} from 'common';

import {
  PSmall,
} from 'common/components';

import {
  TextContainer,
} from './projectsComponentsStyled';

export const ProjectInformationDisplay = ({
  project,
  color = Colors.Black
}) => {

  const industries = project.industries || [];

  const industryDisplay = industries.map(i => i.display);

  const countries = project.countries || [];

  const countryDisplay = countries.map(c => c.display);

  return (

    <React.Fragment>

      <TextInputContainer
        padding={'0 0 15px 0'}>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Type
          </PSmall>

          <PSmall
            color={color}>

              {(project.nature && project.nature.display) || 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Form
          </PSmall>

          <PSmall
            color={color}>

              {(project.type && project.type.display) || 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Title
          </PSmall>

          <PSmall
            color={color}>

              {project.title || 'Not specified'}
          </PSmall>

        </TextContainer>

      </TextInputContainer>

      <TextInputContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Industry
          </PSmall>

          <PSmall
            color={color}>

              {industryDisplay.join(', ') || 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Country
          </PSmall>

          <PSmall
            color={color}>

              {countryDisplay.join(', ') || 'Not specified'}
          </PSmall>

        </TextContainer>

        <TextContainer>

          <PSmall
            color={color}
            fontWeight={'bold'}>

              Stage
          </PSmall>

          <PSmall
            color={color}>

              {(project.status && project.status.display) || 'Not specified'}
          </PSmall>

        </TextContainer>

      </TextInputContainer>

    </React.Fragment>
  );
};