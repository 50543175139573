import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
} from 'common';

import {
  InformationBubble,
} from 'common/components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => !props.isOpen && 'overflow: hidden;'}
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: ${props => props.headerCenterText ? 'center' : 'space-between'};
  align-items: center;
  padding: ${props => props.padding};

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props => props.showPointer && `cursor: pointer;`}
  ${props => props.borderColor && `border: 2px solid ${props.borderColor};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius} ${props.borderRadius} 0 0;`}
  ${props => !props.isOpen && props.borderRadius && `border-radius: ${props.borderRadius};`}
`;

const HeaderText = styled.div`
  color: ${props => props.color || Colors.Green3};
  font-size: ${FontSizes.Regular};
`;

const Content = styled.div`
  ${props => props.maxHeight !== null ? 
    props.maxHeight !== 0 ?`max-height: ${props.maxHeight * 1.5}px;`
      : 'max-height: 0px;'
    : ''}

  ${props => !props.isCollapsable && `height: 100%; position: relative;`}
  ${props => props.borderColor && `border: 2px solid ${props.borderColor};`}
  ${props => props.borderRadius && `border-radius: 0 0 ${props.borderRadius} ${props.borderRadius};`}
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${props => !props.isOpen && 'overflow: hidden; border: 0;'}

  border-top: 0;
`;

const Svg = styled.svg`
  ${props => !props.isDown && 'transform: rotate(180deg);'}
`;

export const Accordion = ({
  id,
  header,
  children,
  backgroundColor,
  borderRadius,
  contentBackgroundColor,
  headerColor = Colors.Green3,
  headerPadding = '5px 0',
  headerCenterText = false,
  padding = '',
  initialIsOpen = true,
  showArrow = true,
  isCollapsable = true,
  onHeaderClick = null,
}) => {

  const containerRef = React.createRef();

  const [state, setState] = React.useState({
    isOpen: initialIsOpen,
    maxContentHeight: initialIsOpen ? null : 0,
  });

  const onHeaderLocalClick = () => {

    onHeaderClick && onHeaderClick();

    if (!isCollapsable) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      isOpen: !prevState.isOpen,
      maxContentHeight: !prevState.isOpen ? null : 0,
    }));
  };

  return (

    <Container
      id={id}
      borderColor={backgroundColor}
      borderRadius={borderRadius}
      padding={padding}
      isOpen={state.isOpen}>

      <Header
        padding={headerPadding}
        backgroundColor={backgroundColor}
        borderColor={backgroundColor}
        borderRadius={borderRadius}
        headerCenterText={headerCenterText}
        onClick={onHeaderLocalClick}
        isCollapsable={isCollapsable}
        isOpen={state.isOpen}
        showPointer={isCollapsable || onHeaderClick}>

        <HeaderText
          color={headerColor}
          fontSize={FontSizes.Regular}>

          {header}

          <InformationBubble
            id={id}
          />

        </HeaderText>

        { showArrow &&

          <Svg
            width={'16px'}
            height={'16px'}
            viewBox={'0 0 1024 1024'}
            isDown={!state.isOpen}>

            <path
              fill={headerColor || Colors.Black}
              d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3 0.1-12.7-6.4-12.7z"
              p-id="935"
            />

          </Svg>
        }

      </Header>

      <Content
        ref={containerRef}
        maxHeight={state.maxContentHeight}
        isCollapsable={isCollapsable}
        backgroundColor={contentBackgroundColor}
        borderColor={backgroundColor}
        borderRadius={borderRadius}
        isOpen={state.isOpen}>

        {children}
      </Content>

    </Container>
  );
};