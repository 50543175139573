import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  ErrorToast,
  Logger,
  FontSizes,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  PaymentProvider,
} from 'providers';

import {
  PaymentProgressStepper,
} from 'ui/payment/components';

import {
  StepperContainer,
  ContentContainer,
} from './paymentStyled';

const Container = styled.div`
  background-color: ${Colors.White};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HeaderText = styled.div`
  color: ${Colors.PrimaryOne};
  font-size: ${FontSizes.Big};
  font-weight: bold;
  padding: 25px 0 20px 0;
  text-align: center;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Bigger};
    padding: 30px 15px 25px 15px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Biggest};
  }
`;

export const SubHeaderText = styled.div`
  font-size: ${FontSizes.Small};
  text-align: center;
  max-width: 550px;
  padding: 0 0 25px 0;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Regular};
    padding: 0 0 40px 0;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    font-size: ${FontSizes.Big};
  }
`;

const PaymentResultUI = props => {

  const [isLoading, isLoadingSetState] = React.useState(true);

  const onToLoginClick = () => {
    props.common.navigate('/identity/login');
  };

  const onToDashboardClick = () => {
    props.common.navigate('/home');
  };

  const status = new URLSearchParams(window.location.search).get('status');
  const sessionId = new URLSearchParams(window.location.search).get('session_id');
  const priceId = new URLSearchParams(window.location.search).get('price_id');

  const mainButton = !!DataStore.get('LOGGED_IN_USER')
    ? <Button text={'Back to dashboard'} onClick={onToDashboardClick} />
    : <Button text={'Back to login'} onClick={onToLoginClick} />;

  const header = status === 'SUCCESS'
    ? 'Payment success'
    : status === 'CANCELLED'
      ? 'Payment cancelled'
      : Messages.ErrorHeading;

  const subHeader = status === 'SUCCESS'
    ? 'Your payment was successful. You are now subscribed.'
    : status === 'CANCELLED'
      ? 'You have cancelled your payment.'
      : Messages.TechnicalError;

  React.useEffect(() => {

    PaymentProvider
      .completePayment(sessionId, priceId)
      .then(ret => {

        isLoadingSetState(false);

        if (isValidProviderResult(ret)) {
          return;
        }

        const message = `PAYMENT SESSION ID: ${sessionId}, STATUS: ${status}, ${ret.data.message || 'Unable to complete payment'}`;
        Logger.error(message);
      })
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);
      });

  }, [sessionId, status, priceId]);

  if (isLoading) {

    return (
      <Container>

        <Loader/>

      </Container>
    );
  }

  return (
    <Container>

      <StepperContainer>

        <PaymentProgressStepper
          selectedIndex={999}
          title={'Payment'}
        />

      </StepperContainer>

      <ContentContainer>

        <HeaderText>{header}</HeaderText>

        <SubHeaderText>{subHeader}</SubHeaderText>

        {mainButton}

      </ContentContainer>

    </Container>
  );
};

export const PaymentResult = withCommon(
  PaymentResultUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  },
);