import
  React
from 'react';

import {
  Colors,
  FontSizes,
  DesktopSpace,
  TextInputContainer,
} from 'common';

import {
  MultiAddInput,
  Text,
  InformationBubble,
} from 'common/components';

export class SwotAnalysisForm extends React.Component {

  state = {
    strengthListError: '',
    weaknessListError: '',
    opportunityListError: '',
    threatListError: '',
  };

  strengthList = [];
  weaknessList = [];
  opportunityList = [];
  threatList = [];

  onStrengthsChange = val => {

    if (typeof this.props.onChange !== 'function') {
      return;
    }

    const list = val.map(v => ({
      ...v,
      projectId: this.props.project.id,
      swotTypeId: 1,
    }));

    this.strengthList = list || [];

    this.props.onChange(
      this.getCombinedList(),
    );

    this.state.strengthListError && this.setState({
      strengthListError: '',
    });
  };

  onWeaknessesChange = val => {

    if (typeof this.props.onChange !== 'function') {
      return;
    }

    const list = val.map(v => ({
      ...v,
      projectId: this.props.project.id,
      swotTypeId: 2,
    }));

    this.weaknessList = list || [];

    this.props.onChange(
      this.getCombinedList(),
    );

    this.state.weaknessListError && this.setState({
      weaknessListError: '',
    });
  };

  onOpportunitiesChange = val => {

    if (typeof this.props.onChange !== 'function') {
      return;
    }

    const list = val.map(v => ({
      ...v,
      projectId: this.props.project.id,
      swotTypeId: 3,
    }));

    this.opportunityList = list || [];

    this.props.onChange(
      this.getCombinedList(),
    );

    this.state.opportunityListError && this.setState({
      opportunityListError: '',
    });
  };

  onThreatsChange = val => {

    if (typeof this.props.onChange !== 'function') {
      return;
    }

    const list = val.map(v => ({
      ...v,
      projectId: this.props.project.id,
      swotTypeId: 4,
    }));

    this.threatList = list || [];

    this.props.onChange(
      this.getCombinedList(),
    );

    this.state.threatListError && this.setState({
      threatListError: '',
    });
  };

  getCombinedList = () => {

    return [
      ...this.strengthList,
      ...this.weaknessList,
      ...this.opportunityList,
      ...this.threatList,
    ];
  };

  validate = () => {

    let errors = {};

    if (!this.strengthList.length) {
      errors.strengthListError = 'Please add at least one strength';
    }

    if (!this.weaknessList.length) {
      errors.weaknessListError = 'Please add at least one weakness';
    }

    if (!this.opportunityList.length) {
      errors.opportunityListError = 'Please add at least one opportunity';
    }

    if (!this.threatList.length) {
      errors.threatListError = 'Please add at least one threat';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  componentDidMount() {

    this.props.onLoad && this.props.onLoad(this.validate);

    if (!this.props.project || !Array.isArray(this.props.project.project_swots) || !this.props.project.project_swots.length) {
      return;
    }

    let swot;

    for (let i = 0; i < this.props.project.project_swots.length; i++) {

      swot = this.props.project.project_swots[i];

      if (swot.swotTypeId === 1) {

        this.strengthList.push(swot);
      }

      if (swot.swotTypeId === 2) {

        this.weaknessList.push(swot);
      }

      if (swot.swotTypeId === 3) {

        this.opportunityList.push(swot);
      }

      if (swot.swotTypeId === 4) {

        this.threatList.push(swot);
      }
    }

    this.forceUpdate();
  }

  render () {

    return (

      <React.Fragment>

        <Text
          color={Colors.Black}
          fontSize={FontSizes.Small}
          fontWeight={'bold'}
          padding={'10px 0'}>

            SWOT analysis

            <InformationBubble
              id={'custom-project-swot-analysis'}
            />
        </Text>

        <TextInputContainer>

          <MultiAddInput
            id={'multi-add-project-swot-strengths'}
            label={'Strengths'}
            labelColor={Colors.Black}
            items={this.strengthList}
            flex={'1'}
            charCounterMax={250}
            maxItems={10}
            onChange={this.onStrengthsChange}
            hasError={!!this.state.strengthListError}
            errorMessage={this.state.strengthListError}
          />

          <DesktopSpace/>

          <MultiAddInput
            id={'multi-add-project-swot-weaknesses'}
            label={'Weaknesses'}
            labelColor={Colors.Black}
            items={this.weaknessList}
            flex={'1'}
            charCounterMax={250}
            maxItems={10}
            onChange={this.onWeaknessesChange}
            hasError={!!this.state.weaknessListError}
            errorMessage={this.state.weaknessListError}
          />

        </TextInputContainer>

        <TextInputContainer>

          <MultiAddInput
            id={'multi-add-project-swot-opportunities'}
            label={'Opportunities'}
            labelColor={Colors.Black}
            items={this.opportunityList}
            flex={'1'}
            charCounterMax={250}
            maxItems={10}
            onChange={this.onOpportunitiesChange}
            hasError={!!this.state.opportunityListError}
            errorMessage={this.state.opportunityListError}
          />

          <DesktopSpace/>

          <MultiAddInput
            id={'multi-add-project-swot-threats'}
            label={'Threats'}
            labelColor={Colors.Black}
            items={this.threatList}
            flex={'1'}
            charCounterMax={250}
            maxItems={10}
            onChange={this.onThreatsChange}
            hasError={!!this.state.threatListError}
            errorMessage={this.state.threatListError}
          />

        </TextInputContainer>

      </React.Fragment>
    );
  }
}