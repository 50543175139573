import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Chat,
  ErrorToast,
  Logger,
  MenuItems,
  ChatTabOptions,
  SafeAreas,
  IsMobile,
} from 'common';

import {
  withCommon,
} from 'common/hocs';

import {
  Container,
  SideMenuContainer,
  ConversationsContainer,
  NoItemContainer,
} from 'ui/contacts/contactsStyled';

import {
  ChatArea,
  ChatContact,
  ChatOptions,
} from 'ui/contacts/components';

const ContactSpace = styled.div`
  height: ${SafeAreas.bottom};
`;

class ContactsChatUI extends React.Component {

  state = {
    conversations: [],
    currentConversation: null,
    currentConversationLastMessage: null,
  };

  conversationsRawList = [];

  onNewMessage = (message) => {

    if (message.conversation === this.state.currentConversation) {

      this.setState({
        currentConversationLastMessage: message,
      });

      return;
    }

    Chat.getConversations()
      .then(conversations => {

        this.conversationsRawList = conversations;

        this.setState({
          conversations: this.createConversationElements(conversations),
        });
      });
  };

  onConversationUpdated = (conversations) => {

    this.setState({
      conversations: this.createConversationElements(conversations),
    });
  };

  onConversationClick = (conversation) => {

    if (!conversation) {
      return;
    }

    this.props.common.navigate('#conversation_selected');

    this.setState({
      currentConversation: conversation,
    }, () => {

      this.setState({
        conversations: this.createConversationElements(this.conversationsRawList)
      });
    });
  };

  onUserUpdated = () => {
    
    Chat.getConversations()
      .then(conversations => {

        this.conversationsRawList = conversations;

        this.setState({
          conversations: this.createConversationElements(conversations),
        });
      });
  };

  createConversationElements = (conversations) => {

    if (!Array.isArray(conversations) || !conversations.length) {
      return [];
    }

    const conversationElements = [];

    const loggedInIdentity = this.props.common.user.id.toString();

    for (const conversation of conversations) {

      const otherUser = conversation.users.find(u => u.identity !== loggedInIdentity) || {};

      const unreadMessageCount = (!!conversation.totalUnreadMessages
        && conversation !== this.state.currentConversation // if a new message comes through, but this is the open chat, do not show unread count on that conversation
        && conversation.totalUnreadMessages
      ) || 0;

      const profileImage = otherUser.attributes && otherUser.attributes.profilePictureUrl;

      conversationElements.push(

        <ChatContact
          key={conversation.sid}
          conversation={conversation}
          isSelected={!!this.state.currentConversation && this.state.currentConversation.sid === conversation.sid}
          isOnline={otherUser.isOnline}
          projectName={conversation.friendlyName}
          contactPerson={otherUser.friendlyName}
          lastMessage={conversation.lastConversationMessage}
          lastMessageTime={conversation.lastMessage && conversation.lastMessage.dateCreated}
          profileImage={profileImage}
          totalUnreadMessages={unreadMessageCount}
          onClick={() => this.onConversationClick(conversation)}
        />
      );
    }

    return conversationElements;
  };

  load = async () => {

    this.props.common.showLoading({
      showSideMenu: true,
    });

    const conversations = await Chat.getConversations();

    Chat.subscribe('messageAdded', this.onNewMessage);
    Chat.subscribe('conversationAdded', this.onConversationUpdated);
    Chat.subscribe('conversationUpdated', this.onConversationUpdated);
    Chat.subscribe('conversationRemoved', this.onConversationUpdated);
    Chat.subscribe('userUpdated', this.onUserUpdated);

    this.conversationsRawList = conversations;

    this.setState({
      conversations: this.createConversationElements(conversations)
    });

    this.props.common.hideLoading();
  };

  componentWillUnmount() {

    Chat.unsubscribe('messageAdded', this.onNewMessage);
    Chat.unsubscribe('conversationAdded', this.onConversationUpdated);
    Chat.unsubscribe('conversationUpdated', this.onConversationUpdated);
    Chat.unsubscribe('conversationRemoved', this.onConversationUpdated);
    Chat.unsubscribe('userUpdated', this.onUserUpdated);
  }

  componentDidMount() {
    this.load()
      .catch(e => {

        Logger.error(e);

        ErrorToast.show('', 'We were unable to load all conversations, please try again.');
      });
  }

  componentDidUpdate() {
    
    if(this.props.common.history.location.hash === '' && !!this.state.currentConversation) {

      this.setState({
        currentConversation: null,
      }, () => {

        this.setState({
          conversations: this.createConversationElements(this.conversationsRawList),
        });
      });
    }
  }

  render() {

    return (
      <Container>

        { (!IsMobile || !this.state.currentConversation) &&

          <SideMenuContainer>

            <ChatOptions
              selectedTab={ChatTabOptions.Chat}
            />

            <ConversationsContainer>

              { Array.isArray(this.state.conversations) && !!this.state.conversations.length
                
                ? this.state.conversations

                : <NoItemContainer>No chats to display</NoItemContainer>
              }

            </ConversationsContainer>

            <ContactSpace/>

          </SideMenuContainer>
        }

        { this.state.currentConversation &&

          <ChatArea
            common={this.props.common}
            conversation={this.state.currentConversation}
            lastMessage={this.state.currentConversationLastMessage}
            identity={this.props.common.user.id.toString()}
          />
        }

      </Container>
    );
  }
}

export const ContactsChat = withCommon(
  ContactsChatUI, {
    selectedMenuItem: MenuItems.Contacts,
  }
);