import
  React
from 'react';

import {
  Images,
  Logger,
  Messages,
  getQueryParameterByName,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  HeaderText,
  SubHeaderText,
  TickImage,
} from './verifyEmailStyled';

import {
  Container,
} from '../identityStyled';

import {
  IdentityProvider,
} from 'providers';

class VerifiedEmailUI extends React.Component {

  state = {
    isLoading: true,
    showError: false,
    errorMessage: Messages.TechnicalError,
  };

  onLoginClick = () => {
    this.props.common.navigate('/identity/login');
  };

  onRetryClick = () => {
    window.location.reload();
  };

  componentDidMount() {

    let token = getQueryParameterByName('t');
    let email = getQueryParameterByName('email');

    IdentityProvider
      .verifyEmail(
        email,
        token,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
            errorMessage: ret.message || Messages.TechnicalError,
            isLoading: false,
          });

          return;
        }

        this.setState({
          isLoading: false,
        });
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          isLoading: false,
        });
      })
  }

  render () {

    return (

      <Container>

        { this.state.isLoading &&

          <Loader/>
        }

        { !this.state.isLoading && this.state.showError &&

          <React.Fragment>

            <HeaderText>We were unable to verify your email</HeaderText>

            <SubHeaderText>{this.state.errorMessage}</SubHeaderText>

            <Button
              text={'RETRY'}
              onClick={this.onRetryClick}
            />

          </React.Fragment>
        }

        { !this.state.isLoading && !this.state.showError &&

          <React.Fragment>

            <TickImage
              src={Images.Icons.CircleTick}
            />

            <HeaderText>Email Verified</HeaderText>

            <SubHeaderText>You email has successfully been verified. Please click the button below to continue to the login page.</SubHeaderText>

            <Button
              text={'GO TO LOGIN'}
              onClick={this.onLoginClick}
            />

          </React.Fragment>
        }

      </Container>
    );
  }
}

export const VerifiedEmail = withCommon(
  VerifiedEmailUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
