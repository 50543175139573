import
  * as Sentry
from '@sentry/react';

export class Logger {

  static error(err) {

    if (!err) {
      return;
    }

    if (!err instanceof Error) {

      err = Error(err);
    }

    Sentry.captureException(err);
  }

  static addBreadcrumb(message) {

    if (!message) {
      return;
    }

    Sentry.addBreadcrumb({
      category: 'info',
      message: message,
      level: Sentry.Severity.Info,
    });
  }
}