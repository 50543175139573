import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Filesystem,
  Directory,
} from '@capacitor/filesystem';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  ErrorToast,
  SuccessToast,
  Environment,
  Logger,
  Image,
  Images,
  getImageByExtension,
  blobToBase64,
  formatDateTimeForDisplay,
} from 'common';

import {
  Loader,
} from 'common/components';

import {
  ChatImagePreview,
} from './ChatImagePreview';

const Container = styled.div`
  display: flex;
  padding: 30px 10px 23px 10px;
  width: 100%;
  position: relative;
  justify-content: ${props => props.fromSelf ? 'flex-end' : 'flex-start'};
`;

const BubbleContainer = styled.div`
  background-color: ${props => props.fromSelf ? Colors.Gray4 : '#FFEEDA'};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 65%;
  position: relative;
  overflow-wrap: break-word; 
`;

const Header = styled.div`
  font-size: ${FontSizes.Smaller};
  font-weight: bold;
  padding: 0 0 2px 0;
  width: 100%;
  position: absolute;
  top: -23px;
  left: 10px;
`;

const Message = styled.div`
  font-size: ${FontSizes.Smaller};
  padding: ${props => props.padding || '3px 0'};
  width: 100%;
  white-space: pre-wrap;

  ${props => props.height && `height: ${props.height};`}
`;

const Footer = styled.div`
  font-size: ${FontSizes.Smallest};
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: -18px;
  right: 15px;
`;

const MediaContainer = styled.div`
  position: relative;
  min-width: 150px;
  min-height: 50px;
`;

const Audio = styled.audio`
  width: 300px;

  @media ${DeviceMediaQueries.Tablet} {
    width: 450px;
  }
`;

const ImageLabel = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: ${Colors.Black};
  font-size: ${FontSizes.Smaller};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px;
`;

const ChatBubbleComponent = props => {

  const [state, setState] = React.useState({
    mediaUrl: '',
    showMediaError: false,
  });
  
  const hasMedia = props.message.type === 'media';

  const mediaType = (() => {

    if (!hasMedia) {
      return '';
    }

    const contentType = props.message.media.contentType && props.message.media.contentType.toLowerCase();

    return contentType || '';
  })();

  const onMediaClick = () => {

    if (!state.mediaUrl) {
      return;
    }

    props.history.push('#conversation_selected?preview=true');

    // hack to get around a safari styling issue
    props.onBubblePreview && props.onBubblePreview(
      <ChatImagePreview
        imageUrl={state.mediaUrl}
        type={mediaType}
        onDownload={onMediaDownload}
        onClose={onMediaClose}
      />
    );
  };

  const onMediaClose = () => {

    props.onCloseBubblePreview && props.onCloseBubblePreview();
  };

  const onMediaDownload = () => {

    if (!state.mediaUrl) {
      return;
    }

    fetch(state.mediaUrl, {
      method: 'GET', 
      mode: 'cors', 
      cache: 'no-cache',
    }).then((t) => {

      return t.blob().then((b) => {

        let extension = '.jpg';

        if (b.type === 'image/png') {
          extension = '.png';
        }

        if (b.type === 'application/msword') {
          extension = '.doc';
        }

        if (b.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          extension = '.docx';
        }

        if (mediaType === 'application/pdf') {
          extension = '.pdf';
        }

        if (Environment.channel === 'ios') {

          handleIOSDownload(b, extension);
          return;
        }

        var a = document.createElement('a');

        a.href = URL.createObjectURL(b);

        a.setAttribute('download', `Download-${new Date().getTime()}${extension}`);

        a.click();

        props.onCloseBubblePreview && props.onCloseBubblePreview();
      });
    })
    .catch(e => {

      Logger.error(e);

      ErrorToast.show();

      props.onCloseBubblePreview && props.onCloseBubblePreview();
    });
  };

  const handleIOSDownload = (blb, extension) => {

    blobToBase64(blb)
      .then(base64string => {

        Filesystem.writeFile({
          path: `Download-${new Date().getTime()}${extension}`,
          data: base64string,
          directory: Directory.Data,
        })
        .then((sf) => {

          SuccessToast.show('', 'Download successful');

          props.onCloseBubblePreview && props.onCloseBubblePreview();
        })
        .catch(e => {

          Logger.error(e);

          ErrorToast.show();

          props.onCloseBubblePreview && props.onCloseBubblePreview();
        });

      })
      .catch(err => {
        
        Logger.error(err);

        ErrorToast.show();

        props.onCloseBubblePreview && props.onCloseBubblePreview();
      });
  };

  React.useEffect(() => {

    const objDiv = document.getElementById('chat-bubble-container');
    objDiv.scrollTop = objDiv.scrollHeight;

    if (!hasMedia) {
      return;
    }

    props.message.media
      .getContentTemporaryUrl()
      .then((url) => {

        setState(prevState => ({
          ...prevState,
          mediaUrl: url,
        }));

      })
      .catch((e) => {

        Logger.error(e);

        setState(prevState => ({
          ...prevState,
          showMediaError: true,
        }));
      });

  }, [props.message, hasMedia, mediaType]);

  let author = props.fromSelf
    ? 'You'
    : props.message.author;

  if (!props.fromSelf && props.message.conversation && props.message.conversation.users) {

    const participant = props.message.conversation.users.find(p => p.identity === props.message.author);

    if (participant) {
      author = participant.friendlyName;
    }
  }

  return (

    <Container
      id={props.message.sid}
      fromSelf={props.fromSelf}>

        { hasMedia && 

          <MediaContainer>

            <Header>{author}</Header>

            { state.showMediaError &&
              
              <Message height={'200px'} padding={'15px 0'}>Failed to load media</Message>
            }

            { state.mediaUrl && !state.showMediaError &&
              <>

                { mediaType.startsWith('image') &&
                
                  <Image
                    borderRadius={'15px'}
                    boxShadow={'0px 2px 5px rgba(0, 0, 0, 0.2)'}
                    height={'auto'}
                    maxHeight={'200px'}
                    maxWidth={'300px'}
                    src={getImageByExtension(state.mediaUrl)}
                    onClick={onMediaClick}
                  />
                }

                { (mediaType === 'application/msword' || mediaType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') &&
                  
                  <>
                  
                  <Image
                    borderRadius={'15px'}
                    boxShadow={'0px 2px 5px rgba(0, 0, 0, 0.2)'}
                    height={'auto'}
                    maxHeight={'200px'}
                    maxWidth={'300px'}
                    src={Images.Icons.DocPlaceholder}
                    onClick={onMediaClick}
                  />
                  
                  <ImageLabel>{props.message.attributes && props.message.attributes.fileName}</ImageLabel>

                  </>
                }

                { mediaType === 'application/pdf' &&
                
                  <>

                  <Image
                    borderRadius={'15px'}
                    boxShadow={'0px 2px 5px rgba(0, 0, 0, 0.2)'}
                    height={'auto'}
                    maxHeight={'200px'}
                    maxWidth={'300px'}
                    src={Images.Icons.PdfPlaceholder}
                    onClick={onMediaClick}
                  />

                  <ImageLabel>{props.message.attributes && props.message.attributes.fileName}</ImageLabel>

                  </>
                }

                { mediaType.startsWith('audio') &&
                
                  <Audio
                    controls
                    preload={'metadata'}
                    src={state.mediaUrl}
                  />
                }
              </>
            }

            { !state.mediaUrl && !state.showMediaError &&
              
              <Message height={'200px'} padding={'15px 0'}>

                <Loader
                  size={30}
                />

              </Message>
            }

            <Footer>{formatDateTimeForDisplay(props.message.state.timestamp)}</Footer>

          </MediaContainer>
        }

        { !hasMedia &&
        
          <BubbleContainer
            fromSelf={props.fromSelf}>

            <Header>{author}</Header>

            <Message>{props.message.body}</Message>

            <Footer>{formatDateTimeForDisplay(props.message.state.timestamp)}</Footer>

          </BubbleContainer>
        }

    </Container>
  );
};

export const ChatBubble = withRouter(ChatBubbleComponent);