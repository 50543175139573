import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  FontSizes,
  ErrorToast,
  Logger,
  Image,
  Images,
  Space,
  getFileExtension,
  imageFileToCompressedBlob,
} from 'common';

import {
  Loader,
  ProjectChatHeader,
} from 'common/components';

import {
  MatchesProfileDetails,
} from 'ui/matches/components';

import {
  ChatBubble,
} from './ChatBubble';

import {
  ChatImagePreview,
} from './ChatImagePreview';

import {
  ChatRecorder,
} from './ChatRecorder';

const Container = styled.div`
  background-color: ${Colors.White};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -50px;
  z-index: 1;

  @media ${DeviceMediaQueries.Tablet} {
    left: 300px;
    max-height: 100%;
    bottom: 0;
  }
`;

const ChatBubbleContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  padding: 5px 5px 40px 5px;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 35px;
  }
`;

const NoMessagesContainer = styled.div`
  background-color: ${Colors.Gray1};
  font-size: ${FontSizes.Small};
  position: relative;
  width: 100%;
  padding: 15px;
  text-align: center;
`;

const SendBarContainer = styled.div`
  background-color: ${Colors.Gray1};
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: relative;
`;

const LoadingContainer = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    left: 300px;
  }
`;

const ExpandableTextareaContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ExpandableTextarea = styled.div`
  display: inline-block;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Orange1};
  border-radius: 20px;
  font-size: ${FontSizes.Small};
  min-height: 40px;
  padding: 9px 20px;
  resize: both;
  outline: 0px solid transparent;
  max-height: 300px;
  width: 100%;
  min-width: 100%;
  overflow-y: auto;
  position: relative;

  &:empty::before {
    content: "${props => props.placeholder || 'Type your message...'}";
    color: ${Colors.Gray5};
    font-size: ${FontSizes.Small};
    font-style: italic;
  }

  &:empty:focus::before {
    content: "";
  }

  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`;

const MoreActionsIcon = styled.div`
  position: relative;
`;

const MoreActionsContainer = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
`;

const ActionImage = styled.div`
  background-color: ${props => props.backgroundColor || Colors.White};
  background-image: url(${props => props.image});
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${Colors.Orange1};
  border-radius: 23px;
  width: 46px;
  height: 46px;
  min-width: 46px;
`;

const CharCounterContainer = styled.div`
  background-color: ${Colors.Orange1};
  color: ${Colors.White};
  font-size: ${FontSizes.Smallest};
  border-radius: 0 18px;
  padding: 2px 10px;
  position: absolute;
  top: 0px;
  right: 0px;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Smallest};
    padding: 2px 15px;
  }
`;

const LoaderContainer = styled.div`
  height: 46px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileContainer = styled.div`
  background-color: ${Colors.White};
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  overflow-y: auto;
`;

const BackContainer = styled.div`
  background-color: ${Colors.White};
  position: absolute;
  top: -50px;
  left: 23px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

export const ChatAreaUI = props => {

  const [state, setState] = React.useState({
    isLoading: true,
    message: '',
    content: null,
    previewImage: '',
    currentFile: null,
    currentFileType: null,
    showMoreActions: false,
    showRecordingBar: false,
    sendingShowLoader: false,
    selectedContactProfile: null,
    showBubblePreview: false,
    bubblePreviewComponent: null,
  });

  const uploadInput = React.createRef();
  const maxMessageLength = 800;

  const createChatBubbles = React.useCallback((messages) => {

    if (!Array.isArray(messages) || !messages.length) {
      return [];
    }

    let bubbles = [];

    for (const message of messages) {

      bubbles.push(
        <ChatBubble
          key={message.sid}
          message={message}
          fromSelf={props.identity === message.author}
          onBubblePreview={onShowBubblePreview}
          onCloseBubblePreview={onCloseBubblePreview}
        />
      );
    }

    return bubbles;

  }, [props.identity]);

  const onConversationChange = React.useCallback((showLoading = true) => {

    if (!props.conversation) {
      return;
    }

    if (showLoading) {

      setState(prevState => ({
        ...prevState,
        isLoading: true,
      }));
    }

    props.conversation.getMessages(999)
      .then(messages => {

        if (!Array.isArray(messages.items) || !messages.items.length) {

          setState(prevState => ({
            ...prevState,
            isLoading: false,
            content: <NoMessagesContainer>No messages, please add your first message</NoMessagesContainer>
          }));

          return;
        }

        const bubbles = createChatBubbles(messages.items);

        props.conversation.advanceLastReadMessageIndex(messages.items[messages.items.length - 1].index);

        setState(prevState => ({
          ...prevState,
          isLoading: false,
          content: bubbles
        }));
      });

  }, [props.conversation, createChatBubbles]);

  const onShowBubblePreview = (component) => {

    setState(prevState => ({
      ...prevState,
      showBubblePreview: true,
      bubblePreviewComponent: component,
    }));
  };

  const onCloseBubblePreview = () => {

    setState(prevState => ({
      ...prevState,
      showBubblePreview: false,
      bubblePreviewComponent: null,
    }));
  };

  const onMessageChange = (e) => {

    const m = e.target.innerText;

    if (m.length > maxMessageLength) {

      document.getElementById('chat-textbox').innerText = state.message;
      return;
    }

    setState(prevState => ({
      ...prevState,
      message: m
    }));
  };

  const onKeyPress = (e) => {

    e = e || window.event || {};

    if ((e.which === 13 || e.keyCode === 13 || e.charCode === 13) && !e.shiftKey) {

      onSendMessageClick();
      typeof e.preventDefault === 'function' && e.preventDefault();
    }
  };

  const onBackClick = () => {
    props.history.goBack();
  };

  const onProfileClick = () => {

    if (state.selectedContactProfile || !props.conversation.attributes || !Array.isArray(props.conversation.attributes.projectInfo)) {
      return;
    }

    const user = DataStore.get('LOGGED_IN_USER') || {};

    const project = props.conversation.attributes.projectInfo.find(p => p.userId !== user.id);

    setState(prevState => ({
      ...prevState,
      selectedContactProfile: {
        projectId: project.projectId,
      }
    }));

    props.common.navigate('#conversation_selected_view_profile');
  };

  const onSendMessageClick = () => {

    const m = state.message.trim();

    if (!m) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      sendingShowLoader: true,
    }));

    props.conversation.sendMessage(m)
      .then(index => {

        setState(prevState => ({
          ...prevState,
          sendingShowLoader: false,
        }));

        props.conversation.advanceLastReadMessageIndex(index);
      })
      .catch(e => {

        ErrorToast.show('', 'We were unable to send your message');

        Logger.error(e);

        setState(prevState => ({
          ...prevState,
          sendingShowLoader: false,
        }));
      });

    document.getElementById('chat-textbox').innerHTML = '';

    setState(prevState => ({
      ...prevState,
      message: ''
    }));
  };

  const onShowMoreActionsClick = () => {

    setState(prevState => ({
      ...prevState,
      showMoreActions: true,
    }));
  };

  const onHideMoreActionsClick = () => {

    setState(prevState => ({
      ...prevState,
      showMoreActions: false,
    }));
  };

  const onClosePreview = () => {

    setState(prevState => ({
      ...prevState,
      previewImage: '',
    }));
  };

  const onCreateVoiceNote = () => {

    setState(prevState => ({
      ...prevState,
      showRecordingBar: true,
    }));
  };

  const onAttachMediaClick = () => {

    uploadInput
      && uploadInput.current
      && typeof uploadInput.current.click === 'function'
      && uploadInput.current.click();
  };

  const onFileChange = (e) => {

    if (!e || !e.target || !e.target.files) {

      ErrorToast.show();
      return;
    }

    const f = e.target.files[0];

    const ext = getFileExtension(f.name);
    const allowdExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];

    if (allowdExtensions.indexOf(ext) === -1) {

      setState(prevState => ({
        ...prevState,
        showMoreActions: false,
      }));

      ErrorToast.show('File type is invalid', `Supported file types include jpg, png, pdf, doc and docx.`);
      return;
    }

    if (f.size > 10 * 1024 * 1024) {

      ErrorToast.show('File too big', 'Please make sure that your file is not bigger than 10MB.');
      return;
    }

    const reader = new FileReader();

    reader.addEventListener('loadend', () => {

      props.history.push('#conversation_selected?preview=true');

      setState(prevState => ({
        ...prevState,
        showMoreActions: false,
        previewImage: reader.result,
        currentFile: f,
        currentFileType: f.type,
      }));
    });

    reader.readAsDataURL(f);
  };

  const onSendVoiceRecording = (data) => {

    sendFile(data);
  };

  const sendFile = (customFile) => {

    try {

      const file = state.currentFile;

      if (!file && !customFile) {

        ErrorToast.show('', 'We were unable to send your file, please try again.');
        return;
      }

      setState(prevState => ({
        ...prevState,
        sendingShowLoader: true,
      }));

      const ext = getFileExtension((customFile || file).name);
      const imageExtensions = ['jpg', 'jpeg', 'png'];
      
      if (imageExtensions.indexOf(ext) !== -1) {

        imageFileToCompressedBlob(file, (blob) => {
        
          const request = customFile || {
            contentType: blob.type,
            media: blob,
          };
    
          postFile(request);
        });

        return;
      }
      
      const request = customFile || {
        contentType: file.type,
        media: file,
      };

      postFile(request);
    }
    catch (ex) {
      
      ErrorToast.show('Unable to send media', 'We were unable to send some media files, please try again');

      Logger.error(ex);

      setState(prevState => ({
        ...prevState,
        sendingShowLoader: false,
      }));
    }
  };

  const postFile = async (request, fileName) => {

    if (!request) {
      return;
    }

    let index = await props.conversation.sendMessage(request, {
      fileName: fileName || '',
    });

    props.conversation.advanceLastReadMessageIndex(index);

    setState(prevState => ({
      ...prevState,
      previewImage: '',
      sendingShowLoader: false,
    }));
  };

  React.useEffect(() => {

      onConversationChange();

      setState(prevState => ({
        ...prevState,
        showRecordingBar: false,
        showMoreActions: false,
        selectedContactProfile: null,
      }));
    },
    [props.conversation, onConversationChange],
  );

  // useEffect to handle new message added
  React.useEffect(
    () => onConversationChange(false),
    [props.lastMessage, onConversationChange],
  );

  React.useEffect(() => {

    if (props.common.history.location.hash === '#conversation_selected' && state.selectedContactProfile) {

      setState(prevState => ({
        ...prevState,
        selectedContactProfile: null,
      }))
    }

  }, [props.common.history.location, state.selectedContactProfile])

  if (state.isLoading) {

    return (

      <LoadingContainer>

        <Loader/>

      </LoadingContainer>
    );
  }

  const otherUser = props.conversation.users.find(u => u.identity !== props.identity) || {};

  const profileImage = otherUser.attributes && otherUser.attributes.profilePictureUrl;

  return (

    <Container>

      <ProjectChatHeader
        addShadow={true}
        projectOwner={otherUser.friendlyName}
        projectName={props.conversation.friendlyName}
        profileImage={profileImage}
        showOnlineIndicator={true}
        isOnline={otherUser.isOnline}
        onClick={onProfileClick}
      />

      <ChatBubbleContainer
        id={'chat-bubble-container'}>

        {state.content}

      </ChatBubbleContainer>

      <SendBarContainer>

        { state.showRecordingBar

          ? <ChatRecorder
              showLoader={state.sendingShowLoader}
              onCloseClick={() => setState(prevState => ({
                ...prevState,
                showRecordingBar: false,
                showMoreActions: false,
              }))}
              onSendMessageClick={onSendVoiceRecording}
            />
          
          : <>
            <MoreActionsIcon>

              { state.showMoreActions &&

                <MoreActionsContainer>

                  <ActionImage
                    backgroundColor={Colors.Orange1}
                    image={Images.Icons.ChatMedia}
                    onClick={onAttachMediaClick}
                  />

                  <Space/>

                  <ActionImage
                    backgroundColor={Colors.Orange1}
                    image={Images.Icons.ChatMic}
                    onClick={onCreateVoiceNote}
                  />

                  <Space/>

                  <ActionImage
                    backgroundColor={Colors.Orange1}
                    image={Images.Icons.ChatCancel}
                    onClick={onHideMoreActionsClick}
                  />

                  <input
                    style={{ display: 'none' }}
                    ref={uploadInput}
                    type={'file'}
                    accept={'image/png,image/jpeg,application/pdf,.doc,.docx'}
                    onChange={onFileChange}
                  />

                </MoreActionsContainer>
              }

              <ActionImage
                image={Images.Icons.ChatAddAction}
                onClick={onShowMoreActionsClick}
              />

            </MoreActionsIcon>

            <Space/>

            <ExpandableTextareaContainer>

              <ExpandableTextarea
                id={'chat-textbox'}
                role={'textbox'}
                contentEditable={true}
                onInput={onMessageChange}
                onKeyPress={onKeyPress}
              />

              <CharCounterContainer>
                {`${state.message.length}/${maxMessageLength}`}
              </CharCounterContainer>

            </ExpandableTextareaContainer>

            <Space/>

            { state.sendingShowLoader &&
      
              <LoaderContainer>

                <Loader
                  size={30}
                />

              </LoaderContainer>
            }

            { !state.sendingShowLoader &&

              <ActionImage
                image={Images.Icons.ChatSend}
                onClick={onSendMessageClick}
              />
            }
            </>
        }
        
        { !state.showMoreActions &&

          <BackContainer>

            <Image
              src={Images.Icons.Back}
              width={'35px'}
              height={'35px'}
              onClick={onBackClick}
            />

          </BackContainer>
        }

      </SendBarContainer>

      { !!state.selectedContactProfile &&

        <ProfileContainer>

          <MatchesProfileDetails
            common={props.common}
            color={Colors.Orange2}
            headerColor={Colors.Orange2}
            colorSvg={Colors.Orange1}
            selectedContactProfile={state.selectedContactProfile}
            fromContacts={true}
          />

        </ProfileContainer>
      }

      { !!state.previewImage && 
      
        <ChatImagePreview
          imageUrl={state.previewImage}
          type={state.currentFileType}
          file={state.currentFile}
          onClose={onClosePreview}
          onSend={sendFile}
        />
      }

      {/* Might seem odd but this is used to get past a Safari styling issue */}
      { !!state.showBubblePreview && state.bubblePreviewComponent}

    </Container>
  );
};

export const ChatArea = withRouter(ChatAreaUI);