import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
} from 'common';

import {
  Loader,
} from './Loader';

import {
  InformationBubble,
} from './InformationBubble';

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.fontSize || FontSizes.Small};
  height: ${props => props.height || '45px'};
  line-height: ${props => props.height || '45px'};
  border-radius: ${props => props.borderRadius || '22.5px'};
  border: ${props => props.border || `1px solid ${Colors.Black}`};
  width: ${props => props.width || '300px'};
  user-select: none;
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;

  ${props => props.margin && `margin: ${props.margin};`}

  background-color: ${props => !props.disabled
    ? props.backgroundColor || Colors.White
    : Colors.Gray5};

    color: ${props => !props.disabled
      ? props.activeColor || Colors.Gray9
      : props.disabledColor || Colors.Grey7};

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  :hover {
    background-color: ${props => !props.disabled
      ? props.hoverBackgroundColor || Colors.Tan
      : Colors.Gray5};

     ${props => props.hoverBorder && `border: ${props.hoverBorder}`};
  }
`;

const InfoButtonContainer = styled.div`
  position: absolute;
  right: 10px;
`;

export const Button = props => {

  const onClick = (e) => {

    if (props.disabled || props.showLoading) {
      return;
    }

    typeof props.onClick === 'function' && props.onClick(props.onClickArgs || e);
  };

  return (

    <Container
      id={props.id}
      activeColor={props.activeColor}
      disabledColor={props.disabledColor}
      backgroundColor={props.backgroundColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      flexDirection={props.flexDirection}
      height={props.height}
      width={props.width}
      fontSize={props.fontSize}
      borderRadius={props.borderRadius}
      border={props.border}
      hoverBorder={props.hoverBorder}
      margin={props.margin}
      disabled={props.disabled || props.showLoading}
      onClick={onClick}>

        { props.showLoading

          ? <Loader
              backgroundColor={Colors.Gray5}
              spinnerColor={Colors.White}
              size={35}
            />

          : props.text || props.children || ''
        }

        <InfoButtonContainer>

          <InformationBubble
            id={props.id}
          />

        </InfoButtonContainer>

    </Container>
  );
};