import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  SafeAreas,
} from './constants';

import {
  DeviceMediaQueries,
} from './device';

export const CenterLoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  background-color: ${props => props.backgroundColor || Colors.Gray1};

  @media ${DeviceMediaQueries.Tablet} {

    top: 60px;
    left: 60px;

    ${props => props.showSideMenu && `
      left: 360px;
    `}
  }
`;

export const TextInputContainer = styled.div`
  width: 100%;

  ${props => props.padding  && `padding: ${props.padding};`}
  ${props => props.showBorder  && `border-bottom: 1px solid ${Colors.Gray2};`}

  @media ${DeviceMediaQueries.Laptop} {
    display: flex;
    justify-content: space-between;
    padding: 0 0 15px 0;

    :last-child {
      padding: 0;
    }
  }
`;

export const DesktopSpace = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    width: 15px;
  }
`;

export const PopupFooter = styled.div`
  background-color: ${props => props.footerBackgroundColor || Colors.Gray5};
  display: flex;
  align-items: center;
  height: 70px;
  min-height: 60px;
`;

export const PopupActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;

  @media ${DeviceMediaQueries.Tablet} {
    justify-content: flex-end;
    padding: 0 25px;
  }
`;

export const PopupActionButtonContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 180px;
  }
`;

export const Space = styled.div`
  height: ${props => props.size || props.height || '10px'};
  width: ${props => props.size || props.width || '10px'};
`;

export const Image = styled.img`
  height: ${props => props.height || '100px'};
  width: ${props => props.width || 'auto'};
  ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
  ${props => props.padding && `padding: ${props.padding};`}
  ${props => props.position && `position: ${props.position};`}
  ${props => props.left && `left: ${props.left};`}
  ${props => props.right && `right: ${props.right};`}
  ${props => props.top && `top: ${props.top};`}
  ${props => props.bottom && `bottom: ${props.bottom};`}
  ${props => props.transform && `transform: ${props.transform};`}
  ${props => props.onClick && `cursor: pointer;`}

  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
`;

export const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 65px;
  display: flex;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${SafeAreas.bottom};

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

export const RoundButton = styled.div`
  cursor: pointer;
  background-color: ${Colors.Gray1};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px;
  width: 66px;
  overflow: hidden;
  border-radius: 33px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const InformationBubbleContainer = styled.div`
  display: none;
  background-color: ${Colors.White};
  color: ${Colors.Black};
  font-size: ${FontSizes.Smaller};
  font-weight: normal;
  position: fixed;
  border: 1px solid ${Colors.Gray6};
  border-radius: 10px;
  padding: 10px;
  max-width: ${props => props.maxWidth || '550px'};
  z-index: 1001;
  white-space: pre-wrap;

  ${props => 
    `left: ${props.left || 0}px;
    top: ${props.top || 0}px;`}
`;

export const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;