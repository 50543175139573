import {
  Client as ConversationsClient
} from '@twilio/conversations';

import {
  Logger,
  isValidProviderResult,
} from 'common';

import {
  ChatProvider,
  ContactsProvider,
} from 'providers';

export class Chat {

  static client = null;
  static conversations = [];
  static conversationsWithMessagesCount = 0;

  static async init(loadConversations = true) {

    try {

      if (Chat.client) {
        return;
      }

      const ret = await ChatProvider.getToken();

      if (!isValidProviderResult(ret) || !ret.data.twilioJwt) {
        return;
      }

      Chat.client = await ConversationsClient.create(ret.data.twilioJwt);

      Chat.initEvents();

      if (loadConversations) {
        await Chat.getConversations();
      }
    }
    catch (ex) {
      Logger.error(ex);
    }
  }

  static async getConversations() {

    try {

      if (!Chat.client) {
        await this.init(false);
      }

      const conversations = await Chat.client.getSubscribedConversations();

      let conversationsWithMessagesCount = 0;

      for (let conversation of conversations.items) {

        conversation.totalUnreadMessages = await conversation.getUnreadMessagesCount() || 0;

        const lastMessage = await conversation.getMessages(1,
          (conversation.lastMessage && conversation.lastMessage.index) || 0
        );

        if (lastMessage && Array.isArray(lastMessage.items) && lastMessage.items.length) {

          if (lastMessage.items[0].media) {

            const contentType = (lastMessage.items[0].media.contentType && lastMessage.items[0].media.contentType.toLowerCase()) || '';

            if (contentType.startsWith('audio')) {
              conversation.lastConversationMessage = 'Audio message'
            }

            if (contentType.startsWith('image')) {
              conversation.lastConversationMessage = 'Image'
            }

            if (contentType.startsWith('application')) {
              conversation.lastConversationMessage = 'Document'
            }
          }

          if (!lastMessage.items[0].media) {
            conversation.lastConversationMessage = lastMessage.items[0].body;
          }
        }

        if (conversation.lastReadMessageIndex === null) {

          conversation.totalUnreadMessages = (conversation.lastMessage
            && !isNaN(conversation.lastMessage.index)
            && conversation.lastMessage.index + 1) || 0;
        }

        if (conversation.totalUnreadMessages) {
          conversationsWithMessagesCount++;
        }

        const participants = await conversation.getParticipants();

        for (const p of participants) {

          const user = await p.getUser();

          conversation.users = conversation.users || [];

          let existingUser = conversation.users.find(u => u.identity === user.identity);

          if (!existingUser) {
            conversation.users.push(user);
          }
        }
      }

      Chat.conversationsWithMessagesCount = conversationsWithMessagesCount;
      CustomEventHandles.handleCustomEvents('messageCountUpdated', conversationsWithMessagesCount);

      conversations.items.sort((a, b) => {

        const aDate = (a.lastMessage && a.lastMessage.dateCreated) || a.dateCreated;
        const bDate = (b.lastMessage && b.lastMessage.dateCreated) || b.dateCreated;

        return bDate - aDate;
      });

      return conversations.items;
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async subscribe(evnt, fn) {

    try {
      CustomEventHandles.subscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async unsubscribe(evnt, fn) {

    try {
      CustomEventHandles.unsubscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async shutdown() {

    try {

      if (!Chat.client) {
        return;
      }

      await Chat.client.shutdown();

      Chat.client = null;
    }
    catch (ex) {
      Logger.error(ex);
    }
  }

  static initEvents() {

    Chat.client.on('conversationAdded', async (conversation) => {

      const conversations = await Chat.getConversations();

      CustomEventHandles.handleCustomEvents('conversationAdded', conversations);
    });

    Chat.client.on('conversationUpdated', async (conversation) => {

      const conversations = await Chat.getConversations();

      CustomEventHandles.handleCustomEvents('conversationUpdated', conversations);
    });

    Chat.client.on('conversationRemoved', async (conversation) => {

      const conversations = await Chat.getConversations();

      CustomEventHandles.handleCustomEvents('conversationRemoved', conversations);
    });

    Chat.client.on('connectionError', (error) => {
      CustomEventHandles.handleCustomEvents('connectionError', error);
    });

    Chat.client.on('connectionStateChanged', (state) => {

      if (state === 'connected') {
        CustomEventHandles.handleCustomEvents('connected');
      }
    });

    Chat.client.on('messageAdded', (m) => {
      CustomEventHandles.handleCustomEvents('messageAdded', m);
    });

    Chat.client.on('userUpdated', (user) => {

      // for now only monitor user online status change, you can add more reasons to check
      if (user && Array.isArray(user.updateReasons) && user.updateReasons.indexOf('reachabilityOnline') === -1) {
        return;
      }

      CustomEventHandles.handleCustomEvents('userUpdated', user);
    });

    Chat.client.on('tokenAboutToExpire', () => {
      Chat.updateToken();
    });
  }

  static updateToken() {

    ChatProvider.getToken()
      .then(ret => {

        if (!isValidProviderResult(ret) || !ret.data.twilioJwt) {
          return;
        }
  
        Chat.client.updateToken(ret.data.twilioJwt);
      })
      .catch(e => Logger.error(e));
  }
}

export class Invites {

  static interval = null;
  static inviteCount = 0;
  static isInitialized = false;

  static init() {

    if (Invites.isInitialized) {
      return;
    }

    Invites.getInviteCount();

    Invites.interval = setInterval(
      Invites.getInviteCount,
      30000
    );

    Invites.isInitialized = true;
  }

  static refresh() {
    Invites.getInviteCount();
  }

  static getInviteCount() {

    ContactsProvider.getInvitationCount()
      .then(ret => {

        if (!isValidProviderResult(ret)) {
          return;
        }

        if (ret.data.invitationCount !== Invites.inviteCount) {

          Invites.inviteCount = ret.data.invitationCount;

          CustomEventHandles.handleCustomEvents('inviteCountUpdated', ret.data.invitationCount);
        }
      })
      .catch(e => Logger.error(e));
  }

  static async subscribe(evnt, fn) {

    try {
      CustomEventHandles.subscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async unsubscribe(evnt, fn) {

    try {
      CustomEventHandles.unsubscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async shutdown() {

    clearInterval(Invites.interval);

    Invites.isInitialized = false;

    Invites.interval = null;
  }
}

export class ShareRequest {

  static interval = null;
  static requestCount = 0;
  static isInitialized = false;

  static init() {

    if (ShareRequest.isInitialized) {
      return;
    }

    ShareRequest.getRequestCount();

    ShareRequest.interval = setInterval(
      ShareRequest.getRequestCount,
      30000
    );

    ShareRequest.isInitialized = true;
  }

  static refresh() {
    ShareRequest.getRequestCount();
  }

  static getRequestCount() {

    ContactsProvider.getShareRequestCount()
      .then(ret => {

        if (!isValidProviderResult(ret)) {
          return;
        }

        if (ret.data.requestCount !== ShareRequest.requestCount) {

          ShareRequest.requestCount = ret.data.requestCount;

          CustomEventHandles.handleCustomEvents('shareRequestCountUpdated', ret.data.requestCount);
        }
      })
      .catch(e => Logger.error(e));
  }

  static async subscribe(evnt, fn) {

    try {
      CustomEventHandles.subscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async unsubscribe(evnt, fn) {

    try {
      CustomEventHandles.unsubscribe(evnt, fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async shutdown() {

    clearInterval(ShareRequest.interval);

    ShareRequest.isInitialized = false;

    ShareRequest.interval = null;
  }
}

class CustomEventHandles {

  static subscriptions = {};

  static handleCustomEvents(evnt, args) {

    if (!CustomEventHandles.subscriptions || !Array.isArray(CustomEventHandles.subscriptions[evnt])) {
      return;
    }

    for (const callback of CustomEventHandles.subscriptions[evnt]) {
      callback(args);
    }
  }

  static async subscribe(evnt, fn) {

    try {

      CustomEventHandles.subscriptions = CustomEventHandles.subscriptions || {};

      if (!CustomEventHandles.subscriptions[evnt]) {
        CustomEventHandles.subscriptions[evnt] = [];
      }

      CustomEventHandles.subscriptions[evnt].push(fn);
    }
    catch (e) {
      Logger.error(e);
    }
  }

  static async unsubscribe(evnt, fn) {

    try {

      if (!CustomEventHandles.subscriptions || !CustomEventHandles.subscriptions[evnt]) {
        return;
      }

      const subs = CustomEventHandles.subscriptions[evnt].filter(s => s !== fn);

      CustomEventHandles.subscriptions[evnt] = subs
    }
    catch (e) {
      Logger.error(e);
    }
  }
}