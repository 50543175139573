import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
} from 'common';

import {
  H1,
} from 'common/components';

export const Container = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
`;

export const InputContainer = styled.div`
  width: 100%;

  @media ${DeviceMediaQueries.MobileL} {
    width: 300px;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    width: 350px;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ToggleText = styled.div`
  color: ${Colors.Black};
  ${props => props.selected && `border-bottom: 2px solid ${Colors.PrimaryTwo};`}
  cursor: pointer;
  font-weight: bold;
  margin: 0 0 15px 0;
  padding: 10px 20px;
`;

export const Header = styled(H1)`
  font-size: ${FontSizes.Biggest};
  margin: 0 0 16px 0;

  @media ${DeviceMediaQueries.MobileL} {
    font-size: ${FontSizes.Huge};
    margin: 0 0 20px 0;
  }
`;