import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  ChatTabOptions,
  Chat,
  Invites,
  ShareRequest,
} from 'common';

const Container = styled.div`
  background-color: ${Colors.Orange1};
  padding: 5px 20px 0 20px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
`;

const OptionsContainer = styled.div`
  cursor: pointer;
  height: 45px;
  width: 100%;
  position: relative;
  display: flex;
`;

const Option = styled.div`
  border: 1px solid ${Colors.White};
  color: ${Colors.White};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${props => props.selected && `
    background-color: ${Colors.White};
    color: ${Colors.Orange1};
  `}

  :first-child {
    border-right: 0;
    border-radius: 15px 0 0 15px;
  }

  :last-child {
    border-left: 0;
    border-radius: 0 15px 15px 0;
  }
`;

const NotificationCount = styled.div`
  color: ${props => props.selected ? Colors.Orange1 : Colors.White};
  background-color: ${props => props.selected ? Colors.White : Colors.Orange1};
  border: 1px solid ${props => props.selected ? Colors.Orange1 : Colors.White};
  font-size: ${FontSizes.Smallest};
  border-radius: 10px;
  height: 16px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  position: absolute;
  top: -8px;
`;

class ChatOptionsComponent extends React.Component {

  state = {
    chatCount: Chat.conversationsWithMessagesCount || 0,
    invitesCount: Invites.inviteCount || 0,
    requestCount: ShareRequest.requestCount || 0,
  };

  onMessageCountUpdate = (count) => {

    if (this.state.chatCount === count) {
      return;
    }

    this.setState({
      chatCount: count,
    });
  };

  onInvitesCountUpdate = (count) => {

    if (this.state.invitesCount === count) {
      return;
    }

    this.setState({
      invitesCount: count,
    });
  };

  onShareRequestCountUpdate = (count) => {

    if (this.state.requestCount === count) {
      return;
    }

    this.setState({
      requestCount: count,
    });
  };

  componentDidMount() {

    Chat.subscribe('messageCountUpdated', this.onMessageCountUpdate);
    Invites.subscribe('inviteCountUpdated', this.onInvitesCountUpdate);
    ShareRequest.subscribe('shareRequestCountUpdated', this.onShareRequestCountUpdate);
  }

  componentWillUnmount() {

    Chat.unsubscribe('messageCountUpdated', this.onMessageCountUpdate);
    Invites.unsubscribe('inviteCountUpdated', this.onInvitesCountUpdate);
    ShareRequest.unsubscribe('shareRequestCountUpdated', this.onShareRequestCountUpdate);
  }

  render() {

    return (

      <Container>

        <OptionsContainer>

          <Option
            selected={this.props.selectedTab === ChatTabOptions.Chat}
            onClick={() => this.props.history.push('/contacts/chat')}>

            <NotificationCount selected={this.props.selectedTab === ChatTabOptions.Chat}>{this.state.chatCount}</NotificationCount>
            
            Chat
          </Option>

          <Option
            selected={this.props.selectedTab === ChatTabOptions.Shares}
            onClick={() => this.props.history.push('/contacts/shares')}>

            <NotificationCount selected={this.props.selectedTab === ChatTabOptions.Shares}>{this.state.requestCount}</NotificationCount>
            
            Shares
          </Option>

          <Option
            selected={this.props.selectedTab === ChatTabOptions.Invites}
            onClick={() => this.props.history.push('/contacts/invites')}>

            <NotificationCount selected={this.props.selectedTab === ChatTabOptions.Invites}>{this.state.invitesCount}</NotificationCount>
            
            Invites
          </Option>

        </OptionsContainer>

      </Container>
    );
  }
}

export const ChatOptions = withRouter(ChatOptionsComponent);