import {
  PaymentPackageSelect,
  PaymentLoginRegister,
  PaymentResult,
  PaymentGateway,
  PaymentProcess,
} from 'ui/payment';

export const PaymentRoutes = {
  paymentPackageSelect: {
    component: PaymentPackageSelect,
    path: '/payment/package/select',
  },
  paymentLoginRegister: {
    component: PaymentLoginRegister,
    path: '/payment/login/register',
  },
  paymentResult: {
    component: PaymentResult,
    path: '/payment/result',
  },
  paymentGateway: {
    component: PaymentGateway,
    path: '/payment/gateway',
  },
  paymentProcess: {
    component: PaymentProcess,
    path: '/payment/process',
  },
}