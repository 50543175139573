import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Images,
  Image,
} from 'common';

const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.orientation === 'horizontal' ? 'row-reverse' : 'column'};
  justify-content: space-between;
`;

export const Rating = ({
  max = 5,
  rating = 0,
  light = false,
  orientation = 'vertical',
}) => {

  let images = [];
  let ratingOnIcon = light ? Images.Icons.RatingOnWhite : Images.Icons.RatingOn;

  for (let i = max; i > 0; i--) {

    images.push(
      <Image
        key={i}
        src={i <= rating ? ratingOnIcon : Images.Icons.Rating}
        height={'20px'}
        width={'20px'}
      />
    );
  }
  
  return (

    <Container
      orientation={orientation}>

      {images}

    </Container>
  );
};