import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
  patch,
  post,
} from 'providers/axios';

export class IdentityProvider {

  static async login(
    email,
    password,
  ) {
    
    try
    {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!password) {

        return {
          ok: false,
          message: 'Please provide a valid password',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}authentication`, {
          email: email.trim(),
          password,
          strategy: 'local',
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      let ret = resolveRejected(ex);

      if (ex && ex.response && ex.response.status === 401) {
        ret.message = 'Invalid username or password';
      }

      return ret;
    }
  }

  static async register(
    email,
    password,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}users`, {
          email: email.trim(),
          password: password,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async verifyEmail(
    email,
    token,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!token) {

        return {
          ok: false,
          message: 'The token provided is not valid',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}email-verification?email=${email}&token=${token}`, {
          email,
          token,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async requestPasswordChange(
    email,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      let res = await get(
        `${Environment.apiHost}reset-password?email=${email.trim()}`
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async resetPassword(
    email,
    password,
    token,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!password) {

        return {
          ok: false,
          message: 'Please provide a valid password',
          data: null,
        };
      }

      if (!token) {

        return {
          ok: false,
          message: 'The token provided is not valid',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}reset-password`, {
          email,
          password,
          token,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async requestEmailChange(
    email,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      let res = await get(
        `${Environment.apiHost}change-email?userEmail=${email.trim()}`
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async changeEmail(
    userEmail,
    oldEmail,
    token,
  ) {

    try {

      if (!userEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!oldEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!token) {

        return {
          ok: false,
          message: 'The token provided is not valid',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}change-email`, {
          userEmail: userEmail.trim(),
          oldEmail: oldEmail.trim(),
          token,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async changeEmailVerify(
    newEmail,
    oldEmail,
    token,
  ) {

    try {

      if (!newEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!oldEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!token) {

        return {
          ok: false,
          message: 'The token provided is not valid',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}change-email-confirm`, {
          newEmail: newEmail.trim(),
          oldEmail: oldEmail.trim(),
          token,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async requestDeactivateAccount(
    email,
  ) {

    try {

      if (!email) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      let res = await get(
        `${Environment.apiHost}deactivate-delete-user?userEmail=${email}`
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async requestDeleteAccount(
    userEmail,
  ) {

    try {

      if (!userEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      let res = await patch(
        `${Environment.apiHost}deactivate-delete-user`, 
        {
          userEmail
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async removeAccount(
    userEmail,
    type,
    token,
  ) {

    try {

      if (!userEmail) {

        return {
          ok: false,
          message: 'Please provide a valid email',
          data: null,
        };
      }

      if (!type) {

        return {
          ok: false,
          message: 'Please provide a valid type',
          data: null,
        };
      }
      
      if (!token) {

        return {
          ok: false,
          message: 'Please provide a valid token',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}deactivate-delete-user`, 
        {
          userEmail,
          type,
          token,
        }
      );

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}
