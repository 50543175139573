import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Images,
  Image,
  Space,
} from 'common';

import {
  Rating,
  ProfileImage,
} from 'common/components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px 0;
`;

const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MatchProfilePicture = props => {

  let socialButtons = [];
  const linkedAccounts =  props.linkedAccounts || [];

  for (let i = 0; i < linkedAccounts.length; i++) {

    let icon;

    if (linkedAccounts[i].linkedAccountsLookupId === 1) {
      icon = Images.Icons.TwitterDark;
    }

    if (linkedAccounts[i].linkedAccountsLookupId === 2) {
      icon = Images.Icons.FacebookDark;
    }

    if (linkedAccounts[i].linkedAccountsLookupId === 3) {
      icon = Images.Icons.InstagramDark;
    }
    
    if (linkedAccounts[i].linkedAccountsLookupId === 4) {
      icon = Images.Icons.LinkedInDark;
    }
    
    if (linkedAccounts[i].linkedAccountsLookupId === 5) {
      icon = Images.Icons.WebsiteDark;
    }

    if (icon) {
      
      socialButtons.push(
        <Image
          key={i}
          src={icon}
          height={'20px'}
          width={'20px'}
        />
      );

      if (i !== (linkedAccounts.length - 1)) {

        socialButtons.push(
          <Space
            key={`space-${i}`}
            height={'22px'}
            width={'20px'}
          />
        );
      }
    }
  }

  if (!socialButtons.length) {

    socialButtons.push(
      <Space
        key={`space`}
        height={'5px'}
        width={'20px'}
      />
    );
  }

  return (

    <Container>

      <Rating
        rating={props.rating}
        light={props.light}
      />

      <ProfileImage
        image={props.image}
        label={props.profileImageLabel}
        labelBackgroundColor={props.profileImageLabelColor}
        size={props.imageSize}
      />

      <SocialsContainer>

        {socialButtons}

      </SocialsContainer>

    </Container>
  );
};