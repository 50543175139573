import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  IsMobile,
  FontSizes,
  GoalTypes,
  ErrorToast,
  SuccessToast,
  Messages,
  Logger,
  Image,
  Images,
  SafeAreas,
  Space,
  MobileButtonContainer,
  RoundButton,
  getImageByExtension,
  isValidProviderResult,
  addColorTransparency,
} from 'common';

import {
  Accordion,
  Button,
  OverlayImageGallery,
  Loader,
  Popup,
  ProjectChatHeader,
  PSmall,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  GoalAdviceSvg,
  GoalEmployeeSvg,
  GoalFundingSvg,
} from 'common/svg';

import {
  MatchProfilePicture,
} from 'ui/matches/components';

import {
  AdviceGoalDisplay,
  EmployeeGoalDisplay,
  FundingGoalDisplay,
  ProjectInformationDisplay,
  ProjectDetailsDisplay,
  SwotAnalysisDisplay,
} from 'ui/projects/components';

import {
  MatchesProvider,
  ProjectsProvider,
} from 'providers';

const Container = styled.div`
  border: 2px solid ${props => props.color || Colors.Green3};
  border-radius: 15px;
  background-color: ${props => props.color || Colors.Green3};
  overflow: hidden;
  margin: 15px;
`;

const HeaderContainer = styled.div`
  position: relative;
  padding: 20px 20px 50px 20px;
  display: flex;
  flex-direction: column;
  max-width: 1350px;
  margin: 0 auto;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DeviceMediaQueries.LaptopM} {
    flex-direction: row;
  }
`;

const HeaderCol = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}

  @media ${DeviceMediaQueries.LaptopM} {
    padding: ${props => props.desktopPadding || '0'};
  }
`;

const PersonalInfoRow = styled.div`
  position: relative;
  display: flex;
  flex: ${props => props.flex || '1'};
`;

const PersonalInfoText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px 0 0;
`;

const ProfilePictureContainer = styled.div`
  width: 100%;
  padding: 0 0 15px 0;

  @media ${DeviceMediaQueries.LaptopM} {
    max-width: 270px;
    padding: 0;
  }
`;

const NameContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 15px 0;

  @media ${DeviceMediaQueries.LaptopM} {
    padding: 15px 0 0 0;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const ProjectInfoContainer = styled.div`
  background-color: ${Colors.White};
  border-radius: 15px 15px 0 0;
  padding: 30px 20px 20px 20px;
`;

const GoalHeading = styled.div`
  display: flex;
  align-items: center;
`;

const GoalHeadingText = styled.div`
  font-size: ${FontSizes.Small};
  font-weight: bold;
  padding: 20px 0 20px 10px;
`;

const FormContainer = styled.div`
  padding: 15px 0;
`;

const ButtonContainer = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    position: absolute;
    bottom: -20px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0 0 0;
  width: 100%;
`;

const UploadedDocTile = styled.div`
  border: 1px solid ${Colors.Gray2};
  cursor: pointer;
  position: relative;
  margin: 0 15px 15px 0;
  height: 180px;
  width: 180px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const PopupButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${props => props.padding && `padding: ${props.padding};`};
`;

const Line = styled.div`
  background-color: ${props => props.color || Colors.Green3};
  height: 2px;
  width: 75%;
  margin: 20px auto;
`;

const CVContainerOverlay = styled.div`
  background-color: ${addColorTransparency(Colors.Black, 0.6)};
  position: fixed;
  top: ${SafeAreas.top};
  bottom: ${SafeAreas.bottom};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const CVContainer = styled.div`
  position: absolute;
  height: 90%;
  width: 90%;
`;

const CVCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 15px 0;
  width: 100%;
`;

const IFrameContainer = styled.div`
  background-color: ${Colors.Black};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IFrame = styled.iframe`
  border: 1px solid ${Colors.Gray2};
  height: 100%;
  width: 100%;
  position: absolute;
`;

const IFrameLoadingContainer = styled.div`
  position: absolute;
`;

const BackContainer = styled.div`
  background-color: ${Colors.White};
  position: fixed;
  box-shadow: 0 0 5px 0 ${Colors.Gray5};
  bottom: 0;
  left: 0px;
  right: 0px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  margin-bottom: ${SafeAreas.bottom};

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

const BottomSpace = styled.div`
  height: ${SafeAreas.bottom};
  padding: 0 0 160px 0;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 0;
  }
`;

const InActiveMessage = styled.div`
  border: 1px solid ${props => props.color || Colors.Green3};
  border-radius: 10px;
  color: ${props => props.color || Colors.Green3};
  font-size: ${FontSizes.Small};
  padding: 20px;
  text-align: center;
`;

export class MatchesProfileDetailsUI extends React.Component {

  state = {
    primaryGoal: null,
    secondaryGoals: [],
    mediaList: [],
    showImageGallery: false,
    selectedImageIndex: 0,
    showSendInvitePopup: false,
    profileScore: 0,
    isCollaboration: false,
    showCv: false,
    isInactive: false,
    inactiveMessage: '',
  };

  age = undefined;
  selectedProject = {};
  projectDetails = {};
  accountInformation = {};
  linkedAccounts = [];
  inviteNote = '';
  projectsList = [];

  onProjectChange = (val) => {
    this.selectedProject = val;
  };

  onBackClick = () => {
    this.props.history.goBack();
  };

  onShowGalleryClick = (index) => {

    this.setState({
      showImageGallery: true,
      selectedImageIndex: index,
    });
  };

  onHideGalleryClick = () => {
    
    this.setState({
      showImageGallery: false,
    });
  };

  onSendInviteClick = () => {

    this.inviteNote = '';

    this.setState({
      showSendInvitePopup: true,
    });
  };

  onAcceptInviteClick = () => {

    this.props.selectedContactProfile
    && this.props.selectedContactProfile.invitation
    && this.props.onAcceptInviteClick
    && this.props.onAcceptInviteClick(this.props.selectedContactProfile.invitation);
  };

  onSendInvitationConfirm = () => {

    if (!this.selectedProject.value) {

      ErrorToast.show('Invalid form', 'Please select the project you want to match with');
      return;
    }

    this.setState({
      showSendInvitePopup: false,
    });

    const matchDetails = DataStore.get('selectedMatch') || this.props.selectedContactProfile || {};
    const user = DataStore.get('LOGGED_IN_USER') || {};

    MatchesProvider
      .sendInvite(
        user.id,
        matchDetails.projectId,
        this.inviteNote,
        this.selectedProject.value,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show('We were unable to send your invite', ret.message);

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        SuccessToast.show('', 'Your invite was sent successfully');
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onCvView = () => {

    this.setState({
      showCv: true,
    });
  };

  onCvClose = () => {

    this.setState({
      showCv: false,
    });
  };

  getGoalComponent = goal => {

    goal = goal || {};

    if (goal.type === GoalTypes.Employee) {

      // done purely to reuse the component
      const project = {
        project_employees: [goal],
      };

      return (

        <React.Fragment
          key={`employee-${goal.id}`}>

          <GoalHeading>

            <GoalEmployeeSvg
              fill={this.props.colorSvg}
              height={'22px'}
              width={'22px'}
            />

            <GoalHeadingText>Hiring goal</GoalHeadingText>

          </GoalHeading>

          <EmployeeGoalDisplay
            project={project}
            backgroundColor={Colors.White}
            color={Colors.Black}
            padding={'0'}
            showHeader={false}
          />

        </React.Fragment>
      );
    }

    if (goal.type === GoalTypes.Funding) {

      // done purely to reuse the component
      const project = {
        project_fundings: [goal],
      };

      return (

        <React.Fragment
          key={`funding-${goal.id}`}>

          <GoalHeading>

            <GoalFundingSvg
              fill={this.props.colorSvg}
              height={'22px'}
              width={'22px'}
            />

            <GoalHeadingText>Funding goal</GoalHeadingText>

          </GoalHeading>

          <FundingGoalDisplay
            project={project}
            backgroundColor={Colors.White}
            color={Colors.Black}
            padding={'0'}
            showHeader={false}
          />

        </React.Fragment>
      );
    }

    if (goal.type === GoalTypes.Advice) {

      // done purely to reuse the component
      const project = {
        project_advices: [goal],
      };

      return (
        <React.Fragment
          key={`advice-${goal.id}`}>

          <GoalHeading>

            <GoalAdviceSvg
              fill={this.props.colorSvg}
              height={'20px'}
              width={'20px'}
            />

            <GoalHeadingText>Mentoring goal</GoalHeadingText>

          </GoalHeading>

          <AdviceGoalDisplay
            project={project}
            backgroundColor={Colors.White}
            color={Colors.Black}
            padding={'0'}
            showHeader={false}
          />

        </React.Fragment>
      );
    }

    return (
      <Text
        fontSize={FontSizes.Smaller}
        padding={'0 0 20px 0'}>

          No information available
      </Text>
    );
  };

  createMediaTiles = () => {

    const media = this.projectDetails.media || [];
    let mediaList = [];
    let m;
    let tileImage;

    for (let i = 0; i < media.length; i++) {

      m = media[i];

      if (!m || !m.url) {
        continue;
      }

      tileImage = getImageByExtension(m.url);

      mediaList.push(

        <UploadedDocTile
          key={i}
          src={tileImage}
          onClick={() => this.onShowGalleryClick(i)}
        />
      );
    }

    return mediaList;
  };

  load = async () => {

    const matchDetails = DataStore.get('selectedMatch') || this.props.selectedContactProfile || {};

    if (!matchDetails.projectId) {

      this.props.common.navigate('/matches/view');
      return;
    }

    let res = await ProjectsProvider.getProjectView(matchDetails.projectId);

    if (isValidProviderResult(res) && res.data.inActive) {

      this.setState({
        isInactive: true,
        inactiveMessage: res.data.message
      });
      return;
    }

    if (!isValidProviderResult(res) || !res.data.userInformation || !res.data.projectDetails) {

      ErrorToast.show();

      Logger.error(res.message || Messages.TechnicalError);
      return;
    }

    this.accountInformation = (
      Array.isArray(res.data.userInformation.account)
      && res.data.userInformation.account.length
      && res.data.userInformation.account[res.data.userInformation.account.length - 1]
    ) || {};

    if (this.accountInformation.dateOfBirth) {

      let ageDifMs = Date.now() - new Date(this.accountInformation.dateOfBirth);
      let ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    this.projectDetails = res.data.projectDetails;
    this.linkedAccounts = (res.data.userInformation && res.data.userInformation.linkedAccounts) || [];

    if (!Array.isArray(this.projectDetails.goals) || !this.projectDetails.goals.length) {
  
      this.setState({
        mediaList: this.createMediaTiles(),
        profileScore: this.accountInformation.profileScore || 0,
        isCollaboration: this.projectDetails.natureId === 1,
      });
      
      return;
    }

    const secondaryGoals = [];

    // first goal is always primary, all after will be secondary goals
    for (let i = 1; i < this.projectDetails.goals.length; i++) {

      secondaryGoals.push(this.getGoalComponent(this.projectDetails.goals[i]));
    }

    this.projectsList = (DataStore.get('MATCHES_CARDS_PROJECT_LIST') || []).filter(p => p.nature !== this.projectDetails.natureId);

    this.setState({
      mediaList: this.createMediaTiles(),
      profileScore: this.accountInformation.profileScore || 0,
      primaryGoal: this.getGoalComponent(this.projectDetails.goals[0]),
      secondaryGoals,
      isCollaboration: this.projectDetails.natureId === 1,
    });
  };

  componentDidMount() {

    this.props.common.showLoading({
      showContent: false,
    });

    this.load()
      .then(() => {
        this.props.common.hideLoading();
      })
      .catch(ex => {

        ErrorToast.show();

        Logger.error(ex);

        this.props.common.hideLoading();
      });
  }

  componentWillUnmount() {

    DataStore.clear('selectedMatch');
    DataStore.clear('selectedContactProfile');
  }

  render () {

    if (this.state.isInactive) {

      return (
        <InActiveMessage color={this.props.color}>{this.state.inactiveMessage || 'This project has been deactivated'}</InActiveMessage>
      );
    }

    return (

      <>

      <Container
        color={this.props.color}>

        <HeaderContainer>

          <NameContainer>

            <Text
              color={Colors.White}
              fontWeight={'bold'}
              fontSize={FontSizes.Regular}>

                {`${this.accountInformation.name} ${this.accountInformation.surname}`}
            </Text>

            <Text
              color={Colors.White}
              fontSize={FontSizes.Small}>

                {`${this.accountInformation.city}, ${this.accountInformation.country}`}
            </Text>

          </NameContainer>

          <HeaderRow>

            <HeaderCol
              justifyContent={'center'}>
          
              <ProfilePictureContainer>

                <MatchProfilePicture
                  image={this.accountInformation.profilePictureUrl}
                  linkedAccounts={this.linkedAccounts || []}
                  rating={this.state.profileScore}
                  imageSize={190}
                  profileImageLabel={this.state.isCollaboration ? 'COLLABORATOR' :'PROPOSER'}
                  profileImageLabelColor={this.state.isCollaboration ? Colors.Blue1 : Colors.Red1}
                  light
                />

              </ProfilePictureContainer>

            </HeaderCol>

            <HeaderCol
              desktopPadding={'70px 0 0 0'}>

              <PersonalInfoRow>

                <PersonalInfoText>

                  <Text
                    color={Colors.White}
                    fontWeight={'bold'}
                    fontSize={FontSizes.Small}>

                      Status
                  </Text>

                  <Text
                    color={Colors.White}
                    fontSize={'14.4px'}
                    padding={'0 0 20px 0'}>

                      {(this.accountInformation.currentWorkStatus && this.accountInformation.currentWorkStatus.display) || 'Not specified'}
                  </Text>

                </PersonalInfoText>

                <PersonalInfoText>

                  <Text
                    color={Colors.White}
                    fontWeight={'bold'}
                    fontSize={FontSizes.Small}>

                      Languages
                  </Text>

                  <Text
                    color={Colors.White}
                    fontSize={'14.4px'}>

                      {(Array.isArray(this.accountInformation.languages) && this.accountInformation.languages.join(', ')) || 'Not specified'}
                  </Text>

                </PersonalInfoText>

              </PersonalInfoRow>

              <PersonalInfoRow>

                <PersonalInfoText>

                  <Text
                    color={Colors.White}
                    fontWeight={'bold'}
                    fontSize={FontSizes.Small}>

                      Education
                  </Text>

                  <Text
                    color={Colors.White}
                    fontSize={'14.4px'}
                    padding={'0 0 20px 0'}>

                      {this.accountInformation.highestEducation || 'Not specified'}
                  </Text>

                </PersonalInfoText>

                <PersonalInfoText>

                  <Text
                    color={Colors.White}
                    fontWeight={'bold'}
                    fontSize={FontSizes.Small}>

                      Age
                  </Text>

                  <Text
                    color={Colors.White}
                    fontSize={'14.4px'}>

                      {this.age || '-'}
                  </Text>

                </PersonalInfoText>
                
              </PersonalInfoRow>

            </HeaderCol>

            <HeaderCol
              desktopPadding={'70px 0 0 0'}>

              <Text
                color={Colors.White}
                fontWeight={'bold'}
                fontSize={FontSizes.Small}>

                  Personal summary
              </Text>

              <Text
                color={Colors.White}
                fontSize={'14.4px'}>

                  {this.accountInformation.aboutMe || 'Not specified'}
              </Text>

            </HeaderCol>

          </HeaderRow>

          <ButtonContainer>

            { this.props.fromMatches &&

              <>
                <Button
                  border={`1px solid ${Colors.Black}`}
                  hoverBorder={`1px solid ${Colors.Tan}`}
                  text={'Back to matches'}
                  height={'40px'}
                  width={'200px'}
                  onClick={() => this.props.common.navigate('/matches/view')}
                />

                <Space/>

                <Button
                  border={`1px solid ${Colors.Black}`}
                  hoverBorder={`1px solid ${Colors.Tan}`}
                  text={'Send invite'}
                  height={'40px'}
                  width={'200px'}
                  onClick={this.onSendInviteClick}
                />

                <Space/>
              </>
            }

            { this.props.fromContacts && this.props.onAcceptInviteClick &&

              <>
                <Button
                  border={`1px solid ${Colors.Black}`}
                  hoverBorder={`1px solid ${Colors.Tan}`}
                  text={'Accept invite'}
                  height={'40px'}
                  width={'200px'}
                  onClick={this.onAcceptInviteClick}
                />

                <Space/>
              </>
            }

            { this.accountInformation.cvUrl &&

              <Button
                border={`1px solid ${Colors.Black}`}
                hoverBorder={`1px solid ${Colors.Tan}`}
                text={'View CV'}
                height={'40px'}
                width={'200px'}
                onClick={this.onCvView}
              />
            }

          </ButtonContainer>

        </HeaderContainer>

        <ProjectInfoContainer>
          
          <Accordion
            header={'Project information'}
            headerColor={this.props.headerColor}
            initialIsOpen={!IsMobile}>

            <FormContainer>

              <ProjectInformationDisplay
                project={this.projectDetails}
                color={Colors.Black}
              />

            </FormContainer>

          </Accordion>

          <Line
            color={this.props.color}
          />

          { this.state.primaryGoal &&
          
            <>
              <Accordion
                header={'Primary goal'}
                headerColor={this.props.headerColor}
                initialIsOpen={!IsMobile}>

                {this.state.primaryGoal}

              </Accordion>

              { (!this.state.isCollaboration || (Array.isArray(this.state.secondaryGoals) && !!this.state.secondaryGoals.length)) &&

                <Line
                  color={this.props.color}
                />
              }
            </>
          }

          { Array.isArray(this.state.secondaryGoals) && !!this.state.secondaryGoals.length &&

            <>
              <Accordion
                header={'Secondary goals'}
                headerColor={this.props.headerColor}
                initialIsOpen={!IsMobile}>

                {this.state.secondaryGoals}

              </Accordion>

              { !this.state.isCollaboration &&

                <Line
                  color={this.props.color}
                />
              }
            </>
          }

          { !this.state.isCollaboration &&
            <>
              <Accordion
                header={'Project business plan'}
                headerColor={this.props.headerColor}
                initialIsOpen={!IsMobile}>

                <FormContainer>

                  <ProjectDetailsDisplay
                    project={this.projectDetails}
                    color={Colors.Black}
                    hideNonPublicDetails={!this.projectDetails.isProjectShared}
                    hideProjectDetailsFlag={true}
                  />

                </FormContainer>

              </Accordion>

              { this.projectDetails.isProjectShared &&

                <>
                  <Line
                    color={this.props.color}
                  />
                
                  <Accordion
                    header={'SWOT analysis'}
                    headerColor={this.props.headerColor}
                    initialIsOpen={!IsMobile}>

                    <FormContainer>

                      <SwotAnalysisDisplay
                        project={this.projectDetails}
                        showHeader={false}
                        color={Colors.Black}
                      />

                    </FormContainer>

                  </Accordion>

                  <Line
                    color={this.props.color}
                  />

                  <Accordion
                    header={'Media'}
                    headerColor={this.props.headerColor}
                    initialIsOpen={!IsMobile}>

                    <FormContainer>

                      { this.projectDetails.media && this.projectDetails.media.length

                        ? <MediaContainer>{this.state.mediaList}</MediaContainer>

                        : <PSmall>No media to display</PSmall>
                      }

                    </FormContainer>
                      
                  </Accordion>

                  <OverlayImageGallery
                    show={this.state.showImageGallery}
                    images={this.projectDetails.media || []}
                    selectedIndex={this.state.selectedImageIndex}
                    onClose={this.onHideGalleryClick}
                  />
                </>
              }
            </>
          }

        </ProjectInfoContainer>

        { this.props.fromContacts &&

          <BackContainer>

            <Image
              src={Images.Icons.Back}
              width={'35px'}
              height={'35px'}
              onClick={this.onBackClick}
            />

          </BackContainer>
        }

        <MobileButtonContainer>

          { this.accountInformation.cvUrl &&

            <RoundButton
              onClick={this.onCvView}>

              <Image
                src={Images.Icons.MatchCV}
                height={'60px'}
                width={'60px'}
              />

            </RoundButton>
          }

          { this.props.fromMatches && this.accountInformation.cvUrl && <Space/>}

          { this.props.fromMatches &&

            <RoundButton
              onClick={this.onSendInviteClick}>

              <Image
                src={Images.Icons.MatchInvite}
                height={'60px'}
                width={'60px'}
              />

            </RoundButton>
          }

        </MobileButtonContainer>

        { this.state.showSendInvitePopup &&
      
          <Popup
            header={'Invite'}
            headerBackgroundColor={this.props.color || Colors.Green3}
            maxWidth={'500px'}
            onClose={() => this.setState({ showSendInvitePopup: false })}>

              <ProjectChatHeader
                profileImage={this.accountInformation.profilePictureUrl}
                projectName={this.projectDetails.title}
                projectOwner={`${this.accountInformation.name} ${this.accountInformation.surname}`}
              />

              <Text
                fontSize={FontSizes.Small}
                padding={'20px 20px 0 20px'}>

                  Select project to match with:
              </Text>

              <Select
                padding={'20px 20px 0 20px'}
                width={'100%'}
                options={this.projectsList}
                onChange={this.onProjectChange}
              />

              <Text
                fontSize={FontSizes.Small}
                padding={'20px 20px 0 20px'}>

                  (Optional) Add an invite note:
              </Text>

              <div style={{
                padding: '20px 20px 10px 20px',
                flex: 1,
              }}>

                <TextInput
                  width={'100%'}
                  label={''}
                  multiline={true}
                  placeholder={'Provide a note for the user to see with the intive...'}
                  onChange={val => { this.inviteNote = val }}
                  showCharCounter={true}
                  charCounterColor={Colors.Green3}
                  charCounterMax={5000}
                />

              </div>

              <PopupButtonContainer
                backgroundColor={this.props.color || Colors.Green3}
                padding={'15px'}>

                <Button
                  text={'Cancel'}
                  height={'40px'}
                  width={'48%'}
                  onClick={() => this.setState({ showSendInvitePopup: false })}
                />

                <Button
                  text={'Invite'}
                  height={'40px'}
                  width={'48%'}
                  onClick={this.onSendInvitationConfirm}
                />

              </PopupButtonContainer>
          </Popup>
        }

        { this.state.showCv &&
        
          <CVContainerOverlay>

            <CVContainer>

              <CVCloseContainer>

                <Image
                  src={Images.Icons.Close}
                  height={'20px'}
                  onClick={this.onCvClose}
                />

              </CVCloseContainer>

              <IFrameContainer>

                <IFrameLoadingContainer>

                  <Loader
                    backgroundColor={Colors.Black}
                    spinnerColor={Colors.White}
                  />

                </IFrameLoadingContainer>

                <IFrame
                  src={`https://drive.google.com/viewerng/viewer?url=${this.accountInformation.cvUrl}&embedded=true`}
                />

              </IFrameContainer>

            </CVContainer>

          </CVContainerOverlay>
        }

      </Container>

      <BottomSpace/>

      </>
    );
  }
}

export const MatchesProfileDetails = withRouter(MatchesProfileDetailsUI);