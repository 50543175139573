import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  post,
} from 'providers/axios';

export class MatchesProvider {

  static async sendInvite(
    userId,
    projectId,
    message,
    requestedByProjectId,
  ) {

    try {

      const res = await post(`${Environment.apiHost}project-invitations`, {
        userId,
        projectId,
        requestedByProjectId,
        message: message || '',
      });
      
      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }
}