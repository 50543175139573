import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const DashboardSvg = props => {

  return (

    <SvgContainer {...props}>

      <Svg id="Group_545" xmlns="http://www.w3.org/2000/svg" width="500" height="50" viewBox="0 0 500 500">

        <Path id="Path_272" d="M250,0C388.071,0,500,111.929,500,250S388.071,500,250,500,0,388.071,0,250,111.929,0,250,0Z" fill={Colors.Gray9}/>

        <Path id="Icon_open-headphones" d="M179.732,0c-74.139,0-134.8,55.784-134.8,123.965v41.322H22.467C10.059,165.286,0,174.536,0,185.947V268.59C0,280,10.059,289.251,22.467,289.251H67.4c12.408,0,22.467-9.25,22.467-20.661V123.965c0-45.867,39.99-82.643,89.866-82.643S269.6,78.1,269.6,123.965V268.59c0,11.411,10.059,20.661,22.467,20.661H337c12.408,0,22.467-9.25,22.467-20.661V185.947c0-11.411-10.059-20.661-22.467-20.661H314.531V123.965C314.531,55.784,253.872,0,179.732,0Z" transform="translate(70 58)" fill="#fff"/>

        <text id="_" transform="translate(181 352)" fill="#fff" fontSize="301" fontFamily="Calibri-Bold, Calibri" fontWeight="700"><tspan x="0" y="0">?</tspan></text>

        <line id="Line_58" y1="38" x2="93" transform="translate(267.5 385.5)" fill="none" stroke="#fff" strokeWidth="23"/>

        <g id="Ellipse_134" transform="translate(222 385.959)">
          <Path id="Path_273" d="M33.221,50.541A41.276,41.276,0,0,1,20.444,48.56a34.507,34.507,0,0,1-10.41-5.39,25.757,25.757,0,0,1-6.986-7.957,19.7,19.7,0,0,1,0-19.385,25.757,25.757,0,0,1,6.986-7.957,34.506,34.506,0,0,1,10.41-5.39A42.19,42.19,0,0,1,46,2.481a34.506,34.506,0,0,1,10.41,5.39,25.757,25.757,0,0,1,6.986,7.957,19.705,19.705,0,0,1,0,19.385,25.757,25.757,0,0,1-6.986,7.957A34.507,34.507,0,0,1,46,48.56,41.276,41.276,0,0,1,33.221,50.541Z" fill="#fff"/>
          <Path id="Path_274" d="M33.221,1C15.454,1,1,12,1,25.521S15.454,50.041,33.221,50.041s32.221-11,32.221-24.521S50.987,1,33.221,1m0-1C51.568,0,66.441,11.426,66.441,25.521S51.568,51.041,33.221,51.041,0,39.615,0,25.521,14.873,0,33.221,0Z" fill="#fff"/>
        </g>

        <rect id="Rectangle_1630" width="34" height="103" rx="17" transform="translate(340 295)" fill="#fff"/>

      </Svg>

    </SvgContainer>
  );
};