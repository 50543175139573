import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  MenuItems,
  Space,
  Images,
  Image,
  MobileButtonContainer,
  RoundButton,
  getImageByExtension,
} from 'common';

import {
  Accordion,
  Button,
  PSmall,
  OverlayImageGallery,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  ProjectInformationDisplay,
  ProjectDetailsDisplay,
  SwotAnalysisDisplay,
  GoalDisplay,
} from 'ui/projects/components';

import {
  withProjectsSideMenu,
} from 'ui/projects/hocs';

import  {
  ActionContainer,
  ActionButtonContainer,
  ContentContainer,
  GoalFormContainer,
  MobileSpace,
  DesktopSpace,
} from './projectsStyled';

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
  justify-content: center;
  width: 100%;
`;

const UploadedDocTile = styled.div`
  border: 1px solid ${Colors.Gray2};
  cursor: pointer;
  position: relative;
  margin: 0 15px 15px 0;
  height: 200px;
  width: 200px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const ProjectsViewUI = props => {

  const [state, setState] = React.useState({
    showImageGallery: false,
    selectedImageIndex: 0,
  });

  const onEditClick = () => {
    props.common.navigate('/projects/edit?selected=true');
  };

  const onShowGalleryClick = (index) => {

    setState(prevState => ({
      ...prevState,
      showImageGallery: true,
      selectedImageIndex: index,
    }));
  };

  const onHideGalleryClick = () => {
    
    setState(prevState => ({
      ...prevState,
      showImageGallery: false,
    }));
  };

  const project = DataStore.get('current_project');

  if (!project || !project.title) {

    return (

      <PSmall>No project selected. Please select a project that you would like to view.</PSmall>
    );
  }

  const media = project.media || [];
  let mediaList = [];
  let m;
  let tileImage;

  for (let i = 0; i < media.length; i++) {

    m = media[i];

    if (!m || !m.url) {
      continue;
    }

    tileImage = getImageByExtension(m.url);

    mediaList.push(

      <UploadedDocTile
        key={i}
        src={tileImage}
        onClick={() => onShowGalleryClick(i)}
      />
    );
  }

  return (

    <React.Fragment>

      <ActionContainer>

        <ActionButtonContainer>

          <Button
            width={'100%'}
            text={'Edit project'}
            onClick={onEditClick}
          />

        </ActionButtonContainer>

      </ActionContainer>

      <DesktopSpace/>

      <Accordion
        header={'Project information'}
        headerColor={Colors.White}
        backgroundColor={Colors.Blue3}
        borderRadius={'15px'}
        headerPadding={'5px 15px'}
        contentPadding={'15px'}
        contentBackgroundColor={Colors.White}>

        <ContentContainer>

          <ProjectInformationDisplay
            project={project}
          />

        </ContentContainer>

      </Accordion>

      <Space
        size={'25px'}
      />

      <Accordion
        header={'Set a goal'}
        headerColor={Colors.White}
        backgroundColor={Colors.Blue3}
        borderRadius={'15px'}
        headerPadding={'5px 15px'}
        contentPadding={'15px'}
        contentBackgroundColor={Colors.White}>

        <ContentContainer>

          { (!project.project_advices || !project.project_advices.length)
            && (!project.project_employees || !project.project_employees.length)
            && (!project.project_fundings || !project.project_fundings.length)

            ? <PSmall>No goal added</PSmall>

            : <GoalFormContainer>
          
                <GoalDisplay
                  project={project}
                />

              </GoalFormContainer>
          }

        </ContentContainer>

      </Accordion>

      { project.nature && project.nature.value === 2 &&

        <React.Fragment>

          <Space
            size={'25px'}
          />

          <Accordion
            header={'Project details'}
            headerColor={Colors.White}
            backgroundColor={Colors.Blue3}
            borderRadius={'15px'}
            headerPadding={'5px 15px'}
            contentPadding={'15px'}
            contentBackgroundColor={Colors.White}>

            <ContentContainer>

              <ProjectDetailsDisplay
                project={project}
              />

              <SwotAnalysisDisplay
                project={project}
              />

              <MediaContainer>
                {mediaList}
              </MediaContainer>

            </ContentContainer>

          </Accordion>

        </React.Fragment>
      }

      <MobileButtonContainer>

        <RoundButton
          onClick={onEditClick}>

          <Image
            src={Images.Icons.EditPencil}
            height={'60px'}
            width={'60px'}
          />

        </RoundButton>

      </MobileButtonContainer>

      <MobileSpace/>

      <OverlayImageGallery
        show={state.showImageGallery}
        images={project.media || []}
        selectedIndex={state.selectedImageIndex}
        onClose={onHideGalleryClick}
      />

    </React.Fragment>
  );
}

export const ProjectsView = withCommon(
  withProjectsSideMenu(ProjectsViewUI), {
    selectedMenuItem: MenuItems.Projects,
    sideMenuActionUrl: '/projects/view',
  }
);