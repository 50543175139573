import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  Chat,
  Invites,
  ShareRequest,
  DeviceMediaQueries,
  Environment,
  FontSizes,
  Images,
  Image,
  MenuItems,
  SafeAreas,
} from 'common';

import {
  Text,
} from 'common/components';

import {
  UserSvg,
} from 'common/svg';

import {
  Version,
} from 'generated/constants';

const Container = styled.div`
  background-color: ${Colors.White};
  position: fixed;
  top: ${SafeAreas.top};
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 50px;
  width: 100%;
  padding: 0 15px;
  box-shadow: 0 0 10px 0 ${Colors.Gray5};
  z-index: 1;

  @media ${DeviceMediaQueries.Tablet} {
    position: absolute;
    flex-direction: row;
    height: 60px;
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const LogoImage = styled.div`
  cursor: pointer;
  height: 40px;
  max-width: 50px;
  width: 100%;
  background-image: url(${Images.LogoMobile});
  background-size: contain;
  background-repeat: no-repeat;

  @media ${DeviceMediaQueries.Tablet} {
    background-image: url(${Images.Logo});
    max-width: 180px;
    height: 43px;
  }
`;

const MenuSection = styled.div`
  position: relative;
  display: ${props => props.mobileDisplayNone ? 'none' : 'flex'};
  align-items: center;
  justify-content: ${props => props.justifyContent || 'space-between'};
  height: 75%;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    height: auto;
    width: 30%;
    ${props => props.minWidth && `min-width: ${props.minWidth};`}
  }
`;

const MenuSectionDesktop = styled(MenuSection)`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
  }
`;

const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  font-size: ${FontSizes.Regular};
  font-weight: bold;
  color: ${props => props.color || Colors.Black};
`;

const MenuItemNotification = styled.div`
  background-color: ${Colors.Orange2};
  color: ${Colors.White};
  height: 16px;
  width: 16px;
  border-radius: 8px;
  position: absolute;
  top: -5px;
  right: -16px;
  font-size: ${FontSizes.Smallest};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileImageContainer = styled.div`
  height: 100%;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

const PopupMenuOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Black};
  opacity: 0.6;
  z-index: 2;
`;

const PopupMenuContainer = styled.div`
  background-color: ${Colors.White};
  box-shadow: 0 0 10px 5px ${Colors.Gray9};
  border-radius: 5px;
  position: fixed;
  padding: 5px;
  top: 50px;
  right: 20px;
  left: 20px;
  z-index: 2;

  @media ${DeviceMediaQueries.Laptop} {
    left: auto;
    width: 200px;
  }
`;

const PopupMenuItem = styled.div`
  cursor: pointer;
  font-size: ${FontSizes.Small};
  position: relative;
  padding: 5px;
  width: 100%;

  :nth-child(even) {
    background: ${Colors.Gray1};
  }

  :hover {
    background-color: ${Colors.Gray2};
  }
`;

const MobileNavigatorContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

const MobileNavigator = props => {

  const items = [
    {
      display: 'Home',
      url: '/home',
      color: Colors.Black,
    },
    {
      key: MenuItems.Projects,
      display: 'Projects',
      url: '/projects/view',
      color: Colors.Blue3,
    },
    {
      key: MenuItems.Contacts,
      display: 'Contacts',
      url: '/contacts/chat',
      color: Colors.Orange2,
    },
    {
      key: MenuItems.Matches,
      display: 'Matches',
      url: '/matches/view',
      color: Colors.Green3,
    },
    {
      key: MenuItems.Account,
      display: 'Account',
      url: '/account/view',
      color: Colors.Red3,
    }
  ];

  const selectedItem = items.find(i => i.key === props.selectedItem) || items[0];

  const onLeftArrowClick = () => {

    const indexOfCurrent = items.indexOf(selectedItem);

    if (indexOfCurrent === 0) {

      const item = items[items.length - 1];
      props.history.push(item.url);
      return;
    }

    const item = items[indexOfCurrent - 1];
    props.history.push(item.url);
  };

  const onRightArrowClick = () => {

    const indexOfCurrent = items.indexOf(selectedItem);

    if (indexOfCurrent === (items.length - 1)) {

      const item = items[0];
      props.history.push(item.url);
      return;
    }

    const item = items[indexOfCurrent + 1];
    props.history.push(item.url);
  };

  return (

    <MobileNavigatorContainer>

      <Image
        src={Images.Icons.ArrowThinRightBlack}
        height={'10px'}
        width={'10px'}
        transform={'rotate(180deg)'}
        onClick={onLeftArrowClick}
      />

      <Text
        color={selectedItem.color}
        fontSize={FontSizes.Small}>

        {selectedItem.display}
      </Text>

      <Image
        src={Images.Icons.ArrowThinRightBlack}
        height={'10px'}
        width={'10px'}
        onClick={onRightArrowClick}
      />

    </MobileNavigatorContainer>
  );
};

const PopupMenu = props => (

  <React.Fragment>

    <PopupMenuOverlay
      onClick={props.onPopupOverlayClick}
    />

    <PopupMenuContainer>

      <PopupMenuItem
        onClick={props.onAccountClick}>
          
          Account
      </PopupMenuItem>

      <PopupMenuItem
        onClick={props.onProjectsClick}>

          Projects
      </PopupMenuItem>

      <PopupMenuItem
        onClick={props.onContactsClick}>

          Contacts
      </PopupMenuItem>

      <PopupMenuItem
        onClick={props.onMatchesClick}>

          Matches
      </PopupMenuItem>

      <PopupMenuItem
        onClick={() => { window.showTutorial(); props.onPopupOverlayClick();}}>

          Tutorial
      </PopupMenuItem>

      <PopupMenuItem
        onClick={props.onLogoutClick}>
          
          Logout
      </PopupMenuItem>

      <Text
        fontSize={FontSizes.Smallest}
        textAlign={'center'}
        width={'100%'}>

          {Environment.name + ' ' + Version}
      </Text>

    </PopupMenuContainer>

  </React.Fragment>
);

const HeaderComponent = props => {
  
  const [showPopupMenu, setStateShowPopupMenu] = React.useState(false);
  const [chatNotificationCount, chatNotificationCountSetState] = React.useState(Chat.conversationsWithMessagesCount || 0);
  const [contactsNotificationCount, contactsNotificationCountSetState] = React.useState(Invites.inviteCount || 0);
  const [shareRequestNotificationCount, contactsShareRequestCountSetState] = React.useState(ShareRequest.requestCount || 0);

  const onContactsNotificationCountUpdate = (count) => {
    contactsNotificationCountSetState(count || 0);
  };

  const onShareRequestNotificationCountUpdate = (count) => {
    contactsShareRequestCountSetState(count || 0);
  };

  const onChatNotificationCountUpdate = (count) => {
    chatNotificationCountSetState(count || 0);
  };

  const onHomeClick = () => {

    if (props.history.location.pathname === '/home') {
      return;
    }

    props.history.push('/home');
  };

  const onAccountClick = () => {

    if (props.history.location.pathname === '/account/view') {
      return;
    }

    props.history.push('/account/view');
  };

  const onProjectsClick = () => {

    if (props.history.location.pathname === '/projects/view') {
      return;
    }

    props.history.push('/projects/view');
  };

  const onContactsClick = () => {

    if (props.history.location.pathname === '/contacts/chat') {
      return;
    }

    props.history.push('/contacts/chat');
  };

  const onMatchesClick = () => {

    if (props.history.location.pathname === '/matches/view') {
      return;
    }

    props.history.push('/matches/view');
  };

  const onLogoutClick = () => {
    props.history.push('/identity/login');
  };

  const onDropDownMenuClick = () => {
    setStateShowPopupMenu(true);
  };

  const onPopupOverlayClick = () => {
    setStateShowPopupMenu(false);
  };

  React.useEffect(() => {

    Invites.subscribe('inviteCountUpdated', onContactsNotificationCountUpdate);
    ShareRequest.subscribe('shareRequestCountUpdated', onShareRequestNotificationCountUpdate);
    Chat.subscribe('messageCountUpdated', onChatNotificationCountUpdate);

    return () => {

      Invites.unsubscribe('inviteCountUpdated', onContactsNotificationCountUpdate);
      ShareRequest.unsubscribe('shareRequestCountUpdated', onShareRequestNotificationCountUpdate);
      Chat.unsubscribe('messageCountUpdated', onChatNotificationCountUpdate);
    };
  }, []);

  return (

    <React.Fragment>

    <Container>

      <MenuSection>

        <LogoImage
          onClick={onHomeClick}
        />

        <MobileNavigator
          history={props.history}
          selectedItem={props.selectedItem}
        />

        <MobileImageContainer>

          <Image
            src={Images.Icons.FillArrow}
            height={'20px'}
            onClick={onDropDownMenuClick}
          />

          <UserSvg
            fill={Colors.Gray4}
            selectedFill={Colors.White}
            showBackground={true}
            selected={props.selectedItem === MenuItems.Account}
            selectedBackgroundColor={Colors.Red3}
            onClick={onAccountClick}
          />

          { showPopupMenu &&

            <PopupMenu
              onPopupOverlayClick={onPopupOverlayClick}
              onAccountClick={onAccountClick}
              onProjectsClick={onProjectsClick}
              onContactsClick={onContactsClick}
              onMatchesClick={onMatchesClick}
              onLogoutClick={onLogoutClick}
            />
          }

        </MobileImageContainer>

      </MenuSection>

      <MenuSectionDesktop
        minWidth={'350px'}>
        
        <MenuItem
          color={props.selectedItem === MenuItems.Projects ? Colors.Blue3 : Colors.Black}
          onClick={onProjectsClick}>
            Projects
        </MenuItem>

        <MenuItem
          color={props.selectedItem === MenuItems.Contacts ? Colors.Orange2 : Colors.Black}
          onClick={onContactsClick}>
            Contacts

            { (!!contactsNotificationCount || !!chatNotificationCount || !!shareRequestNotificationCount) &&

              <MenuItemNotification>{chatNotificationCount + contactsNotificationCount + shareRequestNotificationCount}</MenuItemNotification>
            }
        </MenuItem>

        <MenuItem
          color={props.selectedItem === MenuItems.Matches ? Colors.Green3 : Colors.Black}
          onClick={onMatchesClick}>
            Matches
        </MenuItem>

      </MenuSectionDesktop>

      <MenuSection
        justifyContent={'flex-end'}
        mobileDisplayNone={true}>
        
        <MenuItem
          color={props.selectedItem === MenuItems.Account ? Colors.Red3 : Colors.Black}
          onClick={onAccountClick}>
            
            Account
        </MenuItem>

        &nbsp;&nbsp;&nbsp;

        <Image
          src={Images.Icons.FillArrow}
          height={'35%'}
          onClick={onDropDownMenuClick}
        />

      </MenuSection>

    </Container>

    { showPopupMenu &&

      <PopupMenu
        onPopupOverlayClick={onPopupOverlayClick}
        onAccountClick={onAccountClick}
        onProjectsClick={onProjectsClick}
        onContactsClick={onContactsClick}
        onMatchesClick={onMatchesClick}
        onLogoutClick={onLogoutClick}
      />
    }

    </React.Fragment>
  );
};

export const Header = withRouter(HeaderComponent);