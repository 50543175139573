import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  SafeAreas,
} from 'common';

export const Container = styled.div`
  background-color: ${Colors.White};
  display: flex;
  flex: 1;
  position: relative;
`;

export const SideMenuContainer = styled.div`
  background-color: ${Colors.Orange2};
  min-height: 100%;
  width: 100%;
  position: absolute;
  padding-bottom: 50px;

  @media ${DeviceMediaQueries.Tablet} {
    height: 100%;
    width: 300px;
    overflow: auto;
    padding-bottom: 0;
  }
`;

export const ConversationsContainer = styled.div`
  padding: 3px;
`;

export const ContentContainer = styled.div`
  background-color: ${Colors.White};
  width: 100%;
  position: relative;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 20px;
    position: absolute;
    left: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    width: auto;
  }

  @media ${DeviceMediaQueries.LaptopL} {
    padding: 40px;
  }
`;

export const NoItemContainer = styled.div`
  background-color: ${Colors.Orange1};
  color: ${Colors.White};
  font-size: ${FontSizes.Small};
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 3px 0;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
`;

export const BackContainer = styled.div`
  background-color: ${Colors.White};
  position: fixed;
  bottom: 75px;
  left: 23px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-bottom: ${SafeAreas.bottom};

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;