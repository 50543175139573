import
  React
from 'react';

import {
  Colors,
  Images,
  Messages,
  Logger,
  getQueryParameterByName,
  isValidEmail,
  isValidProviderResult,
} from 'common';

import {
  Button,
  PSmaller,
  TextInput,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  EmailImage,
  HeaderText,
  SubHeaderText,
} from './changeEmailStyled';

import {
  Container,
  InputContainer,
} from '../identityStyled';

import {
  IdentityProvider,
} from 'providers';

class ChangeEmailCaptureNewUI extends React.Component {

  state = {
    showButtonLoading: false,
    hasEmailError: false,
    showError: false,
    isEmailSent: false,
    emailMessage: '',
    errorMessage: '',
  };

  email = '';
  oldEmail = '';
  token = '';

  onEmailChange = val => {
    
    this.email = val;

    if (this.state.hasEmailError || this.state.showError) {

      this.setState({
        hasEmailError: false,
        showError: false,
      });
    }
  };

  onChangeEmailClick = () => {

    if (!isValidEmail(this.email)) {

      this.setState({
        hasEmailError: true,
        emailMessage: 'Please provide a valid email',
      });

      return;
    }

    this.setState({
      showButtonLoading: true,
    });

    IdentityProvider
      .changeEmail(
        this.email,
        this.oldEmail,
        this.token,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
            errorMessage: ret.message || Messages.TechnicalError,
            showButtonLoading: false,
          });

          return;
        }

        this.setState({
          isEmailSent: true,
        });
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          showButtonLoading: false,
        });
      });
  };

  onBackClick = () => {
    this.props.common.navigate('/identity/login');
  };

  componentDidMount() {

    this.token = getQueryParameterByName('t');
    this.oldEmail = getQueryParameterByName('email');
  }

  render () {

    if (this.state.isEmailSent) {

      return (

        <Container>
        
          <EmailImage
            src={Images.Icons.EmailSent}
          />

          <HeaderText>
            Check your mail
          </HeaderText>

          <SubHeaderText>
            We have sent the change email instructions to the entered email
          </SubHeaderText>

          <Button
            text={'BACK TO LOGIN'}
            onClick={this.onBackClick}
          />

        </Container>
      );
    }

    return (

      <Container>

        <HeaderText>
          Change your email
        </HeaderText>

        <SubHeaderText>
          Please enter the new email and we'll send you the instructions
        </SubHeaderText>

        { this.state.showError &&
          
          <PSmaller
            color={Colors.Red}
            padding={'0 0 10px 0'}
            textAlign={'center'}>

              {this.state.errorMessage}
          </PSmaller>
        }

        <InputContainer>

          <TextInput
            type={'email'}
            placeholder={'Email'}
            width={'100%'}
            onChange={this.onEmailChange}
            hasError={this.state.hasEmailError}
            errorMessage={this.state.emailMessage}
          />

          <Button
            text={'SEND INSTRUCTIONS'}
            width={'100%'}
            showLoading={this.state.showButtonLoading}
            onClick={this.onChangeEmailClick}
          />

        </InputContainer>

      </Container>
    );
  }
}

export const ChangeEmailCaptureNew = withCommon(
  ChangeEmailCaptureNewUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);