import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  VoiceRecorder
} from 'capacitor-voice-recorder';

import {
  Colors,
  FontSizes,
  Images,
  ErrorToast,
  Logger,
  WebVoiceRecorder,
  Environment,
  convertURIToBlob,
} from 'common';

import {
  Loader,
} from 'common/components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ActionImage = styled.div`
  background-color: ${props => props.backgroundColor || Colors.White};
  background-image: url(${props => props.image});
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${Colors.Orange1};
  border-radius: 23px;
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
`;

const TimeContainer = styled.div`
  background-color: ${props => props.backgroundColor || Colors.White};
  border: 1px solid ${Colors.Orange1};
  border-radius: 23px;
  color: ${props => props.color || Colors.Orange1};
  font-size: ${FontSizes.Big};
  height: 46px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderContainer = styled.div`
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatRecorder = props => {

  const [state, setState] = React.useState({
    timer: '00:00',
    isRecording: false,
  });

  const onSendMessageClick = async () => {

    try {

      const result = await ChatRecorder.voiceRecorder.stopRecording();

      if (!result.value.recordDataBase64) {

        result.message = 'No recording data found';

        ErrorToast.show('', 'No recording data found');
        Logger.error(result);

        return;
      }

      const mimeType = result.value.mimeType;

      stopRecording();

      props.onSendMessageClick && props.onSendMessageClick({
        contentType: mimeType,
        media: convertURIToBlob(result.value.recordDataBase64, mimeType),
      });

      setState(prevState => ({
        ...prevState,
        isRecording: false,
        timer: '00:00',
      }));
    }
    catch(e) {

      Logger.error(e);
      ErrorToast.show();
    }
  };

  const onStartRecording = async () => {

    try {

      const canRecord = await ChatRecorder.voiceRecorder.canDeviceVoiceRecord();

      if (!canRecord.value) {

        ErrorToast.show('', 'Your device does not support voice recording.');
        return;
      }

      const requestPermision = await ChatRecorder.voiceRecorder.requestAudioRecordingPermission();

      if (!requestPermision.value) {

        ErrorToast.show('No permission', 'Please allow the app to access your recording device.');
        return;
      }

      const voiceRecordingStarted = await ChatRecorder.voiceRecorder.startRecording();

      if (!voiceRecordingStarted.value) {

        ErrorToast.show('', 'We were unable to start your voice recording');
        return;
      }

      startTimer();
    }
    catch(e) {

      Logger.error(e);

      ErrorToast.show('', 'We were unable to record your voice note, please try again.');
    }

    setState(prevState => ({
      ...prevState,
      isRecording: true,
    }));
  };

  const onCancelRecodringClick = () => {

    if (!state.isRecording) {

      props.onCloseClick && props.onCloseClick();
      return;
    }

    stopRecording();

    ChatRecorder.voiceRecorder.stopRecording()
      .catch(error => Logger.error(error));

    setState(prevState => ({
      ...prevState,
      isRecording: false,
      timer: '00:00',
    }));
  };

  const stopRecording = () => {

    if (ChatRecorder.interval) {
      clearInterval(ChatRecorder.interval);
    }
  };

  const startTimer = () => {

    ChatRecorder.time = 0;

    ChatRecorder.interval = setInterval(() => {

      ChatRecorder.time++;

      let seconds = (ChatRecorder.time % 60).toString();

      let minutes = (Math.floor(ChatRecorder.time / 60)).toString();

      if (seconds.length < 2) {
        seconds = `0${seconds}`;
      }

      if (minutes.length < 2) {
        minutes = `0${minutes}`;
      }

      setState(prevState => ({
        ...prevState,
        timer: `${minutes}:${seconds}`,
      }));

    }, 1000);
  };

  React.useEffect(() => {

    ChatRecorder.voiceRecorder = Environment.channel === 'ios'
      ? VoiceRecorder
      : new WebVoiceRecorder();

    stopRecording();

    ChatRecorder.time = 0;

    ChatRecorder.voiceRecorder.requestAudioRecordingPermission()
      .then(requestPermision => {

        if (!requestPermision.value) {

          ErrorToast.show('No permission', 'Please allow the app to access your recording device.');
          return;
        }
      })
      .catch(e => Logger.error(e));

    return () => {

      stopRecording();

      if (ChatRecorder && ChatRecorder.voiceRecorder && ChatRecorder.voiceRecorder.stopStream) {
        ChatRecorder.voiceRecorder.stopStream();
      }
    };
  }, []);

  return (

    <Container>

      <ActionImage
        backgroundColor={Colors.Orange1}
        image={Images.Icons.ChatCancel}
        onClick={onCancelRecodringClick}
      />

      <TimeContainer
        backgroundColor={state.isRecording ? Colors.Orange1 : Colors.White}
        color={state.isRecording ? Colors.White : Colors.Orange1}>

        {state.timer}
      </TimeContainer>

      { props.showLoader &&
      
        <LoaderContainer>

          <Loader
            size={30}
          />

        </LoaderContainer>
      }

      { !props.showLoader && state.isRecording &&

        <ActionImage
          image={Images.Icons.ChatSend}
          onClick={onSendMessageClick}
        />
      }

      { !props.showLoader && !state.isRecording &&
        
        <ActionImage
          image={Images.Icons.ChatRecord}
          onClick={onStartRecording}
        />
      }

    </Container>
  );
};