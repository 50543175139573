import
  React
from 'react';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const OfficeBuildingSvg = props => {

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <Path d="M37,2A35,35,0,1,1,2,37,35,35,0,0,1,37,2m0-2A37,37,0,1,0,74,37,37,37,0,0,0,37,0Z"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M43.34,29H30.71a4.57,4.57,0,0,1,4.55-4.19H38.8A4.56,4.56,0,0,1,43.34,29Z"/>
        
        <Path fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" d="M52.58,33.59V45.42A4.56,4.56,0,0,1,48,50H26a4.56,4.56,0,0,1-4.56-4.57V33.59A4.56,4.56,0,0,1,26,29H48A4.56,4.56,0,0,1,52.58,33.59Z"/>
        
        <rect x="22.43" y="36.14" width="12.25" height="2.11"/>
        
        <rect x="39.21" y="36.14" width="12.72" height="2.11"/>
        
        <circle fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" cx="37.03" cy="37.19" r="2.09"/>
        
      </Svg>

    </SvgContainer>
  );
};