import
  React
from 'react';

import {
  Images,
  Image,
  MenuItems,
  Logger,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Card,
  H2,
  PSmall,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountScreen,
} from 'ui/account/components';

import {
  IdentityProvider,
} from 'providers';

class AccountChangePasswordUI extends React.Component {

  state =  {
    showSuccess: false,
    showError: false,
  };

  onChangePasswordClick = () => {

    if (!this.props.common.user.email) {

      this.setState({
        showError: true,
      });

      return;
    }

    this.props.common.showLoading();

    IdentityProvider.requestPasswordChange(this.props.common.user.email)
      .then(ret => {

        this.props.common.hideLoading();

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
          });

          return;
        }

        this.setState({
          showSuccess: true,
          showError: false,
        });
      })
      .catch(e => {

        this.props.common.hideLoading();

        Logger.error(e);

        this.setState({
          showError: true,
        });
      });
  };

  render() {

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}>

        <H2
          margin={'0 0 20px 0'}>

          Change Password
        </H2>

        { !this.state.showSuccess && !this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'15px'}>

            <PSmall
              padding={'0 0 20px 0'}>

                We will send you an email with the instuctions to change your password.
            </PSmall>

            <Button
              width={'200px'}
              text={'Send Instructions'}
              onClick={this.onChangePasswordClick}
            />

          </Card>
        }

        { this.state.showSuccess && !this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'20px 30px'}
            alignItems={'center'}>
              
              <Image
                height={'50px'}
                src={Images.Icons.EmailSent}
              />

              <H2>
                Check your mail
              </H2>

              <PSmall
                textAlign={'center'}>
                We have sent the password change instructions to your email
              </PSmall>

          </Card>
        }

        { this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'20px'}
            alignItems={'center'}>
              
              <H2
                margin={'0 0 20px 0'}>

                  Oops...
              </H2>

              <PSmall
                textAlign={'center'}>

                  Unfortunately an error occurred and we were unable to send you the instructions, please try again later.
              </PSmall>

          </Card>
        }

      </AccountScreen>
    );
  }
}

export const AccountChangePassword = withCommon(
  AccountChangePasswordUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);