import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  DeviceMediaQueries,
  Environment,
  ErrorToast,
  Logger,
  PlanTypes,
  isValidProviderResult,
} from 'common';

import {
  Button,
  H3,
  H4,
  PSmall,
  Rating,
  InformationBubble,
} from 'common/components';

import {
  PaymentProvider,
} from 'providers';

const Container = styled.div`
  padding: 20px;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  background: ${Colors.Gray1};
  height: 2px;
  margin: 15px 0;
`;

const Space = styled.div`
  @media ${DeviceMediaQueries.Laptop} {
    flex: 1;
  }
`;

const MenuItem = styled(PSmall)`
  color: ${Colors.White};
  display: block;
  padding: 10px 0 0 0;
`;

const AccountsSideMenuComponent = props => {

  const [isManageButtonLoading, isManageButtonLoadingSetState] = React.useState(false);
  const user = DataStore.get('LOGGED_IN_USER');
  const features = DataStore.get('MAIN_CONFIG', {}).features || {};

  const onManageSubscriptionClick = () => {

    if (user && !user.packageId) {

      props.history.push('/payment/package/select');
      return;
    }

    isManageButtonLoadingSetState(true);

    PaymentProvider.createPortalSession(Environment.channel)
      .then(ret => {

        if (isValidProviderResult(ret) && ret.data.redirect) {

          window.location.href = ret.data.redirect;
          return;
        }

        isManageButtonLoadingSetState(false);

        ErrorToast.show();
        Logger.error(ret.data.message);
      })
      .catch(e => {

        isManageButtonLoadingSetState(false);

        ErrorToast.show();
        Logger.error(e);
      });
  };

  const onChangeEmailClick = () => {
    props.history.push('/account/email/change');
  };

  const onChangePasswordClick = () => {
    props.history.push('/account/password/change');
  };

  const onGoToAonClick = () => {
    window.open('https://www.afrion.com', '_blank');
  };

  const onCookiePolicyClick = () => {
    
    props.onShowIframePopup && props.onShowIframePopup('https://www.afrion.com/cookies-policy?embedded=true');
  };

  const onPrivacyPolicyClick = () => {
    
    props.onShowIframePopup && props.onShowIframePopup('https://www.afrion.com/privacy-policy?embedded=true');
  };

  const onTermsAndConditionsClick = () => {

    props.onShowIframePopup && props.onShowIframePopup('https://www.afrion.com/terms-and-conditions?embedded=true');
  };

  const onDeactivateAccountClick = () => {
    props.history.push('/account/deactivate');
  };

  const onDeleteAccountClick = () => {
    props.history.push('/account/delete');
  };

  const pricePlan = PlanTypes.find(p => p.priceId === user.packageId);

  return (
    <>
    <Container>

      <H3 color={Colors.White}>Account settings</H3>

      <H4 color={Colors.White} margin={'5px 0 15px 0'}>
        Account score

        <InformationBubble
          id={'custom-account-score'}
        />
      </H4>

      { typeof props.profileScore === 'number'
      
        ? <Rating
            rating={props.profileScore}
            orientation={'horizontal'}
            light={true}
          />

        : <PSmall color={Colors.White} height={'20px'}>{props.profileScore || '0%'}</PSmall>
      }

      <Line/>

      { features.subscription &&
        
        <>
        <H4 color={Colors.White}>Subscription</H4>

        <MenuItem>
          {`Plan: ${(pricePlan && pricePlan.name.toUpperCase()) || 'FREE'}`} 
        </MenuItem>

        <Button
          text={'Manage'}
          width={'100%'}
          margin={'10px 0 0 0'}
          onClick={onManageSubscriptionClick}
          showLoading={isManageButtonLoading}
        />

        <Line/>
        </>
      }

      <H4 color={Colors.White}>General settings</H4>

      <MenuItem
        onClick={onChangeEmailClick}>
          Change email
      </MenuItem>

      <MenuItem
        onClick={onChangePasswordClick}>
          Change password
      </MenuItem>

      <MenuItem
        onClick={onDeactivateAccountClick}>
          Deactivate account
      </MenuItem>

      <MenuItem
        onClick={onDeleteAccountClick}>
          Delete account
      </MenuItem>

      <Line/>

      <Space/>

      <H4 color={Colors.White}>Help centre</H4>

      <MenuItem
        onClick={onTermsAndConditionsClick}>
          Terms and conditions
      </MenuItem>

      <MenuItem
        onClick={onCookiePolicyClick}>
          Cookies policy
      </MenuItem>

      <MenuItem
        onClick={onPrivacyPolicyClick}>
          Privacy policy
      </MenuItem>

      <MenuItem
        onClick={onGoToAonClick}>
          AfriOn.com
      </MenuItem>

    </Container>

    </>
  );
};

export const AccountsSideMenu = withRouter(AccountsSideMenuComponent);