import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  post,
} from 'providers/axios';

export class PaymentProvider {

  static async getPaymentUrl(
    userId,
    priceId,
    channel,
  ) {

    try {

      const res = await post(`${Environment.apiHost}payment-checkout-session`, {
        priceId,
        userId,
        channel,
      });

      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async completePayment(
    sessionId,
    priceId,
  ) {

    try {

      const res = await post(`${Environment.apiHost}payment-checkout-complete`, {
        sessionId,
        priceId,
      });
      
      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async createPortalSession(
    channel,
  ) {

    try {

      const res = await post(`${Environment.apiHost}payment-portal-session`, {
        channel
      });

      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }
}
