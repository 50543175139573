import {
  DataStore,
  Environment,
  GoalTypes,
  getLookupDisplayByValue,
  resolveResponse,
  resolveRejected,
  isValidProviderResult,
} from 'common';

import {
  get,
  post,
  put,
  patch,
  deleteCall,
} from 'providers/axios';

export class ProjectsProvider {

  static async getBasicProjectList() {

    try {

      let res = await get(`${Environment.apiHost}projects-list`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      let currentProject;

      let accountInfo = await DataStore.get('ACCOUNT_INFORMATION') || {};

      accountInfo = accountInfo.data
        && Array.isArray(accountInfo.data.account)
        && !!accountInfo.data.account.length
        && accountInfo.data.account[0];

      let mainProjectId = accountInfo.mainProject || -1;

      let projects = [];

      for (let i = 0; i < ret.data.length; i++) {

        currentProject = ret.data[i];

        if (!currentProject || !currentProject.title || currentProject.deleted) {
          continue;
        }

        if (mainProjectId === -1) {
          mainProjectId = currentProject.id;
        }

        currentProject.isMain = (mainProjectId === currentProject.id);
        currentProject.title = currentProject.title || `New project ${i + 1}`;

        currentProject.status = {
          display: getLookupDisplayByValue('projectStages', currentProject.stageDescription),
          value: currentProject.stageDescription,
        };

        currentProject.type = {
          display: getLookupDisplayByValue('projectTypes', currentProject.typeId),
          value: currentProject.typeId,
        };

        currentProject.nature = {
          display: getLookupDisplayByValue('projectNatures', currentProject.natureId),
          value: currentProject.natureId,
        };

        currentProject.country = {
          display: getLookupDisplayByValue('countries', currentProject.countryId),
          value: currentProject.countryId,
        };

        currentProject.industry = {
          display: getLookupDisplayByValue('projectIndustries', currentProject.industryId),
          value: currentProject.industryId,
        };

        projects.push(currentProject);
      }

      ret.data = projects;

      return ret;
    }
    catch (e) {

      return resolveRejected(e);
    }
  }

  static async getUserProjectById(projectId) {

    try {

      let res = await get(`${Environment.apiHost}projects/${projectId}`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      let currentProject;

      let accountInfo = await DataStore.get('ACCOUNT_INFORMATION') || {};

      accountInfo = accountInfo.data
        && Array.isArray(accountInfo.data.account)
        && !!accountInfo.data.account.length
        && accountInfo.data.account[0];

      let mainProjectId = accountInfo.mainProject || -1;

      currentProject = ret.data;

      if (!currentProject || !currentProject.title || currentProject.deleted) {
        return ret;
      }

      if (mainProjectId === -1) {
        mainProjectId = currentProject.id;
      }

      currentProject.isMain = (mainProjectId === currentProject.id);
      currentProject.project_stage = currentProject.project_stage || {};

      currentProject.status = {
        display: getLookupDisplayByValue('projectStages', currentProject.project_stage.stageDescription),
        value: currentProject.project_stage.stageDescription,
      };

      currentProject.type = {
        display: getLookupDisplayByValue('projectTypes', currentProject.typeId),
        value: currentProject.typeId,
      };

      currentProject.nature = {
        display: getLookupDisplayByValue('projectNatures', currentProject.natureId),
        value: currentProject.natureId,
      };

      currentProject.industries = [];
      currentProject.countries = [];

      if (Array.isArray(currentProject.project_industries)) {

        let industry;

        for (let i = 0; i < currentProject.project_industries.length; i++) {

          industry = currentProject.project_industries[i];

          currentProject.industries.push({
            display: getLookupDisplayByValue('projectIndustries', industry.industryDescription),
            value: industry.industryDescription,
            id: industry.id,
          });
        }
      }

      if (Array.isArray(currentProject.project_locations)) {

        let country;

        for (let i = 0; i < currentProject.project_locations.length; i++) {

          country = currentProject.project_locations[i];

          currentProject.countries.push({
            display: getLookupDisplayByValue('countries', country.countryId),
            value: country.countryId,
            id: country.id,
          });
        }
      }

      if (Array.isArray(currentProject.project_fundings)) {
      
        let funding;

        for (let i = 0; i < currentProject.project_fundings.length; i++) {

          funding = currentProject.project_fundings[i];
          funding.type = GoalTypes.Funding;
          funding.display = 'Funding goal';

          funding.country = {
            display: getLookupDisplayByValue('countries', funding.countryId),
            value: funding.countryId,
          };
    
          funding.currency = {
            display: getLookupDisplayByValue('currencies', funding.currencyId),
            value: funding.currencyId,
          };
        }
      }

      if (Array.isArray(currentProject.project_employees)) {
      
        let employee;

        for (let i = 0; i < currentProject.project_employees.length; i++) {

          employee = currentProject.project_employees[i];
          employee.type = GoalTypes.Employee;
          employee.display = 'Hiring goal';

          employee.country = {
            display: getLookupDisplayByValue('countries', employee.countryId),
            value: employee.countryId,
          };
    
          employee.employmentType = {
            display: getLookupDisplayByValue('employeeEmploymentType', employee.employmentTypeId),
            value: employee.employmentTypeId,
          };

          employee.role = {
            display: getLookupDisplayByValue('employeeRoles', employee.roleId),
            value: employee.roleId,
          };
        }
      }

      if (Array.isArray(currentProject.project_advices)) {
      
        let advice;

        for (let i = 0; i < currentProject.project_advices.length; i++) {

          advice = currentProject.project_advices[i];
          advice.type = GoalTypes.Advice;
          advice.display = 'Mentoring goal';

          advice.communicationMethod = {
            display: getLookupDisplayByValue('adviceContactMethod', advice.communicationMethodId),
            value: advice.communicationMethodId,
          };
    
          advice.frequency = {
            display: getLookupDisplayByValue('adviceFrequency', advice.frequencyTypeId),
            value: advice.frequencyTypeId,
          };
        }
      }

      currentProject.goals = [
        ...currentProject.project_advices,
        ...currentProject.project_employees,
        ...currentProject.project_fundings,
      ];

      currentProject.goals.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      currentProject.mainGoal = currentProject.goals.length
      ? {
          display: currentProject.goals[0].display,
          date: new Date(currentProject.goals[0].createdAt)
        }
      : {
          display: '',
          date: new Date(),
        };

      ret.data = currentProject;

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }


  static async getUserProjectList(userId) {

    try {

      let res = await get(`${Environment.apiHost}projects?userId=${userId}&$limit=999`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      let currentProject;

      let accountInfo = await DataStore.get('ACCOUNT_INFORMATION') || {};

      accountInfo = accountInfo.data
        && Array.isArray(accountInfo.data.account)
        && !!accountInfo.data.account.length
        && accountInfo.data.account[0];

      let mainProjectId = accountInfo.mainProject || -1;

      let projects = [];

      for (let i = 0; i < ret.data.length; i++) {

        currentProject = ret.data[i];

        if (!currentProject || !currentProject.title || currentProject.deleted) {
          continue;
        }

        if (mainProjectId === -1) {
          mainProjectId = currentProject.id;
        }

        currentProject.isMain = (mainProjectId === currentProject.id);
        currentProject.title = currentProject.title || `New project ${i + 1}`;
        currentProject.project_stage = currentProject.project_stage || {};

        currentProject.status = {
          display: getLookupDisplayByValue('projectStages', currentProject.project_stage.stageDescription),
          value: currentProject.project_stage.stageDescription,
        };

        currentProject.type = {
          display: getLookupDisplayByValue('projectTypes', currentProject.typeId),
          value: currentProject.typeId,
        };

        currentProject.nature = {
          display: getLookupDisplayByValue('projectNatures', currentProject.natureId),
          value: currentProject.natureId,
        };

        currentProject.industries = [];
        currentProject.countries = [];

        if (Array.isArray(currentProject.project_industries)) {

          let industry;
  
          for (let i = 0; i < currentProject.project_industries.length; i++) {
  
            industry = currentProject.project_industries[i];
  
            currentProject.industries.push({
              display: getLookupDisplayByValue('projectIndustries', industry.industryDescription),
              value: industry.industryDescription,
              id: industry.id,
            });
          }
        }

        if (Array.isArray(currentProject.project_locations)) {

          let country;
  
          for (let i = 0; i < currentProject.project_locations.length; i++) {
  
            country = currentProject.project_locations[i];
  
            currentProject.countries.push({
              display: getLookupDisplayByValue('countries', country.countryId),
              value: country.countryId,
              id: country.id,
            });
          }
        }

        if (Array.isArray(currentProject.project_fundings)) {
        
          let funding;
  
          for (let i = 0; i < currentProject.project_fundings.length; i++) {
  
            funding = currentProject.project_fundings[i];
            funding.type = GoalTypes.Funding;
            funding.display = 'Funding goal';
  
            funding.country = {
              display: getLookupDisplayByValue('countries', funding.countryId),
              value: funding.countryId,
            };
      
            funding.currency = {
              display: getLookupDisplayByValue('currencies', funding.currencyId),
              value: funding.currencyId,
            };
          }
        }

        if (Array.isArray(currentProject.project_employees)) {
        
          let employee;
  
          for (let i = 0; i < currentProject.project_employees.length; i++) {
  
            employee = currentProject.project_employees[i];
            employee.type = GoalTypes.Employee;
            employee.display = 'Hiring goal';

            employee.country = {
              display: getLookupDisplayByValue('countries', employee.countryId),
              value: employee.countryId,
            };
      
            employee.employmentType = {
              display: getLookupDisplayByValue('employeeEmploymentType', employee.employmentTypeId),
              value: employee.employmentTypeId,
            };

            employee.role = {
              display: getLookupDisplayByValue('employeeRoles', employee.roleId),
              value: employee.roleId,
            };
          }
        }

        if (Array.isArray(currentProject.project_advices)) {
        
          let advice;
  
          for (let i = 0; i < currentProject.project_advices.length; i++) {
  
            advice = currentProject.project_advices[i];
            advice.type = GoalTypes.Advice;
            advice.display = 'Mentoring goal';
  
            advice.communicationMethod = {
              display: getLookupDisplayByValue('adviceContactMethod', advice.communicationMethodId),
              value: advice.communicationMethodId,
            };
      
            advice.frequency = {
              display: getLookupDisplayByValue('adviceFrequency', advice.frequencyTypeId),
              value: advice.frequencyTypeId,
            };
          }
        }

        currentProject.goals = [
          ...currentProject.project_advices,
          ...currentProject.project_employees,
          ...currentProject.project_fundings,
        ];

        currentProject.goals.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });

        currentProject.mainGoal = currentProject.goals.length
        ? {
            display: currentProject.goals[0].display,
            date: new Date(currentProject.goals[0].createdAt)
         }
        : {
            display: '',
            date: new Date(),
         };

        projects.push(currentProject);
      }

      ret.data = projects;

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectView(projectId) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}project-view?id=${projectId}`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      if (ret.data.inActive) {
        return ret;
      }

      let currentProject = ret.data.projectDetails;

      currentProject.title = currentProject.title || `Unknown project`;
      currentProject.project_stage = currentProject.project_stage || {};

      currentProject.status = {
        display: getLookupDisplayByValue('projectStages', currentProject.project_stage.stageDescription),
        value: currentProject.project_stage.stageDescription,
      };

      currentProject.type = {
        display: getLookupDisplayByValue('projectTypes', currentProject.typeId),
        value: currentProject.typeId,
      };

      currentProject.nature = {
        display: getLookupDisplayByValue('projectNatures', currentProject.natureId),
        value: currentProject.natureId,
      };

      currentProject.industries = [];
      currentProject.countries = [];

      if (Array.isArray(currentProject.project_industries)) {

        let industry;

        for (let i = 0; i < currentProject.project_industries.length; i++) {

          industry = currentProject.project_industries[i];

          currentProject.industries.push({
            display: getLookupDisplayByValue('projectIndustries', industry.industryDescription),
            value: industry.industryDescription,
            id: industry.id,
          });
        }
      }

      if (Array.isArray(currentProject.project_locations)) {

        let country;

        for (let i = 0; i < currentProject.project_locations.length; i++) {

          country = currentProject.project_locations[i];

          currentProject.countries.push({
            display: getLookupDisplayByValue('countries', country.countryId),
            value: country.countryId,
            id: country.id,
          });
        }
      }

      if (Array.isArray(currentProject.project_fundings)) {
      
        let funding;

        for (let i = 0; i < currentProject.project_fundings.length; i++) {

          funding = currentProject.project_fundings[i];

          funding.type = GoalTypes.Funding;

          funding.country = {
            display: getLookupDisplayByValue('countries', funding.countryId),
            value: funding.countryId,
          };
    
          funding.currency = {
            display: getLookupDisplayByValue('currencies', funding.currencyId),
            value: funding.currencyId,
          };
        }
      }

      let advicesRet = await ProjectsProvider.getProjectAdvices(currentProject.id);

      if (isValidProviderResult(advicesRet)) {
        currentProject.project_advices = advicesRet.data || [];
      }

      let employeesRet = await ProjectsProvider.getProjectEmployees(currentProject.id);

      if (isValidProviderResult(employeesRet)) {
        currentProject.project_employees = employeesRet.data || [];
      }

      currentProject.project_advices.forEach((element) => {
        element.type = GoalTypes.Advice;
      });

      currentProject.project_employees.forEach((element) => {
        element.type = GoalTypes.Employee;
      });

      currentProject.goals = [
        ...currentProject.project_advices,
        ...currentProject.project_employees,
        ...currentProject.project_fundings,
      ];

      currentProject.goals.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      let account = (
        ret.data.userInformation
        && Array.isArray(ret.data.userInformation.account)
        && ret.data.userInformation.account.length
        && ret.data.userInformation.account[ret.data.userInformation.account.length - 1]
      ) || {};

      account.currentWorkStatus = {
        display: getLookupDisplayByValue('employmentTypes', account.currentWorkStatusId),
        value: account.currentWorkStatusId,
      };

      account.country = getLookupDisplayByValue('countries', account.countryId);
      account.city = account.cityId;
      account.languages = [];

      if (Array.isArray(account.user_languages)) {

        let language;

        for (let i = 0; i < account.user_languages.length; i++) {

          language = account.user_languages[i];
          account.languages.push(getLookupDisplayByValue('languages', language.languageId));
        }
      }

      let educationalHistory = (Array.isArray(ret.data.userInformation.educationalHistory) && ret.data.userInformation.educationalHistory) || [];
      let highestEducation;
      let degreeLevel = 0;
      let education;

      for (let i = 0; i < educationalHistory.length; i++) {

        education = educationalHistory[i];
  
        if (!education) {
          continue;
        }
  
        const isInProgress = !education.endYear && !education.endMonth;

        if (parseInt(education.degreeLevelId) > degreeLevel && !isInProgress) {

          degreeLevel = parseInt(education.degreeLevelId);
          highestEducation = education.degreeTitle;
        }
      }

      account.highestEducation = highestEducation;
      account.profileScore = Math.floor(((ret.data.userInformation && ret.data.userInformation.profileScore) || 0) / 20);  // profilScore is out of 100, profileRating out of 5, thus val.profileScore / 10 / 2 = val.profileScore / 20

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async createProject(
    userId,
  ) {

    try {

      let res = await post(
        `${Environment.apiHost}projects`, {
          userId
        }
      );

      let ret = resolveResponse(res);

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async updateProjectInformation(
    projectId,
    userId,
    natureId,
    typeId,
    title,
    mainProject,
    statusId,
    projectScore,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      let res = await put(
        `${Environment.apiHost}projects/${projectId}`, {
          userId,
          natureId,
          typeId,
          title,
          mainProject,
          statusId,
          projectScore,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async createProjectDetails(
    projectId,
    executiveSummary,
    marketAnalysis,
    problemAnalysis,
    solutionAnalysis,
    targetMarket,
    currentCompetitors,
    additionalInformation,
    publicShare,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-details`, {
          projectId,
          executiveSummary,
          marketAnalysis,
          problemAnalysis,
          solutionAnalysis,
          targetMarket,
          currentCompetitors,
          additionalInformation,
          publicShare,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async updateProjectDetails(
    detailsId,
    projectId,
    executiveSummary,
    marketAnalysis,
    problemAnalysis,
    solutionAnalysis,
    targetMarket,
    currentCompetitors,
    additionalInformation,
    publicShare,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await put(
        `${Environment.apiHost}project-details/${detailsId}`, {
          projectId,
          executiveSummary,
          marketAnalysis,
          problemAnalysis,
          solutionAnalysis,
          targetMarket,
          currentCompetitors,
          additionalInformation,
          publicShare,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addCountry(
    projectId,
    countryId,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-locations`, {
          projectId,
          countryId,
          cityId: 0,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteCountry(
    countryId
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}project-locations/${countryId}`);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addIndustry(
    projectId,
    industryId,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-industries`, {
          projectId,
          industryDescription: industryId,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteIndustry(
    id
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}project-industries/${id}`);

      return resolveResponse(res);

    } catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addFundingGoal(
    projectId,
    requiredDate,
    amountFrom,
    amountTo,
    currencyId,
    countryId,
    roiPercentage,
    holdPeriod,
    description,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-funding`, {
          projectId,
          requiredDate,
          amountFrom,
          amountTo,
          currencyId,
          countryId,
          roiPercentage,
          holdPeriod,
          description,
          cityId: 0,
        }
      );

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      ret.data.country = {
        display: getLookupDisplayByValue('countries', ret.data.countryId),
        value: ret.data.countryId,
      };

      ret.data.currency = {
        display: getLookupDisplayByValue('currencies', ret.data.currencyId),
        value: ret.data.currencyId,
      };

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async patchFundingGoal(
    id,
    params,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await patch(
        `${Environment.apiHost}project-funding/${id}`,
        params,
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteFundingGoal(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid ID provided',
          data: null,
        };
      }

      let res = await deleteCall(`${Environment.apiHost}project-funding/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addEmployeeGoal(
    projectId,
    roleId,
    employmentTypeId,
    frequencyId,
    countryId,
    requiredDate,
    description,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-employee`, {
          projectId,
          roleId,
          employmentTypeId,
          frequencyId,
          countryId,
          requiredDate,
          description,
          cityId: 0,
        }
      );

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      ret.data.country = {
        display: getLookupDisplayByValue('countries', ret.data.countryId),
        value: ret.data.countryId,
      };

      ret.data.employmentType = {
        display: getLookupDisplayByValue('employeeEmploymentType', ret.data.employmentTypeId),
        value: ret.data.employmentTypeId,
      };

      ret.data.role = {
        display: getLookupDisplayByValue('employeeRoles', ret.data.roleId),
        value: ret.data.roleId.toString(),
      };

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async patchEmployeeGoal(
    id,
    params,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await patch(
        `${Environment.apiHost}project-employee/${id}`,
        params,
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteEmployeeGoal(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid ID provided',
          data: null,
        };
      }

      let res = await deleteCall(`${Environment.apiHost}project-employee/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addAdviceGoal(
    projectId,
    communicationMethodId,
    frequencyTypeId,
    description,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-advice`, {
          projectId,
          communicationMethodId,
          frequencyTypeId,
          description,
        }
      );

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret)) {
        return ret;
      }

      ret.data.communicationMethod = {
        display: getLookupDisplayByValue('adviceContactMethod', ret.data.communicationMethodId),
        value: ret.data.communicationMethodId,
      };

      ret.data.frequency = {
        display: getLookupDisplayByValue('adviceFrequency', ret.data.frequencyTypeId),
        value: ret.data.frequencyTypeId,
      };

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async patchAdviceGoal(
    id,
    params,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await patch(
        `${Environment.apiHost}project-advice/${id}`,
        params,
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteAdviceGoal(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid ID provided',
          data: null,
        };
      }

      let res = await deleteCall(`${Environment.apiHost}project-advice/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectMedia(
    projectId,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}media?projectId=${projectId}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addCompensationType(
    projectEmployeeId,
    typeDescription,
  ) {

    try {

      let res = await post(
        `${Environment.apiHost}compensation-types`, {
          projectEmployeeId,
          typeDescription,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteCompensationType(
    id,
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}compensation-types/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addProjectAdviceType(
    projectAdviceId,
    typeDescription,
  ) {

    try {

      let res = await post(
        `${Environment.apiHost}project-advice-type`, {
          projectAdviceId,
          typeDescription,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteProjectAdviceType(
    id,
  ) {

    try {

      let res = await deleteCall(`${Environment.apiHost}project-advice-type/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async addSwot(
    projectId,
    swotTypeId,
    description,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      if (!swotTypeId) {

        return {
          ok: false,
          message: 'Invalid type ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}project-swot`, {
          projectId,
          swotTypeId,
          description,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async deleteSwot(
    id,
  ) {

    try {

      if (!id) {

        return {
          ok: false,
          message: 'Invalid ID provided',
          data: null,
        };
      }

      let res = await deleteCall(`${Environment.apiHost}project-swot/${id}`);

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectAdvices(
    projectId,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}project-advice?projectId=${projectId}`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret) && !Array.isArray(ret.data)) {
        return ret;
      }

      let currentItem;
      let newList = [];

      for (let i = 0; i < ret.data.length; i++) {

        currentItem = ret.data[i];
        currentItem.type = GoalTypes.Advice;
        currentItem.display = 'Mentoring goal';

        const cId = currentItem.id;

        let listItem = newList.find(x => x.id === cId);

        if (!listItem) {

          currentItem.communicationMethod = {
            display: getLookupDisplayByValue('adviceContactMethod', currentItem.communicationMethodId),
            value: currentItem.communicationMethodId,
          };

          currentItem.frequency = {
            display: getLookupDisplayByValue('adviceFrequency', currentItem.frequencyTypeId),
            value: currentItem.frequencyTypeId,
          };

          listItem = currentItem;

          listItem.topics = [];

          newList.push(listItem);
        }

        if (Array.isArray(currentItem.project_advice_types)) {

          let adviceType;

          for (let j = 0; j < currentItem.project_advice_types.length; j++) {

            adviceType = currentItem.project_advice_types[j];

            if (!currentItem.topics) {
              currentItem.topics = [];
            }

            currentItem.topics.push({
              display: getLookupDisplayByValue('adviceTopic', adviceType.typeDescription),
              value: adviceType.typeDescription,
              id: adviceType.id,
            });
          }
        }
      }

      ret.data = newList;

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async getProjectEmployees(
    projectId,
  ) {

    try {

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}project-employee?projectId=${projectId}`);

      let ret = resolveResponse(res);

      if (!isValidProviderResult(ret) && !Array.isArray(ret.data)) {
        return ret;
      }

      let currentItem;

      for (let i = 0; i < ret.data.length; i++) {

        currentItem = ret.data[i];
        currentItem.type = GoalTypes.Employee;
        currentItem.display = 'Hiring goal';

        currentItem.country = {
          display: getLookupDisplayByValue('countries', currentItem.countryId),
          value: currentItem.countryId,
        };
  
        currentItem.employmentType = {
          display: getLookupDisplayByValue('employeeEmploymentType', currentItem.employmentTypeId),
          value: currentItem.employmentTypeId,
        };
  
        currentItem.role = {
          display: getLookupDisplayByValue('employeeRoles', currentItem.roleId),
          value: currentItem.roleId.toString(),
        };

        if (Array.isArray(currentItem.compensation_types)) {

          let compensationType;

          for (let j = 0; j < currentItem.compensation_types.length; j++) {

            compensationType = currentItem.compensation_types[j];

            if (!currentItem.compensations) {
              currentItem.compensations = [];
            }

            currentItem.compensations.push({
              display: getLookupDisplayByValue('employeeCompensation', compensationType.typeDescription),
              value: compensationType.typeDescription,
              id: compensationType.id,
            });
          }
        }
      }

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async updateCompleteProject(
    data,
  ) {

    try {

      let projectData = {
        project_advices: [],
        project_employees: [],
        project_fundings: [],
        project_industries: [],
        project_invitations: [],
        project_locations: [],
        project_swots: [],
        project_detail: {},
        ...data,
      };

      if (!projectData.project_type) {
        projectData.project_type = {};
      }

      if (!projectData.project_stage) {
        projectData.project_stage = {};
      }

      if (!projectData.project_detail) {
        projectData.project_detail = {};
      }

      let res = await put(
        `${Environment.apiHost}update-all-project`,
        projectData
      );

      let ret = resolveResponse(res);

      if (!ret.ok) {
        return ret;
      }

      const swotPromises = [];

      for (const swot of projectData.project_swots) {

        if (swot.shouldDelete && swot.id) {
          swotPromises.push(ProjectsProvider.deleteSwot(swot.id));
        }
      }

      await Promise.all(swotPromises);

      const industryPromises = [];

      for (const industry of projectData.project_industries) {

        if (industry.shouldDelete && industry.id) {
          industryPromises.push(ProjectsProvider.deleteIndustry(industry.id));
        }
      }

      await Promise.all(industryPromises);

      const countryPromises = [];

      for (const location of projectData.project_locations) {

        if (location.shouldDelete && location.id) {
          countryPromises.push(ProjectsProvider.deleteCountry(location.id));
        }
      }

      await Promise.all(countryPromises);

      const compensationTypePromises = [];

      for (const employee of projectData.project_employees) {

        if (!Array.isArray(employee.compensation_types)) {
          continue;
        }

        for (const t of employee.compensation_types) {

          if (t.id && t.shouldDelete) {
            compensationTypePromises.push(ProjectsProvider.deleteCompensationType(t.id));
          }
        }
      }

      await Promise.all(compensationTypePromises);

      const adviceTypePromises = [];

      for (const advice of projectData.project_advices) {

        if (!Array.isArray(advice.project_advice_type)) {
          continue;
        }

        for (const t of advice.project_advice_type) {

          if (t.id && t.shouldDelete) {
            adviceTypePromises.push(ProjectsProvider.deleteProjectAdviceType(t.id));
          }
        }
      }

      await Promise.all(adviceTypePromises);

      return ret;
    }
    catch (ex) {

      resolveRejected(ex);
    }
  }

  static async deleteProject(projectId) {

    try {

      let res = await patch(`${Environment.apiHost}projects/${projectId}`, {
        deleted: true,
      });

      return resolveResponse(res);
    }
    catch (ex) {

      resolveRejected(ex);
    }
  }

  static async setMainProject(
    accountId,
    projectId,
  ) {

    try {

      if (!accountId) {

        return {
          ok: false,
          message: 'Invalid account ID provided',
          data: null,
        };
      }

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await patch(
        `${Environment.apiHost}accounts/${accountId}`,{
          mainProject: projectId,
        },
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async searchProject({
    natures = [],
    types = [],
    countries =  [],
    industries = [],
    stages = [],
    goals = [],
    matchProject = null,
    hideViewed = true,
    showSaved = false,
    page = 0,
    pageSize = 10,
    callId,
  }) {

    try {

      let res = await post(
        `${Environment.apiHost}project-search`, {
          pageSize,
          page,
          filters: {
            natures,
            types,
            countries,
            industries, 
            stages,
            goals,
            hideViewed,
            showSaved,
          },
          matchProject,
        },
      );

      let ret = resolveResponse(res);

      ret.callId = callId;

      if (!isValidProviderResult(ret) || !Array.isArray(ret.data.cards)) {
        return ret;
      }

      ret.data.matchesCount = ret.data.matchesCount || {};
      ret.data.matchesCount.isProjectMatch = !!matchProject;

      for (let val of ret.data.cards) {

        val.profileRating = Math.floor((val.profileScore || 0) / 20);  // profilScore is out of 100, profileRating out of 5, thus val.profileScore / 10 / 2 = val.profileScore / 20

        val.country = getLookupDisplayByValue('countries', val.countryId);

        val.type = getLookupDisplayByValue('projectTypes', val.type);

        val.stage = getLookupDisplayByValue('projectStages', val.stage && val.stage.stageDescription);

        val.industries = val.industries || [];

        for (let industry of val.industries) {

          if (!industry) {
            continue;
          }

          industry.display = getLookupDisplayByValue('projectIndustries', industry.industryDescription);
        }

        val.locations = val.locations || [];

        for (let location of val.locations) {

          if (!location) {
            continue;
          }

          location.display = getLookupDisplayByValue('countries', location.countryId);
        }

        val.project_advices = val.project_advices || [];
        val.project_employees = val.project_employees || [];
        val.project_fundings = val.project_fundings || [];

        val.project_advices.forEach((element) => {
          element.type = GoalTypes.Advice;
          element.frequency = getLookupDisplayByValue('adviceFrequency', element.frequencyTypeId);
        });

        val.project_employees.forEach((element) => {
          element.type = GoalTypes.Employee;
          element.position = getLookupDisplayByValue('employeeRoles', element.roleId);
          element.contractType = getLookupDisplayByValue('employeeEmploymentType', element.employmentTypeId);
          element.country = getLookupDisplayByValue('countries', element.countryId);
        });

        val.project_fundings.forEach((element) => {
          element.type = GoalTypes.Funding;
          element.currency = getLookupDisplayByValue('currencies', element.currencyId);
        });

        val.goals = [
          ...val.project_advices,
          ...val.project_employees,
          ...val.project_fundings,
        ];

        val.goals.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });

        val.linkedAccounts.sort((a, b) => {
          return a.linkedAccountsLookupId - b.linkedAccountsLookupId;
        });
      }

      return ret;
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async saveProject(
    userId,
    projectId,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}stored-projects`, {
          reason: '',
          statusId: 2,
          accountStoreTypeId: 2,
          projectId,
          userId,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async hideProject(
    userId,
    projectId,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}stored-projects`, {
          reason: '',
          statusId: 2,
          accountStoreTypeId: 1,
          projectId,
          userId,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async reportProject(
    userId,
    projectId,
    reason,
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}stored-projects`, {
          reason,
          statusId: 2,
          accountStoreTypeId: 3,
          projectId,
          userId,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }

  static async projectViewed(
    userId,
    projectId
  ) {

    try {

      if (!userId) {

        return {
          ok: false,
          message: 'Invalid user ID provided',
          data: null,
        };
      }

      if (!projectId) {

        return {
          ok: false,
          message: 'Invalid project ID provided',
          data: null,
        };
      }

      let res = await post(
        `${Environment.apiHost}stored-projects`, {
          reason: '',
          statusId: 2,
          accountStoreTypeId: 6,
          projectId,
          userId,
        }
      );

      return resolveResponse(res);
    }
    catch (ex) {

      return resolveRejected(ex);
    }
  }
}