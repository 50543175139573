import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  MenuItems,
  Image,
  Images,
  SafeAreas,
  SuccessToast,
  ErrorToast,
  Logger,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  Space,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Popup,
  Loader,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  ChatBubbleSvg,
  DashboardSvg,
  OfficeBuildingSvg,
  PeopleConnectionSvg,
  UserSvg,
} from 'common/svg';

import {
  MediaProvider,
  SupportProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.White};
  box-shadow: 0 0 5px 0 ${Colors.Gray5};
  position: fixed;
  bottom: ${SafeAreas.bottom};
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1;
  
  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    height: auto;
    box-shadow: 0 5px 10px 0 ${Colors.Gray5};
    position: relative;
    width: 60px;
  }
`;

const Menu = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  bottom: ${SafeAreas.bottom};
  left: 0;
  width: 100%;
  height: 50px;

  @media ${DeviceMediaQueries.Tablet} {
    flex-direction: column;
    top: 60px;
    height: auto;
    width: 60px;
  }
`;

const MenuSection = styled.div`
  display: flex;
  width: ${props => props.mobileWidth || '33%'};
  justify-content: ${props => props.justifyContent || 'space-between'};

  @media ${DeviceMediaQueries.Tablet} {
    flex-direction: column;
    height: 33%;
    width: auto;
  }
`;

const ShowDesktop = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: block;
  }
`;

const ShowMobile = styled.div`
  display: flex;
  align-items: center;

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

const SupportContainer = styled.div`
  padding: 15px;
  overflow: auto;
  flex: 1;
`;

const UploadInput = styled.input`
  display: none;
`;

const AttachmentItem = styled.div`
  background-color: ${Colors.Gray1};
  color: ${Colors.Black};
  padding: 8px 15px;
  border-radius: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  ailgn-items: center;
`;

const SideMenuComponent = props => {

  const uploadInput = React.createRef();
  const [showSupportPopup, showSupportPopupSetState] = React.useState(false);
  const supportSubject = React.useRef('General query');
  const supportDescription = React.useRef('');
  const [showSupportButtonLoading, showSupportButtonLoadingSetState] = React.useState(false);

  const [supportValidation, supportValidationSetState] = React.useState({
    subjectMessage: '',
    descriptionMessage: '',
  });

  const attachments = React.useRef([]);
  const [attachmentComponents, attachmentComponentsSetState] = React.useState([]);

  const onAccountClick = () => {
    
    if (props.history.location.pathname === '/account/view') {
      return;
    }

    props.history.push('/account/view');
  };

  const onProjectsClick = () => {

    if (props.history.location.pathname === '/projects/view') {
      return;
    }

    props.history.push('/projects/view');
  };

  const onSupportClick = () => {

    showSupportPopupSetState(true);
  };

  const onContactsClick = () => {

    if (props.history.location.pathname === '/contacts/chat') {
      return;
    }

    props.history.push('/contacts/chat');
  };

  const onMatchesClick = () => {

    if (props.history.location.pathname === '/matches/view') {
      return;
    }

    props.history.push('/matches/view');
  };

  const onActionUrlClick = () => {

    if (!props.actionUrl) {
      return;
    }

    props.history.push(props.actionUrl);
  };

  const onSubjectChange = (val) => {

    supportSubject.current = val.value;
  };

  const onDescriptionChange = (val) => {
    
    supportDescription.current = val;

    if (supportValidation.descriptionMessage) {

      supportValidationSetState(prevState => ({
        ...prevState,
        descriptionMessage: '',
      }));
    }
  };

  const onSupportTicketSubmit = () => {

    let errors = {
      descriptionMessage: '',
    };

    if (!supportDescription.current.trim()) {
      errors.descriptionMessage = 'Please provide an issue description';
    }

    if (errors.descriptionMessage) {

      supportValidationSetState(errors);
      return;
    }

    showSupportButtonLoadingSetState(true);

    SupportProvider.createTicket(
      supportSubject.current,
      supportDescription.current.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      window.location.href,
      attachments.current || [],
    )
    .then(res => {

      showSupportButtonLoadingSetState(false);

      if (!isValidProviderResult(res)) {

        ErrorToast.show();
        Logger.error(res);
        return;
      }

      supportSubject.current = 'General query';
      supportDescription.current = '';
      attachments.current = [];
      attachmentComponentsSetState([]);

      showSupportPopupSetState(false);
      SuccessToast.show('', 'Your support query was successfully captured.');
    })
    .catch(e => {

      showSupportButtonLoadingSetState(false);
      ErrorToast.show();
      Logger.error(e);
    });
  };

  const addAttachment = () => {

    if (Array.isArray(attachments.current) && attachments.current.filter(a => a.status !== 'failed').length >= 3) {

      ErrorToast.show('Attachment limit reached', 'Only 3 attachments allowed when creating a support query.');
      return;
    }

    uploadInput
    && uploadInput.current
    && typeof uploadInput.current.click === 'function'
    && uploadInput.current.click();
  };

  const onAttachmentChange = (e) => {

    if (!e || !e.target || !e.target.files) {
      return;
    }

    let currentFile;

    for (let i = 0; i < e.target.files.length; i++) {

      currentFile = e.target.files[i];

      if (currentFile.size > 10 * 1024 * 1024) {

        ErrorToast.show('File too big', 'Please make sure that your file is not bigger than 10MB.');
        continue;
      }

      const file = {
        id: new Date().getTime(),
        filename: currentFile.name,
        status: 'uploading'
      };

      attachments.current.push(file);

      renderAttachments();

      MediaProvider.uploadEmailAttachment(currentFile, file.id)
        .then(ret => {

          let att = attachments.current.find(a => a.id === ret.data.fileId);

          if (isValidProviderResult(ret)) {

            att.status = 'success';
            att.path = ret.data.url;
            renderAttachments();
            return;
          }

          att.status = 'failed';

          if (ret.message === 'Unsupported file type.') {

            ErrorToast.show('File type not supported', 'Please make sure to upload one of the following formats: pdf, doc, docx, jpg, png');
          }
          else {
            ErrorToast.show();
          }

          renderAttachments();
        })
        .catch(er => {

          ErrorToast.show();
          Logger.error(er);
        });
    }
  };

  const onRemoveAttachment = (fileId) => {

    attachments.current = attachments.current.filter((a) => a.id !== fileId);
    renderAttachments();
  };

  const renderAttachments = () => {

    if (!attachments.current || !attachments.current.length) {

      attachmentComponentsSetState([]);
      return;
    }

    let components = [];

    for (let i = 0; i < attachments.current.length; i++) {

      if (attachments.current[i].status === 'failed') {
        continue;
      }

      components.push(
        <AttachmentItem key={`${attachments.current[i].id}-${i}`}>

          <Text fontSize={FontSizes.Smaller} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>{attachments.current[i].filename}</Text>
        
          {attachments.current[i].status === 'uploading' && <Loader size={20} margin={'0'} backgroundColor={Colors.Gray1}/>}

          {attachments.current[i].status === 'success' && <Text fontSize={FontSizes.Smaller} onClick={() => onRemoveAttachment(attachments.current[i].id)}>X</Text>}

        </AttachmentItem>
      );
    }

    attachmentComponentsSetState(components);
  };

  return (
    <>

    <Container>

      <Menu>

        <MenuSection
          mobileWidth={'20%'}>

          <DashboardSvg
            id={'btn-help-desk'}
            fill={Colors.Gray4}
            selectedFill={Colors.White}
            showBackground={true}
            margin={'10px 0 0 0'}
            onClick={onSupportClick}
          />

        </MenuSection>

        <MenuSection
          mobileWidth={'45%'}>

          <OfficeBuildingSvg
            fill={Colors.Gray4}
            selectedFill={Colors.White}
            showBackground={true}
            padding={'10px'}
            selected={props.selectedItem === MenuItems.Projects}
            selectedBackgroundColor={Colors.Blue3}
            onClick={onProjectsClick}
          />

          <ChatBubbleSvg
            fill={Colors.Gray4}
            selectedFill={Colors.White}
            showBackground={true}
            padding={'10px'}
            selected={props.selectedItem === MenuItems.Contacts}
            selectedBackgroundColor={Colors.Orange2}
            onClick={onContactsClick}
          />

          <PeopleConnectionSvg
            fill={Colors.Gray4}
            selectedFill={Colors.White}
            showBackground={true}
            padding={'10px'}
            selected={props.selectedItem === MenuItems.Matches}
            selectedBackgroundColor={Colors.Green3}
            onClick={onMatchesClick}
          />
        
        </MenuSection>

        <MenuSection
          mobileWidth={'20%'}
          justifyContent={'flex-end'}>

          <ShowDesktop>

            <UserSvg
              fill={Colors.Gray4}
              selectedFill={Colors.White}
              showBackground={true}
              padding={'10px'}
              margin={'0 0 10px 0'}
              selected={props.selectedItem === MenuItems.Account}
              selectedBackgroundColor={Colors.Red3}
              onClick={onAccountClick}
            />

          </ShowDesktop>

          <ShowMobile>

            <Image
              height={'40px'}
              width={'40px'}
              src={Images.Icons.CircleCircle}
              onClick={onActionUrlClick}
            />

          </ShowMobile>

        </MenuSection>

      </Menu>

    </Container>

    { showSupportPopup &&

      <Popup
        headerBackgroundColor={Colors.Gray8}
        header={'How can we help you?'}
        desktopWidth={'400px'}
        onClose={() => { showSupportPopupSetState(false); }}>

        <SupportContainer>

          <Text
            fontSize={FontSizes.Small}
            padding={'0 0 10px 10px'}>

              Tell us about your experience so far…
          </Text>

          <Select
            width={'100%'}
            placeholder={'Select issue type...'}
            options={[
              {
                display: 'General query',
                value: 'General query',
              },
              {
                display: 'Reporting an issue',
                value: 'Reporting an issue',
              },
              {
                display: 'Suggesting a change',
                value: 'Suggesting a change',
              },
              {
                display: 'New feature request',
                value: 'New feature request',
              },
              {
                display: 'Other',
                value: 'Other',
              }
            ]}
            defaultValue={supportSubject.current}
            onChange={onSubjectChange}
          />

          <TextInput
            placeholder={`Type your note here (files can be attached).
Please be as detailed as possible.
We will contact you if necessary.
Thank you for using AfriOn.`}
            multiline={true}
            showCharCounter={true}
            charCounterMax={2500}
            width={'100%'}
            height={'200px'}
            onChange={onDescriptionChange}
            defaultValue={supportDescription.current}
            hasError={!!supportValidation.descriptionMessage}
            errorMessage={supportValidation.descriptionMessage}
          />

          <div style={{flex: 1}}/>

          <div>
            {attachmentComponents}
          </div>

        </SupportContainer>

        <PopupFooter
          footerBackgroundColor={Colors.Gray8}>

          <PopupActionContainer>

            <PopupActionButtonContainer>

              <Button
                onClick={addAttachment}
                text={'Attach media'}
                width={'100%'}
              />

            </PopupActionButtonContainer>

            <Space/>

            <PopupActionButtonContainer>

              <Button
                text={'Send'}
                width={'100%'}
                showLoading={showSupportButtonLoading}
                onClick={onSupportTicketSubmit}
              />

            </PopupActionButtonContainer>

          </PopupActionContainer>

        </PopupFooter>

      </Popup>
    }

    <UploadInput
      ref={uploadInput}
      type={'file'}
      accept={'image/png,image/jpeg,application/pdf,.doc,.docx'}
      onChange={onAttachmentChange}
    />

    </>
  );
};

export const SideMenu = withRouter(SideMenuComponent);