import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const PlusSvg = props => {

  const fill = props.fill || Colors.Black;

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
        
        <Path fill={fill} d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/>
        
      </Svg>
          
    </SvgContainer>
  );
};