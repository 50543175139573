import
  React
from 'react';

export const withPromiseCleanup = (WrappedComponent, options = {}) => {

  return props => {

    const isMounted = () => {
      return WrappedComponent.isMounted;
    };

    const withCleanup = (callback) => {

      if (!WrappedComponent.isMounted) {
        return;
      }

      typeof callback === 'function' && callback();
    };

    React.useEffect(() => {

      WrappedComponent.isMounted = true;

      return () => {

        WrappedComponent.isMounted = false;
      };
    }, []);

    return (

      <WrappedComponent
        history={props.history}
        isMounted={isMounted}
        withCleanup={withCleanup}
      />
    );
  };
};