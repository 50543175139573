import
  React
from 'react';

import {
  Environment,
  MenuItems,
  Logger,
} from 'common';

import {
  H2,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountScreen,
} from 'ui/account/components';

import {
  HtmlContent,
} from './accountStyled';

class AccountTermsAndConditionsUI extends React.Component {

  state = {
    html: null,
  };

  componentDidMount() {

    this.props.common.showLoading();

    fetch(`${Environment.assetBase}/html/terms-and-conditions.html`)
      .then(response => response.text())
      .then(html => {

        this.props.common.hideLoading();
        
        this.setState({
          html
        });
      })
      .catch(e => {

        this.props.common.hideLoading();

        Logger.error(e);

        this.setState({
          html: 'We were unable to load the terms and conditions, please try again.',
        });
      });
  }

  render() {

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}>

        <H2>
          Terms and Conditions
        </H2>

        <HtmlContent
          dangerouslySetInnerHTML={{ __html: this.state.html }}
        />
    
      </AccountScreen>
    );
  }
}

export const AccountTermsAndConditions = withCommon(
  AccountTermsAndConditionsUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);
