import
  React
from 'react';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const UserSvg = props => {

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <Path d="M37,2A35,35,0,1,1,2,37,35,35,0,0,1,37,2m0-2A37,37,0,1,0,74,37,37,37,0,0,0,37,0Z"/>
        
        <circle fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2px" cx="37.5" cy="29" r="8"/>
        
        <Path fill="none" stroke="#000" strokeWidth="2px" strokeLinecap="round" strokeLinejoin="round" d="M33,36a10.51,10.51,0,0,0,9,0,12.24,12.24,0,0,1,6,7,11.84,11.84,0,0,1,0,7,19.84,19.84,0,0,1-21,0,11.84,11.84,0,0,1,0-7A12.24,12.24,0,0,1,33,36Z"/>
        
      </Svg>
    
    </SvgContainer>
  );
};