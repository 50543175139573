import
  React
from 'react';

import {
  Colors,
  DataStore,
  MenuItems,
  Logger,
  Messages,
  FontSizes,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Card,
  H2,
  PSmall,
  Text,
  Popup,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountScreen,
} from 'ui/account/components';

import {
  DeactivateActionContainer,
} from './accountStyled';

import {
  IdentityProvider,
} from 'providers';

class AccountDeactivateUI extends React.Component {

  state =  {
    showSuccess: false,
    showError: false,
    showConfirm: false,
  };

  onDeactivateClick = () => {

    if (!this.props.common.user.email) {

      this.setState({
        showError: true,
        showConfirm: false,
      });

      return;
    }

    this.props.common.showLoading();

    this.setState({
      showConfirm: false,
    });

    IdentityProvider.requestDeactivateAccount(
      this.props.common.user.email,
    )
    .then(ret => {

      this.props.common.hideLoading();

      if (!isValidProviderResult(ret)) {

        Logger.error(ret.message || Messages.TechnicalError);

        this.setState({
          showError: true,
        });

        return;
      }

      this.setState({
        showSuccess: true,
        showError: false,
      });

      DataStore.clear('ACCESS_TOKEN');

      setTimeout(() => {
        this.props.common.navigate('/');
      }, 6000);
    })
    .catch(e => {

      this.props.common.hideLoading();

      Logger.error(e);

      this.setState({
        showError: true,
      });
    });
  };

  render() {

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}>

        <H2>Deactivate Account</H2>

        { !this.state.showSuccess && !this.state.showError &&

          <Card
            padding={'15px'}
            flexDirection={'column'}>

            <PSmall
              fontWeight={'bold'}
              padding={'0 0 15px 0'}>

                This can be temporary
            </PSmall>

            <PSmall
              padding={'0 0 15px 0'}>

                Your account will be disabled and your name and information will be removed from most things you've shared.
            </PSmall>

            <PSmall
              padding={'0 0 15px 0'}>

                Your account will be re-activated once you login again.
            </PSmall>

            <DeactivateActionContainer>

              <Button
                text={'Deactivate'}
                width={'100%'}
                onClick={() => this.setState({ showConfirm: true })}
              />

            </DeactivateActionContainer>

          </Card>
        }

        { this.state.showSuccess && !this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'10px 30px 20px 30px'}
            alignItems={'center'}>

              <H2>
                Account deactivated
              </H2>

              <PSmall
                textAlign={'center'}>
                  Your account has been deactivated, you will be logged out in 6 seconds.
              </PSmall>

          </Card>
        }

        { this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'20px'}
            alignItems={'center'}>
              
              <H2
                margin={'0 0 20px 0'}>

                  Oops...
              </H2>

              <PSmall
                textAlign={'center'}>

                  Unfortunately an error occurred and we were unable to send you the email, please try again later.
              </PSmall>

          </Card>
        }

        { this.state.showConfirm &&
        
          <Popup
            maxWidth={'500px'}
            header={'Are you sure?'}
            headerBackgroundColor={Colors.Red3}
            onClose={() => this.setState({ showConfirm: false })}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 120px 30px'}
              flex={'1'}>

                Are you sure you want to deactivate your account?
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Red3}>

              <PopupActionContainer>

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'NO'}
                    onClick={() => this.setState({ showConfirm: false })}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'YES'}
                    onClick={this.onDeactivateClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

      </AccountScreen>
    );
  }
}

export const AccountDeactivate = withCommon(
  AccountDeactivateUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);