import
  React
from 'react';

import {
  Colors,
  MenuItems,
  ChatTabOptions,
  Logger,
  Invites,
  Space,
  Image,
  Images,
  IsMobile,
  ErrorToast,
  SuccessToast,
  isValidProviderResult,
} from 'common';

import {
  withCommon,
} from 'common/hocs';

import {
  Container,
  SideMenuContainer,
  ContentContainer,
  ConversationsContainer,
  NoItemContainer,
  BackContainer,
} from 'ui/contacts/contactsStyled';

import {
  ChatContact,
  ChatOptions,
} from 'ui/contacts/components';

import {
  MatchesProfileDetails,
} from 'ui/matches/components';

import {
  ContactsProvider,
} from 'providers';

class ContactsInvitesUI extends React.Component {

  state = {
    inviteList: [],
    selectedContactProfile: null,
  };

  onViewProfileClick = (invitation) => {

    this.props.common.navigate('#invite_selected');

    this.setState({
      selectedContactProfile: {
        projectId: invitation.requestedByProjectId,
        invitation: invitation,
      },
    });
  };

  onAcceptInviteClick = (invitation) => {

    this.props.common.showLoading({
      showSideMenu: true,
    });

    ContactsProvider.acceptInvite(invitation.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message);

          this.props.common.hideLoading();

          return;
        }

        this.load()
          .then(() => {

            SuccessToast.show('', 'Your contact was successfully added');

            this.setState({
              selectedContactProfile: null,
            });

            this.props.common.hideLoading();
          })
          .catch(e => {

            Logger.error(e);

            ErrorToast.show();

            this.setState({
              selectedContactProfile: null,
            });

            this.props.common.hideLoading();
          });
      })
      .catch(ex => {

        ErrorToast.show();

        Logger.error(ex);

        this.props.common.hideLoading();
      });
  };

  onRejectInviteClick = (invitation) => {

    this.props.common.showLoading({
      showSideMenu: true,
    });

    ContactsProvider.rejectInvite(invitation.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message);

          this.props.common.hideLoading();

          return;
        }

        this.load()
          .then(() => {

            this.setState({
              selectedContactProfile: null,
            });

            this.props.common.hideLoading();
          })
          .catch(e => {

            Logger.error(e);

            ErrorToast.show();

            this.setState({
              selectedContactProfile: null,
            });

            this.props.common.hideLoading();
          });
      })
      .catch(ex => {

        ErrorToast.show();

        Logger.error(ex);
      });
  };

  createInvitationComponents = (invitations) => {

    if (!invitations || !invitations.length) {
      return [];
    }

    let components = [];

    for (const invitation of invitations) {

      invitation.account = invitation.requestedByAccount || {};
      invitation.project = invitation.project || {};

      components.push(
        <ChatContact
          key={invitation.id}
          profileImage={invitation.account.profilePictureUrl}
          projectName={invitation.project.title}
          contactPerson={`${invitation.account.name} ${invitation.account.surname}`}
          hideNotificationContainer={true}
          hideOnlineIndicator={true}
          hideApprovalContainer={false}
          onClick={() => this.onViewProfileClick(invitation)}
          onAcceptInviteClick={() => this.onAcceptInviteClick(invitation)}
          onRejectInviteClick={() => this.onRejectInviteClick(invitation)}
        />
      );
    }

    return components;
  };

  load = async () => {

    let ret = await ContactsProvider.getInviteList();

    if (!isValidProviderResult(ret)) {

      Logger.error(ret.message);

      ErrorToast.show();
      return;
    }

    Invites.refresh();

    this.setState({
      inviteList: this.createInvitationComponents(ret.data),
    });
  };

  componentDidMount() {

    this.props.common.showLoading({
      showSideMenu: true,
    });

    this.load()
      .then(() => {
        this.props.common.hideLoading();
      })
      .catch(e => {

        Logger.error(e);

        ErrorToast.show();

        this.props.common.hideLoading();
      });
  }

  componentDidUpdate() {

    if(this.props.common.history.location.hash === '' && !!this.state.selectedContactProfile) {

      this.setState({
        selectedContactProfile: null,
      });
    }
  }

  render() {

    return (
      <Container>

        { (!IsMobile || !this.state.selectedContactProfile) &&

          <SideMenuContainer>

            <ChatOptions
              selectedTab={ChatTabOptions.Invites}
            />

            <ConversationsContainer>

              { Array.isArray(this.state.inviteList) && !!this.state.inviteList.length
              
                ? this.state.inviteList

                : <NoItemContainer>No invites to display</NoItemContainer>
              }

            </ConversationsContainer>

          </SideMenuContainer>
        }

        { !!this.state.selectedContactProfile &&

          <ContentContainer>

            <MatchesProfileDetails
              common={this.props.common}
              color={Colors.Orange2}
              headerColor={Colors.Orange2}
              colorSvg={Colors.Orange1}
              selectedContactProfile={this.state.selectedContactProfile}
              onAcceptInviteClick={this.onAcceptInviteClick}
              fromContacts={true}
            />

            <Space/>

            <BackContainer>

              <Image
                src={Images.Icons.Back}
                width={'35px'}
                height={'35px'}
                onClick={() => this.props.common.history.goBack()}
              />

            </BackContainer>

          </ContentContainer>
        }

      </Container>
    );
  }
}

export const ContactsInvites = withCommon(
  ContactsInvitesUI, {
    selectedMenuItem: MenuItems.Contacts,
  }
);