import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  DeviceMediaQueries,
  Image,
  Images,
} from 'common';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 15px 50px 15px 0;
  }
`;

export const LinkedAccountsBar = props => {

  const d = props.data;

  if (!d.linkedAccountsLookupId) {
    return <></>;
  }

  return (

    <Container>

      {d.linkedAccountsLookupId === 1 && <Image height={'42px'} src={Images.Icons.TwitterDark}/>}
      {d.linkedAccountsLookupId === 2 && <Image height={'42px'} src={Images.Icons.FacebookDark}/>}
      {d.linkedAccountsLookupId === 3 && <Image height={'42px'} src={Images.Icons.InstagramDark}/>}
      {d.linkedAccountsLookupId === 4 && <Image height={'42px'} src={Images.Icons.LinkedInDark}/>}
      {d.linkedAccountsLookupId === 5 && <Image height={'42px'} src={Images.Icons.WebsiteDark}/>}    

    </Container>
  );
};