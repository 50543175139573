import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
} from 'common';

const Container = styled.div`
  background-color: ${props => props.isOn ? Colors.White : Colors.Gray4};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${props => props.isOn ? 'flex-end' : 'flex-start'};
  border: 2px solid ${props => props.isOn ? Colors.White : Colors.Gray4};
  border-radius: 9px;
  height: 18px;
  width: 45px;
`;

const Switch = styled.div`
  background-color: ${Colors.Green3};
  border-radius: 7px;
  height: 14px;
  width: 14px;
`;

export const Toggle = props => {

  const [isOn, isOnSetState] = React.useState(!!props.isOn);

  const onClick = () => {

    const newState = !isOn;

    isOnSetState(newState);
    typeof props.onChange === 'function' && props.onChange(newState);
  };

  return (

    <Container
      isOn={isOn}
      onClick={onClick}>
      
      <Switch/>

    </Container>
  );
};