import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  Images,
  Image,
  Logger,
  ErrorToast,
  SuccessToast,
  isValidProviderResult,
  addColorTransparency,
} from 'common';

import {
  Button,
  Loader,
  Popup,
  Text,
} from 'common/components';


import {
  ContactsProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${props => props.isSelected ? addColorTransparency(Colors.Orange1, 0.6) : Colors.Orange1};
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 8px 0 12px;
  margin: 0 0 3px 0;
  position: relative;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  overflow: visible;

  ${props => props.onClick && `cursor: pointer;`}
`;

const ProfileImage = styled.div`
  background-image: url(${props => props.image || Images.Icons.ProfilePlaceholder});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  height: 60px;
  min-width: 60px;
  width: 60px;
  position: relative;
  overflow: hidden;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  overflow: visible;
  width: 10%;
`;

const EllipsisText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30px;
  width: 30px;
  height: 55px;
`;

const ConversationUnreadMessagesCount = styled.div`
  color: ${Colors.Orange1};
  background-color: ${Colors.White};
  font-size: ${FontSizes.Smallest};
  right: 15px;
  border-radius: 10px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 3px;
  margin: 1px 0 0 0;
`;

const OnlineIndicatorContainer = styled.div`
  position: absolute;
  left: 2px;
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;
`;

const OnlineIndicatorOuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.color};
  border-radius: 10px;
  height: 20px;
  width: 20px;
`;

const OnlineIndicatorInnerCircle = styled.div`
  background-color: ${props => props.color};
  border-radius: 6px;
  height: 12px;
  width: 12px;
`;

const ApprovalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;

const ApprovalImage = styled.div`
  background-image: url(${props => props.image});
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${Colors.White};
  border-radius: 20px;

  :hover {
    background-color: ${Colors.Orange2};
  }
`;

const MoreOptionsContainer = styled.div`
  background-color: ${Colors.White};
  border-radius: 8px;
  position: absolute;
  top: 35px;
  right: 10px;
  width: 200px;
  padding: 5px;
  box-shadow: 0 0 5px 0 ${Colors.Gray8};
`;

const MoreOptionsOption = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  padding: 0 5px;

  :hover {
    background-color: ${Colors.Gray1};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.Orange1};
  padding: 15px;
`;

const OnlineIndicator = props => (

  <OnlineIndicatorContainer>

    <OnlineIndicatorOuterCircle
      color={props.isOnline ? Colors.Green4 : Colors.Gray7}>

      <OnlineIndicatorInnerCircle
        color={props.isOnline ? Colors.Green4 : Colors.Gray7}
      />

    </OnlineIndicatorOuterCircle>

  </OnlineIndicatorContainer>
);

export const ChatContact = ({
  conversation,
  profileImage,
  projectName,
  contactPerson,
  lastMessage,
  totalUnreadMessages,
  isOnline,
  isSelected = false,
  hideNotificationContainer = false,
  hideOnlineIndicator = false,
  hideApprovalContainer = true,
  lastMessageTime = '',
  onClick = () => {},
  onAcceptInviteClick = () => {},
  onRejectInviteClick = () => {},
}) => {

  const [showMoreOptions, showMoreOptionsSetState] = React.useState(false);
  const [showConfirmPopup, showConfirmPopupSetState] = React.useState(false);
  const [showLoading, showLoadingSetState] = React.useState(false);

  const actionType = React.useRef('');

  const onOutsideClick = () => {

    showMoreOptionsSetState(false);
    document.removeEventListener('click', onOutsideClick)
  };

  const onMoreOptionsClick = (e) => {

    e.stopPropagation();
    showMoreOptionsSetState(true);

    document.addEventListener('click', onOutsideClick)
  };

  const onAcceptClick = (e) => {

    e.stopPropagation();

    actionType.current = 'ACCEPT';

    showConfirmPopupSetState(true);
  }

  const onDeclineClick = (e) => {

    e.stopPropagation();

    actionType.current = 'REJECT';

    showConfirmPopupSetState(true);
  }

  const onConfirmActionClick = (e) => {

    if (!actionType.current) {
      return;
    }

    e.stopPropagation();
    showConfirmPopupSetState(false);

    if (actionType.current === 'ACCEPT') {

      onAcceptInviteClick();
      return;
    }

    if (actionType.current === 'REJECT') {

      onRejectInviteClick();
      return;
    }

    if (actionType.current === 'DELETE') {

      deleteProject();
      return;
    }
  };

  const onDeleteProjectClick = (e) => {

    e.stopPropagation();

    actionType.current = 'DELETE';

    showConfirmPopupSetState(true);
  }

  const deleteProject = (e) => {

    showLoadingSetState(true);

    ContactsProvider
      .removeConversation(
        conversation.sid,
        conversation.attributes && conversation.attributes.inviteId
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message);

          ErrorToast.show();

          showLoadingSetState(false);

          return;
        }

        SuccessToast.show('', 'Project successfully deleted');
      })
      .catch(e => {

        Logger.error(e);

        ErrorToast.show();

        showLoadingSetState(false);
      });

    showMoreOptionsSetState(false);
  };

  if (showLoading) {

    return (

      <Container>

        <LoadingContainer>

          <Loader
            backgroundColor={Colors.Orange1}
            spinnerColor={Colors.White}
          />

        </LoadingContainer>

      </Container>
    );
  }

  const messageLastTime = (() => {

    if (!lastMessageTime) {
      return '';
    }

    let currentDate = new Date();

    let mm = lastMessageTime.getMonth() + 1; // getMonth() is zero-based
    let dd = lastMessageTime.getDate();
    let yyyy = lastMessageTime.getFullYear();
    let hh = lastMessageTime.getHours();
    let min = lastMessageTime.getMinutes();
    let dateString;

    if (currentDate.getFullYear() === yyyy && ((currentDate.getMonth() + 1) !== mm || currentDate.getDate() !== dd)) {
        dateString = `${(dd > 9 ? '' : '0') + dd}/${(mm > 9 ? '' : '0') + mm}`;
    }

    if (currentDate.getFullYear() !== yyyy) {
      dateString = `${(dd > 9 ? '' : '0') + dd}/${(mm > 9 ? '' : '0') + mm}/${yyyy}`;
    }

    dateString = `${dateString || ''} ${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}`;

    return dateString;

  })();

  return (

    <Container
      onClick={onClick}
      isSelected={isSelected}>

      <ProfileImage
        image={profileImage}
      />

      { !hideOnlineIndicator &&

        <OnlineIndicator
          isOnline={isOnline}
        />
      }

      <TextContainer>

        <EllipsisText
          color={Colors.White}
          fontSize={FontSizes.Small}
          fontWeight={'bold'}
          lineHeight={'1'}
          padding={'0 0 2px 0'}>

          {projectName || 'Unknown project'}

        </EllipsisText>

        <EllipsisText
          color={Colors.White}
          fontSize={FontSizes.Smaller}
          padding={'2px 0 0 0'}>

          {contactPerson || 'Unknown contact'}

        </EllipsisText>

        { !!lastMessage &&

          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'visible',
            marginRight: '-35px',
          }}>

            <EllipsisText
              color={Colors.White}
              fontSize={FontSizes.Smallest}
              fontStyle={'italic'}
              padding={'3px 0 0 0'}>

              {lastMessage}

            </EllipsisText>

            <Text
              color={Colors.White}
              fontSize={FontSizes.Smallest}
              padding={'3px 0 0 5px'}
              whiteSpace={'nowrap'}>

                {messageLastTime || ''}
            </Text>

          </div>
        }

      </TextContainer>

      { !hideNotificationContainer &&

        <NotificationContainer>

          <Image
            src={Images.Icons.MoreOptions}
            width={'70%'}
            height={'auto'}
            onClick={onMoreOptionsClick}
          />

          { !!totalUnreadMessages &&

            <ConversationUnreadMessagesCount>{totalUnreadMessages}</ConversationUnreadMessagesCount>
          }

        </NotificationContainer>
      }

      { !hideApprovalContainer &&
      
        <ApprovalContainer>

          <ApprovalImage
            image={Images.Icons.ChatApprove}
            onClick={onAcceptClick}
          />

          <ApprovalImage
            image={Images.Icons.ChatIgnore}
            onClick={onDeclineClick}
          />

        </ApprovalContainer>
      }

      { showMoreOptions &&

        <MoreOptionsContainer>

          <MoreOptionsOption
            onClick={onDeleteProjectClick}>

              Delete match
          </MoreOptionsOption>

        </MoreOptionsContainer>
      }

      { showConfirmPopup &&
      
        <Popup
          header={'Confirm action'}
          headerBackgroundColor={Colors.Orange1}
          maxWidth={'500px'}
          onClose={(e) => { e.stopPropagation(); showConfirmPopupSetState(false); }}
          onClick={(e) => e.stopPropagation()}>

            <Text
              fontSize={FontSizes.Small}
              padding={'30px 30px 120px 30px'}
              flex={'1'}>

                { actionType.current === 'ACCEPT'
                    ? 'Are you sure you want to accept this invitation?'
                    : actionType.current === 'REJECT'
                      ? 'Are you sure you want to decline this invitation?'
                      : actionType.current === 'DELETE'
                        ? 'Are you sure you want to delete this match?'
                        : 'Are you sure?'
                }

            </Text>

            <ButtonContainer>

              <Button
                text={'Cancel'}
                height={'40px'}
                width={'48%'}
                onClick={(e) => { e.stopPropagation(); showConfirmPopupSetState(false); }}
              />

              <Button
                text={'Confirm'}
                height={'40px'}
                width={'48%'}
                onClick={onConfirmActionClick}
              />

            </ButtonContainer>

        </Popup>
      }

    </Container>
  );
};