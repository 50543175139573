let envConfig;

let env = (window.origin && window.origin.endsWith('.') && ((window.origin.includes('qa-app') && 'android_qa') || 'android_prod'))
  || (navigator && navigator.userAgent === 'capacitor - ios - qa' && 'ios_qa')
  || (navigator && navigator.userAgent === 'capacitor - ios' && 'ios_prod')
  || (window.origin.includes('qa-app') && 'qa')
  || process.env.NODE_ENV;

if (env === 'production') {

  envConfig = {
    channel: 'web',
    name: 'Production',
    uiHost: 'https://app.afrion.com/',
    apiHost: 'https://backend-app.afrion.com/',
    configHost: 'https://afrion-config.s3.eu-west-1.amazonaws.com/',
    assetBase: '/assets',
  };
}

if (env === 'ios_prod') {

  envConfig = {
    channel: 'ios',
    name: 'IOS Production',
    uiHost: 'https://app.afrion.com/',
    apiHost: 'https://backend-app.afrion.com/',
    configHost: 'https://afrion-config.s3.eu-west-1.amazonaws.com/',
    assetBase: '/assets',
  };
}

if (env === 'ios_qa') {

  envConfig = {
    channel: 'ios',
    name: 'IOS QA',
    uiHost: 'https://qa-app.afrion.com/',
    apiHost: 'https://qa-backend-app.afrion.com/',
    configHost: 'https://qa-app.afrion.com/',
    assetBase: '/assets',
  };
}

if (env === 'android_prod') {

  envConfig = {
    channel: 'android',
    name: 'Android Prod',
    uiHost: 'https://app.afrion.com/',
    apiHost: 'https://backend-app.afrion.com/',
    configHost: 'https://afrion-config.s3.eu-west-1.amazonaws.com/',
    assetBase: '/assets',
  };
}

if (env === 'android_qa') {

  envConfig = {
    channel: 'android',
    name: 'Android QA',
    uiHost: 'https://qa-app.afrion.com/',
    apiHost: '/api/',
    configHost: '/',
    assetBase: '/assets',
  };
}

if (env === 'qa') {

  envConfig = {
    channel: 'web',
    name: 'QA',
    uiHost: 'https://qa-app.afrion.com/',
    apiHost: '/api/',
    configHost: '/',
    assetBase: '/assets',
  };
}

if (env === 'development') {

  envConfig = {
    channel: 'web',
    name: 'Development',
    uiHost: 'http://localhost:3000/',
    apiHost: 'https://qa-backend-app.afrion.com/',
    configHost: '/',
    assetBase: '/assets',
  };
}

export const Environment = envConfig;