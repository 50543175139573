import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
  deleteCall,
} from 'providers/axios';

export class ContactsProvider {

  static async getInviteList() {

    try {

      let res = await get(`${Environment.apiHost}project-invitations`);

      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async getInvitationCount() {

    try {

      let res = await get(`${Environment.apiHost}project-invitations?$limit=0`);

      let ret = resolveResponse(res);

      if (!ret.ok) {
        return ret;
      }

      return {
        ok: true,
        data: {
          invitationCount: res.data.total
        }
      };
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async acceptInvite(inviteId) {

    try {

      let res = await get(`${Environment.apiHost}invitation-management/${inviteId}`);

      return resolveResponse(res);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }

  static async rejectInvite(inviteId) {

    try {

      let res = await deleteCall(`${Environment.apiHost}invitation-management/${inviteId}`);

      return resolveResponse(res);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }

  static async getShareRequestCount() {

    try {

      let res = await get(`${Environment.apiHost}project-share-request?$limit=0`);

      let ret = resolveResponse(res);

      if (!ret.ok) {
        return ret;
      }

      return {
        ok: true,
        data: {
          requestCount: res.data.total
        }
      };
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async getShareRequestList() {

    try {

      let res = await get(`${Environment.apiHost}project-share-request`);

      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }

  static async acceptShareRequest(inviteId) {

    try {

      let res = await get(`${Environment.apiHost}shared-request-management/${inviteId}`);

      return resolveResponse(res);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }

  static async rejectShareRequest(inviteId) {

    try {

      let res = await deleteCall(`${Environment.apiHost}shared-request-management/${inviteId}`);

      return resolveResponse(res);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }

  static async removeConversation(conversationId, inviteId) {

    try {

      let res = await deleteCall(`${Environment.apiHost}conversation-management/${inviteId}?conversationId=${conversationId}`);

      return resolveResponse(res);
    }
    catch (ex) {
      return resolveRejected(ex);
    }
  }
}