import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  Logger,
  Messages,
  MenuItems,
  isValidProviderResult,
} from 'common';

import {
  AccountsSideMenu
} from 'ui/account/components';

import {
  withCommon,
} from 'common/hocs';

import {
  UserProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.Red3};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`;

const AccountMobileMenuUI = props => {

  const [score, scoreSetState] = React.useState('Loading score...');

  const load = () => {

    const user = DataStore.get('LOGGED_IN_USER') || {};

    UserProvider.getProfileScore(user.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          scoreSetState('Unable to load score.');

          return;
        }

        DataStore.set('PROFILE_SCORE', ret.data.profileScore);

        scoreSetState(ret.data.profileScore);
      })
      .catch(e => {

        Logger.error(e);

        scoreSetState('Unable to load score.');
      });
  };

  React.useEffect(
    load,
    [],
  );

  return (

    <Container>

      <AccountsSideMenu
        profileScore={score}
        onShowIframePopup={props.common.showIframePopup}
      />

    </Container>
  );
};

export const AccountMobileMenu = withCommon(
  AccountMobileMenuUI, {
    selectedMenuItem: MenuItems.Account,
  }
);
