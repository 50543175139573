import
  React
from 'react';

import {
  Colors,
  TextInputContainer,
  formatDateForDisplay,
  numberWithCommas,
} from 'common';

import {
  Accordion,
  PSmallWhite,
} from 'common/components';

import {
  FormContainer,
  TextContainer,
} from './projectsComponentsStyled';

export const FundingForm = ({
  f,
  color,
  padding,
}) => (

  <FormContainer
    padding={padding}>

    <TextInputContainer
      padding={'0 0 15px 0'}>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

          Maximum capital amount
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {numberWithCommas(f.amountTo) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

          Minimum capital amount
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {numberWithCommas(f.amountFrom) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Currency
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(f.currency && f.currency.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Country
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(f.country && f.country.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

    <TextInputContainer
      padding={'0 0 15px 0'}>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Transaction date
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {formatDateForDisplay(f.requiredDate) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            ROI per year
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(f.roiPercentage && `${f.roiPercentage}% per year`) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Hold Period (in years)
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(f.holdPeriod && `${f.holdPeriod} year(s)`) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer/>

    </TextInputContainer>

    <TextInputContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

          Further Information
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {f.description || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

  </FormContainer>
);

export const FundingGoalDisplay = ({
  project,
  padding,
  color = Colors.Black,
  showHeader = true,
}) => {

  const fundings = project.project_fundings || [];

  const cards = fundings.map((f, i) => {

    return (

      <React.Fragment key={i}>

      { showHeader &&

        <Accordion
          key={i}
          header={'Funding goal'}
          headerColor={Colors.White}
          backgroundColor={Colors.Blue3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}
          isCollapsable={true}
          showArrow={true}>
            
            <FundingForm
              f={f}
              color={color}
              padding={padding}
            />

        </Accordion>
      }

      { !showHeader &&
      
        <FundingForm
          key={i}
          f={f}
          color={color}
          padding={padding}
        />
      }

      </React.Fragment>
    );
  });

  return (

    <React.Fragment>

      {cards}

    </React.Fragment>
  );
};