import
  React
from 'react';

import {
  Colors,
  DataStore,
  ErrorToast,
  FontSizes,
  MenuItems,
  Logger,
  Messages,
  Space,
  Images,
  Image,
  MobileButtonContainer,
  RoundButton,
  isValidProviderResult,
} from 'common';

import {
  Accordion,
  Button,
  FileUpload,
  MultiAddButton,
  Popup,
  RouteLeavingGuard,
  Text,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  ProjectDetailsForm,
  ProjectInformationForm,
  AdviceGoalForm,
  EmployeeGoalForm,
  FundingGoalForm,
  SwotAnalysisForm,
} from 'ui/projects/components';

import {
  withProjectsSideMenu,
} from 'ui/projects/hocs';

import  {
  ActionContainer,
  ActionButtonContainer,
  EvenlySpacedContainer,
  ContentContainer,
  GoalFormContainer,
  MobileSpace,
  DesktopSpace,
} from './projectsStyled';

import {
  ProjectsProvider,
} from 'providers';

class ProjectsEditUI extends React.Component {

  state = {
    adviceGoalForms: [],
    fundingGoalForms: [],
    employeeGoalForms: [],
    showProjectDetails: false,
    showFundingGoalPopup: false,
    showAdviceGoalPopup: false,
    showEmployeeGoalPopup: false,
  };

  project = DataStore.get('current_project');
  projectInformationFormObj = {};
  swotValidate = () => {};
  hasUnsavedData = false;

  onCancelClick = () => {
    this.props.common.navigate('/projects/view');
  };

  onSaveClick = () => {

    const isProjectInfoValid = this.projectInformationFormObj.validate();
    const isProjectDetailsValid = !this.state.showProjectDetails || this.project.project_detail.validate();
    const isSwotValid = !this.state.showProjectDetails || this.swotValidate();

    if (!isProjectInfoValid || !isProjectDetailsValid || !isSwotValid) {

      ErrorToast.show(
        'Invalid field values',
        'Some fields have invalid values, please review all forms and try again.'
      );

      return;
    }

    if ((!this.project.project_employees || !this.project.project_employees.length)
      && (!this.project.project_advices || !this.project.project_advices.length)
      && (!this.project.project_fundings || !this.project.project_fundings.length)) {

      ErrorToast.show(
        'Missing project goals',
        'Please add at least one goal before you save this project'
      );

      return;
    }

    this.projectInformationFormObj.userId = this.props.common.user.id;

    this.project = {
      ...this.project,
      ...this.projectInformationFormObj,
    };

    this.updateDataStore();

    this.saveChanges();
  };

  onShowAdviceGoalPopupClick = () => {

    if (this.state.adviceGoalForms && this.state.adviceGoalForms.length >= 3) {

      ErrorToast.show('Mentoring goal limit reached', Messages.GoalAddError);
      return;
    }

    this.setState({
      showAdviceGoalPopup: true,
    });
  };

  onShowEmployeeGoalPopupClick = () => {

    if (this.state.employeeGoalForms && this.state.employeeGoalForms.length >= 3) {

      ErrorToast.show('Hiring goal limit reached', Messages.GoalAddError);
      return;
    }

    this.setState({
      showEmployeeGoalPopup: true,
    });
  };

  onShowFundingGoalPopupClick = (goal) => {

    if (this.state.fundingGoalForms && this.state.fundingGoalForms.length >= 3) {

      ErrorToast.show('Funding goal limit reached', Messages.GoalAddError);
      return;
    }

    this.setState({
      showFundingGoalPopup: true,
    });
  };

  onEmployeeGoalClose = (goal) => {

    let stateObj = {
      showEmployeeGoalPopup: false,
    };

    if (goal) {

      this.project.project_employees = this.project.project_employees || [];

      goal.createdAt = new Date();

      this.project.project_employees.push(goal);

      this.updateDataStore();

      stateObj.employeeGoalForms = this.createEmployeeGoalForms();
    }

    this.setState(stateObj);
  };

  onFundingGoalClose = (goal) => {

    let stateObj = {
      showFundingGoalPopup: false,
    };

    if (goal) {

      this.project.project_fundings = this.project.project_fundings || [];

      goal.createdAt = new Date();

      this.project.project_fundings.push(goal);

      this.updateDataStore();

      stateObj.fundingGoalForms = this.createFundingGoalForms();
    }

    this.setState(stateObj);
  };

  onAdviceGoalClose = (goal) => {

    let stateObj = {
      showAdviceGoalPopup: false,
    };

    if (goal) {

      this.project.project_advices = this.project.project_advices || [];

      goal.createdAt = new Date();

      this.project.project_advices.push(goal);

      this.updateDataStore();

      stateObj.adviceGoalForms = this.createAdviceGoalForms();
    }

    this.setState(stateObj);
  };

  onDeleteFundingGoal = (index) => {

    this.project.project_fundings.splice(index, 1);

    this.updateDataStore();

    this.setState({
      fundingGoalForms: [],
    });

    setTimeout(() => {

      this.setState({
        fundingGoalForms: this.createFundingGoalForms(),
      });
    }, 0);
  };

  onDeleteEmployeeGoal = (index) => {

    this.project.project_employees.splice(index, 1);

    this.updateDataStore();

    this.setState({
      employeeGoalForms: [],
    });

    setTimeout(() => {

      this.setState({
        employeeGoalForms: this.createEmployeeGoalForms(),
      });
    }, 0);
  };

  onDeleteAdviceGoal = (index) => {

    this.project.project_advices.splice(index, 1);

    this.updateDataStore();

    this.setState({
      adviceGoalForms: [],
    });

    setTimeout(() => {

      this.setState({
        adviceGoalForms: this.createAdviceGoalForms(),
      });
    }, 0);
  };

  onFundingGoalFormBlur = (i, values) => {

    this.project.project_fundings[i] = values;
    this.updateDataStore();
  };

  onAdviceGoalFormBlur = (i, values) => {

    this.project.project_advices[i] = values;
    this.updateDataStore();
  };

  onEmployeeGoalFormBlur = (i, values) => {

    this.project.project_employees[i] = values;
    this.updateDataStore();
  };

  onProjectInformationChange = (infoObj, isOnLoad) => {

    this.project = {
      ...this.project,
      ...infoObj,
    };

    this.updateDataStore(!isOnLoad);

    this.projectInformationFormObj = infoObj;

    const showDetails = !!(infoObj.nature && infoObj.nature.value === 2); // show details when proposition is selected

    if (this.state.showProjectDetails !== showDetails) {

      this.setState({
        showProjectDetails: showDetails,
      });
    }
  };

  onProjectDetailsFormChange = (detailsObj, isOnLoad) => {

    this.project.project_detail = detailsObj;
    this.project.project_detail.projectId = this.project.id;
    this.updateDataStore(!isOnLoad);
  };

  onSwotAnalysisFormChange = (list) =>  {

    this.project.project_swots = list;
    this.updateDataStore();
  };

  onSwotAnalysisFormLoad = (val) => {
    this.swotValidate = val;
  };

  updateDataStore = (shouldChange = true) => {

    // this might seem odd, but the logic is sould, trust me, don't change it
    if (shouldChange) {
      this.hasUnsavedData = true;
    }

    DataStore.set(
      'current_project',
      this.project,
    );
  };

  saveChanges = async () => {

    try {

      this.props.common.showLoading();

      let ret = await ProjectsProvider.updateCompleteProject(
        this.project
      );

      if (!isValidProviderResult(ret)) {

        ErrorToast.show();

        Logger.error(ret.message || Messages.TechnicalError);

        this.props.common.hideLoading();
        return;
      }

      this.hasUnsavedData = false;

      this.props.common.navigate('/projects/view');
    }
    catch(e) {

      ErrorToast.show();

      Logger.error(e);

      this.props.common.hideLoading();
    }
  };

  createAdviceGoalForms = () => {

    if (!Array.isArray(this.project.project_advices) || !this.project.project_advices.length) {
      return [];
    }

    let forms = [];

    for (let i = 0; i < this.project.project_advices.length; i++) {

      forms.push({
        date: this.project.project_advices[i].createdAt
          ? new Date(this.project.project_advices[i].createdAt)
          : new Date(),
        component: (
          <Accordion
            key={`advice-${i}`}
            header={'Mentoring goal'}
            headerColor={Colors.White}
            backgroundColor={Colors.Blue3}
            borderRadius={'15px'}
            headerPadding={'5px 15px'}
            contentPadding={'15px'}
            contentBackgroundColor={Colors.White}
            isCollapsable={true}
            showArrow={true}>

            <AdviceGoalForm
              project={this.project}
              advice={this.project.project_advices[i]}
              hideFooter={true}
              saveOnBlur={true}
              allowDelete={true}
              onFieldBlur={(values) => this.onAdviceGoalFormBlur(i, values)}
              onDelete={() => this.onDeleteAdviceGoal(i)}
            />

          </Accordion>
        )
      });
    }

    return forms;
  };

  createFundingGoalForms = () => {

    if (!Array.isArray(this.project.project_fundings) || !this.project.project_fundings.length) {
      return [];
    }

    let forms = [];

    for (let i = 0; i < this.project.project_fundings.length; i++) {

      forms.push({
        date: this.project.project_fundings[i].createdAt
          ? new Date(this.project.project_fundings[i].createdAt)
          : new Date(),
        component: (
          <Accordion
            key={`funding-${i}`}
            header={'Funding goal'}
            headerColor={Colors.White}
            backgroundColor={Colors.Blue3}
            borderRadius={'15px'}
            headerPadding={'5px 15px'}
            contentPadding={'15px'}
            contentBackgroundColor={Colors.White}
            isCollapsable={true}
            showArrow={true}>

            <FundingGoalForm
              project={this.project}
              funding={this.project.project_fundings[i]}
              hideFooter={true}
              allowDelete={true}
              saveOnBlur={true}
              onFieldBlur={(values) => this.onFundingGoalFormBlur(i, values)}
              onDelete={() => this.onDeleteFundingGoal(i)}
            />

          </Accordion>
        )
      });
    }

    return forms;
  };

  createEmployeeGoalForms = () => {

    if (!Array.isArray(this.project.project_employees) || !this.project.project_employees.length) {
      return [];
    }

    let forms = [];

    for (let i = 0; i < this.project.project_employees.length; i++) {

      forms.push({
        date: this.project.project_employees[i].createdAt
          ? new Date(this.project.project_employees[i].createdAt)
          : new Date(),
        component: (
          <Accordion
            key={`employee-${i}`}
            header={'Hiring goal'}
            headerColor={Colors.White}
            backgroundColor={Colors.Blue3}
            borderRadius={'15px'}
            headerPadding={'5px 15px'}
            contentPadding={'15px'}
            contentBackgroundColor={Colors.White}
            isCollapsable={true}
            showArrow={true}>

            <EmployeeGoalForm
              project={this.project}
              employee={this.project.project_employees[i]}
              hideFooter={true}
              saveOnBlur={true}
              allowDelete={true}
              onFieldBlur={(values) => this.onEmployeeGoalFormBlur(i, values)}
              onDelete={() => this.onDeleteEmployeeGoal(i)}
            />

          </Accordion>
        )
      });
    }

    return forms;
  };

  shouldBlockNavigation = () => {
    return this.hasUnsavedData;
  };

  load = async () => {

    if (!this.project) {

      this.props.common.showLoading();

      let ret = await ProjectsProvider.createProject(this.props.common.user.id);

      if (!isValidProviderResult(ret) && ret.message === 'PROJECT_LIMIT_REACHED') {

        ErrorToast.show(Messages.ProjectLimitHeader, Messages.ProjectLimitBody);

        this.props.common.history.goBack();
        return;
      }

      if (!isValidProviderResult(ret)) {

        ErrorToast.show();

        Logger.error(ret.message || Messages.TechnicalError);

        return;
      }

      this.project = ret.data;

      DataStore.set(
        'current_project',
        ret.data
      );

      let projects = DataStore.get('USER_PROJECTS') || [];

      projects.push(ret.data);

      DataStore.set(
        'USER_PROJECTS',
        projects,
      );

      this.props.common.hideLoading();
    }

    const adviceGoalForms = this.createAdviceGoalForms();
    const fundingGoalForms = this.createFundingGoalForms();
    const employeeGoalForms = this.createEmployeeGoalForms();

    if (document.documentElement) {
      document.documentElement.scrollTop = 0;
    }

    this.setState({
      adviceGoalForms,
      fundingGoalForms,
      employeeGoalForms,
    });
  };

  componentDidMount() {
    this.load();
  }

  render () {

    let goalForms = [
      ...this.state.adviceGoalForms,
      ...this.state.fundingGoalForms,
      ...this.state.employeeGoalForms,
    ];

    goalForms.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    let goalFormComponents = goalForms.map(gf => {
      return gf.component;
    });

    return (

      <React.Fragment>

        <RouteLeavingGuard
          when={true}
          navigate={path => this.props.common.navigate(path)}
          shouldBlockNavigation={this.shouldBlockNavigation}
        /> 

        <ActionContainer>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Cancel'}
              onClick={this.onCancelClick}
            />

          </ActionButtonContainer>

          <Space/>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Save changes'}
              onClick={this.onSaveClick}
            />

          </ActionButtonContainer>

        </ActionContainer>

        <DesktopSpace/>

        <Accordion
          header={'Project information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Blue3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

            <ContentContainer>

              <ProjectInformationForm
                project={this.project}
                onFormLoad={this.onProjectInformationChange}
                onFormChange={this.onProjectInformationChange}
              />

            </ContentContainer>

        </Accordion>

        <Space
          size={'25px'}
        />

        <Accordion
          id={'accordion-project-set-goal'}
          header={'Set a goal'}
          headerColor={Colors.White}
          backgroundColor={Colors.Blue3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

            <ContentContainer>

              <Space/>

              <EvenlySpacedContainer>

                <MultiAddButton
                  id={'popup-project-add-funding-goal'}
                  counter={this.project && Array.isArray(this.project.project_fundings) && this.project.project_fundings.length}
                  text={'Funding goal'}
                  onClick={this.onShowFundingGoalPopupClick}
                />

                <MultiAddButton
                  id={'popup-project-add-advice-goal'}
                  counter={this.project && Array.isArray(this.project.project_advices) && this.project.project_advices.length}
                  text={'Mentoring goal'}
                  onClick={this.onShowAdviceGoalPopupClick}
                />

                <MultiAddButton
                  id={'popup-project-add-employee-goal'}
                  counter={this.project && Array.isArray(this.project.project_employees) && this.project.project_employees.length}
                  text={'Hiring goal'}
                  onClick={this.onShowEmployeeGoalPopupClick}
                />

              </EvenlySpacedContainer>

              { !goalFormComponents.length

                ? <Text
                    color={Colors.Black}
                    fontSize={FontSizes.Small}
                    padding={'25px 0 0 0'}>

                      No goals added.  Please select a goal type above that you like to add.
                  </Text>

                : <GoalFormContainer>

                    <Space/>

                    {goalFormComponents}

                  </GoalFormContainer>
              }

          </ContentContainer>

        </Accordion>

        { this.state.showProjectDetails &&

          <React.Fragment>

            <Space
              size={'25px'}
            />

            <Accordion
              header={'Project details'}
              headerColor={Colors.White}
              backgroundColor={Colors.Blue3}
              borderRadius={'15px'}
              headerPadding={'5px 15px'}
              contentPadding={'15px'}
              contentBackgroundColor={Colors.White}>

              <ContentContainer>

                <ProjectDetailsForm
                  project={this.project}
                  onFormLoad={this.onProjectDetailsFormChange}
                  onFormChange={this.onProjectDetailsFormChange}
                />

                <SwotAnalysisForm
                  project={this.project}
                  onLoad={this.onSwotAnalysisFormLoad}
                  onChange={this.onSwotAnalysisFormChange}
                />

                <FileUpload
                  id={'file-project-details'}
                  project={this.project}
                  maxUpload={4}
                  onLoaded={() => this.forceUpdate()}
                />

              </ContentContainer>

            </Accordion>

          </React.Fragment>
        }

        <ActionContainer
          padding={'20px 0 0 0'}>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Cancel'}
              onClick={this.onCancelClick}
            />

          </ActionButtonContainer>

          <Space/>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Save changes'}
              onClick={this.onSaveClick}
            />

          </ActionButtonContainer>

        </ActionContainer>

        <MobileButtonContainer>

          <RoundButton
            onClick={this.onCancelClick}>

            <Image
              src={Images.Icons.EditCancelX}
              height={'60px'}
              width={'60px'}
            />

          </RoundButton>

          <Space />

          <RoundButton
            onClick={this.onSaveClick}>

            <Image
              src={Images.Icons.EditSaveTick}
              height={'60px'}
              width={'60px'}
            />

          </RoundButton>

        </MobileButtonContainer>

        <MobileSpace/>

        { this.state.showFundingGoalPopup &&

          <Popup
            id={'popup-project-add-funding-goal'}
            header={'Add funding goal'}
            headerBackgroundColor={Colors.Blue3}
            onClose={() => this.onFundingGoalClose()}>

            <FundingGoalForm
              labelColor={Colors.Gray9}
              project={this.project}
              onClose={this.onFundingGoalClose}
            />

          </Popup>
        }

        { this.state.showAdviceGoalPopup &&

          <Popup
            id={'popup-project-add-advice-goal'}
            header={'Add mentoring goal'}
            headerBackgroundColor={Colors.Blue3}
            footerBackgroundColor={Colors.Blue3}
            onClose={() => this.onAdviceGoalClose()}>

            <AdviceGoalForm
              labelColor={Colors.Gray9}
              project={this.project}
              onClose={this.onAdviceGoalClose}
            />

          </Popup>
        }

        { this.state.showEmployeeGoalPopup &&

          <Popup
            id={'popup-project-add-employee-goal'}
            header={'Add hiring goal'}
            headerBackgroundColor={Colors.Blue3}
            onClose={() => this.onEmployeeGoalClose()}>

            <EmployeeGoalForm
              labelColor={Colors.Gray9}
              project={this.project}
              onClose={this.onEmployeeGoalClose}
            />

          </Popup>
        }

      </React.Fragment>
    );
  }
}

export const ProjectsEdit = withCommon(
  withProjectsSideMenu(
    ProjectsEditUI, {
      loadProjects: false,
      showOnlySelected: true,
    }), {
    selectedMenuItem: MenuItems.Projects,
    sideMenuActionUrl: '/projects/view',
  }
);