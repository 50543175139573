import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const GoalFundingSvg = props => {

  const fill = props.fill || Colors.Green2;

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <circle fill={fill} cx="37" cy="37" r="37"/>
        
        <Path fill={Colors.White} d="M35.17,55.85V52.43a20.39,20.39,0,0,1-11.53-5.11l3.42-4.08a16.33,16.33,0,0,0,8.11,4.08V38.23c-3.53-.85-6.12-1.95-7.76-3.33A7.51,7.51,0,0,1,25,28.79a8.57,8.57,0,0,1,2.84-6.64,11.53,11.53,0,0,1,7.38-2.81V17h3v2.39a19.65,19.65,0,0,1,9.75,3.51l-3.05,4.32a15.05,15.05,0,0,0-6.7-2.72v8.81h.14q5.38,1.26,7.92,3.42A7.75,7.75,0,0,1,48.76,43a8.5,8.5,0,0,1-2.91,6.68,12,12,0,0,1-7.68,2.81v3.38ZM31.87,25.6a3.35,3.35,0,0,0-1.2,2.62,3.3,3.3,0,0,0,.94,2.49,9.42,9.42,0,0,0,3.56,1.75v-8.1A5.55,5.55,0,0,0,31.87,25.6Zm9.86,20.55A3.62,3.62,0,0,0,42,40.83,10.36,10.36,0,0,0,38.17,39v8.44A6.59,6.59,0,0,0,41.73,46.15Z"/>
        
      </Svg>

    </SvgContainer>

  );
};