import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Images,
  Image,
  SafeAreas,
  addColorTransparency,
} from 'common';

import {
  Button,
  Text,
} from 'common/components';

const Container = styled.div`
  background-color: ${addColorTransparency(Colors.Black, 0.6)};
  position: fixed;
  top: ${SafeAreas.top};
  left: 0;
  right: 0;
  bottom: ${SafeAreas.bottom};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  left: 10px;
  right: 10px;
  bottom: 0px;

  @media ${DeviceMediaQueries.Tablet} {
    top: 20px;
    left: 50px;
    right: 50px;
    bottom: 20px;
  }
`;

const ImageContainer = styled.div`
  background-color: ${Colors.Black};
  display: flex;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
`;

const IFrame = styled.iframe`
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
`;

const DocumentUploadContainer = styled.div`
  background-color: ${Colors.Black};
  border: 1px solid ${Colors.Gray2};
  display: flex;
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${DeviceMediaQueries.Tablet} {
    width: 500px;
  }
`;

const CloseContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  min-height: 35px;
`;

const ChatImagePreviewComponent = props => {

  const [showButtonLoading, showButtonLoadingSetState] = React.useState(false);

  const onKeyDown = (event) => {

    // we need to hide this when escape is pressed, a requirement
    if(event.keyCode === 27) {
      typeof props.onClose === 'function' && props.onClose();
    }
  }

  const onSendClick = (e) => {

    e.stopPropagation();

    props.onSend();
    showButtonLoadingSetState(true);
  };

  const onDownloadClick = (e) => {

    e.stopPropagation();

    props.onDownload();
    showButtonLoadingSetState(true);
  };

  let component = '';

  if (props.type === 'application/msword'
    || props.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {

    // Nested conditions :( I'm sorry
    if (props.onDownload) {

      let docUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(props.imageUrl)}`;

      component = <IFrame onClick={(e) => e.stopPropagation()} title='Document viewer' src={docUrl}>Loading document...</IFrame>;
    }

    if (!props.onDownload) {

      component = (
        <DocumentUploadContainer
          onClick={(e) => e.stopPropagation()}>
          
          <Image
            height={'160px'}
            src={Images.Icons.DocPlaceholder}
          />

          <Text
            color={Colors.White}
            fontSize={FontSizes.Small}
            textAlign={'center'}
            padding={'0 20px'}>

              {props.file.name}
          </Text>

        </DocumentUploadContainer>
      );
    }
  }

  if (props.type === 'application/pdf') {

    component = props.onDownload
      ? <IFrame onClick={(e) => e.stopPropagation()} src={`https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(props.imageUrl)}&embedded=true`}>Loading document...</IFrame>
      : (
          <DocumentUploadContainer
            onClick={(e) => e.stopPropagation()}>
            
            <Image
              height={'160px'}
              src={Images.Icons.PdfPlaceholder}
            />

            <Text
              color={Colors.White}
              fontSize={FontSizes.Small}
              textAlign={'center'}
              padding={'0 20px'}>

                {props.file.name}
            </Text>

          </DocumentUploadContainer>
        );
  }

  if (component === '') {

    component = (
      <ImageContainer
        src={props.imageUrl}
        onClick={(e) => e.stopPropagation()}
      />
    );
  }

  React.useEffect(() => {

    if (props.location.hash !== '#conversation_selected?preview=true') {
      props.onClose && props.onClose();
    }

  }, [props, props.onClose, props.location])

  React.useEffect(() => {

    document.addEventListener('keydown', onKeyDown, false);

    return () => {

      if (window.location.hash === '#conversation_selected?preview=true') {
        props.history.goBack();
      }

      document.removeEventListener('keydown', onKeyDown, false);
    };

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (

    <Container
      onClick={() => props.onClose && props.onClose()}>

      <ContentContainer>

        <CloseContainer>

          <Image
            height={'20px'}
            src={Images.Icons.Close}
            onClick={() => props.onClose && props.onClose()}
          />

        </CloseContainer>

        { component || <Text color={Colors.White}>Unable to load preview</Text>}

        { props.onSend &&

          <ButtonContainer>

            <Button
              text={'Send'}
              width={'150px'}
              onClick={onSendClick}
              showLoading={showButtonLoading}
            />

          </ButtonContainer>
        }

        { props.onDownload &&

          <ButtonContainer>

            <Button
              text={'Download'}
              width={'150px'}
              onClick={onDownloadClick}
              showLoading={showButtonLoading}
            />

          </ButtonContainer>
        }

      </ContentContainer>

    </Container>
  );
};

export const ChatImagePreview = withRouter(ChatImagePreviewComponent);