import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Image,
  Images,
  SafeAreas,
} from 'common';

import {
  Text,
} from 'common/components/text';

const Container = styled.div`
  position: fixed;
  margin: ${SafeAreas.top} auto 0 auto;
  top: ${props => props.show ? '20px' : '-100px'};
  opacity: ${props => props.show ? '1' : '0'};
  width: 100%;
  padding: 0 15px;

  display: flex;
  justify-content: center;
  z-index: 99999;

  transition: all 1s ease-in-out;
  overflow: hidden;
`;

const ToastContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  box-shadow: 0 0 5px 0 ${Colors.Gray5};
  width: 100%;
  padding: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${DeviceMediaQueries.Tablet} {
    max-width: 800px;
    width: 60%;
  }
`;

const ToastContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Toast = props => {

  let bgColor = Colors.Blue3;

  if (props.type === 'success') {
    bgColor = Colors.Green2;
  }

  if (props.type === 'error') {
    bgColor = Colors.Red2;
  }

  React.useEffect(() => {

    if (props.show) {

      setTimeout(() => {

        props.show && props.onClose && props.onClose();

      }, 8000);
    }
  }, [props]);

  return (

    <Container
      show={props.show}>

      <ToastContainer
        backgroundColor={bgColor}>
          
          <ToastContentContainer>
            
            <Text
              color={Colors.White}
              fontSize={FontSizes.Regular}
              fontWeight={'bold'}
              padding={'0 0 5px 0'}>

                {props.heading}
            </Text>

            <Text
              color={Colors.White}
              fontSize={FontSizes.Small}>

                {props.subHeading}
            </Text>

          </ToastContentContainer>

          <Image
            height={'20px'}
            src={Images.Icons.Close}
            onClick={() => { props.onClose && props.onClose(); }}
          />

      </ToastContainer>

    </Container>
  );
};