import {
  DataStore
} from './data/dataStore';

import {
  Environment,
} from './environment';

import {
  addColorTransparency,
} from './utils';

export const Colors = {
  PrimaryOne: '#388CBA',
  PrimaryTwo: '#EDC899',
  PrimaryThree: '#9FC490',
  PrimaryFour: '#FFA7A7',
  Blue1: '#14133F',
  Blue2: '#378AB9',
  Blue2Transparent: addColorTransparency('#378AB9', 0.75),
  Blue3: '#65A3C7',
  Green1: '#0E4629',
  Green2: '#1D9658',
  Green2Transparent: addColorTransparency('#1D9658', 0.75),
  Green3: '#52AC82',
  Green4: '#0ABE53',
  Red1: '#BD2E30',
  Red2: '#F13B3C',
  Red2Transparent: addColorTransparency('#F13B3C', 0.75),
  Red3: '#CC6264',
  Gold: '#F89F29',
  Gray1: '#F0F0F0',
  Gray2: '#E0E0E0',
  Gray3: '#D0D0D0',
  Gray4: '#BEBEBE',
  Gray5: '#A9A9A9',
  Gray6: '#989898',
  Gray7: '#787878',
  Gray8: '#606060',
  Gray9: '#303030',
  Orange1: '#DD8229',
  Orange2: '#E6A15E',
  Brown: '#60441F',
  Black: '#000000',
  White: '#ffffff',
  Red: '#BF2933',
  Green: '#297E51',
  Tan: '#EDC899',
  TanTransparent: addColorTransparency('#EDC899', 0.5),
  Transparent: addColorTransparency('#000000', 0),
};

export const FontSizes = {
  Tiny: '8px',
  Smallest: '9.6px',
  Smaller: '12.8px',
  Small: '16px',
  Regular: '19.2px',
  Big: '24px',
  Bigger: '28.8px',
  Biggest: '35.2px',
  Huge: '40px'
};

export const Images = {
  Backgrounds: {
    Login: '',
  },
  Icons: {
    AdviceGoal: `${Environment.assetBase}/images/icons/advice-goal.svg`,
    ArrowRight: `${Environment.assetBase}/images/icons/arrow-right.svg`,
    ArrowThinRight: `${Environment.assetBase}/images/icons/arrow-thin-right.svg`,
    ArrowThinRightBlack: `${Environment.assetBase}/images/icons/arrow-thin-right-black.svg`,
    Back: `${Environment.assetBase}/images/icons/back.svg`,
    CircleCircle: `${Environment.assetBase}/images/icons/circle-circle.svg`,
    CircleTick: `${Environment.assetBase}/images/icons/circle-tick.svg`,
    ChatAccount: `${Environment.assetBase}/images/icons/chat-account.svg`,
    ChatAddAction: `${Environment.assetBase}/images/icons/chat-add-action.svg`,
    ChatApprove: `${Environment.assetBase}/images/icons/chat-approve.svg`,
    ChatCancel: `${Environment.assetBase}/images/icons/chat-cancel.svg`,
    ChatIgnore: `${Environment.assetBase}/images/icons/chat-ignore.svg`,
    ChatMedia: `${Environment.assetBase}/images/icons/chat-media.svg`,
    ChatMic: `${Environment.assetBase}/images/icons/chat-mic.svg`,
    ChatRecord: `${Environment.assetBase}/images/icons/chat-record.svg`,
    ChatSend: `${Environment.assetBase}/images/icons/chat-send.svg`,
    Close: `${Environment.assetBase}/images/icons/close.svg`,
    DocPlaceholder: `${Environment.assetBase}/images/icons/doc-placeholder.svg`,
    EditCancelX: `${Environment.assetBase}/images/icons/edit-cancel-x.svg`, 
    EditPencil: `${Environment.assetBase}/images/icons/edit-pencil.svg`,
    EditSaveTick: `${Environment.assetBase}/images/icons/edit-save-tick.svg`,
    EmailSent: `${Environment.assetBase}/images/icons/email-sent.svg`,
    EmployeeGoal: `${Environment.assetBase}/images/icons/employee-goal.svg`,
    FacebookDark: `${Environment.assetBase}/images/icons/facebook-dark.svg`,
    FacebookLight: `${Environment.assetBase}/images/icons/facebook-light.svg`,
    FillArrow: `${Environment.assetBase}/images/icons/fill-arrow.svg`,
    FundingGoal: `${Environment.assetBase}/images/icons/funding-goal.svg`,
    HideUserGreenCircle: `${Environment.assetBase}/images/icons/hide-user-green-circle.svg`,
    InformationIcon: `${Environment.assetBase}/images/icons/information-icon.svg`,
    InstagramDark: `${Environment.assetBase}/images/icons/instagram-dark.svg`,
    InstagramLight: `${Environment.assetBase}/images/icons/instagram-light.svg`,
    LinkedInDark: `${Environment.uiHost}assets/images/icons/linkedin-dark.svg`,
    LinkedInLight: `${Environment.uiHost}assets/images/icons/linkedin-light.svg`,
    MatchCV: `${Environment.assetBase}/images/icons/match-cv.svg`,
    MatchInvite: `${Environment.assetBase}/images/icons/match-invite.svg`,
    MoreOptions: `${Environment.assetBase}/images/icons/more-options.svg`,
    PdfPlaceholder: `${Environment.assetBase}/images/icons/pdf-placeholder.svg`,
    Plus: `${Environment.assetBase}/images/icons/plus.svg`,
    PlusCircle: `${Environment.assetBase}/images/icons/plus-circle.svg`,
    ProfilePlaceholder: `${Environment.assetBase}/images/icons/profile-placeholder.svg`,
    Rating: `${Environment.assetBase}/images/icons/rating.svg`,
    RatingOn: `${Environment.assetBase}/images/icons/rating-on.svg`,
    RatingOnWhite: `${Environment.assetBase}/images/icons/rating-on-white.svg`,
    ReportUserGreenCircle: `${Environment.assetBase}/images/icons/report-user-green-circle.svg`,
    SaveUserGreenCircle: `${Environment.assetBase}/images/icons/save-user-green-circle.svg`,
    Tick: `${Environment.assetBase}/images/icons/tick.svg`,
    TrashCan: `${Environment.assetBase}/images/icons/trash-can.svg`,
    TrashCanBlue: `${Environment.assetBase}/images/icons/trash-can-blue.svg`,
    TrashCanRed: `${Environment.assetBase}/images/icons/trash-can-red.svg`,
    TwitterDark: `${Environment.assetBase}/images/icons/twitter-dark.svg`,
    TwitterLight: `${Environment.assetBase}/images/icons/twitter-light.svg`,
    User: `${Environment.assetBase}/images/icons/user.svg`,
    WebsiteDark: `${Environment.assetBase}/images/icons/website-dark.svg`,
    WebsiteLight: `${Environment.assetBase}/images/icons/website-light.svg`,
  },
  Logo: `${Environment.assetBase}/images/logo.png`,
  LogoMobile: `${Environment.assetBase}/images/logo-mobile.png`,
};

export const Messages = {
  InvalidPassword: 'Must contain at least one special character, one number, one uppercase letter, one lowercase letter, and at least 8 or more characters',
  ErrorHeading: 'An error occurred',
  TechnicalError: 'Unfortunately a technical error occurred, please try again.',
  FileUploadError: 'We were unable to upload your file, please try again.',
  FormError: 'Some fields on this form has invalid values.  Please correct them and try again.',
  GoalAddError: 'You are only allowed to add three goals per goal type.',
  ProjectLimitHeader: 'Project create limit reached',
  ProjectLimitBody: 'You have reached the maximum allowed projects for your account',
};

export const MenuItems = {
  Matches: 'MATCHES',
  Chats: 'CHATS',
  Contacts: 'CONTACTS',
  Projects: 'PROJECTS',
  Account: 'ACCOUNT',
};

export const GoalTypes = {
  Funding: 'FUNDING',
  Employee: 'EMLOYEE',
  Advice: 'ADVICE',
};

export const ChatTabOptions = {
  Chat: 'CHAT',
  Shares: 'SHARES',
  Invites: 'INVITES',
};

export const InformationIcons = () => {
  return DataStore.get('INFO_BUBBLES') || {};
};

export const PlanTypes = [
  {
    name: 'Basic plan',
    priceId: 'price_1JakWaFXvLGFwhs17VWiVeQ9'
  },
  {
    name: 'Premium plan',
    priceId: 'price_1Jalc4FXvLGFwhs12k5cTJvd'
  }
];

export const SafeAreas = {
  top: 'env(safe-area-inset-top)',
  bottom: 'env(safe-area-inset-bottom)',
};
