import
  React
from 'react';

import {
  withRouter,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DataStore,
  ErrorToast,
  Messages,
} from 'common';

import {
  Button,
  H3White,
  PSmallWhite,
  Popup,
} from 'common/components';

import {
  ProjectsSideMenuItem,
} from './ProjectsSideMenuItem';

import {
  ProjectDelete,
} from './ProjectDelete';

import {
  ProjectSetMain,
} from './ProjectSetMain';

const Container = styled.div`
  background-color: ${Colors.Blue3};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ContentContainer = styled.div`
  position: absolute;
  padding: 35px 20px;
  top: 0;
  bottom: 190px;
  left: 0;
  right: 0;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  background-color: ${Colors.Blue3};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px 20px 15px;
`;

const Line = styled.div`
  background-color: ${Colors.White};
  height: 2px;
  width: 100%;
  margin: 10px 0;
`;

const ProjectsSideMenuComponent = props => {

  const [showDeletePopup, showDeletePopupSetState] = React.useState(false);
  const [showSetMainPopup, showSetMainPopupSetState] = React.useState(false);

  const onNewProjectClick = () => {

    if (props.projects.length >= 3) {

      ErrorToast.show(Messages.ProjectLimitHeader, Messages.ProjectLimitBody);
      return;
    }

    DataStore.clear('current_project');
    props.history.push('/projects/edit?selected=true');
  };

  const onProjectClick = (project) => {

    if (props.showOnlySelected) {
      return;
    }

    DataStore.set(
      'current_project',
      project,
    );

    typeof props.onProjectClick === 'function' && props.onProjectClick(project);
  };

  const onDeleteProjectClick = () => {
    showDeletePopupSetState(true);
  };

  const onSetMainProjectClick = () => {
    showSetMainPopupSetState(true);
  };

  const currentProject = DataStore.get('current_project') || {};
  const projects = props.projects || [];
  let projectList = [];
  let project;

  for (let i = 0; i < projects.length; i++) {

    project = projects[i];

    if (props.showOnlySelected) {

      if (currentProject.id === project.id) {

        projectList.push(
          <ProjectsSideMenuItem
            key={`item-${i}`}
            title={project.title}
            nature={project.nature && project.nature.display}
            industry={project.industry.display}
            country={project.country.display}
            stage={project.status && project.status.display}
            goal={project.mainGoal}
            onClick={onProjectClick}
            onClickArgs={project}
          />
        );

        break;
      }

      continue;
    }

    if (i > 0) {

      projectList.push(
        <Line
          key={`line-${i}`}
        />
      );
    }

    projectList.push(
      <ProjectsSideMenuItem
        key={`item-${i}`}
        title={project.title}
        isMain={project.isMain}
        nature={project.nature && project.nature.display}
        industry={project.industry.display}
        country={project.country.display}
        stage={project.status && project.status.display}
        goal={project.mainGoal}
        onClick={onProjectClick}
        onClickArgs={project}
      />
    );
  }

  if  (props.showOnlySelected && !projectList.length) {

    projectList.push(
      <ProjectsSideMenuItem
        key={`item-new`}
      />
    );
  }

  return (

    <>

    <Container>

      <ContentContainer>

        { !projectList.length
        
          ? <React.Fragment>
            
              <H3White>My projects</H3White>

              <PSmallWhite>Use the button below to add your first project.</PSmallWhite>

            </React.Fragment>

          : projectList
        }
      </ContentContainer>

      { !props.showOnlySelected &&

        <ButtonContainer>

          <Button
            id={'button-projects-new'}
            text={'New project'}
            margin={'0 0 10px 0'}
            width={'100%'}
            onClick={onNewProjectClick}
          />

          <Button
            id={'button-projects-delete'}
            text={'Delete project'}
            margin={'0 0 10px 0'}
            width={'100%'}
            onClick={onDeleteProjectClick}
          />

          <Button
            id={'button-projects-set-main-project'}
            text={'Set main project'}
            width={'100%'}
            onClick={onSetMainProjectClick}
          />

        </ButtonContainer>
      }

    </Container>

    { showDeletePopup &&

      <Popup
        header={'Delete project'}
        headerBackgroundColor={Colors.Blue3}
        onClose={() => showDeletePopupSetState(false)}>

          <ProjectDelete
            onClose={() => showDeletePopupSetState(false)}
            onDeleted={props.onProjectDelete}
          />
      </Popup>
    }

    { showSetMainPopup &&

      <Popup
        id={'popup-project-set-main'}
        header={'Set main project'}
        headerBackgroundColor={Colors.Blue3}
        onClose={() => showSetMainPopupSetState(false)}>

          <ProjectSetMain
            account={props.account}
            user={props.user}
            onSetMainProject={props.onSetMainProject}
            onClose={() => showSetMainPopupSetState(false)}
          />
      </Popup>
    }

    </>
  );
}

export const ProjectsSideMenu = withRouter(ProjectsSideMenuComponent);