import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  Chat,
  FontSizes,
  Invites,
  ShareRequest,
  DataStore,
  DeviceMediaQueries,
  CenterLoadingContainer,
  Logger,
  SafeAreas,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Header,
  Loader,
  IFramePopup,
  Popup,
  SideMenu,
  Text,
} from 'common/components';

import {
  DashboardProvider,
} from 'providers';

import {
  withPromiseCleanup,
} from 'common/hocs';

const Container = styled.div`
  position: absolute;
  top: ${SafeAreas.top};
  right: 0;
  bottom: ${SafeAreas.bottom};
  left: 0;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${props => props.addMobileBottom && `
    padding-bottom: 50px;
    
    @media ${DeviceMediaQueries.Tablet} {
      padding-bottom: 0;
    }
  `}

  ${props => props.addTop && `
    padding-top: 50px;
    
    @media ${DeviceMediaQueries.Tablet} {
      padding-top: 60px;
    }
  `}
`;

const Content = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  background-color: ${Colors.Gray1};
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0;

  @media ${DeviceMediaQueries.Tablet} {
    top: ${props => props.addTop ? '60px' : '0'};
    bottom: 0px;
    left: ${props => props.addLeft ? '60px' : '0'};
  }
`;

const SafeAreaTop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Black};
  height: ${SafeAreas.top};
  z-index: 10;
`;

const SafeAreaBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.Black};
  height: ${SafeAreas.bottom};
  z-index: 10;
`;

export const withCommon = (WrappedComponent, options = {}) => {

  const {
    showHeader = true,
    showSideMenu = true,
    selectedMenuItem = '',
    requiresAuth = true,
    addExitSafeguard = true,
    sideMenuActionUrl = '',
  } = options;

  return withPromiseCleanup(props => {

    const [isLoading, setStateIsLoading] = React.useState(false);
    const [showFeedbackPopup, setStateShowFeedbackPopup] = React.useState(false);

    const [iFrameState, iFrameStateSetState] = React.useState({
      show: false,
      url: '',
    });

    const user = DataStore.get('LOGGED_IN_USER') || {};
    const safeguardToken = '#t_19c5cce2-1cac-4ef9-b32a-f70b401f177e';

    let loadingArgs = React.useRef({
      showSideMenu: false,
      showContent: true,
    });

    const onFeedbackYesClick = () => {

      setStateShowFeedbackPopup(false);

      const helpDeskBtn = document.getElementById('btn-help-desk');
      helpDeskBtn.click();

      props.history.push(safeguardToken);
    };

    const onFeedbackNoClick = () => {
      props.history.goBack();
    };

    const onFeedbackClose = () => {

      setStateShowFeedbackPopup(false);
      props.history.push(safeguardToken);
    };

    const showLoading = (args = {}) => {

      loadingArgs.current = {
        showSideMenu: false,
        showContent: true,
        ...args,
      };

      setStateIsLoading(true);
    };

    const hideLoading = () => {

      loadingArgs.current = {
        showSideMenu: false,
        showContent: true,
      };

      setStateIsLoading(false);
    };

    const showIframePopup = (url) => {

      if (!url) {
        return;
      }

      iFrameStateSetState({
        show: true,
        url
      });
    }

    const navigate = (path) => {

      if (!path || !props.history) {
        return;
      }

      typeof props.history.push === 'function' && props.history.push(path);
    };

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const common = {
      navigate,
      user,
      showLoading,
      hideLoading,
      showIframePopup,
      history: props.history,
      uriParams: params,
    };

    const location = `${window.location.pathname}${window.location.search}`;

    React.useEffect(() => {

      Logger.addBreadcrumb(`Navigated to: ${location}`);

      if (DataStore.get('ACCESS_TOKEN')) {

        DashboardProvider.sanityCheck()
          .then(ret => {
            
            if (isValidProviderResult(ret) && (ret.data.deleted || ret.data.deactivated)) {
              props.history.push('/identity/login');
            }
          })
          .catch(e => Logger.error(e));
      }
    }, [location]); // eslint-disable-line

    React.useEffect(() => {

      if (!window.showLoading) {

        window.showLoading = showLoading;
        window.hideLoading = hideLoading;
      }

      const previousPathname = withCommon.previousPathname;
      withCommon.previousPathname = window.location.pathname;

      if (!requiresAuth && !DataStore.get('ACCESS_TOKEN')) {
        return;
      }

      if (!Chat.client) {
        Chat.init();
      }

      if (!Invites.isInitialized) {
        Invites.init();
      }

      if (!ShareRequest.isInitialized) {
        ShareRequest.init();
      }

      if (!addExitSafeguard || (previousPathname && previousPathname !== '/identity/login' && window.location.hash !== safeguardToken)) {
        return;
      }

      if (!previousPathname && DataStore.get('MAIN_SAFEGUARD_SET')) {
        return;
      }

      const listen = props.history.listen((_, action) => {

        if (action === 'POP') {
          setStateShowFeedbackPopup(true);
        }
      });

      if (window.location.hash !== safeguardToken) {

        DataStore.set('MAIN_SAFEGUARD_SET', true);
        props.history.push(safeguardToken);
      }

      return () => {
        listen && listen();
      };

    }, []); // eslint-disable-line

    if (requiresAuth && !DataStore.get('ACCESS_TOKEN')) {

      typeof props.history.push === 'function' && props.history.push('/identity/login');
      return null;
    }

    return (

      <>

      <Container
        id={'with-common-container'}>
        
        <Main
          addTop={showHeader}
          addMobileBottom={showSideMenu}>

          { showSideMenu &&

            <SideMenu
              actionUrl={sideMenuActionUrl}
              selectedItem={selectedMenuItem}
            />
          }

          { showHeader &&

            <Header
              selectedItem={selectedMenuItem}
            />
          }

          <Content
            show={loadingArgs.current.showContent}
            addTop={showHeader}
            addLeft={showSideMenu}>

            <WrappedComponent
              common={common}
              isMounted={props.isMounted}
              withCleanup={props.withCleanup}
            />

          </Content>

          { isLoading &&

            <CenterLoadingContainer
              {...loadingArgs.current}>

              <Loader
                backgroundColor={Colors.Gray1}
                showLabel={true}
              />

            </CenterLoadingContainer>
          }

        </Main>

        <SafeAreaTop/>
        <SafeAreaBottom/>

      </Container>

      { showFeedbackPopup &&
      
        <Popup
          maxWidth={'500px'}
          header={'Before you go...'}
          headerBackgroundColor={Colors.Gray8}
          onClose={onFeedbackClose}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 120px 30px'}
              flex={'1'}>

                Thank you for using AfriOn. Would you like to tell us about your experience so far?
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Gray8}>

              <PopupActionContainer>

                <PopupActionButtonContainer
                  style={{ width: '100%' }}>

                  <Button
                    activeColor={Colors.Black}
                    backgroundColor={Colors.Red1}
                    hoverBackgroundColor={Colors.Red3}
                    width={'100%'}
                    text={'No (leave)'}
                    onClick={onFeedbackNoClick}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer
                  style={{ width: '100%' }}>

                  <Button
                    activeColor={Colors.Black}
                    backgroundColor={Colors.Green2}
                    hoverBackgroundColor={Colors.Green3}
                    width={'100%'}
                    text={'Yes'}
                    onClick={onFeedbackYesClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

        { iFrameState.show &&
            
          <IFramePopup
            src={iFrameState.url}
            onClose={() => iFrameStateSetState({ url: '', show: false })}
          />
        }

      </>
    );
  });
};
