import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  Checkbox,
  TextInput,
} from 'common/components';

export class ProjectDetailsForm extends React.Component {

  state = {
    executiveSummaryErrorMessage: '',
    marketAnalysisErrorMessage: '',
    problemAnalysisErrorMessage: '',
    solutionAnalysisErrorMessage: '',
    targetMarketErrorMessage: '',
    currentCompetitorsErrorMessage: '',
  };

  projectDetails = (this.props.project && this.props.project.project_detail) || {};

  formValues = {
    id: this.projectDetails.id || null,
    executiveSummary: this.projectDetails.executiveSummary || '',
    marketAnalysis: this.projectDetails.marketAnalysis || '',
    problemAnalysis: this.projectDetails.problemAnalysis || '',
    solutionAnalysis: this.projectDetails.solutionAnalysis || '',
    targetMarket: this.projectDetails.targetMarket || '',
    currentCompetitors: this.projectDetails.currentCompetitors || '',
    additionalInformation: this.projectDetails.additionalInformation || '',
    publicShare: (this.projectDetails.publicShare === undefined || this.projectDetails.publicShare === null)
      ? true
      : this.projectDetails.publicShare,
  };

  onExecutiveSummaryChange = val => {

    this.formValues.executiveSummary = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.executiveSummaryErrorMessage) {

      this.setState({
        executiveSummaryErrorMessage: '',
      });
    }
  };

  onMarketAnalysisChange = val => {

    this.formValues.marketAnalysis = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.marketAnalysisErrorMessage) {

      this.setState({
        marketAnalysisErrorMessage: '',
      });
    }
  };

  onProblemAnalysisChange = val => {

    this.formValues.problemAnalysis = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.problemAnalysisErrorMessage) {

      this.setState({
        problemAnalysisErrorMessage: '',
      });
    }
  };

  onSolutionAnalysisChange = val => {

    this.formValues.solutionAnalysis = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.solutionAnalysisErrorMessage) {

      this.setState({
        solutionAnalysisErrorMessage: '',
      });
    }
  };

  onTargetMarketChange = val => {

    this.formValues.targetMarket = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.targetMarketErrorMessage) {

      this.setState({
        targetMarketErrorMessage: '',
      });
    }
  };

  onCurrentCompetitorsChange = val => {

    this.formValues.currentCompetitors = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);

    if (!!this.state.currentCompetitorsErrorMessage) {

      this.setState({
        currentCompetitorsErrorMessage: '',
      });
    }
  };

  onAdditionalInformationChange = val => {

    this.formValues.additionalInformation = val;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);
  };

  onIsPublicClick = (checked) => {

    this.formValues.publicShare = checked;
    typeof this.props.onFormChange === 'function' && this.props.onFormChange(this.formValues);
  };

  validate = () => {

    let errors = {};

    if (typeof this.formValues.executiveSummary !== 'string' || !this.formValues.executiveSummary.trim()) {
      errors.executiveSummaryErrorMessage = 'Please provide an executive summary';
    }

    if (typeof this.formValues.marketAnalysis !== 'string' || !this.formValues.marketAnalysis.trim()) {
      errors.marketAnalysisErrorMessage = 'Please provide a market analysis';
    }

    if (typeof this.formValues.problemAnalysis !== 'string' || !this.formValues.problemAnalysis.trim()) {
      errors.problemAnalysisErrorMessage = 'Please provide a problem analysis';
    }

    if (typeof this.formValues.solutionAnalysis !== 'string' || !this.formValues.solutionAnalysis.trim()) {
      errors.solutionAnalysisErrorMessage = 'Please provide a solution analysis';
    }

    if (typeof this.formValues.targetMarket !== 'string' || !this.formValues.targetMarket.trim()) {
      errors.targetMarketErrorMessage = 'Please provide a target customer';
    }

    if (typeof this.formValues.currentCompetitors !== 'string' || !this.formValues.currentCompetitors.trim()) {
      errors.currentCompetitorsErrorMessage = 'Please provide a competitor analysis';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  load = () => {

    this.formValues.validate = this.validate;

    typeof this.props.onFormLoad === 'function' && this.props.onFormLoad(this.formValues, true);
  };

  componentDidMount() {
    this.load();
  }

  render() {

    return (

      <React.Fragment>

        <TextInput
          id={'txt-project-details-executive-summary'}
          label={'Executive summary'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.executiveSummary}
          onChange={this.onExecutiveSummaryChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={1500}
          hasError={!!this.state.executiveSummaryErrorMessage}
          errorMessage={this.state.executiveSummaryErrorMessage}
        />

        <Checkbox
          id={'checkbox-project-details-public'}
          flex={'1'}
          padding={'10px 2px 15px 2px'}
          label={'Auto-share business plan details.'}
          isDefaultChecked={this.formValues.publicShare}
          onClick={this.onIsPublicClick}
        />

        <TextInput
          id={'txt-project-details-market-analysis'}
          label={'Market analysis'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.marketAnalysis}
          onChange={this.onMarketAnalysisChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={5000}
          hasError={!!this.state.marketAnalysisErrorMessage}
          errorMessage={this.state.marketAnalysisErrorMessage}
        />

        <TextInput
          id={'txt-project-details-problem-analysis'}
          label={'Problem analysis'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.problemAnalysis}
          onChange={this.onProblemAnalysisChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={5000}
          hasError={!!this.state.problemAnalysisErrorMessage}
          errorMessage={this.state.problemAnalysisErrorMessage}
        />

        <TextInput
          id={'txt-project-details-solution-analysis'}
          label={'Solution analysis'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.solutionAnalysis}
          onChange={this.onSolutionAnalysisChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={5000}
          hasError={!!this.state.solutionAnalysisErrorMessage}
          errorMessage={this.state.solutionAnalysisErrorMessage}
        />

        <TextInput
          id={'txt-project-details-target-market'}
          label={'Target customer'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.targetMarket}
          onChange={this.onTargetMarketChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={5000}
          hasError={!!this.state.targetMarketErrorMessage}
          errorMessage={this.state.targetMarketErrorMessage}
        />

        <TextInput
          id={'txt-project-details-current-competitors'}
          label={'Competitor analysis'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.currentCompetitors}
          onChange={this.onCurrentCompetitorsChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={5000}
          hasError={!!this.state.currentCompetitorsErrorMessage}
          errorMessage={this.state.currentCompetitorsErrorMessage}
        />

        <TextInput
          id={'txt-project-details-additional-information'}
          label={'Further Information'}
          multiline={true}
          width={'100%'}
          defaultValue={this.formValues.additionalInformation}
          onChange={this.onAdditionalInformationChange}
          showCharCounter={true}
          charCounterColor={Colors.Blue3}
          charCounterMax={2500}
        />

      </React.Fragment>
    );
  }
}