import
  React
from 'react';

import {
  Colors,
  GoalTypes,
} from 'common';

import {
  Accordion,
} from 'common/components';

import {
  AdviceForm,
} from './AdviceGoalDisplay';

import {
  EmployeeForm,
} from './EmployeeGoalDisplay';

import {
  FundingForm,
} from './FundingGoalDisplay';

export const GoalDisplay = ({
  project,
  padding,
  color = Colors.Black,
}) => {

  const goals = project.goals || [];

  const cards = goals.map((g, i) => {

    const cs = g.compensations || [];

    const compensations = cs.map(c => {

      return (
        <div key={c.value}>{c.display}</div>
      );
    });

    const t = g.topics || [];

    const topics = t.map(topic => {

      return (
        <div key={topic.value}>{topic.display}</div>
      );
    });

    return (

      <Accordion
        key={i}
        header={g.display}
        headerColor={Colors.White}
        backgroundColor={Colors.Blue3}
        borderRadius={'15px'}
        headerPadding={'5px 15px'}
        contentPadding={'15px'}
        contentBackgroundColor={Colors.White}
        isCollapsable={true}
        showArrow={true}>
          
          { g.type === GoalTypes.Funding &&

            <FundingForm
              f={g}
              color={color}
              padding={padding}
            />
          }

          { g.type === GoalTypes.Advice &&

            <AdviceForm
              key={i}
              a={g}
              color={color}
              padding={padding}
              topics={topics}
            />
          }

          { g.type === GoalTypes.Employee &&

            <EmployeeForm
              e={g}
              color={color}
              padding={padding}
              compensations={compensations}
            />
          }

      </Accordion>
    );
  });

  return (

    <React.Fragment>

      {cards}

    </React.Fragment>
  );
};