import
  React
from 'react';

import {
  Colors,
  TextInputContainer,
} from 'common';

import {
  Accordion,
  PSmallWhite,
} from 'common/components';

import {
  FormContainer,
  TextContainer,
} from './projectsComponentsStyled';

export const AdviceForm = ({
  a,
  color,
  padding,
  topics,
}) => (

  <FormContainer
    padding={padding}>

    <TextInputContainer
      padding={'0 0 15px 0'}>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Topic
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(topics.length && topics) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Frequency
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(a.frequency && a.frequency.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Contact method
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {(a.communicationMethod && a.communicationMethod.display) || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

    <TextInputContainer>

      <TextContainer>

        <PSmallWhite
          color={color}
          fontWeight={'bold'}>

            Further Information
        </PSmallWhite>

        <PSmallWhite
          color={color}>

            {a.description || 'Not specified'}
        </PSmallWhite>

      </TextContainer>

    </TextInputContainer>

  </FormContainer>
);

export const AdviceGoalDisplay = ({
  project,
  padding,
  color = Colors.Black,
  showHeader = true,
}) => {

  const advices = (project && project.project_advices) || [];

  const cards = advices.map((a, i) => {

    const t = a.topics || [];

    const topics = t.map(topic => {

      return (
        <div key={topic.value}>{topic.display}</div>
      );
    });

    return (
      <React.Fragment key={i}>

      { showHeader &&

        <Accordion
          key={i}
          header={'Mentoring goal'}
          headerColor={Colors.White}
          backgroundColor={Colors.Blue3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}
          isCollapsable={true}
          showArrow={true}>
            
            <AdviceForm
              a={a}
              color={color}
              padding={padding}
              topics={topics}
            />

        </Accordion>
      }

      { !showHeader &&

        <AdviceForm
          key={i}
          a={a}
          color={color}
          padding={padding}
          topics={topics}
        />
      }

      </React.Fragment>
    );
  });

  return (

    <React.Fragment>

      {cards}

    </React.Fragment>
  );
};