import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Images,
  Image,
  SafeAreas,
} from 'common';

import {
  InformationBubble,
} from 'common/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: ${SafeAreas.top};
  left: 0;
  bottom: ${SafeAreas.bottom};
  right: 0;
  z-index: 3;
`;

const OverlayContainer = styled.div`
  background-color: ${Colors.Black};
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
`;

const ContentContainer = styled.div`
  background-color: ${props => props.backgroundColor || Colors.White};
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  box-shadow: 0 0 10px 5px ${Colors.Gray9};
  z-index: 3;

  display: flex;
  flex-direction: column;

  @media ${DeviceMediaQueries.Tablet} {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    max-height: 90vh;
    width: ${props => props.desktopWidth || '75vw'};
    
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
  }

  @media ${DeviceMediaQueries.LaptopL} {
    width: ${props => props.desktopWidth || '70vw'};
    max-width: ${props => props.maxWidth || props.desktopWidth || '1200px'};
  }
`;

const PopupHeader = styled.div`
  background-color: ${props => props.headerBackgroundColor || Colors.Gray5};
  border-bottom: 1px solid ${Colors.Gray2};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 15px 25px;
  }
`;

const PopupHeaderText = styled.div`
  color: ${Colors.White};
  font-size: ${FontSizes.Big};
  position: relative;
`;

const PopupHeaderClose = styled.div`
  font-size: ${FontSizes.Big};
  font-weight: 100;
  cursor: pointer;
`;

const PopupContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const Popup = props => (

  <Container
    id={props.id}
    onClick={(e) => { props.onClick && props.onClick(e); }}>

    <OverlayContainer
      onClick={(e) => { props.onClose && props.onClose(e); }}
    />

    <ContentContainer
      backgroundColor={props.backgroundColor}
      maxWidth={props.maxWidth}
      desktopWidth={props.desktopWidth}>
        
      <PopupHeader
        headerBackgroundColor={props.headerBackgroundColor}>

        <PopupHeaderText>

          {props.header || ''}

          <InformationBubble
            id={props.id}
          />

        </PopupHeaderText>

        <PopupHeaderClose
          onClick={(e) => { props.onClose && props.onClose(e); }}>
        
          <Image
            src={Images.Icons.Close}
            width={'20px'}
            height={'20px'}
          />

        </PopupHeaderClose>

      </PopupHeader>

      <PopupContent>

        {props.children}

      </PopupContent>

    </ContentContainer>

  </Container>
);