import
  styled
from 'styled-components/macro';

import {
  DeviceMediaQueries,
} from 'common';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 20px 15px;
`;

export const EvenlySpacedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media ${DeviceMediaQueries.Laptop} {
    width: 50%;
  }

  @media ${DeviceMediaQueries.LaptopM} {
    width: 630px;
  }
`;

export const GoalFormContainer = styled.div`

  > div {
    margin: 0 0 15px 0;
  }

  > div:last-child {
    margin: 0;
  }
`;

export const Space = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
`;

export const ActionContainer = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    ${props => props.padding && `padding: ${props.padding};`}
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  width: 100%;
  
  @media ${DeviceMediaQueries.Tablet} {
    width: 150px;
  }

  @media ${DeviceMediaQueries.Laptop} {
    width: 180px;
  }
`;

export const DesktopInputPlaceholder = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Laptop} {
    display: flex;
    flex: 1;
  }
`;

export const DesktopSpace = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Tablet} {
    display: flex;
    height: 25px;
  }
`;

export const MobileSpace = styled.div`
  height: 80px;

  @media ${DeviceMediaQueries.Tablet} {
    height: 0;
  }
`;