import {
  Login,
  Register,
  VerifyEmail,
  VerifiedEmail,
  ForgotPasswordChange,
  ForgotPasswordEmail,
  ChangeEmailCaptureNew,
  ChangeEmailVerifyNew,
  LinkedInAuth,
  AccountRemove,
} from 'ui/identity';

export const IdentityRoutes = {
  login: {
    component: Login,
    path: '/identity/login',
    default: true,
  },
  register: {
    component: Register,
    path: '/identity/register',
  },
  verifyEmail: {
    component: VerifyEmail,
    path: '/identity/email/verify',
  },
  verifiedEmail: {
    component: VerifiedEmail,
    path: '/identity/email/verified',
  },
  forgotPasswordChange: {
    component: ForgotPasswordChange,
    path: '/identity/password/reset',
  },
  forgotPasswordEmail: {
    component: ForgotPasswordEmail,
    path: '/identity/password/reset/email',
  },
  changeEmailCaptureNew: {
    component: ChangeEmailCaptureNew,
    path: '/identity/email/change/capture/new',
  },
  changeEmailVerifyNew: {
    component: ChangeEmailVerifyNew,
    path: '/identity/email/change/verify',
  },
  linkedInAuth: {
    component: LinkedInAuth,
    path: '/identity/linkedin/auth',
  },
  accountRemove: {
    component: AccountRemove,
    path: '/identity/account/remove',
  }
};