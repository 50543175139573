import
  React
from 'react';

import {
  Colors,
  TextInputContainer,
} from 'common';

import {
  PSmall,
} from 'common/components';

import {
  TextContainer,
} from './projectsComponentsStyled';

export const ProjectDetailsDisplay = ({
  project,
  color = Colors.Black,
  hideNonPublicDetails = false,
  hideProjectDetailsFlag = false,
}) => {

  const details = project.project_detail || {};

  return (

    <React.Fragment>

      <TextInputContainer
        padding={'0 0 15px 0'}>

        <TextContainer>
          
          <PSmall
            color={color}
            fontWeight={'bold'}>

              Executive summary
          </PSmall>

          <PSmall
            color={color}>

              {details.executiveSummary || 'Not specified'}
          </PSmall>
          
        </TextContainer>

      </TextInputContainer>

      { (!hideNonPublicDetails || details.publicShare) &&

        <>

          { !hideProjectDetailsFlag &&

            <TextInputContainer
              padding={'0 0 15px 0'}>

              <TextContainer>
                
                <PSmall
                  color={color}
                  fontWeight={'bold'}>

                  Auto-share business plan details.
                </PSmall>

                <PSmall
                  color={color}>

                    {details.publicShare ? 'Yes' : 'No'}
                </PSmall>
                
              </TextContainer>

            </TextInputContainer>
          }

          <TextInputContainer
            padding={'0 0 15px 0'}>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                  Market analysis
              </PSmall>

              <PSmall
                color={color}>

                  {details.marketAnalysis || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>

          <TextInputContainer
            padding={'0 0 15px 0'}>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                  Problem analysis
              </PSmall>

              <PSmall
                color={color}>

                  {details.problemAnalysis || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>

          <TextInputContainer
            padding={'0 0 15px 0'}>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                  Solution analysis
              </PSmall>

              <PSmall
                color={color}>

                  {details.solutionAnalysis || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>

          <TextInputContainer
            padding={'0 0 15px 0'}>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                  Target customer
              </PSmall>

              <PSmall
                color={color}>

                  {details.targetMarket || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>

          <TextInputContainer
            padding={'0 0 15px 0'}>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                Competitor analysis
              </PSmall>

              <PSmall
                color={color}>

                  {details.currentCompetitors || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>

          <TextInputContainer>

            <TextContainer>
              
              <PSmall
                color={color}
                fontWeight={'bold'}>

                Further Information
              </PSmall>

              <PSmall
                color={color}>

                  {details.additionalInformation || 'Not specified'}
              </PSmall>
              
            </TextContainer>

          </TextInputContainer>
        </>
      }

    </React.Fragment>
  );
};