import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  MenuItems,
  Images,
  Image,
  DeviceMediaQueries,
  SafeAreas,
} from 'common';

import {
  withCommon,
} from 'common/hocs';

import {
  withMatchesSideMenu,
} from 'ui/matches/hocs';

import {
  MatchesProfileDetails,
} from 'ui/matches/components';

const BackContainer = styled.div`
  background-color: ${Colors.White};
  position: fixed;
  bottom: 70px;
  left: 20px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-bottom: ${SafeAreas.bottom};

  @media ${DeviceMediaQueries.Tablet} {
    display: none;
  }
`;

const MatchesProfileViewUI = props => {

  React.useEffect(() => {

    return () => {

      if (props.history.location.pathname !== '/matches/view') {

        DataStore.clear('matchesList');
        DataStore.clear('matchesPageIndex');
        DataStore.clear('matchesScrollTop');
        DataStore.clear('matchesFilters');
        DataStore.clear('matchesCount');
      }
    };
  }, [props.history.location]);
  
  return (
    <>
    <MatchesProfileDetails
      common={props.common}
      fromMatches={true}
    />

    <BackContainer>

      <Image
        src={Images.Icons.Back}
        width={'35px'}
        height={'35px'}
        onClick={() => props.history.goBack()}
      />

    </BackContainer>
    </>
  );
};

export const MatchesProfileView = withCommon(
  withMatchesSideMenu(MatchesProfileViewUI, {
      hideSideMenuOnMobile: true,
      clearSideMenu: true,
    }), {
    selectedMenuItem: MenuItems.Matches,
  }
);