import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  get,
} from 'providers/axios';

export class LinkedInProvider {

  static async login(
    code,
    state,
  ) {

    try {

      if (!code) {

        return {
          ok: false,
          message: 'Invalid code provided',
          data: null,
        };
      }

      if (!state) {

        return {
          ok: false,
          message: 'Invalid state provided',
          data: null,
        };
      }

      let res = await get(`${Environment.apiHost}auth/linkedin?code=${code}&state=${state}`);

      let ret = resolveResponse(res);

      ret.ok = ret.ok && ret.data && ret.data.accessToken && ret.data.user && ret.data.user.id;

      return ret;

    } catch (ex) {

      return resolveRejected(ex);
    }
  }
}