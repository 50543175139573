import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const GoalAdviceSvg = props => {

  const fill = props.fill || Colors.Green2;

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">
        
        <Path fill={fill} d="M74,37a37.43,37.43,0,0,1-1.36,10,36.33,36.33,0,0,1-1.39,4,37,37,0,0,1-68.5,0,36.33,36.33,0,0,1-1.39-4A37.43,37.43,0,0,1,0,37,36.71,36.71,0,0,1,4.67,19a35.52,35.52,0,0,1,2.59-4,37,37,0,0,1,59.48,0,35.52,35.52,0,0,1,2.59,4A36.71,36.71,0,0,1,74,37Z"/>
        
        <Path fill={Colors.White} d="M69.33,19H4.67a35.52,35.52,0,0,1,2.59-4H66.74A35.52,35.52,0,0,1,69.33,19Z"/>
        
        <Path fill={Colors.White} d="M21,47v4H2.75a36.33,36.33,0,0,1-1.39-4Z"/>

        <rect fill={Colors.White} x="24" y="47" width="10" height="4"/>
        
        <Path fill={Colors.White} d="M72.64,47a36.33,36.33,0,0,1-1.39,4H37V47Z"/>
        
        <Path fill={Colors.White} d="M59,24.12a.73.73,0,0,0-1-.41L46.81,27.65a2.45,2.45,0,0,0-3.36.35L39.9,32.14l-3.15-2.7c0,.23.07.44.07.67V36l1.75,1.5A2.45,2.45,0,0,0,42,37.2l4.23-4.94,3.41-2,9-5.25A.73.73,0,0,0,59,24.12Z"/>
        
        <Path fill={Colors.White} d="M29.91,25.17h-2.1a4.9,4.9,0,0,0-3.94,2l-6.47,6a2.45,2.45,0,0,0-.19,3.47l5.28,5.89a2.45,2.45,0,1,0,3.65-3.27l-3.63-4.06,5.16-4.63-3,4.09,2.95,3.29A4.41,4.41,0,0,1,22.86,45s0,11.36,0,23.52a3,3,0,0,0,5.9,0V50.94h2V68.5a5,5,0,0,1-.61,2.33,2.9,2.9,0,0,0,1.78.61,2.94,2.94,0,0,0,2.94-2.94c0-12.16,0-7.06,0-38.39A5,5,0,0,0,29.91,25.17Z"/>
        
        <Path fill={Colors.White} d="M28.86,25.06a6.81,6.81,0,1,1,3.08-.77A6.7,6.7,0,0,1,28.86,25.06Z"/>
        
        <Path fill={fill} d="M28.86,12.58A5.72,5.72,0,0,1,31.48,23.4a5.55,5.55,0,0,1-5.25,0,5.73,5.73,0,0,1,2.63-10.82m0-2a7.74,7.74,0,0,0,0,15.48,7.83,7.83,0,0,0,7.74-7.74,7.76,7.76,0,0,0-7.74-7.74Z"/>
      
      </Svg>

    </SvgContainer>

  );
};