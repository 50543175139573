import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DataStore,
  ErrorToast,
  Environment,
  Logger,
  Messages,
  IsMobile,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Loader,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  PaymentProgressStepper,
} from 'ui/payment/components';

import {
  StepperContainer,
  ContentContainer,
} from './paymentStyled';

import {
  PaymentProvider,
} from 'providers';

const Container = styled.div`
  background-color: ${Colors.White};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PaymentPackageSelectUI = props => {

  const [isLoading, isLoadingSetState] = React.useState(false);
  const user = DataStore.get('LOGGED_IN_USER');

  const initiateCheckoutSession = (priceId) => {

    isLoadingSetState(true);

    PaymentProvider
      .getPaymentUrl(user.id, priceId, Environment.channel)
      .then(ret => {

        if (!isValidProviderResult(ret) || !ret.data.redirect) {

          isLoadingSetState(false);

          ErrorToast.show();
          Logger.error(ret.error || Messages.TechnicalError);
          return;
        }

        window.location.href = ret.data.redirect;
      })
      .catch(e => {

        isLoadingSetState(false);

        ErrorToast.show();
        Logger.error(e);
      });
  };

  const onSelectPackageClick = (priceId) => {

    DataStore.set(
      'price_id',
      priceId,
    );

    if (user) {

      initiateCheckoutSession(priceId);
      return;
    }

    props.common.navigate('/payment/login/register');
  };

  React.useEffect(() => {
    DataStore.clear('price_id');
  }, [props.common, user]);

  if (user && user.paymentCustomerId) {
      
    IsMobile
      ? props.common.navigate('/account/mobile/menu')
      : props.common.navigate('/account/view');

    return <></>
  }

  if (isLoading) {

    return (
      <Container>

        <Loader/>

      </Container>
    );
  }

  return (
    <div>

      <StepperContainer>

        <PaymentProgressStepper
          selectedIndex={0}
          title={'Select package'}
        />

      </StepperContainer>

      <ContentContainer>

        <Button
          text={'BASIC PLAN'}
          onClick={() => onSelectPackageClick('price_1JakWaFXvLGFwhs17VWiVeQ9')}
        />

        <Button
          text={'PREMIUM PLAN'}
          onClick={() => onSelectPackageClick('price_1Jalc4FXvLGFwhs12k5cTJvd')}
        />

      </ContentContainer>

    </div>
  );
};

export const PaymentPackageSelect = withCommon(
  PaymentPackageSelectUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
