import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
} from 'common';

import {
  Button,
  Popup,
  Text,
} from 'common/components';

import {
  ActionButtonContainer,
} from './accountComponentsStyled';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  border-top: 1px solid ${Colors.Gray2};
  padding: 15px 0;

  @media ${DeviceMediaQueries.Tablet} {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 0;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 0;
  }
`;

const DeleteContainer = styled.div`
  padding: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const DeleteText = styled.div`
  font-size: ${FontSizes.Big};
  padding: 40px 15px;
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const EducationBar = props => {

  const [showConfirmPopup, setStateShowConfirmPopup] = React.useState(false);

  const onEditClick = item => {
    typeof props.onEditClick === 'function' && props.onEditClick(item);
  };

  const onDeleteClick = () => {
    setStateShowConfirmPopup(true);
  };

  const onDeleteCancelClick = () => {
    setStateShowConfirmPopup(false);
  };

  const onDeleteYesClick = () => {

    setStateShowConfirmPopup(false);

    typeof props.onDeleteClick === 'function' && props.onDeleteClick(props.data.id);
  };

  return (

    <Container>

      <LeftColumn>

        <Text
          fontSize={FontSizes.Small}
          fontWeight={'bold'}>

            {props.data.degreeTitle || 'Not specified'}
        </Text>

        <div>

          <Text
            fontSize={FontSizes.Small}>

              {props.data.institutionName || 'Not specified'}
          </Text>
          &nbsp;
          <Text
            fontSize={FontSizes.Small}>

              ({(props.data.country && props.data.country.display) || 'Not specified'})
          </Text>

        </div>

        <div>

          <Text
            color={Colors.Gray7}
            fontSize={FontSizes.Smaller}>

              {(props.data.startMonth && props.data.startMonth.display) || 'Not specified'}
          </Text>
          &nbsp;
          <Text
            color={Colors.Gray7}
            fontSize={FontSizes.Smaller}>

              {(props.data.startYear && props.data.startYear.display) || 'Not specified'}
          </Text>
          &nbsp;
          <Text
            color={Colors.Gray7}
            fontSize={FontSizes.Smaller}>

              to
          </Text>
          &nbsp;

          { props.data.isInProgress
            
            ? <Text
                color={Colors.Gray7}
                fontSize={FontSizes.Smaller}>

                  present
              </Text>
            
            : <React.Fragment>

                <Text
                  color={Colors.Gray7}
                  fontSize={FontSizes.Smaller}>

                    {(props.data.endMonth && props.data.endMonth.display) || 'Not specified'}
                </Text>
                &nbsp;
                <Text
                  color={Colors.Gray7}
                  fontSize={FontSizes.Smaller}>

                    {(props.data.endYear && props.data.endYear.display) || 'Not specified'}
                </Text>

              </React.Fragment>
          }
          
        </div>

        <Text
          color={Colors.Gray7}
          fontSize={FontSizes.Smaller}>

            {props.data.description || 'No description specified'}
        </Text>

      </LeftColumn>

      { !props.hideButtons &&

        <ActionButtonContainer>

          <Button
            width={'100%'}
            text={'EDIT'}
            onClick={onEditClick}
            onClickArgs={props.data}
          />

          &nbsp;&nbsp;

          <Button
            width={'100%'}
            text={'DELETE'}
            onClick={onDeleteClick}
          />

        </ActionButtonContainer>
      }

      { showConfirmPopup &&

        <Popup>

          <DeleteContainer>

            <DeleteText>
              Are you sure you want to delete this entry?
            </DeleteText>

            <ActionButtonContainer>

              <Button
                width={'100%'}
                text={'NO'}
                onClick={onDeleteCancelClick}
                onClickArgs={props.data}
              />

              &nbsp;&nbsp;

              <Button
                activeColor={Colors.Gray9}
                backgroundColor={Colors.PrimaryThree}
                width={'100%'}
                text={'YES'}
                onClick={onDeleteYesClick}
              />

            </ActionButtonContainer>

          </DeleteContainer>

        </Popup>
      }

    </Container>
  );
};