import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  MenuItems,
} from 'common';

import {
  Button,
  Card,
  H3White,
  PSmallWhite,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

const Container = styled.div`
  position: relative;
  padding: 15px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProjectsNoAccountUI = props => (

  <Container>

    <Card
      backgroundColor={Colors.Blue3}
      flexDirection={'column'}
      alignItems={'center'}
      padding={'15px'}
      width={'100%'}>

        <ContentContainer>

          <H3White>
            Incomplete account
          </H3White>

          <PSmallWhite
            padding={'0 0 8px 0'}
            textAlign={'center'}>
              You need to complete your account details before you can create projects.
          </PSmallWhite>

          <PSmallWhite
            textAlign={'center'}>
              Please click the button below to proceed to your account.
          </PSmallWhite>

          <Button
            margin={'15px 0 10px 0'}
            text={'Go to account'}
            onClick={() => props.common.navigate('/account/edit')}
          />

        </ContentContainer>

    </Card>

  </Container>
);

export const ProjectsNoAccount = withCommon(
  ProjectsNoAccountUI, {
    selectedMenuItem: MenuItems.Projects,
  }
);