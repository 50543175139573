import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Images,
} from 'common';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${Colors.Gray2};
  padding: 10px 0 5px 0;

  @media ${DeviceMediaQueries.Laptop} {
    align-items: flex-end;
    flex-direction: row;
  }
`;

export const ItemContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 0 0;
  justify-content: flex-start;

  @media ${DeviceMediaQueries.Laptop} {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.width && `width: ${props.width};`}

  @media ${DeviceMediaQueries.Laptop} {
    
    ${props => !props.width && `flex: 1;`}
    padding: ${props => props.padding || '0 15px 0 0'};

    :last-child {
      padding: 0;
    }
  }
`;

export const ItemHeader = styled.div`
  font-size: ${FontSizes.Small};
  padding: 0 0 10px 0;
`;

export const ItemText = styled.div`
  font-size: ${FontSizes.Small};
  font-weight: bold;
  min-height: 45px;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
`;

export const ItemButtonContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 150px;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 0;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px;

  @media ${DeviceMediaQueries.Tablet} {
    justify-content: flex-end;
  }
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    width: 180px;
  }
`;

export const LoadingContainer = styled.div`
  background-color: ${Colors.White};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopupContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 20px;
  min-height: 320px;
`;

export const PopupHeader = styled.div`
  background-color: ${props => props.headerBackgroundColor || Colors.Gray5};
  border-bottom: 1px solid ${Colors.Gray2};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  @media ${DeviceMediaQueries.Tablet} {
    padding: 15px 25px;
  }
`;

export const PopupHeaderText = styled.div`
  color: ${Colors.White};
  font-size: ${FontSizes.Big};
`;

export const PopupHeaderClose = styled.div`
  font-size: ${FontSizes.Big};
  font-weight: 100;
  cursor: pointer;
`;

export const DeleteContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;

  background-image: url(${Images.Icons.TrashCanRed});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media ${DeviceMediaQueries.Laptop} {
    top: 20px;
    right: 20px;
    height: 22px;
    width: 22px;
  }
`;