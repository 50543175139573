import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
} from 'common';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: ${props => props.backgroundColor || Colors.White};
  padding: ${props => props.padding || '0'};
  box-shadow: ${props => props.boxShadow || `0 0 5px 0 ${Colors.Gray5}`};

  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.borderRadius && `border-radius: ${props.borderRadius};`}

  ${props => props.growOnHover && `
    transition: all .2s linear;
    :hover { transform: scale(1.1); }
  `}
`;

export const Card = props => {

  const onClick = () => {

    typeof props.onClick === 'function' && props.onClick(props.onClickArgs);
  };

  return (

    <Container
      alignItems={props.alignItems}
      backgroundColor={props.backgroundColor}
      borderRadius={props.borderRadius}
      boxShadow={props.boxShadow}
      padding={props.padding}
      margin={props.margin}
      height={props.height}
      width={props.width}
      growOnHover={props.growOnHover}
      flexDirection={props.flexDirection}
      onClick={onClick}>

        {props.children}
    </Container>
  );
};
