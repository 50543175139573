import {
  MatchesView,
  MatchesProfileView,
} from 'ui/matches';

export const MatchesRoutes = {
  matchesProfileView: {
    component: MatchesProfileView,
    path: '/matches/view/profile',
  },
  matchesView: {
    component: MatchesView,
    path: '/matches/view',
  },
}