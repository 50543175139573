import {
  Environment,
  resolveResponse,
  resolveRejected,
} from 'common';

import {
  post,
} from 'providers/axios';

export class SupportProvider {

  static async createTicket(
    subject,
    body,
    pageUrl,
    attachments,
  ) {

    try {

      if (!subject) {

        return {
          ok: false,
          message: 'Please specify the ticket subject',
          data: null,
        };
      }

      if (!body) {

        return {
          ok: false,
          message: 'Please specify a description for the issue you are facing',
          data: null,
        };
      }

      attachments = attachments.filter(a => a.status === 'success');

      const res = await post(`${Environment.apiHost}support-ticket`, {
        subject,
        body,
        pageUrl,
        attachments,
      });

      return resolveResponse(res);
    }
    catch(ex) {
      return resolveRejected(ex);
    }
  }
}