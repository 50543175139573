import
  React
from 'react';

import {
  DataStore,
  Image,
  Images,
  MenuItems,
  Logger,
  Messages,
  isValidProviderResult,
} from 'common';

import {
  Button,
  Card,
  H2,
  PSmall,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountScreen,
} from 'ui/account/components';

import {
  DeactivateActionContainer,
} from './accountStyled';

import {
  IdentityProvider,
} from 'providers';

class AccountDeleteUI extends React.Component {

  state =  {
    showSuccess: false,
    showError: false,
  };

  onDeleteClick = () => {

    if (!this.props.common.user.email) {

      this.setState({
        showError: true,
      });

      return;
    }

    this.props.common.showLoading();

    IdentityProvider.requestDeleteAccount(
      this.props.common.user.email,
    )
    .then(ret => {

      this.props.common.hideLoading();

      if (!isValidProviderResult(ret)) {

        Logger.error(ret.message || Messages.TechnicalError);

        this.setState({
          showError: true,
        });

        return;
      }

      this.setState({
        showSuccess: true,
        showError: false,
      });

      DataStore.clear('ACCESS_TOKEN');

      setTimeout(() => {
        this.props.common.navigate('/identity/login');
      }, 6000);
    })
    .catch(e => {

      this.props.common.hideLoading();

      Logger.error(e);

      this.setState({
        showError: true,
      });
    });
  };

  render() {

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}>

        <H2>Delete Account</H2>

        { !this.state.showSuccess && !this.state.showError &&

          <Card
            padding={'15px'}
            flexDirection={'column'}>

            <PSmall
              fontWeight={'bold'}
              padding={'0 0 15px 0'}>

                This action will permanently delete all the information that you have provided to AfriOn from your device and our servers.
            </PSmall>

            <PSmall
              padding={'0 0 15px 0'}>

                When you delete your account, you won't be able to retrieve the content or information you've created and shared. This includes your conversation history.
            </PSmall>

            <DeactivateActionContainer>

              <Button
                text={'Delete'}
                width={'100%'}
                onClick={this.onDeleteClick}
              />

            </DeactivateActionContainer>

          </Card>
        }

        { this.state.showSuccess && !this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'20px 30px'}
            alignItems={'center'}>
              
              <Image
                height={'50px'}
                src={Images.Icons.EmailSent}
              />

              <H2>
                Check your mail
              </H2>

              <PSmall
                textAlign={'center'}>
                  Please click the link in your mail to delete your account
              </PSmall>

          </Card>
        }

        { this.state.showError &&

          <Card
            flexDirection={'column'}
            padding={'20px'}
            alignItems={'center'}>
              
              <H2
                margin={'0 0 20px 0'}>

                  Oops...
              </H2>

              <PSmall
                textAlign={'center'}>

                  Unfortunately an error occurred and we were unable to send you the email, please try again later.
              </PSmall>

          </Card>
        }

      </AccountScreen>
    );
  }
}

export const AccountDelete = withCommon(
  AccountDeleteUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);