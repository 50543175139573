import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  Chat,
  DataStore,
  DeviceMediaQueries,
  FontSizes,
  MenuItems,
  ErrorToast,
  Logger,
  Messages,
  Space,
  Images,
  Image,
  MobileButtonContainer,
  RoundButton,
  TextInputContainer,
  isValidProviderResult,
} from 'common';

import {
  Accordion,
  Button,
  DatePicker,
  MultiAddButton,
  Popup,
  Select,
  Text,
  TextInput,
  RouteLeavingGuard,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  AccountCvUpload,
  AccountProfilePicUpload,
  AccountScreen,
  EducationForm,
  LinkedAccountsForm,
  LinkedAccountsBar,
  ProfessionalExperienceForm,
} from 'ui/account/components';

import {
  DesktopSpace,
  ActionContainer,
  ActionButtonContainer,
  NoItemsTextContainer,
  ContentContainer,
  ProfilePicContentContainer,
  MobileSpace,
  LinkedAccountContainer,
  LinkedAccountDesktopSpace,
} from './accountStyled';

import {
  LookupProvider,
  UserProvider,
} from 'providers';

const DesktopOnly = styled.div`
  display: none;

  @media ${DeviceMediaQueries.Laptop} {
    display: block;
  }
`;

const MobileOnly = styled.div`
  @media ${DeviceMediaQueries.Laptop} {
    display: none;
  }
`;

class AccountEditUI extends React.Component {

  state = {
    languageList: [],
    employmentTypes: [],
    skillsList: [],
    countryList: [],
    degreeLevelList: [],
    nameError: '',
    surnameError: '',
    countryError: '',
    cityError: '',
    contactNumberError: '',
    birthdayError: '',
    languageError: '',
    personalSummaryError: '',
    currentWorkStatusError: '',
    highestEducationError: '',
    showEducationPopup: false,
    showExperiencePopup: false,
    showLinkedAccountsPopup: false,
    educationBars: [],
    experienceBars: [],
    linkedAccountsBars: [],
    currentEducation: null,
    currentExperience: null,
  };

  accountId = null;
  name = '';
  surname = '';
  country = 0;
  city = '';
  contactNumber = '00';
  birthday = '';
  personalSummary = '';
  currentWorkStatus = {};
  selectedLanguages = [];
  selectedSkills = [];
  educationalHistory = [];
  professionalExperienceList = [];
  linkedAccountsList = [];
  highestEducation = {};
  cvUrl = '';
  profilePicUrl = '';
  hasUnsavedData = false;

  onLanguageSelectChange = (val) => {

    const toDelete = this.selectedLanguages.filter(l => {

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {

          val[j].id = l.id;

          return false;
        }
      }

      return true;
    });

    for (let i = 0; i < toDelete.length; i++) {

      if (!toDelete[i].id) {
        continue;
      }

      UserProvider.deleteLanguage(toDelete[i].id)
        .then(ret => {

          if (!isValidProviderResult(ret)) {
            Logger.error(ret.message || Messages.TechnicalError);
          }
        })
        .catch(e => {
          Logger.error(e);
        });
    }

    this.updateUnsaved();

    this.selectedLanguages = val;
  };

  onSkillSelectChange = val => {

    const toDelete = this.selectedSkills.filter(l => {

      for (let j = 0; j < val.length; j++) {

        if (l.value === val[j].value) {

          val[j].id = l.id;

          return false;
        }
      }

      return true;
    });

    for (let i = 0; i < toDelete.length; i++) {

      if (!toDelete[i].id) {
        continue;
      }

      UserProvider.deleteSkill(toDelete[i].id)
        .then(ret => {

          if (!isValidProviderResult(ret)) {
            Logger.error(ret.message || Messages.TechnicalError);
          }
        })
        .catch(e => {
          Logger.error(e);
        });
    }

    this.updateUnsaved();

    this.selectedSkills = val;
  };

  onCancelChangesClick = () => {
    this.props.common.navigate('/account/view');
  };

  onCvUploadSuccess = (url) => {

    this.updateUnsaved();
    this.cvUrl = url;
  };

  onCvRemoved = () => {
    this.cvUrl = '';
  };

  onProfilePicUploadSuccess = (url) => {

    this.updateUnsaved();
    this.profilePicUrl = url;
  };

  onSaveChangesClick = () => {

    this.saveChanges()
      .catch(e => {

        ErrorToast.show();
        Logger.error(e);

        this.props.common.hideLoading();
      });
  };

  onSaveEducationClick = education => {

    let foundEducation;

    for (let i = 0; i < this.educationalHistory.length; i++) {

      if (this.educationalHistory[i].id === education.id) {

        foundEducation = this.educationalHistory[i];
        break;
      }
    }

    if (!foundEducation) {
      this.educationalHistory.push(education);
    }

    if (foundEducation) {
      foundEducation = education;
    }

    const educationBars = this.createEducationBars();

    this.setState({
      educationBars,
    });
  };

  onSaveExperienceClick = experience => {

    let foundExperience;

    for (let i = 0; i < this.professionalExperienceList.length; i++) {

      if (this.professionalExperienceList[i].id === experience.id) {

        foundExperience = this.professionalExperienceList[i];
        break;
      }
    }

    if (!foundExperience) {
      this.professionalExperienceList.push(experience);
    }

    if (foundExperience) {
      foundExperience = experience;
    }

    const experienceBars = this.createExperienceBars();

    this.setState({
      experienceBars,
    });
  };

  onSaveLinkedAccountClick = accountList => {

    this.linkedAccountsList = accountList;

    const linkedAccountsBars = this.createLinkedAccountsBars();

    this.setState({
      linkedAccountsBars,
    });
  };

  onEditEducationClick = education => {

    if (!education) {
      return;
    }

    this.setState({
      currentEducation: education,
      showEducationPopup: true,
    });
  };

  onEditExperienceClick = experience => {

    if (!experience) {
      return;
    }

    this.setState({
      currentExperience: experience,
      showExperiencePopup: true,
    });
  };

  onDeleteEducationClick = async (data) => {
    
    try {

      if (data.id) {
        
        const ret = await UserProvider.deleteEducation(data.id);

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }
      }

      this.educationalHistory.splice(data.index, 1);

      const educationBars = this.createEducationBars();

      this.setState({
        educationBars: [],
      }, () => {

        this.setState({
          educationBars,
        });
      });
    }
    catch (e) {

      ErrorToast.show();

      Logger.error(e);
    }
  };

  onDeleteExperienceClick = async (data) => {

    try {

      if (data.id) {

        const ret = await UserProvider.deleteExperience(data.id);

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        this.professionalExperienceList.splice(data.index, 1);

        const experienceBars = this.createExperienceBars();

        this.setState({
          experienceBars: [],
        }, () => {
  
          this.setState({
            experienceBars,
          });
        });
      }
    }
    catch (e) {

      ErrorToast.show();
      Logger.error(e);
    }
  };

  deleteLinkedAccount = id => {
    
    if (!id) {
      return;
    }

    UserProvider.deleteLinkedAccount(id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);
          return;
        }
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onNameChange = val => {

    this.updateUnsaved();
    this.name = val;
  };

  onSurnameChange = val => {

    this.updateUnsaved();
    this.surname = val;
  }

  onCountryChange = val => {

    this.updateUnsaved();
    this.country = val && val.value;
  };

  onCityChange = val => {

    this.updateUnsaved();
    this.city = val;
  };

  onContactNumberChange = val => {

    this.updateUnsaved();
    this.contactNumber = val;
  };

  onBirthdayChange = val => {

    this.updateUnsaved();
    this.birthday = val;
  };

  onPersonalSummaryChange = val => {

    this.updateUnsaved();
    this.personalSummary = val;
  };

  onCurrentWorkStatusChange =  val => {

    this.updateUnsaved();
    this.currentWorkStatus = val;
  };

  onHighestLevelOfEducationChange =  val => {

    this.updateUnsaved();
    this.highestEducation = val;
  };

  updateUnsaved = (shouldChange = true) => {

    if (shouldChange) {
      this.hasUnsavedData = true;
    }
  };

  saveChanges = async () => {

    let hasExperienceError = false;
    let hasEducationError = false;

    for (const experience of this.professionalExperienceList) {

      const errors = experience.validate();

      if (!hasExperienceError) {
        hasExperienceError = Object.keys(errors).length;
      }
    }

    for (const education of this.educationalHistory) {

      const errors = education.validate();

      if (!hasEducationError) {
        hasEducationError = Object.keys(errors).length;
      }
    }

    const errors = this.validate();

    if (
      Object.keys(errors).length
      || hasExperienceError
      || hasEducationError
    ) {

      this.setState({
        nameError: '',
        surnameError: '',
        countryError: '',
        cityError: '',
        contactNumberError: '',
        birthdayError: '',
        languageError: '',
        personalSummaryError: '',
        currentWorkStatusError: '',
        highestEducationError: '',
        ...errors,
      });

      document.getElementById('with-common-container').scrollTo({top: 0, behavior: 'smooth'});
      document.getElementById('account-right-container').scrollTo({top: 0, behavior: 'smooth'});

      // settimeout to allow smooth scrolling to finish first
      setTimeout(() => {

        ErrorToast.show(
          'Invalid field values',
          'Some fields have invalid values, please review all forms and try again.'
        );
      }, 300);

      return;
    }

    this.props.common.showLoading();

    let ret;

    if (!this.accountId) {

      ret = await UserProvider.createAccountDetails(
        this.props.common.user.id,
        this.name,
        this.surname,
        this.country,
        this.city,
        this.personalSummary,
        this.currentWorkStatus && this.currentWorkStatus.value,
        this.cvUrl,
        this.profilePicUrl,
        this.contactNumber,
        this.birthday,
        this.highestEducation && this.highestEducation.value,
      );
    }

    if (this.accountId) {

      ret = await UserProvider.updateAccountDetails(
        this.props.common.user.id,
        this.accountId,
        this.name,
        this.surname,
        this.country,
        this.city,
        this.personalSummary,
        this.currentWorkStatus && this.currentWorkStatus.value,
        this.cvUrl,
        this.profilePicUrl,
        this.contactNumber,
        this.birthday,
        this.highestEducation && this.highestEducation.value,
      );
    }

    if (!isValidProviderResult(ret)) {

      ErrorToast.show(
        null,
        'We were unable to update your account, please try again.'
      );

      Logger.error(ret.message || Messages.TechnicalError);

      this.props.common.hideLoading();

      return;
    }

    for (let i = 0; i < this.selectedLanguages.length; i++) {

      if (this.selectedLanguages[i].id) {
        continue;
      }

      try {

        await UserProvider.addLanguage(
          ret.data.id,
          this.selectedLanguages[i].value,
        );
      }
      catch (e) {
        Logger.error(e);
      }
    }

    for (let i = 0; i < this.selectedSkills.length; i++) {

      if (this.selectedSkills[i].id) {
        continue;
      }

      try {

        await UserProvider.addSkill(
          ret.data.id,
          this.selectedSkills[i].value,
        );
      }
      catch (e) {
        Logger.error(e);
      }
    }

    for (let i = 0; i < this.linkedAccountsList.length; i++) {

      let data = this.linkedAccountsList[i];

      if (data.id && !data.value) {

        this.deleteLinkedAccount(data.id);
      }
  
      if (data.id && data.value) {

        try {

          await UserProvider.updateLinkedAccount(
            this.props.common.user.id,
            data.id,
            data.lookupId,
            data.value,
            undefined,
          );
        }
        catch (e) {
          Logger.error(e);
        }
      }
  
      if (!data.id && data.value) {
  
        try {

          await UserProvider.addLinkedAccount(
            this.props.common.user.id,
            data.lookupId,
            data.value,
            undefined,
          );
        }
        catch (e) {
          Logger.error(e);
        }
      }
    }

    let currentExperience;

    for (let i = 0; i < this.professionalExperienceList.length; i++) {

      currentExperience = this.professionalExperienceList[i];

      if (!currentExperience.shouldSave) {
        continue;
      }

      if (currentExperience.id) {

        try {

          await UserProvider.updateExperience(
            this.props.common.user.id,
            currentExperience.id,
            currentExperience.role,
            currentExperience.company,
            currentExperience.employmentType.value,
            currentExperience.startYear.value,
            currentExperience.startMonth.value,
            !currentExperience.isCurrentlyWorkingInThisRole ? currentExperience.endYear.value : undefined,
            !currentExperience.isCurrentlyWorkingInThisRole ? currentExperience.endMonth.value : undefined,
            currentExperience.description,
          );
        }
        catch (e) {
          Logger.error(e);
        }
      }
    }

    let currentEducation;

    for (let i = 0; i < this.educationalHistory.length; i++) {

      currentEducation = this.educationalHistory[i];

      if (!currentEducation.shouldSave) {
        continue;
      }

      if (currentEducation.id) {

        try {

          await UserProvider.updateEducation(
            this.props.common.user.id,
            currentEducation.id,
            currentEducation.degreeLevel.value,
            currentEducation.degreeTitle,
            currentEducation.institutionName,
            currentEducation.country.value,
            currentEducation.startYear.value,
            currentEducation.startMonth.value,
            !currentEducation.isInProgress ? currentEducation.endYear.value : undefined,
            !currentEducation.isInProgress ? currentEducation.endMonth.value : undefined,
            currentEducation.description,
          );

        }
        catch (e) {
          Logger.error(e);
        }
      }
    }

    Chat.client &&
      Chat.client.getUser(this.props.common.user.id.toString())
        .then(user => {

          user.updateAttributes({
            ...user.attributes || {},
            profilePictureUrl: this.profilePicUrl,
          });
        })
        .catch(e => {
          Logger.error(e);
        });

    this.hasUnsavedData = false;

    if (!this.props.isMounted()) {
      return;
    }

    this.props.common.navigate('/account/view');
  };

  createEducationBars = () => {

    if (!Array.isArray(this.educationalHistory) || !this.educationalHistory.length) {
      return [];
    }

    let educationBars = [];
    let education;

    for (let i = 0; i < this.educationalHistory.length; i++) {

      education = this.educationalHistory[i];
      education.index = i;

      if (!education) {
        continue;
      }

      educationBars.push(
        <Accordion
          key={i}
          header={`Formation ${i + 1}`}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}
          isCollapsable={true}
          showArrow={true}>

          <EducationForm
            userId={this.props.common.user.id}
            data={education}
            hideFooter={true}
            onChange={this.updateUnsaved}
            onDeleteClick={this.onDeleteEducationClick}
          />

        </Accordion>
      );

      
      if (i !== (this.educationalHistory.length - 1)) {

        educationBars.push(
          <Space
            key={`education-space-${i}`}
            height={'20px'}
          />
        );
      }
    }

    return educationBars;
  };

  createExperienceBars = () => {

    if (!Array.isArray(this.professionalExperienceList) || !this.professionalExperienceList.length) {
      return [];
    }

    let experienceBars = [];
    let experience;

    for (let i = 0; i < this.professionalExperienceList.length; i++) {

      experience = this.professionalExperienceList[i];
      experience.index = i;

      if (!experience) {
        continue;
      }
      
      experienceBars.push(
        <Accordion
          key={i}
          header={`Experience ${i + 1}`}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}
          isCollapsable={true}
          showArrow={true}>

          <ProfessionalExperienceForm
            userId={this.props.common.user.id}
            data={experience}
            hideFooter={true}
            onChange={this.updateUnsaved}
            onDeleteClick={this.onDeleteExperienceClick}
          />

        </Accordion>
      );

      if (i !== (this.professionalExperienceList.length - 1)) {

        experienceBars.push(
          <Space
            key={`experience-space-${i}`}
            height={'20px'}
          />
        );
      }
    }

    return experienceBars;
  };

  createLinkedAccountsBars = () => {

    if (!Array.isArray(this.linkedAccountsList) || !this.linkedAccountsList.length) {
      return [];
    }

    let linkedAccountsBars = [];
    let account;

    for (let i = 0; i < this.linkedAccountsList.length; i++) {

      account = this.linkedAccountsList[i];

      if (!account) {
        continue;
      }

      account.value = account.value || account.customAccountUrl;

      if (!account.value) {
        continue;
      }

      account.linkedAccountsLookupId = account.linkedAccountsLookupId || account.lookupId;
      account.lookupId = account.lookupId || account.linkedAccountsLookupId;

      linkedAccountsBars.push(
        <LinkedAccountsBar
          key={i}
          data={account}
        />
      );
    }

    return linkedAccountsBars;
  };

  validate =  () => {

    let errors = {};

    if (!this.name) {
      errors.nameError = 'Please enter your name';
    }

    if (!this.surname) {
      errors.surnameError = 'Please enter your surname';
    }

    if (!this.country) {
      errors.countryError = 'Please select a country';
    }

    if (!this.city) {
      errors.cityError = 'Please enter a city';
    }

    if (!this.birthday) {
      errors.birthdayError = 'Please select your date of birth';
    }

    if (!Array.isArray(this.selectedLanguages) || !this.selectedLanguages.length) {
      errors.languageError = 'Please select at least one language';
    }

    if (!this.personalSummary) {
      errors.personalSummaryError = 'Please enter a personal summary';
    }

    if (!this.currentWorkStatus || !this.currentWorkStatus.value) {
      errors.currentWorkStatusError = 'Please select your current work status';
    }

    if (!this.highestEducation || !this.highestEducation.value) {
      errors.highestEducationError = 'Please select your highest level of education';
    }

    if (this.contactNumber !== '00' && this.contactNumber.length < 7 && this.contactNumber.length !== 0) {
      errors.contactNumberError = 'Contact number should contain more than 7 digits';
    }

    return errors;
  };

  load = async () => {

    this.props.common.showLoading();

    let ret = await DataStore.get('ACCOUNT_INFORMATION');

    if (!ret) {
      ret = await UserProvider.getUserInformation(this.props.common.user.id);
    }

    if (!isValidProviderResult(ret)) {

      this.props.common.hideLoading();

      ErrorToast.show(
        null,
        'We were unable to load your account information.  Please try again.',
      );

      Logger.error(ret.message || Messages.TechnicalError);

      return;
    }

    if (Array.isArray(ret.data.account) && ret.data.account.length) {

      const accountInformation = ret.data.account[ret.data.account.length - 1] || {};

      this.accountId = accountInformation.id;
      this.name = accountInformation.name || '';
      this.surname = accountInformation.surname || '';
      this.country = accountInformation.countryId || '';
      this.city = accountInformation.city || '';
      this.birthday = accountInformation.dateOfBirth || '';
      this.contactNumber = accountInformation.phoneNumber || '';
      this.personalSummary = accountInformation.aboutMe || '';
      this.currentWorkStatus = accountInformation.currentWorkStatus || {};
      this.highestEducation = accountInformation.highestEducation || {};
      this.selectedLanguages = accountInformation.languages || [];
      this.selectedSkills = accountInformation.skills || [];
      this.cvUrl = accountInformation.cvUrl;
      this.profilePicUrl = accountInformation.profilePictureUrl;
    }

    this.educationalHistory = ret.data.educationalHistory || [];
    this.professionalExperienceList = ret.data.professionalExperience || [];
    this.linkedAccountsList  = ret.data.linkedAccounts || [];

    const languageList = await LookupProvider.getLanguages();
    const employmentTypes = await LookupProvider.getEmploymentTypes();
    const countryList = await LookupProvider.getCountries();
    const skillsList = await LookupProvider.getSkills();
    const degreeLevelList = await LookupProvider.getDegreeLevels();

    const experienceBars = this.createExperienceBars();
    const educationBars = this.createEducationBars();
    const linkedAccountsBars = this.createLinkedAccountsBars();

    this.setState({
      languageList,
      employmentTypes,
      countryList,
      skillsList,
      degreeLevelList,
      experienceBars,
      educationBars,
      linkedAccountsBars,
    });

    this.props.common.hideLoading();
  };

  shouldBlockNavigation = () => {
    return this.hasUnsavedData;
  };

  componentDidMount() {
    this.load();
  }

  render () {

    return (

      <AccountScreen
        onShowIframePopup={this.props.common.showIframePopup}>

        <RouteLeavingGuard
          when={true}
          navigate={path => this.props.common.navigate(path)}
          shouldBlockNavigation={this.shouldBlockNavigation}
          headerBackgroundColor={Colors.Red3}
          footerBackgroundColor={Colors.Red3}
        />

        <ActionContainer>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Cancel'}
              onClick={this.onCancelChangesClick}
            />

          </ActionButtonContainer>

          <Space/>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Save changes'}
              onClick={this.onSaveChangesClick}
            />

          </ActionButtonContainer>

        </ActionContainer>

        <DesktopSpace
          height={'25px'}  
        />

        <Accordion
          id={'accordion-account-personal-information'}
          header={'Personal information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <ProfilePicContentContainer>

              <AccountProfilePicUpload
                accountId={this.accountId}
                url={this.profilePicUrl}
                onUploadSuccess={this.onProfilePicUploadSuccess}
              />

              <div style={{ flex: 1 }}>

                <TextInputContainer>

                  <TextInput
                    id={'txt-account-name'}
                    flex={'1'}
                    label={'Name'}
                    width={'auto'}
                    defaultValue={this.name}
                    onChange={this.onNameChange}
                    hasError={!!this.state.nameError}
                    errorMessage={this.state.nameError}
                  />

                  <DesktopSpace/>

                  <TextInput
                    id={'txt-account-surname'}
                    flex={'1'}
                    label={'Surname'}
                    width={'auto'}
                    defaultValue={this.surname}
                    onChange={this.onSurnameChange}
                    hasError={!!this.state.surnameError}
                    errorMessage={this.state.surnameError}
                  />

                </TextInputContainer>

                <TextInputContainer>

                  <Select
                    id={'select-account-country'}
                    flex={'1'}
                    width={'auto'}
                    label={'Country'}
                    isSearchable={true}
                    options={this.state.countryList}
                    defaultValue={this.country}
                    onChange={this.onCountryChange}
                    hasError={!!this.state.countryError}
                    errorMessage={this.state.countryError}
                  />

                  <DesktopSpace/>

                  <TextInput
                    id={'txt-account-city'}
                    flex={'1'}
                    width={'auto'}
                    label={'City'}
                    defaultValue={this.city}
                    onChange={this.onCityChange}
                    hasError={!!this.state.cityError}
                    errorMessage={this.state.cityError}
                  />

                  <DesktopSpace/>

                  <Select
                    id={'select-account-languanges'}
                    flex={'1'}
                    width={'auto'}
                    label={'Language(s)'}
                    isMultiSelect={true}
                    displaySelectedInline={true}
                    options={this.state.languageList}
                    defaultOptions={this.selectedLanguages}
                    onChange={this.onLanguageSelectChange}
                    hasError={!!this.state.languageError}
                    errorMessage={this.state.languageError}
                    trashcanColor={Colors.Red3}
                  />

                </TextInputContainer>

                <TextInputContainer>

                  <TextInput
                    id={'txt-account-contact-number'}
                    flex={'1'}
                    width={'auto'}
                    label={'Contact number'}
                    placeholder={'Enter cell...'}
                    defaultValue={this.contactNumber}
                    numeric={true}
                    charMax={15}
                    onChange={this.onContactNumberChange}
                    hasError={!!this.state.contactNumberError}
                    errorMessage={this.state.contactNumberError}
                  />

                  <DesktopSpace/>

                  <DatePicker
                    id={'dp-account-date-of-birth'}
                    flex={'1'}
                    label={'Date of birth'}
                    defaultValue={this.birthday}
                    onChange={this.onBirthdayChange}
                    hasError={!!this.state.birthdayError}
                    errorMessage={this.state.birthdayError}
                    maxDate={new Date()}
                  />

                  <DesktopSpace/>

                  <AccountCvUpload
                    id={'file-account-cv'}
                    accountId={this.accountId}
                    url={this.cvUrl}
                    onUploadSuccess={this.onCvUploadSuccess}
                    onRemove={this.onCvRemoved}
                  />

                </TextInputContainer>

              </div>

            </ProfilePicContentContainer>

            <TextInput
              id={'txt-account-personal-summary'}
              label={'Personal summary'}
              multiline={true}
              width={'100%'}
              defaultValue={this.personalSummary}
              onChange={this.onPersonalSummaryChange}
              hasError={!!this.state.personalSummaryError}
              errorMessage={this.state.personalSummaryError}
              showCharCounter={true}
              charCounterMax={1000}
              charCounterColor={Colors.Red3}
            />

          </ContentContainer>

        </Accordion>

        <Space
          size={'25px'}  
        />

        <Accordion
          header={'Educational information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

          <TextInputContainer
            padding={'0 0 15px 0'}>

              <DesktopOnly>

                <MultiAddButton
                  color={Colors.Red3}
                  counter={0}
                  text={'Add education'}
                  margin={'30px 0 0 0'}
                  hideCounter={true}
                  onClick={() => this.setState({ showEducationPopup: true })}
                />

              </DesktopOnly>

              <DesktopSpace/>

              <Select
                id={'select-account-highest-level-of-education'}
                flex={'1'}
                label={'Highest level of education'}
                width={'auto'}
                options={this.state.degreeLevelList}
                onChange={this.onHighestLevelOfEducationChange}
                defaultValue={this.highestEducation && this.highestEducation.value}
                hasError={!!this.state.highestEducationError}
                errorMessage={this.state.highestEducationError}
              />

              <MobileOnly>

                <MultiAddButton
                  color={Colors.Red3}
                  counter={0}
                  text={'Add education'}
                  margin={'25px 0 5px 0 !important'}
                  hideCounter={true}
                  onClick={() => this.setState({ showEducationPopup: true })}
                />

              </MobileOnly>

              <div style={{flex: 1}}/>

            </TextInputContainer>

            { Array.isArray(this.state.educationBars) && this.state.educationBars.length

              ? this.state.educationBars

              : <NoItemsTextContainer>

                  <Text
                    fontSize={FontSizes.Small}>

                      Seems like you have not listed any previous education yet.
                  </Text>

                  <br/>

                  <Text
                    color={Colors.Gray6}
                    fontSize={FontSizes.Smaller}
                    padding={'0 0 10px 0'}>

                      Use the "add" button to add your previous education to the list.
                  </Text>

                </NoItemsTextContainer>
            }

          </ContentContainer>

        </Accordion>

        <Space
          size={'25px'}  
        />

        <Accordion
          header={'Professional information'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <TextInputContainer
              padding={'0 0 15px 0'}>

              <DesktopOnly>

                <MultiAddButton
                  color={Colors.Red3}
                  counter={0}
                  text={'Add experience'}
                  margin={'30px 0 0 0'}
                  hideCounter={true}
                  onClick={() => this.setState({ showExperiencePopup: true })}
                />

              </DesktopOnly>

              <DesktopSpace/>

              <Select
                id={'select-account-current-work-status'}
                flex={'1'}
                label={'Current work status'}
                width={'auto'}
                options={this.state.employmentTypes}
                onChange={this.onCurrentWorkStatusChange}
                defaultValue={this.currentWorkStatus && this.currentWorkStatus.value}
                hasError={!!this.state.currentWorkStatusError}
                errorMessage={this.state.currentWorkStatusError}
              />

              <DesktopSpace/>

              <Select
                id={'select-account-skills'}
                flex={'1'}
                label={'Skills'}
                width={'auto'}
                isMultiSelect={true}
                displaySelectedInline={true}
                allowOtherAdd={true}
                options={this.state.skillsList}
                defaultOptions={this.selectedSkills}
                trashcanColor={Colors.Red3}
                onChange={this.onSkillSelectChange}
              />

              <MobileOnly>

                <MultiAddButton
                  color={Colors.Red3}
                  counter={0}
                  text={'Add experience'}
                  margin={'25px 0 10px 0 !important'}
                  hideCounter={true}
                  onClick={() => this.setState({ showExperiencePopup: true })}
                />

              </MobileOnly>

            </TextInputContainer>

            { Array.isArray(this.state.experienceBars) && this.state.experienceBars.length

              ? this.state.experienceBars

              : <NoItemsTextContainer>

                  <Text
                    fontSize={FontSizes.Small}>

                      Seems like you have not listed any previous experience yet.
                  </Text>

                  <br/>

                  <Text
                    color={Colors.Gray6}
                    fontSize={FontSizes.Smaller}
                    padding={'0 0 10px 0'}>

                      Use the "add" button to add your previous experience to the list.
                  </Text>

                </NoItemsTextContainer>
            }

          </ContentContainer>

        </Accordion>

        <Space
          size={'25px'}  
        />

        <Accordion
          id={'accordion-account-linked-accounts'}
          header={'Linked accounts'}
          headerColor={Colors.White}
          backgroundColor={Colors.Red3}
          borderRadius={'15px'}
          headerPadding={'5px 15px'}
          contentPadding={'15px'}
          contentBackgroundColor={Colors.White}>

          <ContentContainer>

            <Text
              fontSize={FontSizes.Small}
              fontWeight={'bold'}>
                Add an account or personal website
            </Text>

            <LinkedAccountContainer>

              <MultiAddButton
                color={Colors.Red3}
                counter={0}
                margin={'15px 0 0 0'}
                hideCounter={true}
                onClick={() => {
                  this.setState({ showLinkedAccountsPopup: true })
                }}
              />

              <LinkedAccountDesktopSpace/>

              {Array.isArray(this.state.linkedAccountsBars) && !!this.state.linkedAccountsBars.length && this.state.linkedAccountsBars}

            </LinkedAccountContainer>

          </ContentContainer>

        </Accordion>

        <ActionContainer
          padding={'25px 0'}>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Cancel'}
              onClick={this.onCancelChangesClick}
            />

          </ActionButtonContainer>

          <Space/>

          <ActionButtonContainer>

            <Button
              width={'100%'}
              text={'Save changes'}
              onClick={this.onSaveChangesClick}
            />

          </ActionButtonContainer>

        </ActionContainer>

        <MobileButtonContainer>

          <RoundButton
            onClick={this.onCancelChangesClick}>

            <Image
              src={Images.Icons.EditCancelX}
              height={'60px'}
              width={'60px'}
            />

          </RoundButton>

          <Space />

          <RoundButton
            onClick={this.onSaveChangesClick}>

            <Image
              src={Images.Icons.EditSaveTick}
              height={'60px'}
              width={'60px'}
            />

          </RoundButton>

        </MobileButtonContainer>

        <MobileSpace/>

        { this.state.showExperiencePopup &&

          <Popup
            header={!!(this.state.currentExperience && this.state.currentExperience.id) ? 'Edit Experience' : 'Add Experience'}
            headerBackgroundColor={Colors.Red3}
            onClose={() => {

              this.setState({
                showExperiencePopup: false,
                currentExperience: null,
              }); 
            }}>

            <ProfessionalExperienceForm
              userId={this.props.common.user.id}
              data={this.state.currentExperience}
              onSaveClick={this.onSaveExperienceClick}
              onClose={() => {

                this.setState({
                  showExperiencePopup: false,
                  currentExperience: null,
                }); 
              }}
            />

          </Popup>
        }

        { this.state.showEducationPopup &&

          <Popup
            header={ !!(this.state.currentEducation && this.state.currentEducation.id) ? 'Edit Education' : 'Add Education'}
            headerBackgroundColor={Colors.Red3}
            onClose={() => {

              this.setState({
                showEducationPopup: false,
                currentEducation: null,
              }); 
            }}>

            <EducationForm
              userId={this.props.common.user.id}
              data={this.state.currentEducation}
              onSaveClick={this.onSaveEducationClick}
              onClose={() => {

                this.setState({
                  showEducationPopup: false,
                  currentEducation: null,
                }); 
              }}
            />

          </Popup>
        }

        { this.state.showLinkedAccountsPopup &&

          <Popup
            header={'Linked accounts'}
            headerBackgroundColor={Colors.Red3}
            maxWidth={'500px'}
            onClose={() => {

              this.setState({
                showLinkedAccountsPopup: false,
              }); 
            }}>

            <LinkedAccountsForm
              userId={this.props.common.user.id}
              data={this.linkedAccountsList}
              onSaveClick={this.onSaveLinkedAccountClick}
              onClose={() => {

                this.setState({
                  showLinkedAccountsPopup: false,
                }); 
              }}
            />

          </Popup>
        }

      </AccountScreen>
    );
  }
}

export const AccountEdit = withCommon(
  AccountEditUI, {
    selectedMenuItem: MenuItems.Account,
    sideMenuActionUrl: '/account/mobile/menu',
  }
);