import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  Environment,
  ErrorToast,
  Logger,
  Space,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  IsMobile,
} from 'common';

import {
  Button,
  Popup,
} from 'common/components';

const ImageContainer = styled.div`
  height: 90vh;
  width: 100%;
  background-image: url(${props => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AfriOnTutorial = props => {

  const [state, setState] = React.useState({
    showLoading: true,
    header: 'Loading...',
    image: '',
    steps: [],
    currentStepIndex: 0,
    subStepIndex: 0,
  });

  const onPreviousClick = () => {

    const nextIndex = state.currentStepIndex - 1;

    setState(prevState => ({
      ...prevState,
      ...prevState.steps[nextIndex],
      currentStepIndex: nextIndex,
    }));
  };

  const onNextClick = () => {

    const nextIndex = state.currentStepIndex + 1;

    setState(prevState => ({
      ...prevState,
      ...prevState.steps[nextIndex],
      currentStepIndex: nextIndex,
    }));
  };

  const onCloseClick = () => {
    props.onClose && props.onClose();
  };

  React.useEffect(() => {

    fetch(`${Environment.configHost}tutorial.json`)
      .then(response => response.json())
      .then(data => {

        const identifier = IsMobile ? 'mobileSteps' : 'desktopSteps';

        if (!data || !Array.isArray(data[identifier]) || !data[identifier].length) {

          ErrorToast.show();
          Logger.error('Unable to load tutorial config');
          return;
        }

        const steps = data[identifier];

        setState(prevState => ({
          ...prevState,
          ...steps[0],
          steps,
          showLoading: false,
        }));
      })
      .catch(e => Logger.error(e));

  }, []);

  return (
    <Popup
      headerBackgroundColor={Colors.Green1}
      header={state.header}
      desktopWidth={'90vw'}
      onClose={props.onClose}>

      <ImageContainer
        image={state.image}
      />

      <PopupFooter
        footerBackgroundColor={Colors.Green1}>

        <PopupActionContainer>

          { state.currentStepIndex !== 0 &&

            <PopupActionButtonContainer>

              <Button
                text={'Previous'}
                width={'100%'}
                onClick={onPreviousClick}
              />

            </PopupActionButtonContainer>
          }

          <Space/>

          { state.currentStepIndex !== state.steps.length - 1 &&

            <PopupActionButtonContainer>

              <Button
                text={'Next'}
                width={'100%'}
                onClick={onNextClick}
              />

            </PopupActionButtonContainer>
          }

          { state.currentStepIndex === state.steps.length - 1 &&

            <PopupActionButtonContainer>

              <Button
                text={'Close'}
                width={'100%'}
                onClick={onCloseClick}
              />

            </PopupActionButtonContainer>
          }

        </PopupActionContainer>

      </PopupFooter>

    </Popup>
  );
};