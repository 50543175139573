import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
} from 'common';

import {
  PSmaller,
  Text,
  InformationBubble,
} from 'common/components';

const Container = styled.div`
  position: relative;
  margin: ${props => props.margin || '0 0 10px 0'};
  width: ${props => props.width || '300px'};
  
  ${props => props.flex && `flex: ${props.flex};`}
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

const InputContainer = styled.div`
  border: 1px solid ${props => props.hasError ? Colors.Red : props.backgroundColor || Colors.Gray1};
  background-color: ${props => props.backgroundColor || Colors.Gray1};
  display: flex;
  padding: 5px 15px;
  position: relative;
  border-radius: ${props => props.borderRadius || '20px'};
  align-items: center;
  width: 100%;
  height: ${props => props.height || '40px'};
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${Colors.Gray8};
    transition: background-color 5000s ease-in-out 0s;
  }
`;

const InputShared = color => `
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;

  :focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: ${color || Colors.Gray5};
    font-style: italic;
  }
  ::-moz-placeholder {
    color: ${color || Colors.Gray5};
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: ${color || Colors.Gray5};
    font-style: italic;
  }
  :-moz-placeholder {
    color: ${color || Colors.Gray5};
    font-style: italic;
  }
  :placeholder {
    color: ${color || Colors.Gray5};
    font-style: italic;
  }
`;

const Input = styled.input`
  border: 0;
  background-color: ${Colors.Transparent};
  color: ${Colors.Gray8};
  font-size: ${FontSizes.Small};
  width: 100%;

  ${props => props.type === 'number' &&
  `
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    [type=number] {
      -moz-appearance: textfield;
    }`
  }

  ${props => InputShared(props.placeholderColor)};
`;

const TextArea = styled.textarea`
  border: 0;
  background-color: ${Colors.Transparent};
  color: ${Colors.Gray8};
  font-size: ${FontSizes.Smaller};
  width: 100%;
  height: ${props => props.height || '120px'};
  padding: 15px 0;
  resize: vertical;

  ${props => InputShared(props.placeholderColor)};
`;

const ErrorMessage = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  top: 2px;
  right: 3px;
  position: relative;
  text-align: right;
`;

const CharCounterContainer = styled.div`
  background-color: ${props => props.charCounterColor || Colors.Gray6};
  color: ${Colors.White};
  font-size: ${FontSizes.Smallest};
  border-radius: 0 18px;
  padding: 2px 10px;
  position: absolute;
  top: -1px;
  right: -1px;

  @media ${DeviceMediaQueries.Tablet} {
    font-size: ${FontSizes.Smaller};
    padding: 2px 15px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  position: relative;
`;

export const TextInput = props => {

  const [value, valueSetState] = React.useState(props.value || props.defaultValue || '');

  const onClick = () => {
    typeof props.onClick === 'function' && props.onClick();
  };

  const onBlur = e => {

    if (!e || !e.target) {
      return;
    }

    typeof props.onBlur === 'function' && props.onBlur(e.target.value);
  };

  const onChange = e => {

    if (!e || !e.target) {
      return;
    }

    let val = e.target.value;

    if (props.numeric && isNaN(val)) {
      return;
    }

    const max = props.charMax || props.charCounterMax || 250;

    if (!!max && val.length > max) {
      val = val.substring(0, max);
    }

    valueSetState(val);
    
    typeof props.onChange === 'function' && props.onChange(val, props.onChangeArgs);
  };

  React.useEffect(() => {

      valueSetState(props.value || props.defaultValue || '');
    },
    [props.value, props.defaultValue]
  );

  return (

    <Container
      id={props.id}
      flex={props.flex}
      margin={props.margin}
      width={props.width}
      onClick={onClick}>

      { props.label &&
      
        <LabelContainer>

          <Text
            color={props.labelColor || Colors.Black}
            fontSize={FontSizes.Small}
            padding={'0 0 10px 0'}
            fontWeight={'bold'}>

              {props.label}
          </Text>

          <InformationBubble
            id={props.id}
          />
          
        </LabelContainer>
      }

      <div style={{ display: 'flex', alignItems: 'center' }}>

      <InputContainer
        height={props.height || (props.multiline && 'auto')}
        borderRadius={props.borderRadius}
        hasError={props.hasError}
        backgroundColor={props.backgroundColor}>

        { props.showCharCounter &&

          <CharCounterContainer
            charCounterColor={props.charCounterColor}>

              {`${value.length}/${props.charCounterMax}`}
          </CharCounterContainer>
        }

        { props.prefix &&

          <Text
            fontSize={FontSizes.Small}
            padding={'0 15px 0 0'}
            whiteSpace={'nowrap'}>

              {props.prefix}
          </Text>
        }
        
        { props.multiline
        
          ? <TextArea
              id={props.id}
              placeholder={props.placeholder || 'Enter text...'}
              placeholderColor={props.placeholderColor}
              autoComplete={'off'}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              readOnly={props.readonly || false}
              height={props.height}
            />

          : <Input
              id={props.id}
              type={props.type || 'text'}
              placeholder={props.placeholder || 'Enter text...'}
              placeholderColor={props.placeholderColor}
              autoComplete={'off'}
              onChange={onChange}
              onBlur={onBlur}
              onKeyUp={props.onKeyUp}
              value={value}
              readOnly={props.readonly || false}
              autoFocus={props.autofocus || false}
            />
        }

        { props.postFix &&

          <Text
            fontSize={FontSizes.Small}
            padding={'0 0 0 15px'}
            whiteSpace={'nowrap'}>

              {props.postFix}
          </Text>
        }

      </InputContainer>

      { !props.label &&
        
        <InformationBubble
          id={props.id}
        />
      }

      </div>

      { props.hasError && props.errorMessage &&

        <ErrorMessage>

          <PSmaller
            color={Colors.Red}>

              {props.errorMessage}
          </PSmaller>

        </ErrorMessage>
      }

    </Container>
  );
};