import
  React
from 'react';

import {
  Colors,
  DesktopSpace,
  FontSizes,
  ErrorToast,
  Logger,
  Messages,
  TextInputContainer,
  PopupFooter,
  PopupActionContainer,
  PopupActionButtonContainer,
  Space,
  isValidProviderResult,
} from 'common';

import {
  Button,
  DatePicker,
  H3White,
  Loader,
  Popup,
  Select,
  Text,
  TextInput,
} from 'common/components';

import {
  DesktopInputPlaceholder,
  FormContainer,
  LoadingContainer,
  GoalDeleteContainer,
} from './projectsComponentsStyled';

import {
  LookupProvider,
  ProjectsProvider,
} from 'providers';

export class FundingGoalForm extends React.Component {

  state = {
    fundUpperRangeError: '',
    fundLowerRangeError: '',
    currencyError: '',
    countryError: '',
    requiredDateError: '',
    roiError: '',
    holdPeriodError: '',
    motivationError: '',
    countryList: [],
    currencyList: [],
    isLoading: false,
    showDeletePopup: false,
    hideFooter: this.props.hideFooter || false,
  };

  formValues = {
    fundUpperRange: '',
    fundLowerRange: '',
    currency: {},
    country: {},
    requiredDate: '',
    roi: '',
    holdPeriod: '',
    motivation: '',
  };

  onSubmitClick = () => {

    if (!this.validate()) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    const data = this.getCombinedValues();

    typeof this.props.onClose === 'function' && this.props.onClose(data);
  };

  onDeleteGoalClick = (index) => {

    this.deleteIndex = index;

    this.setState({
      showDeletePopup: true,
    });
  };

  onConfirmDeleteClick = () => {

    this.onHideDeletePopup();

    if (!this.props.funding.id) {

      typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      return;
    }

    ProjectsProvider.deleteFundingGoal(this.props.funding.id)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show();

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        typeof this.props.onDelete === 'function' && this.props.onDelete(this.deleteIndex);
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onHideDeletePopup = () => {

    this.setState({
      showDeletePopup: false,
    });
  };

  onFieldBlur = () => {

    if (!this.props.saveOnBlur) {
      return;
    }

    typeof this.props.onFieldBlur === 'function' && this.props.onFieldBlur(
      this.getCombinedValues()
    );
  };

  onFundUpperRangeChange = val => {

    this.formValues.fundUpperRange = val;

    if (parseFloat(this.formValues.fundLowerRange || 0) > parseFloat(this.formValues.fundUpperRange || 0)) {

      this.setState({
        fundUpperRangeError: 'Upper range cannot be less than lower range',
        fundLowerRangeError: 'Please enter a valid lower range'
      });

      return;
    }

    if (this.state.fundUpperRangeError) {

      this.setState({
        fundUpperRangeError: '',
        fundLowerRangeError: '',
      });

      return;
    }

    if (!this.formValues.fundUpperRange) {
      
      this.setState({
        fundUpperRangeError: 'Please enter a valid maximum capital amount'
      });
    }
  };

  onFundLowerRangeChange = val => {

    this.formValues.fundLowerRange = val;

    if (parseFloat(this.formValues.fundLowerRange || 0) > parseFloat(this.formValues.fundUpperRange || 0)) {

      this.setState({
        fundUpperRangeError: 'Upper range cannot be less than lower range',
        fundLowerRangeError: 'Lower range cannot exceed the upper range'
      });

      return;
    }

    if (this.state.fundLowerRangeError) {

      this.setState({
        fundUpperRangeError: '',
        fundLowerRangeError: '',
      });
    
      return;
    }

    if (!this.formValues.fundLowerRange) {
      
      this.setState({
        fundLowerRangeError: 'Please enter a valid lower range'
      });

      return;
    }
  };

  onCurrencyChange = val => {

    this.formValues.currency = val;

    this.onFieldBlur();

    if (this.state.currencyError) {

      this.setState({
        currencyError: '',
      });
    }
  };

  onCountryChange = val => {

    this.formValues.country = val;

    this.onFieldBlur();
    
    if (this.state.countryError) {

      this.setState({
        countryError: '',
      });
    }
  };

  onRequiredDateChange = val => {

    this.formValues.requiredDate = val;

    this.onFieldBlur();

    if (this.state.requiredDateError) {

      this.setState({
        requiredDateError: '',
      });
    
      return;
    }

    if (!this.formValues.requiredDate) {
      
      this.setState({
        requiredDateError: 'Please select a date'
      });
    }
  };

  onRoiChange = val => {

    this.formValues.roi = val;

    if (this.state.roiError) {

      this.setState({
        roiError: '',
      });
    
      return;
    }

    if (!this.formValues.roi) {
      
      this.setState({
        roiError: 'Please enter a value'
      });
    }
  };

  onHoldPeriodChange = val => {

    this.formValues.holdPeriod = val;

    if (this.state.holdPeriodError) {

      this.setState({
        holdPeriodError: '',
      });
    
      return;
    }

    if (!this.formValues.holdPeriod) {
      
      this.setState({
        holdPeriodError: 'Please enter the hold period'
      });
    }
  };

  onMotivationChange = val => {

    this.formValues.motivation = val;

    if (this.state.motivationError) {

      this.setState({
        motivationError: '',
      });
    
      return;
    }

    if (!this.formValues.motivation) {
      
      this.setState({
        motivationError: 'Please enter your motivation'
      });
    }
  };

  getCombinedValues = () => {

    return {
      ...this.formValues,
      amountFrom: this.formValues.fundLowerRange,
      amountTo: this.formValues.fundUpperRange,
      currencyId: this.formValues.currency.value,
      countryId: this.formValues.country.value,
      cityId: 0,
      roiPercentage: this.formValues.roi,
      description: this.formValues.motivation,
      projectId: this.props.project.id,
      id: this.props.funding && this.props.funding.id,
    };
  };

  validate = () => {

    let errors = {};

    if (!this.formValues.fundUpperRange) {
      errors.fundUpperRangeError = 'Please enter a valid maximum capital amount';
    }

    if (!this.formValues.fundLowerRange) {
      errors.fundLowerRangeError = 'Please enter a valid minimum capital amount';
    }

    if (parseFloat(this.formValues.fundLowerRange || 0) > parseFloat(this.formValues.fundUpperRange || 0)) {
     
      errors.fundUpperRangeError = 'Upper range cannot be less than lower range';
      errors.fundLowerRangeError = 'Lower range cannot exceed the upper range';
    }

    if (!this.formValues.currency || !this.formValues.currency.value) {
      errors.currencyError = 'Please select a currency';
    }

    if (!this.formValues.country || !this.formValues.country.value) {
      errors.countryError = 'Please select a country';
    }

    if (!this.formValues.requiredDate) {
      errors.requiredDateError = 'Please select a date';
    }

    if (!Object.keys(errors).length) {
      return true;
    }

    this.setState(errors);

    return false;
  };

  load = async () => {

    const countryList = await LookupProvider.getProjectCountries();
    const currencyList = await LookupProvider.getCurrencies();

    if (this.props.funding) {

      this.formValues = {
        fundUpperRange: this.props.funding.amountTo || '',
        fundLowerRange: this.props.funding.amountFrom || '',
        currency: this.props.funding.currency || {},
        country: this.props.funding.country || {},
        requiredDate: this.props.funding.requiredDate || '',
        roi: this.props.funding.roiPercentage || '',
        holdPeriod: this.props.funding.holdPeriod || '',
        motivation: this.props.funding.description || '',
      };
    }

    this.setState({
      countryList,
      currencyList,
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {

    return (

      <React.Fragment>

        { this.state.isLoading &&

          <LoadingContainer>

            <Loader
              size={50}
            />

          </LoadingContainer>
        }

        <FormContainer>

          { this.props.header &&

            <H3White
              color={Colors.White}
              fontStyle={'italic'}
              margin={'0 0 15px 0'}>

                {this.props.header}
            </H3White>
          }

          <TextInputContainer>

            <TextInput
              id={'txt-project-goal-funding-upper-range'}
              flex={'1'}
              width={'auto'}
              label={'Maximum capital amount'}
              placeholder={'Enter amount...'}
              numeric={true}
              defaultValue={this.formValues.fundUpperRange}
              onChange={this.onFundUpperRangeChange}
              onBlur={this.onFieldBlur}
              hasError={!!this.state.fundUpperRangeError}
              errorMessage={this.state.fundUpperRangeError}
              charMax={10}
            />

            <DesktopSpace/>

            <TextInput
              id={'txt-project-goal-funding-lower-range'}
              flex={'1'}
              width={'auto'}
              label={'Minimum capital amount'}
              placeholder={'Enter amount...'}
              numeric={true}
              defaultValue={this.formValues.fundLowerRange}
              onChange={this.onFundLowerRangeChange}
              onBlur={this.onFieldBlur}
              hasError={!!this.state.fundLowerRangeError}
              errorMessage={this.state.fundLowerRangeError}
              charMax={10}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-funding-currency'}
              flex={'1'}
              width={'auto'}
              label={'Currency'}
              isSearchable={true}
              defaultValue={this.formValues.currency.value}
              options={this.state.currencyList}
              onChange={this.onCurrencyChange}
              hasError={!!this.state.currencyError}
              errorMessage={this.state.currencyError}
            />

            <DesktopSpace/>

            <Select
              id={'select-project-goal-funding-country'}
              flex={'1'}
              width={'auto'}
              label={'Country'}
              isSearchable={true}
              defaultValue={this.formValues.country.value}
              options={this.state.countryList}
              onChange={this.onCountryChange}
              hasError={!!this.state.countryError}
              errorMessage={this.state.countryError}
            />

          </TextInputContainer>

          <TextInputContainer>

            <DatePicker
              id={'dp-project-goal-funding-required-date'}
              flex={'1'}
              label={'Transaction date'}
              defaultValue={this.formValues.requiredDate}
              onChange={this.onRequiredDateChange}
              hasError={!!this.state.requiredDateError}
              errorMessage={this.state.requiredDateError}
            />

            <DesktopSpace/>

            <TextInput
              id={'txt-project-goal-funding-roi'}
              flex={'1'}
              width={'auto'}
              label={'ROI per year'}
              placeholder={'Enter amount...'}
              numeric={true}
              defaultValue={this.formValues.roi}
              onChange={this.onRoiChange}
              onBlur={this.onFieldBlur}
              hasError={!!this.state.roiError}
              errorMessage={this.state.roiError}
            />

            <DesktopSpace/>

            <TextInput
              id={'txt-project-goal-funding-hold-period'}
              flex={'1'}
              width={'auto'}
              label={'Hold Period (in years)'}
              placeholder={'Enter amount...'}
              numeric={true}
              defaultValue={this.formValues.holdPeriod}
              onChange={this.onHoldPeriodChange}
              onBlur={this.onFieldBlur}
              hasError={!!this.state.holdPeriodError}
              errorMessage={this.state.holdPeriodError}
            />

            <DesktopSpace/>

            <DesktopInputPlaceholder/>

          </TextInputContainer>

          <TextInput
            id={'txt-project-goal-funding-motivation'}
            width={'100%'}
            label={'Further Information'}
            multiline={true}
            margin={'0'}
            defaultValue={this.formValues.motivation}
            onChange={this.onMotivationChange}
            onBlur={this.onFieldBlur}
            hasError={!!this.state.motivationError}
            errorMessage={this.state.motivationError}
            showCharCounter={true}
            charCounterColor={Colors.Blue3}
            charCounterMax={2500}
          />

          { this.props.allowDelete &&

            <GoalDeleteContainer
              onClick={this.onDeleteGoalClick}
            />
          }

        </FormContainer>

        { !this.state.hideFooter &&

          <PopupFooter
            footerBackgroundColor={Colors.Blue3}>

            <PopupActionContainer>

              <PopupActionButtonContainer>

                <Button
                  text={'Cancel'}
                  width={'100%'}
                  onClick={() => { typeof this.props.onClose === 'function' && this.props.onClose(); }}
                />

              </PopupActionButtonContainer>

              <Space/>

              <PopupActionButtonContainer>

                <Button
                  text={'Save'}
                  width={'100%'}
                  onClick={this.onSubmitClick}
                />

              </PopupActionButtonContainer>

            </PopupActionContainer>

          </PopupFooter>
        }

        { this.state.showDeletePopup &&

          <Popup
            header={'Confirm delete'}
            headerBackgroundColor={Colors.Blue3}
            maxWidth={'500px'}
            onClose={this.onHideDeletePopup}>

            <Text
              fontSize={FontSizes.Normal}
              padding={'30px 30px 15px 30px'}>

                Are you sure you want to delete this entry?
            </Text>

            <Text
              color={Colors.Red2}
              fontSize={FontSizes.Small}
              padding={'0 30px 110px 30px'}
              flex={'1'}>

                Please note that this action cannot be undone.
            </Text>

            <PopupFooter
              footerBackgroundColor={Colors.Blue3}>

              <PopupActionContainer>

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'NO'}
                    onClick={this.onHideDeletePopup}
                  />
                
                </PopupActionButtonContainer>

                &nbsp;&nbsp;

                <PopupActionButtonContainer>

                  <Button
                    width={'100%'}
                    text={'YES'}
                    onClick={this.onConfirmDeleteClick}
                  />

                </PopupActionButtonContainer>

              </PopupActionContainer>

            </PopupFooter>

          </Popup>
        }

      </React.Fragment>
    );
  }
}