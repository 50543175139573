import
  React
from 'react';

import {
  Colors,
} from 'common';

import {
  SvgContainer,
  Svg,
  Path,
} from './svgStyled';

export const GoalEmployeeSvg = props => {

  const fill = props.fill || Colors.Green2;

  return (

    <SvgContainer {...props}>

      <Svg xmlns="http://www.w3.org/2000/svg" height={'100%'} width={'100%'} viewBox="0 0 74 74">

        <Path fill={fill} d="M74,37a36.84,36.84,0,0,1-5.38,19.22c-.51.84-1.06,1.66-1.64,2.46a37.23,37.23,0,0,1-8.09,8.15c-.79.58-1.61,1.13-2.45,1.65A37,37,0,0,1,18,68.74c-.85-.51-1.68-1-2.48-1.64a37,37,0,0,1-8.2-8c-.58-.79-1.14-1.61-1.66-2.45a37,37,0,0,1-.12-39.06c.52-.84,1.07-1.66,1.65-2.45A37.23,37.23,0,0,1,15.32,7c.8-.58,1.62-1.13,2.46-1.64a37,37,0,0,1,38.85.26c.84.52,1.65,1.08,2.44,1.66a37.23,37.23,0,0,1,8,8.2c.58.8,1.12,1.63,1.62,2.48A36.68,36.68,0,0,1,74,37Z"/>
        
        <Path fill={Colors.White} d="M20.21,28.76v2.6a1.45,1.45,0,0,1-1.45,1.45h0a1.44,1.44,0,0,1-1.44-1.46v-2L5.52,17.56c.52-.84,1.07-1.66,1.65-2.45L19.79,27.73A1.45,1.45,0,0,1,20.21,28.76Z"/>
        
        <Path fill={Colors.White} d="M34.41,18.57A1.45,1.45,0,0,1,33,20h-4a1.45,1.45,0,0,1-1-.42L15.32,7c.8-.58,1.62-1.13,2.46-1.64L29.53,17.13H33A1.45,1.45,0,0,1,34.41,18.57Z"/>
        
        <Path fill={Colors.White} d="M59.07,7.3,46.76,19.61a1.45,1.45,0,0,1-1,.42H43.11A11.39,11.39,0,0,0,35,23.39l-1.31,1.3a1.41,1.41,0,0,1-1,.43,1.46,1.46,0,0,1-1-2.48L33,21.34a14.24,14.24,0,0,1,10.16-4.21h2L56.63,5.64C57.47,6.16,58.28,6.72,59.07,7.3Z"/>
        
        <Path fill={Colors.White} d="M68.73,18,57.36,29.35v3.43a1.45,1.45,0,0,1-2.9,0v-4a1.46,1.46,0,0,1,.43-1L67.11,15.5C67.69,16.3,68.23,17.13,68.73,18Z"/>
        
        <Path fill={Colors.White} d="M68.62,56.22c-.51.84-1.06,1.66-1.64,2.46l-12.1-12.1a1.46,1.46,0,0,1-.43-1V43a11.31,11.31,0,0,0-.88-4.41,1.47,1.47,0,0,1,.79-1.9,1.43,1.43,0,0,1,1.89.79A14.35,14.35,0,0,1,57.36,43v2Z"/>
        
        <Path fill={Colors.White} d="M58.89,66.83c-.79.58-1.61,1.13-2.45,1.65L45.13,57.18H41.71a1.45,1.45,0,1,1,0-2.9h4a1.45,1.45,0,0,1,1,.42Z"/>
        
        <Path fill={Colors.White} d="M52.08,42.61,41.72,53a14.27,14.27,0,0,1-10.16,4.21h-2L18,68.74c-.85-.51-1.68-1-2.48-1.64L27.91,54.7a1.45,1.45,0,0,1,1-.42h2.63a11.39,11.39,0,0,0,8.11-3.36L50,40.55a1.17,1.17,0,0,0,0-1.67,1.19,1.19,0,0,0-1.57-.1l-3.73,3.73a1.43,1.43,0,0,1-1,.43,1.41,1.41,0,0,1-1-.43,1.44,1.44,0,0,1,0-2l5.58-5.58a1.2,1.2,0,0,0,0-1.67,1.19,1.19,0,0,0-1.57-.1L41,38.79a1.45,1.45,0,0,1-2.06-2l7.34-7.34a1.18,1.18,0,0,0-1.67-1.68l-7.34,7.35a1.46,1.46,0,0,1-2.05,0,1.44,1.44,0,0,1,0-2l5.67-5.69a1.14,1.14,0,0,0,.26-.73,1.19,1.19,0,0,0-2-.84l-9.59,9.59a1.84,1.84,0,0,1-2.87-2.26l1.55-2.56a1.24,1.24,0,0,0-1.29-1.87,1.2,1.2,0,0,0-.8.53l-4,5.93a11.42,11.42,0,0,0-1.94,6.41v4a1.45,1.45,0,0,1-.42,1L7.3,59.07c-.58-.79-1.14-1.61-1.66-2.45L17.31,45V41.53a14.34,14.34,0,0,1,2.44-8l4-5.93a4.15,4.15,0,0,1,5.82-1.08,4.18,4.18,0,0,1,1.75,3l5.79-5.79a4.08,4.08,0,0,1,5.78,0,3.93,3.93,0,0,1,.82,1.17,4.09,4.09,0,0,1,5.46,5.45,4.2,4.2,0,0,1,1.17.82A4.07,4.07,0,0,1,51.51,34,4.14,4.14,0,0,1,51,36a4.54,4.54,0,0,1,1.09.78A4.09,4.09,0,0,1,52.08,42.61Z"/>
      
      </Svg>

    </SvgContainer>

  );
};