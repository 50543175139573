import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  Chat,
  Invites,
  ShareRequest,
  DataStore,
  Environment,
  FontSizes,
  Logger,
  Messages,
  generateLinkedInLoginUrl,
  isValidEmail,
  isValidProviderResult,
} from 'common';

import {
  Button,
  PSmaller,
  Text,
  TextInput,
} from 'common/components';

import {
  withCommon,
} from 'common/hocs';

import {
  Version,
} from 'generated/constants';

import {
  Container,
  InputContainer,
  ToggleContainer,
  ToggleText,
  Header,
} from '../identityStyled';

import {
  IdentityProvider,
  LookupProvider,
  UserProvider,
} from 'providers';

const VersionText = styled(Text)`
  position: fixed;
  top: ${window.innerHeight - 30}px;
  left: 0;
  right: 0;
`;

class LoginUI extends React.Component {

  state = {
    showButtonLoading: false,
    hasEmailError: false,
    hasPasswordError: false,
    showError: false,
    emailMessage: '',
    passwordMessage: '',
    errorMessage: '',
  };

  email = '';
  password = '';

  isFromPayment = !!(new URLSearchParams(window.location.search).get('fromPayment'));

  onEmailChange = val => {
    
    this.email = val;

    if (this.state.hasEmailError || this.state.showError) {

      this.setState({
        hasEmailError: false,
        showError: false,
      });
    }
  };

  onPasswordChange = val => {

    this.password = val;

    if (this.state.hasPasswordError || this.state.showError) {

      this.setState({
        hasPasswordError: false,
        showError: false,
      });
    }
  };

  onKeyUp = e => {

    if (e.key === 'Enter') {
      this.onLoginClick();
    }
  };

  onLoginClick = () => {

    if (!this.validate()) {
      return;
    }

    this.setState({
      showButtonLoading: true,
    });

    IdentityProvider
      .login(
        this.email,
        this.password,
      )
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          Logger.error(ret.message || Messages.TechnicalError);

          this.setState({
            showError: true,
            errorMessage: ret.message || Messages.TechnicalError,
            showButtonLoading: false,
          });

          return;
        }

        DataStore.set(
          'LOGGED_IN_USER',
          ret.data.user,
        );

        DataStore.set(
          'ACCESS_TOKEN',
          ret.data.accessToken,
        );

        if(this.isFromPayment) {

          window.parent.postMessage('login_success', window.location.origin);
          return;
        }

        this.loadAccountInformationAndNavigate(ret.data.user.id);
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          showButtonLoading: false,
        });
      });
  };

  onLinkedInLoginClick = () => {

    if (this.isFromPayment) {

      window.top.window.location.href = generateLinkedInLoginUrl(true);
      return;
    }

    window.location.href = generateLinkedInLoginUrl();
  };

  onSignUpClick = () => {
    this.props.common.navigate('/identity/register' + window.location.search);
  };

  onForgotPasswordClick = () => {
    this.props.common.navigate('/identity/password/reset/email');
  };

  loadAccountInformationAndNavigate = async (userId) => {

    LookupProvider.loadAllLookups()
      .then(() => {

        UserProvider.getUserInformation(userId)
          .then(ret => {

            if (isValidProviderResult(ret)) {

              DataStore.set('ACCOUNT_INFORMATION', ret);
              this.props.common.navigate('/home?login=true');
              return;
            }

            Logger.error(ret.message || Messages.TechnicalError);

            this.setState({
              showError: true,
              errorMessage: ret.message || Messages.TechnicalError,
              showButtonLoading: false,
            });
          })
          .catch(e => {

            Logger.error(e);

            this.setState({
              showError: true,
              errorMessage: Messages.TechnicalError,
              showButtonLoading: false,
            });
          });
      })
      .catch(e => {

        Logger.error(e);

        this.setState({
          showError: true,
          errorMessage: Messages.TechnicalError,
          showButtonLoading: false,
        });
      });;
  };

  validate = () => {

    let emailMessage;
    let passwordMessage;

    if (!this.email) {
      emailMessage = 'Please provide a valid email';
    }

    if (this.email && !isValidEmail(this.email)) {
      emailMessage = 'Incorrect email format';
    }

    if (!this.password) {
      passwordMessage = 'Please provide a valid password';
    }

    if (!emailMessage && !passwordMessage) {
      return true;
    }

    this.setState({
      hasEmailError: !!emailMessage,
      hasPasswordError: !!passwordMessage,
      emailMessage,
      passwordMessage,
    });

    return false;
  };

  componentDidMount() {

    DataStore.clearAll();
    Chat.shutdown();
    Invites.shutdown();
    ShareRequest.shutdown();
  }

  render () {

    return (

      <Container>

        { !this.isFromPayment &&

          <Header
            textAlign={'center'}>

              Welcome to AfriOn
          </Header>
        }

        <InputContainer>

          <ToggleContainer>

            <ToggleText
              selected={true}>

                login
            </ToggleText>

            <ToggleText
              onClick={this.onSignUpClick}>
                
                sign up
            </ToggleText>

          </ToggleContainer>

          { this.state.showError &&
            
            <PSmaller
              color={Colors.Red}
              padding={'0 0 10px 0'}
              textAlign={'center'}
              width={'100%'}>

                {this.state.errorMessage}
            </PSmaller>
          }

          <TextInput
            type={'email'}
            placeholder={'Email'}
            width={'100%'}
            onChange={this.onEmailChange}
            onKeyUp={this.onKeyUp}
            hasError={this.state.hasEmailError}
            errorMessage={this.state.emailMessage}
          />

          <TextInput
            type={'password'}
            placeholder={'Password'}
            width={'100%'}
            onChange={this.onPasswordChange}
            onKeyUp={this.onKeyUp}
            hasError={this.state.hasPasswordError}
            errorMessage={this.state.passwordMessage}
          />

          <Button
            margin={'0 0 10px 0'}
            text={'SIGN IN'}
            width={'100%'}
            showLoading={this.state.showButtonLoading}
            onClick={this.onLoginClick}
          />

          { false &&

            <Button
              backgroundColor={Colors.PrimaryOne}
              activeColor={Colors.White}
              margin={'0 0 5px 0'}
              text={'LINKEDIN LOGIN'}
              width={'100%'}
              onClick={this.onLinkedInLoginClick}
            />
          }

          { !this.isFromPayment &&

            <Text
              fontSize={FontSizes.Smaller}
              fontWeight={'bold'}
              padding={'5px 0 0 3px'}
              onClick={this.onForgotPasswordClick}>
              
                Forgot Password
            </Text>
          }

          <VersionText
            fontSize={FontSizes.Smallest}
            textAlign={'center'}>

              {Environment.name + ' ' + Version}
          </VersionText>

        </InputContainer>

      </Container>
    );
  }
}

export const Login = withCommon(
  LoginUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);
