import
  axios
from 'axios';

import {
  DataStore,
} from 'common';

const setHeaders = (headers = {}) => {

  headers = setAuthHeader(headers);
  headers = setTraceHeader(headers);

  return headers;
};

const setAuthHeader = (headers = {}) => {

  const accessToken = DataStore.get('ACCESS_TOKEN');

  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return headers;
};

const setTraceHeader = (headers = {}) => {

  let traceId = DataStore.get('TRACE_ID');

  if (!traceId) {

    traceId = crypto.randomUUID
      ? crypto.randomUUID()
      : new Date().getTime() + '-' + crypto.getRandomValues(new Uint16Array(1))[0] + '-' + crypto.getRandomValues(new Uint32Array(1))[0];

    DataStore.set('TRACE_ID', traceId);
  }

  headers['x-trace-id'] = traceId;
  return headers;
};

export const get = async (url, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.get(
    url,
    {
      headers: headers,
      withCredentials: false
    }
  );
}

export const post = async (url, data, headers = {}, options = {}) => {

  headers = setHeaders(headers);

  const requestOptions = {
    headers: headers,
    withCredentials: false,
    ...options,
  };

  return await axios.post(
    url,
    data,
    requestOptions,
  );
}

export const put = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.put(
    url,
    data,
    {
      headers: headers,
      withCredentials: false,
    }
  );
}

export const patch = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.patch(
    url,
    data,
    {
      headers: headers,
      withCredentials: false,
    }
  );
}

export const deleteCall = async (url, data, headers = {}) => {

  headers = setHeaders(headers);

  return await axios.delete(
    url,
    {
      headers: headers,
      withCredentials: false,
      data,
    }
  );
}
