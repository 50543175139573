import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Messages,
  ErrorToast,
  Logger,
  Space,
  Image,
  Images,
  InformationIcons,
  isValidProviderResult,
} from 'common';

import {
  Button,
  PSmall,
  Text,
  Popup,
} from 'common/components';

import {
  UserProvider,
} from 'providers';

const Container = styled.div`
  position: relative;
  flex: 1;
  padding: 2px 0 15px 0;

  @media ${DeviceMediaQueries.Laptop} {
    padding: 0;
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${props => props.padding && `padding: ${props.padding};`};
`;

const InformationContainer = styled.div`
  background-color: ${Colors.White};
  font-size: ${FontSizes.Smaller};
  position: absolute;
  border: 1px solid ${Colors.Gray6};
  border-radius: 10px;
  padding: 10px;
  top: ${props => props.top || '25px'};
  max-width: 100%;
  z-index: 5;

  ${props => props.floatRight ? 'right: 0' : 'left: 0'};
`;

export class AccountCvUpload extends React.Component {

  state = {
    isUploading: false,
    uploadPercentage: 0,
    showRemoveConfirm: false,
    url: this.props.url,
    showInformation: false,
  };

  uploadInput = React.createRef();

  onShowInformation = () => {

    this.setState({
      showInformation: true
    });
  };

  onHideInformation = () => {

    this.setState({
      showInformation: false
    });
  };

  onUploadClick = () => {

    this.uploadInput
    && this.uploadInput.current
    && typeof this.uploadInput.current.click === 'function'
    && this.uploadInput.current.click();
  };

  onRemoveCvClick = () => {

    this.setState({
      showRemoveConfirm: true,
    });
  };

  onConfirmRemoveCvClick = () => {

    this.setState({
      showRemoveConfirm: false,
    });

    UserProvider.removeCv(this.props.accountId)
      .then(ret => {

        if (!isValidProviderResult(ret)) {

          ErrorToast.show('', ret.message || Messages.TechnicalError);

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        this.props.onRemove && this.props.onRemove();

        this.setState({
          url: '',
        });
      })
      .catch(e => {

        ErrorToast.show();

        Logger.error(e);
      });
  };

  onChange = e => {

    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    this.upload(e.target.files[0]);
  };

  onFileProgressChange = (e) => {

    if (!e || !e.loaded || !e.total) {
      return;
    }

    this.setState({
      uploadPercentage: Math.round((e.loaded / e.total) * 100),
    });
  };

  upload = (file) => {

    if (file.size > 10 * 1024 * 1024) {

      ErrorToast.show('File too big', 'Please make sure that your file is not bigger than 10MB.');
      return;
    }

    this.setState({
      isUploading: true,
    });

    UserProvider
      .uploadCv(
        file,
        this.props.accountId,
        this.onFileProgressChange,
      )
      .then(ret => {

        this.setState({
          isUploading: false,
        });

        if (!isValidProviderResult(ret) || !ret.data.url) {

          ErrorToast.show('', ret.message || Messages.TechnicalError);

          Logger.error(ret.message || Messages.TechnicalError);

          return;
        }

        this.props.onUploadSuccess && this.props.onUploadSuccess(ret.data.url);

        this.setState({
          url: ret.data.url,
        });
      })
      .catch(e => {

        this.setState({
          isUploading: false,
        });

        ErrorToast.show();

        Logger.error(e);
      });
  };

  componentDidUpdate(prevProps) {

    if (prevProps.url !== this.props.url) {

      this.setState({
        url: this.props.url,
      });
    }
  }

  render () {

    return (

      <Container>

        <UploadInput
          ref={this.uploadInput}
          type={'file'}
          accept={'application/pdf,.doc,.docx'}
          onChange={this.onChange}
        />

        <PSmall
          padding={'0 0 10px 0'}
          fontWeight={'bold'}>

            Curriculum vitae
        </PSmall>

        
        { !!InformationIcons()[this.props.id] &&

          <>

            <Image
              height={'19px'}
              width={'30px'}
              padding={'5px 0 0 0'}
              src={Images.Icons.InformationIcon}
              onMouseOver={this.onShowInformation}
              onMouseLeave={this.onHideInformation}
              onClick={(e) => { e.stopPropagation() }}
            />

            { this.state.showInformation &&

              <InformationContainer>{InformationIcons()[this.props.id]}</InformationContainer>
            }

          </>
        }

        <div style={{ display: 'flex' }}>

          { !this.state.isUploading && this.state.url &&
            <>

            <Button
              fontSize={FontSizes.Smaller}
              text={'Remove CV'}
              width={'100%'}
              height={'40px'}
              onClick={this.onRemoveCvClick}
            />

            <Space/>

            <Button
              fontSize={FontSizes.Smaller}
              text={'Update CV'}
              width={'100%'}
              height={'40px'}
              onClick={this.onUploadClick}
            />

            </>
          }

          { !this.state.isUploading && !this.state.url &&
          
            <Button
              fontSize={FontSizes.Smaller}
              text={'Add CV'}
              width={'100%'}
              height={'40px'}
              onClick={this.onUploadClick}
            />
          }

          { this.state.isUploading &&

            <>
              <br />
              <PSmall>{this.state.uploadPercentage === 0 || this.state.uploadPercentage === 100 ? 'Uploading...' : `${this.state.uploadPercentage}%`}</PSmall>
            </>
          }

        </div>

        { this.state.showRemoveConfirm &&

          <Popup
            header={'Remove CV'}
            headerBackgroundColor={Colors.Red3}
            maxWidth={'500px'}
            onClose={() => this.setState({ showRemoveConfirm: false})}>

              <Text
                fontSize={FontSizes.Small}
                padding={'20px 20px 0 20px'}>

                  Are you sure you want to remove your CV?
              </Text>

              <Text
                color={Colors.Red2}
                fontSize={FontSizes.Small}
                padding={'0 20px 110px 20px'}
                flex={'1'}>

                  Please note that this action cannot be undone.
              </Text>

              <ButtonContainer
                backgroundColor={Colors.Red3}
                padding={'15px'}>

                <Button
                  text={'Cancel'}
                  height={'40px'}
                  width={'48%'}
                  onClick={() => this.setState({showRemoveConfirm: false})}
                />

                <Button
                  text={'Remove CV'}
                  height={'40px'}
                  width={'48%'}
                  onClick={this.onConfirmRemoveCvClick}
                />

              </ButtonContainer>
            </Popup>
          }

      </Container>
    );
  }
}