import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  DeviceMediaQueries,
} from 'common';

import {
  Text,
} from 'common/components';

import {
  MatchCard,
} from './MatchCard';

const Container = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto;
  width: 100%;

  @media ${DeviceMediaQueries.Tablet} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media ${DeviceMediaQueries.LaptopM} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media ${DeviceMediaQueries.LaptopL} {
    grid-gap: 20px 6%;
  }

  @media ${DeviceMediaQueries.desktop} {
    grid-gap: 20px 12%;
  }
`;

export const MatchGrid = props => {

  let matches = props.matches || [];
  let matchList = [];

  for (let i = 0; i < matches.length; i++) {

    const match = matches[i];

    if (!match || match.hidden) {
      continue;
    }

    matchList.push(
      <MatchCard
        key={`${match.projectId}-${i}`}
        project={match}
        onFullProfileClick={() => props.onFullProfileClick(match)}
        onRemoveMatch={props.onRemoveMatch}
      />
    );
  }

  return (

    <>
    { Array.isArray(matchList) && matchList.length
      
      ? <Container>
          {matchList}
        </Container>
    
      : <Text
          width={'100%'}>
            There are currently no projects which correspond to these filters.<br/>
            You can check again later or try a different search.<br/>
            <br/>
            We will notify you as soon as some great projects become available.<br/>
            <br/>
            More and more users are listing their projects on AfriOn every day.<br/>
            So others can find and connect with you in the meantime.<br/>
            You are ahead of the game with AfriOn!
        </Text>
    }
    </>
  );
};