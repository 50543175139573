import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  withCommon,
} from 'common/hocs';

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PaymentFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

const PaymentGatewayUI = props => {

  const url = new URLSearchParams(window.location.search).get('url');

  return (

    <Container>

      <PaymentFrame
        border={0}
        src={url}
      />

    </Container>
  );
};

export const PaymentGateway = withCommon(
  PaymentGatewayUI, {
    showHeader: false,
    showSideMenu: false,
    requiresAuth: false,
    addExitSafeguard: false,
  }
);