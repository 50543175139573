import
  React
from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import
  styled
from 'styled-components/macro';

import {
  SplashScreen,
} from '@capacitor/splash-screen';

import {
  StatusBar,
  Style,
} from '@capacitor/status-bar';

import {
  Colors,
  DataStore,
  Environment,
  FontSizes,
  ErrorToast,
  SuccessToast,
  Logger,
  InformationBubbleContainer,
  addColorTransparency,
} from 'common';

import {
  AfriOnTutorial,
  Button,
  H3,
  Toast,
} from 'common/components';

import {
  Routes,
} from 'navigation';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const NewVersionOverlay = styled.div`
  background-color: ${addColorTransparency(Colors.Black, 0.6)};
  font-size: 16px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  z-index: 999999;
`;

const NewVersionContainer = styled.div`
  background: ${Colors.White};
  font-size: ${FontSizes.Small};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  box-shadow: 0 0 5px 0 ${Colors.Gray8};
  text-align: center;
  max-width: 100%;
`;

const NewVersionText = styled.div`
  font-size: ${FontSizes.Small};
  padding: 15px 0 20px 0;
`;

function App() {

  const [errorToast, setStateErrorToast] = React.useState({
    show: false,
    heading: '',
    subHeading: '',
  });

  const [successToast, setStateSuccessToast] = React.useState({
    show: false,
    heading: '',
    subHeading: '',
  });

  const [unauthenticatedPrompt, unauthenticatedPromptSetState] = React.useState(false);
  const [unauthenticatedButtonLoading, unauthenticatedButtonLoadingSetState] = React.useState(false);

  const [showUpdatePrompt, showUpdatePromptSetState] = React.useState(false);
  const [showButtonLoading, showButtonLoadingSetState] = React.useState(false);

  const [showTutorial, showTutorialSetState] = React.useState(false);

  const onShowUnathedPrompt = () => {

    if (App.isUnauthShowing) {
      return;
    }

    App.isUnauthShowing = true;

    unauthenticatedPromptSetState(true);
  };

  const onShowErrorToast = (
    heading,
    subHeading,
  ) => {

    if (App.isUnauthShowing) {
      return;
    }

    setStateErrorToast({
      show: true,
      heading,
      subHeading,
    });
  };

  const onHideErrorToast = () => {

    setStateErrorToast(prevState => ({
      ...prevState,
      show: false,
    }));
  };

  const onShowSuccessToast = (
    heading,
    subHeading,
  ) => {

    setStateSuccessToast({
      show: true,
      heading,
      subHeading,
    });
  };

  const onHideSuccessToast = () => {

    setStateSuccessToast(prevState => ({
      ...prevState,
      show: false,
    }));
  };

  const onUnauthenticatedButtonClick = () => {

    unauthenticatedButtonLoadingSetState(true);

    window.location.href = `/identity/login`;
  };

  const onUpdateAppClick = () => {

    showButtonLoadingSetState(true);

    window.registration && window.registration.waiting && window.registration.waiting.postMessage({
      type: 'SKIP_WAITING'
    });

    window.location.reload();
  };

  const setIOSStatusBar = async () => {
    await StatusBar.setStyle({ style: Style.Dark });
  };

  const hideIOSSplashScreen = async () => {
    await SplashScreen.hide();
  };

  const showInformationBubble = (text, e) => {

    let infoContainerRef = document.getElementById('info-bubble-main-container-hidden');

    if (!infoContainerRef || !e) {
      return;
    }

    infoContainerRef.innerText = text;

    const posy = (window.innerHeight - (e.clientY + infoContainerRef.offsetHeight + 20)) < 0
      ? e.clientY - infoContainerRef.offsetHeight
      : e.clientY + 20;

    const infoContainerX = window.innerWidth < 550 ? 550 : infoContainerRef.offsetWidth;
    const posx = e.clientX - (infoContainerX || 0) / 2;

    const bubblecontainer = document.getElementById('info-bubble-main-container');

    bubblecontainer.innerText = text;
    bubblecontainer.style.display = 'block';
    bubblecontainer.style.left = `${(posx > 0 ? posx : 0) || 0}px`;
    bubblecontainer.style.top = `${posy < 0 ? 0 : posy}px`;

  };

  const hideInformationBubble = () => {

    const bubblecontainer = document.getElementById('info-bubble-main-container');
    bubblecontainer.style.display = 'none';
  };

  const load = () => {

    ErrorToast.init(
      onShowErrorToast,
      onHideErrorToast,
    );

    SuccessToast.init(
      onShowSuccessToast,
      onHideSuccessToast,
    );

    setIOSStatusBar()
      .catch(e => Logger.error());

    hideIOSSplashScreen()
      .catch(e => Logger.error());

    serviceWorkerRegistration.register({
      onUpdate: registration => {

        if (registration.waiting && registration.waiting.state === 'installed') {

          window.registration = registration;
          showUpdatePromptSetState(true);
        }
      }
    });

    const t = new Date().getTime();

    fetch(`${Environment.configHost}info-bubbles.json?v=${t}`)
      .then(response => response.json())
      .then(data => DataStore.set('INFO_BUBBLES', data))
      .catch(e => Logger.error(e));
    
    fetch(`${Environment.configHost}config.json?v=${t}`)
      .then(response => response.json())
      .then(data => DataStore.set('MAIN_CONFIG', data))
      .catch(e => Logger.error(e));

    window.showUnauthenticatedPrompt = onShowUnathedPrompt;
    window.showTutorial = () => showTutorialSetState(true);

    window.showInformationBubble = showInformationBubble;
    window.hideInformationBubble = hideInformationBubble;
  };

  React.useEffect(
    load,
    []
  );

  let routes = [];
  let route;

  for (const key in Routes) {

    route = Routes[key];

    if (route.default) {

      routes.push(
        <Route exact path={'/'} key={'default-path'}>
          <Redirect to={route.path} />
        </Route>
      );
    }

    routes.push(
      <Route
        key={key}
        component={route.component}
        path={route.path}
        exact={true}
      />
    );
  }

  return (

    <React.Fragment>
      
      <Router>

        <Switch>
          {routes}
        </Switch>

      </Router>

      { showUpdatePrompt &&

        <NewVersionOverlay>

          <NewVersionContainer>

            <H3 margin={'0'}>New version</H3>

            <NewVersionText>A new version of this app is available.</NewVersionText>

            <Button
              showLoading={showButtonLoading}
              text={'Update'}
              onClick={onUpdateAppClick}
            />

          </NewVersionContainer>

        </NewVersionOverlay>
      }

      { unauthenticatedPrompt &&

        <NewVersionOverlay>

          <NewVersionContainer>

            <H3 margin={'0'}>Session expired</H3>

            <NewVersionText>Your session has expired</NewVersionText>

            <Button
              showLoading={unauthenticatedButtonLoading}
              text={'Back to login'}
              onClick={onUnauthenticatedButtonClick}
            />

          </NewVersionContainer>

        </NewVersionOverlay>
      }

      { showTutorial &&
      
        <AfriOnTutorial
          onClose={() => showTutorialSetState(false)}
        />
      }

      {/** this is used to get the container height and width **/}
      <InformationBubbleContainer
        style={{ display: 'block' }}
        top={-10000}
        left={-10000}
        maxWidth={window.innerWidth < 550 ? `${window.innerWidth}px` : '550px'}
        id={'info-bubble-main-container-hidden'}
      />

      {/** we had to add this here to get past a safari/ios bug **/}
      <InformationBubbleContainer
        id={'info-bubble-main-container'}
      />

      <Toast
        heading={successToast.heading}
        subHeading={successToast.subHeading}
        show={successToast.show}
        type={'success'}
        onClose={onHideSuccessToast}
      />

      <Toast
        heading={errorToast.heading}
        subHeading={errorToast.subHeading}
        show={errorToast.show}
        type={'error'}
        onClose={onHideErrorToast}
      />

    </React.Fragment>
  );
}

export default App;
