import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  FontSizes,
  Images,
} from 'common';

const Container = styled.div`
  background-image: url(${props => props.image || Images.Icons.ProfilePlaceholder});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${props => (props.size && props.size / 2) || 65}px;
  height: ${props => props.size || 130}px;
  width: ${props => props.size || 130}px;
  position: relative;
  overflow: hidden;
`;

const LabelContainer = styled.div`
  background-color: ${props => props.backgroundColor || Colors.Red1};
  color: ${Colors.White};
  font-size: ${FontSizes.Tiny};
  font-weight: bold;
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 2px 0 10px 0;
`;

export const ProfileImage = props => (

  <Container
    image={props.image}
    size={props.size}>

    { !!props.label &&

      <LabelContainer backgroundColor={props.labelBackgroundColor}>{props.label}</LabelContainer>
    }

  </Container>
);