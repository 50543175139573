import
  React
from 'react';

import
  styled
from 'styled-components';

import {
  Colors,
  FontSizes,
  PopupFooter,
  Images,
  Image,
} from 'common';

import {
  Button,
  Text,
  TextInput,
} from 'common/components';

import {
  Container,
  PopupContent,
  ActionContainer,
  ActionButtonContainer,
} from './accountComponentsStyled';

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 15px 0;
`;

export class LinkedAccountsForm extends React.Component {

  data = this.props.data || [];

  twitterValue = this.data.find(d => d.linkedAccountsLookupId === 1) || {};
  facebookValue = this.data.find(d => d.linkedAccountsLookupId === 2) || {};
  instagramValue = this.data.find(d => d.linkedAccountsLookupId === 3) || {};
  linkedInValue = this.data.find(d => d.linkedAccountsLookupId === 4) || {};
  websiteValue = this.data.find(d => d.linkedAccountsLookupId === 5) || {};

  onValueChange = (val, data) => {

    if (!data) {
      return;
    }

    if (!this.data) {
      this.data = [];
    }

    let existingValue = this.data.find(d => d.linkedAccountsLookupId === data.linkedAccountsLookupId);

    if (!existingValue) {
      existingValue = this.data.find(d => d.lookupId === data.linkedAccountsLookupId);
    }

    if (existingValue) {

      existingValue.value = val;
      existingValue.customAccountUrl = val;
      existingValue.lookupId = data.linkedAccountsLookupId;
      return;
    }

    this.data.push({
      id: data.id,
      lookupId: data.linkedAccountsLookupId,
      linkedAccountsLookupId: data.linkedAccountsLookupId,
      value: val,
      customAccountUrl: val,
    });
  };

  onSaveClick = () => {

    typeof this.props.onSaveClick === 'function' && this.props.onSaveClick(this.data);

    typeof this.props.onClose === 'function' && this.props.onClose();
  };

  render () {

    return (

      <React.Fragment>

        <PopupContent>

          <Container>

            <Row>

              <Image
                src={Images.Icons.FacebookDark}
                height={'40px'}
              />

              <Text
                fontSize={FontSizes.Regular}
                fontWeight={'bold'}
                padding={'0 15px'}
                width={'130px'}>

                  Facebook
              </Text>

              <TextInput
                id={'txt-linked-accounts-facebook'}
                flex={1}
                margin={'0'}
                width={'auto'}
                onChangeArgs={{
                  ...this.facebookValue,
                  linkedAccountsLookupId: 2,
                }}
                onChange={this.onValueChange}
                defaultValue={this.facebookValue.accountLink}
              />

            </Row>

            <Row>

              <Image
                src={Images.Icons.InstagramDark}
                height={'40px'}
              />

              <Text
                fontSize={FontSizes.Regular}
                fontWeight={'bold'}
                padding={'0 15px'}
                width={'130px'}>

                  Instagram
              </Text>

              <TextInput
                id={'txt-linked-accounts-instagram'}
                flex={1}
                margin={'0'}
                width={'auto'}
                onChangeArgs={{
                  ...this.instagramValue,
                  linkedAccountsLookupId: 3,
                }}
                onChange={this.onValueChange}
                defaultValue={this.instagramValue.accountLink}
              />

            </Row>

            <Row>

              <Image
                src={Images.Icons.LinkedInDark}
                height={'40px'}
              />

              <Text
                fontSize={FontSizes.Regular}
                fontWeight={'bold'}
                padding={'0 15px'}
                width={'130px'}>

                  LinkedIn
              </Text>

              <TextInput
                id={'txt-linked-accounts-linkedin'}
                flex={1}
                margin={'0'}
                width={'auto'}
                onChangeArgs={{
                  ...this.linkedInValue,
                  linkedAccountsLookupId: 4,
                }}
                onChange={this.onValueChange}
                defaultValue={this.linkedInValue.accountLink}
              />

            </Row>

            <Row>

              <Image
                src={Images.Icons.TwitterDark}
                height={'40px'}
              />

              <Text
                fontSize={FontSizes.Regular}
                fontWeight={'bold'}
                padding={'0 15px'}
                width={'130px'}>

                  Twitter
              </Text>

              <TextInput
                id={'txt-linked-accounts-twitter'}
                flex={1}
                margin={'0'}
                width={'auto'}
                onChangeArgs={{
                  ...this.twitterValue,
                  linkedAccountsLookupId: 1,
                }}
                onChange={this.onValueChange}
                defaultValue={this.twitterValue.accountLink}
              />

            </Row>

            <Row>

              <Image
                src={Images.Icons.WebsiteDark}
                height={'40px'}
              />

              <Text
                fontSize={FontSizes.Regular}
                fontWeight={'bold'}
                padding={'0 15px'}
                width={'130px'}>

                  Website
              </Text>

              <TextInput
                id={'txt-linked-accounts-website'}
                flex={1}
                margin={'0'}
                width={'auto'}
                onChangeArgs={{
                  ...this.websiteValue,
                  linkedAccountsLookupId: 5,
                }}
                onChange={this.onValueChange}
                defaultValue={this.websiteValue.accountLink}
              />

            </Row>

          </Container>

        </PopupContent>

        <PopupFooter
          footerBackgroundColor={Colors.Red3}>

          <ActionContainer>

            <ActionButtonContainer>

              <Button
                width={'100%'}
                text={'Cancel'}
                onClick={() => { this.props.onClose && this.props.onClose(); }}
              />

            </ActionButtonContainer>

            &nbsp;&nbsp;

            <ActionButtonContainer>

              <Button
                width={'100%'}
                text={'Save'}
                onClick={this.onSaveClick}
              />

            </ActionButtonContainer>

          </ActionContainer>

        </PopupFooter>

      </React.Fragment>
    );
  }
}