import
  React
from 'react';

import
  styled
from 'styled-components/macro';

import {
  Colors,
  DeviceMediaQueries,
  FontSizes,
  Messages,
  ErrorToast,
  Logger,
  Image,
  Images,
  isValidProviderResult,
  imageFileToCompressedBlob,
} from 'common';

import {
  PSmall,
  Text,
} from 'common/components';

import {
  UserProvider,
} from 'providers';

const Container = styled.div`
  position: relative;

  @media ${DeviceMediaQueries.Laptop} {
    padding: 0 25px 0 0;
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadButton = styled.div`
  background-color: ${Colors.Gray2};
  cursor: pointer;
  border-radius: 100px;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px auto;

  ${props => props.picUrl && `
    background-image: url(${props.picUrl});
    background-position: center;
    background-size: cover;
  `}

  @media ${DeviceMediaQueries.Laptop} {
    margin: 0 0 15px 0;
  }
`;

export class AccountProfilePicUpload extends React.Component {

  state = {
    url: this.props.url,
    isUploading: false,
    uploadPercentage: 0,
  };

  uploadInput = React.createRef();

  onUploadClick = () => {

    this.uploadInput
    && this.uploadInput.current
    && typeof this.uploadInput.current.click === 'function'
    && this.uploadInput.current.click();
  };

  onChange = e => {

    if (!e || !e.target || !e.target.files || !e.target.files.length) {
      return;
    }

    this.upload(e.target.files[0]);
  };

  onFileProgressChange = (e) => {

    if (!e || !e.loaded || !e.total) {
      return;
    }

    this.setState({
      uploadPercentage: Math.round((e.loaded / e.total) * 100),
    });
  };

  upload = (file) => {

    try {

      if (file.size > 10 * 1024 * 1024) {

        ErrorToast.show('File too big', 'Please make sure that your file is not bigger than 10MB.');
        return;
      }

      this.setState({
        url: '',
        isUploading: true,
        uploadPercentage: 0,
      });

      imageFileToCompressedBlob(file, (blob) => {

        UserProvider
          .uploadProfilePicture(
            new File([blob], file.name, { type: blob.type, lastModified: new Date().getTime() }),
            this.props.accountId,
            this.onFileProgressChange,
          )
          .then(ret => {

            this.setState({
              isUploading: false,
            });

            if (!isValidProviderResult(ret) || !ret.data.url) {

              ErrorToast.show('', ret.message || Messages.TechnicalError);

              Logger.error(ret.message || Messages.TechnicalError);

              return;
            }

            this.props.onUploadSuccess && this.props.onUploadSuccess(ret.data.url);

            this.setState({
              url: ret.data.url,
            });

          })
          .catch(e => {

            this.setState({
              isUploading: false,
            });

            ErrorToast.show();

            Logger.error(e);
          });
      });

    }
    catch (e) {
     
      this.setState({
        isUploading: false,
      });

      ErrorToast.show();

      Logger.error(e);
    }
  };

  componentDidUpdate(prevProps) {

    if (prevProps.url !== this.props.url) {

      this.setState({
        url: this.props.url,
      });
    }
  }

  render () {

    return (

      <Container>

        <UploadInput
          ref={this.uploadInput}
          type={'file'}
          accept={'image/png,image/jpeg'}
          onChange={this.onChange}
        />

        <PSmall
          padding={'0 0 10px 0'}
          fontWeight={'bold'}>

            Profile picture
        </PSmall>

        <UploadButton
          picUrl={this.state.url}
          onClick={this.onUploadClick}>

          { !this.state.url && this.state.isUploading &&

            <React.Fragment>

              <Text
                color={Colors.Gray8}
                fontSize={FontSizes.Big}>
                  {this.state.uploadPercentage}%
              </Text>

              <Text
                color={Colors.Gray8}
                fontSize={FontSizes.Small}>
                  Uploading...
              </Text>

            </React.Fragment>
          }

          { !this.state.url && !this.state.isUploading &&

            <Image
              height={'40px'}
              src={Images.Icons.PlusCircle}
            />
          }

        </UploadButton>

      </Container>
    );
  }
}