import {
  AccountChangeEmail,
  AccountChangePassword,
  AccountEdit,
  AccountView,
  AccountPrivacyPolicy,
  AccountTermsAndConditions,
  AccountDeactivate,
  AccountDelete,
  AccountMobileMenu,
} from 'ui/account';

export const AccountRoutes = {
  accountChangeEmail: {
    component: AccountChangeEmail,
    path: '/account/email/change',
  },
  accountChangePassword:  {
    component: AccountChangePassword,
    path: '/account/password/change',
  },
  accountEdit: {
    component: AccountEdit,
    path: '/account/edit',
  },
  accountView: {
    component: AccountView,
    path: '/account/view',
  },
  accountPrivacyPolicy: {
    component: AccountPrivacyPolicy,
    path: '/account/legal/privacy',
  },
  accountTermsAndConditions: {
    component: AccountTermsAndConditions,
    path: '/account/legal/terms',
  },
  accountDeactivate: {
    component: AccountDeactivate,
    path: '/account/deactivate',
  },
  accountDelete: {
    component: AccountDelete,
    path: '/account/delete',
  },
  accountMobileMenu: {
    component: AccountMobileMenu,
    path: '/account/mobile/menu',
  }
};